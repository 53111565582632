import React from 'react'

import { httpRequest } from './api'


export default async function sql_funcation(sql) {
    const newuseritem = {
        "stmt": sql

    }
    return new Promise(function (resolve, reject) {



        try {
            const get_domintid1 = httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: newuseritem,

            });
            resolve(get_domintid1)

        } catch (error) {
            resolve(0)
        }
    })
}