import React, { Component } from 'react'
import {
    Tab, Tabs
} from '@material-ui/core'

import Ivrconfig from './ivrconfig'
import Ivrmenu from './ivrmenu'
import Ivrshudel from './ivrshudel'
import Ivrcancel from './ivrcancel'


export default class ivrmain extends Component {
    constructor(props) {
        super(props)


        this.state = {


            tabvalue: 1,
            pageno: 0

        }



    }
    handleTabChange = (event, value) => {
        this.setState({ tabvalue: value });
    };


    render() {
        return (
            <div>
                {this.state.pageno == 0 &&
                    <div>

                        <Tabs value={this.state.tabvalue} onChange={this.handleTabChange} style={{ marginTop: '.5%', background: '#F5F5F5' }}
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: "#7DAB1C", marginLeft: '2%'

                                }
                            }}

                        >
                            <Tab style={{ color: '#333333', textTransform: 'none', fontSize: '12', fontFamily: 'Roboto-Medium' }} label="Global settings" />
                            <Tab style={{ color: '#333333', textTransform: 'none', fontSize: '12', fontFamily: 'Roboto-Medium' }} label="Menu" />



                        </Tabs>

                        {
                            this.state.tabvalue == 0 &&
                            <Ivrconfig />
                        }
                        {
                            this.state.tabvalue == 1 &&
                            <Ivrmenu />
                        }





                    </div>
                }
            </div>

        )

    }
}
