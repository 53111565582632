
import React, { Component } from 'react'
import { Snackbar, SnackbarContent, Typography, Grid, Button, Checkbox, Tab, Tabs, TextField, Modal, Box, IconButton } from '@material-ui/core'
import Loader from "react-js-loader";
import Select from 'react-select'
import { PivotData } from 'react-pivottable/Utilities';
import { withStyles, styled } from "@material-ui/core/styles";
import DataTable from 'react-data-table-component';
import moment from 'moment'
import { httpRequest } from '../../../ui-utils/api'
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';

import xlsexport from 'xlsexport'
import { createTheme, ThemeProvider, MuiThemeProvider } from "@material-ui/core/styles";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { CloudDownload, PlayArrow, Stop, Close } from '@material-ui/icons';
import backendHosts from '../../../ui-utils/apiConfig';

function DistinctRecords(MYJSON, prop) {
    return MYJSON.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    })
}
const PlotlyRenderers = createPlotlyRenderers(Plot);
const defaultMaterialTheme = createTheme({
    palette: {
        primary: {
            light: '#7DAB1C',
            main: '#7DAB1C',
            dark: '#7DAB1C',
            contrastText: '#fff',

        },
        secondary: {
            light: '#7DAB1C',
            main: '#7DAB1C',
            dark: '#7DAB1C',
            contrastText: '#000',
        },
    },
});
const data1 = [
    { title: 'district', value: '' },
    { title: 'subdistrict', value: '' },
    { title: 'domain', value: 'General (Call-center agent)' },
    { title: 'transfer to agri', value: '' },


]
const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    }
};
const conditionalRowStyles = [
    {
        when: row => row.account_status == "E",
        style: {
            backgroundColor: 'green',
            color: 'white',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    },

];
const customStyles = {
    title: {
        style: {
            fontSize: '14px', // override the row height
        },
    },
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
        stripedStyle: {

            //  background: 'red'



        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#E9F4FF',
            fontSize: '14px',
            color: '#333333',
            height: '45px',
            fontFamily: 'Roboto-Medium'
        },
    },
    cells: {
        style: {
            paddingLeft: '2px', // override the cell padding for data cells
            paddingRight: '2px',


        },
    },
    highlightOnHoverStyle: {
        style: {
            color: 'red',
            background: 'blue',
            backgroundColor: 'yellow'



        },
    },

};
const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#41AB1C",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 10,
            width: 10,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#41AB1C'
        }
    },
    checked: { color: "#41AB1C", }
}))(Checkbox);
const CssTextField = styled(TextField)({
    borderRadius: '23px',
    fontFamily: 'InterRegular',
    background: '#ffff',

    '& .MuiInput-underline:after': {
        borderBottomColor: '#dddddd',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#dddddd',
        },
        '&:hover fieldset': {
            borderColor: '#dddddd',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#dddddd',
        },
    },
});
let distcolumns = []

export class DistrictWise extends Component {

    constructor(props) {
        super(props)


        this.state = {

            pivotTableUIConfig: {},
            domain: 'General (Call-center agent)',
            fdate: moment().subtract(1, 'days'),
            tdate: moment().subtract(1, 'days'),
            userdata: [],
            domaindata: [],
            islodding: false,
            myPivotTableUI: null

        }



    }

    handledobChange(date) {

        this.setState({ fdate: date })


    }
    exportdata = () => {
        console.log('sssssss===', this.state.pivotTableUIConfig)
        let fdate1 = moment(this.state.fdate).format("YYYY_MM_DD")
        var pivotData = new PivotData(this.state.pivotTableUIConfig);

        var rowKeys = pivotData.getRowKeys();
        var colKeys = pivotData.getColKeys();
        if (rowKeys.length === 0) {
            rowKeys.push([]);
        }
        if (colKeys.length === 0) {
            colKeys.push([]);
        }

        console.log(pivotData)

        var headerRow = pivotData.props.rows.map(function (r) {
            return r;
        });
        if (colKeys.length === 1 && colKeys[0].length === 0) {
            headerRow.push(this.state.pivotTableUIConfig.aggregatorName);
        } else {
            colKeys.map(function (c) {
                return headerRow.push(c.join('-'));
            });
        }



        var result = rowKeys.map(function (r) {
            var row = r.map(function (x) {
                return x;
            });
            colKeys.map(function (c) {
                var v = pivotData.getAggregator(r, c).value();
                row.push(v ? v : '');
            });
            return row;
        });



        result.unshift(headerRow);

        var FinalResult = (result.map(function (r) {
            return r.join(',');
        }).join('\n'));

        const element = document.createElement("a");
        const file = new Blob([FinalResult], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = 'Distwise' + fdate1 + '_' + this.state.domain + '.xls';
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click()


        /*   }
           else {
               alert("No Selections Made")
           }*/
    }
    ExportDistWise(array) {
        const link = document.createElement('a');

        let fdate = moment(this.state.fdate).format("YYYY_MM_DD")
        const filename = 'Distwise' + fdate + '_' + this.state.domain + '.xls';
        const csv = new xlsexport(array, filename)
        csv.exportToXLS(filename)

    }

    handledobChange1(date) {

        this.setState({ tdate: date })


    }
    GetDomain = async () => {
        let sql = ""



        sql = "select uid,name from   pad_domains where name<>'lcc-default' order by name"
        console.log(sql)
        const domainitem = {
            "stmt": sql

        }
        this.setState({ islodding: true })
        try {
            const domaindata = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: domainitem,

            });

            this.setState({ islodding: false })
            console.log('domaindata.msg ', domaindata.msg)

            if (domaindata.success == true) {

                // this.setState({ domaindata: domaindata.msg, domain: 'lcc-default' })
            }

            let domaindata1 = {

            }
            domaindata1 = domaindata.msg.map(data => {
                return { label: data.name, value: data.name };
            });

            domaindata1.push({
                label: 'General (Call-center agent)',
                value: 'General (Call-center agent)'
            }
            )
            this.setState({ domaindata: domaindata1 })
        } catch (error) {

            this.setState({ islodding: false })
        }


    }
    componentDidMount = async () => {

        this.GetDomain()
        //   this.GetDistWise()
        //   this.NewGetDistWise()
    }


    handleDomain = (e) => {


        let label = e.target.value;
        this.setState({ domain: label, userdata: [] });

    }


    NewGetDistWise = async () => {
        let fdate = moment(this.state.fdate).format("YYYY-MM-DD")
        let tdate = moment(this.state.tdate).format("YYYY-MM-DD")
        let rdata = []
        let totobj = {}




        this.setState({ islodding: true })
        let item = {}
        if (this.state.domain === "fishery") {
            item = {
                "datetime_from": fdate + " 09:00:00",
                "datetime_to": tdate + " 20:00:00",
                "workinghrs_from": "09:00:00",
                "workinghrs_to": "20:00:00",
                "data": true,
                "field_last_event": false,
                "direction": [
                    "Inbound",


                ],
                "domains": [

                    "3000000000",

                ]
            }

        }
        if (this.state.domain === "agriculture") {
            item = {
                "datetime_from": fdate + " 09:00:00",
                "datetime_to": tdate + " 20:00:00",
                "workinghrs_from": "09:00:00",
                "workinghrs_to": "20:00:00",
                "data": true,
                "field_last_event": false,
                "direction": [
                    "Inbound",


                ],
                "domains": [

                    "1000000000",

                ]
            }

        }
        if (this.state.domain === "animal_husbandry") {
            item = {
                "datetime_from": fdate + " 09:00:00",
                "datetime_to": tdate + " 20:00:00",
                "workinghrs_from": "09:00:00",
                "workinghrs_to": "20:00:00",
                "data": true,
                "field_last_event": false,
                "direction": [
                    "Inbound",


                ],
                "domains": [

                    "2000000000",

                ]
            }

        }
        if (this.state.domain === "General (Call-center agent)") {
            item = {
                "datetime_from": fdate + " 09:00:00",
                "datetime_to": tdate + " 20:00:00",
                "workinghrs_from": "09:00:00",
                "workinghrs_to": "20:00:00",
                "data": true,
                "field_last_event": false,
                "direction": [
                    "Inbound",


                ],
                "domains": [
                    "6742621800",
                    "6742611800",
                    "9000000000",

                ]
            }

        }

        try {

            const roledata = await httpRequest({
                endPoint: `api/lcc/reports/RawDump`,
                method: "post",
                instance: "instanceOne",
                requestBody: item,

            });

            this.setState({ islodding: false })




            rdata = roledata.msg.data

            let maindata = []

            const re = rdata.map(data => {

                let dist = "Unknown"

                if (data.district !== null) {

                    dist = data.district
                }

                if (data.district === "") {

                    dist = "Unknown"
                }

                let disp = ""
                if (data.callstatus === "Other") {
                    disp = "other"
                } else { disp = data.callstatus }

                if (data.agentanswered !== "0") {
                    maindata.push({
                        District: dist,
                        Tag: data.tag1,
                        Dispo: disp
                    })
                }
            })
            maindata.sort()
            console.log(maindata)




            let exdata1 = maindata
            console.log('exdata1**', exdata1)

            const exdata = []

            exdata.push(

                {
                    data: maindata,
                    rows: ['District'],
                    cols: ['Dispo', 'Tag'],

                    rendererName: 'Table',
                    rowOrder: 'key_a_to_z',
                    colOrder: 'key_a_to_z',
                    menuLimit: 500
                }
            )

            console.log('exdata', exdata[0])
            this.setState({ islodding: false, userdata: maindata, pivotTableUIConfig: exdata[0] })



        } catch (error) {
            this.setState({ islodding: false })

        }

    }

    onChangePivotTableUI(s) {
        console.log("Ravi Change**", s)
        this.setState({ pivotTableUIConfig: s })
    }
    CallRep(e) {
        this.setState({ domain: e.label, userdata: [] });
        if (e.label === "agriculture") {


        }
        //  this.GetDistWise()

    }

    render() {
        const { data, ...pivotTableUIConfig } = this.state.pivotTableUIConfig;
        return (
            <div style={{ marginTop: '0%' }}>
                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '1%', marginTop: '2%', }}>
                    <Grid style={{ width: '22%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Typography style={{ marginRight: '1%', color: '#999999', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>From</Typography>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider

                                style={{ height: '20px', }} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    error={false}
                                    helperText={null}

                                    value={this.state.fdate}
                                    onChange={date => { this.handledobChange(date) }}
                                    format="yyyy/MM/dd"

                                    InputProps={{


                                        style: {

                                            fontSize: 14,
                                            height: 38,
                                            color: '#999999',

                                            background: '#ffffff'

                                        }
                                    }}
                                    inputVariant="outlined"



                                    KeyboardButtonProps={{
                                        style: { marginLeft: -24 }
                                    }}

                                />
                            </MuiPickersUtilsProvider>
                        </ThemeProvider>
                    </Grid>

                    <Grid style={{ width: '22%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Typography style={{ marginRight: '1%', color: '#999999', fontSize: '14px', marginLeft: '1%', fontFamily: 'Roboto-Regular' }}>To</Typography>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider

                                style={{ height: '20px' }} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    error={false}
                                    helperText={null}

                                    value={this.state.tdate}
                                    onChange={date => { this.handledobChange1(date) }}
                                    format="yyyy/MM/dd"

                                    InputProps={{


                                        style: {

                                            fontSize: 14,
                                            height: 38,
                                            color: '#999999',

                                            background: '#ffffff'

                                        }
                                    }}
                                    inputVariant="outlined"



                                    KeyboardButtonProps={{
                                        style: { marginLeft: -24 }
                                    }}

                                />
                            </MuiPickersUtilsProvider>
                        </ThemeProvider>
                    </Grid>
                    <Grid style={{ width: '22%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Typography style={{ marginRight: '1%', color: '#999999', fontSize: '14px', marginLeft: '1%', fontFamily: 'Roboto-Regular' }}></Typography>Domain

                        <Grid style={{ width: '300px' }}>
                            <Select ref={(input) => this.menu = input} options={this.state.domaindata} placeholder="Select Domain"

                                inputProps={{
                                    style: { background: '#ffffff', fontSize: '14px', fontFamily: "Roboto-Regular", height: '20px', width: '300px' },


                                }}
                                defaultValue={this.state.domain.length > 0 &&
                                    { label: this.state.domain, value: 0 }
                                }
                                onChange={e => {
                                    this.CallRep(e)
                                }}
                            />

                        </Grid>

                    </Grid>
                    <Grid style={{ width: '15%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Button



                            variant="contained"
                            size="small"

                            style={{
                                color: "#ffffff",
                                fontFamily: "Roboto-Medium",
                                borderRadius: "4px",
                                backgroundColor: "#7DAB1C",
                                textTransform: 'none',
                                padding: '1.5%',
                                fontSize: "14px",
                                width: '125px',
                                height: '32px',
                                marginLeft: '5%'

                            }}

                            onClick={() => { this.NewGetDistWise() }}


                        >

                            Submit
                        </Button>
                    </Grid>
                    <Grid style={{ width: '15%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Button



                            variant="contained"
                            size="small"

                            style={{
                                color: "#ffffff",
                                fontFamily: "Roboto-Medium",
                                borderRadius: "4px",
                                backgroundColor: "#7DAB1C",
                                textTransform: 'none',
                                padding: '1.5%',
                                fontSize: "14px",
                                width: '125px',
                                height: '32px',
                                marginLeft: '5%'

                            }}

                            onClick={() => {
                                //this.ExportDistWise(this.state.userdata)
                                this.exportdata()
                            }}


                        >

                            Export
                        </Button>
                    </Grid>

                </Grid>

                <Grid style={{ height: 'auto', overflow: 'auto', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', }}>



                    <PivotTableUI
                        data={this.state.userdata}
                        rows={["District"]}
                        cols={["Dispo", "Tag"]}
                        ref={this.myPivotTableUI}
                        onChange={s =>
                            this.onChangePivotTableUI(s)
                        }
                        renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                        {...pivotTableUIConfig}
                    />

                </Grid>

                <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',
                            display: 'flex',
                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >
                        <Loader type="spinner-circle" bgColor={"#7DAB1C"} color={'#ffc31b'} size={75} />
                    </Box>

                </Modal>
            </div >
        )
    }
}
function TimeDiff(timestamp1, timestamp2) {
    var timeStart = new Date("01/01/2007 " + timestamp1);
    var timeEnd = new Date("01/01/2007 " + timestamp2);
    var diff_result = 0
    if (timestamp2.length > 3) {
        var difference = timeEnd - timeStart;
        diff_result = new Date(difference);
        diff_result = moment(diff_result).format("hh:mm:ss")
    }

    console.log("***", diff_result)
    return diff_result;
}
let rdata = []
export class CallWise extends Component {

    constructor(props) {
        super(props)


        this.state = {


            domain: 'General (Call-center agent)',
            fdate: moment().subtract(1, 'days'),
            tdate: moment().subtract(1, 'days'),
            userdata: [],
            domaindata: [],
            userdata1: [],

            stext: '',
            islodding: false,
            palybut_type: 0,
            stopcount: 0
        }



    }

    handledobChange(date) {

        this.setState({ fdate: date })


    }
    handledobChange1(date) {

        this.setState({ tdate: date })


    }

    componentDidMount = async () => {
        this.GetDomain()
        let fdate = moment(this.state.fdate).format("YYYY-MM-DD")
        let tdate = moment(this.state.tdate).format("YYYY-MM-DD")

        this.setState({ islodding: true })
        let item = {}
        item = {
            "datetime_from": fdate + " 09:00:00",
            "datetime_to": tdate + " 20:00:00",
            "workinghrs_from": "09:00:00",
            "workinghrs_to": "20:00:00",
            "data": true,
            "field_last_event": false,
            "direction": [
                "Inbound",


            ],
            "domains": [
                "6742621800",
                "6742621808",
                "9000000000",
                "1000000000",
                "2000000000",
                "3000000000"
            ]
        }



        try {

            const roledata = await httpRequest({
                endPoint: `api/lcc/reports/RawDump`,
                method: "post",
                instance: "instanceOne",
                requestBody: item,

            });

            this.setState({ islodding: false })




            rdata = roledata.msg.data
            console.log(rdata)
            let maindata = []

            const re = rdata.map(data => {
                if (data.dialledto === "6742621800" || data.dialledto === "6742621808" || data.dialledto === "9000000000") {
                    if (data.direction !== "short_call") {
                        maindata.push(
                            {

                                call_date: data.callrecived_datetime.substring(0, 19), //+ " : " + moment(data.time1).add(330, 'minutes').format("hh:mm:ss"),
                                farmer_ph_no: data.farmer_no,
                                call_id: data.agentanswered,
                                operator_name: data.operator,
                                callstatus: data.callstatus,
                                direction: data.direction,
                                main_cateogry: data.tag1 === null ? "" : data.tag1,
                                sub_cat1: data.tag2 === null ? "" : data.tag2,
                                sub_cat2: data.tag3 === null ? "" : data.tag3,
                                sub_cat3: data.tag4 === null ? "" : data.tag4,
                                sub_cat4: data.tag5 === null ? "" : data.tag5,
                                call_duration: (data.callduration / 60).toFixed(2),
                                record: data.recordingurl,
                                uid: data.id,
                                uuid: data.uuid


                            })

                    }
                }
            })

            this.setState({
                userdata: maindata, userdata1: maindata
            })


            this.setState({ islodding: false })





        } catch (error) {
            this.setState({ islodding: false })
        }

    }



    GetCallWise = async () => {

        let fdate = moment(this.state.fdate).format("YYYY-MM-DD")
        let tdate = moment(this.state.tdate).format("YYYY-MM-DD")

        this.setState({ islodding: true })
        console.log(this.state.domain)
        let item = {}
        item = {
            "datetime_from": fdate + " 09:00:00",
            "datetime_to": tdate + " 20:00:00",
            "workinghrs_from": "09:00:00",
            "workinghrs_to": "20:00:00",
            "data": true,
            "field_last_event": false,
            "direction": [
                "Inbound",


            ],
            "domains": [
                "6742621800",
                "6742621808",
                "9000000000",
                "1000000000",
                "2000000000",
                "3000000000"
            ]
        }


        if (this.state.domain === "Outbound") {
            item = {
                "datetime_from": fdate + " 09:00:00",
                "datetime_to": tdate + " 20:00:00",
                "workinghrs_from": "09:00:00",
                "workinghrs_to": "20:00:00",
                "data": true,
                "field_last_event": false,
                "direction": [
                    "Outbound",


                ],
                "domains": [
                    "6742621800",
                    "6742621808",
                    "9000000000",
                    "1000000000",
                    "2000000000",
                    "3000000000"
                ]
            }
        }

        this.setState({ islodding: true })


        try {

            const roledata = await httpRequest({
                endPoint: `api/lcc/reports/RawDump`,
                method: "post",
                instance: "instanceOne",
                requestBody: item,

            });

            this.setState({ islodding: false })




            rdata = roledata.msg.data
            console.log(rdata)
            let maindata = []

            const re = rdata.map(data => {



                if (this.state.domain === "Outbound") {
                    if (data.dialledto === "6742621800") {
                        maindata.push(
                            {


                                call_date: data.callrecived_datetime.substring(0, 19), //+ " : " + moment(data.time1).add(330, 'minutes').format("hh:mm:ss"),


                                farmer_ph_no: data.farmer_no,
                                call_id: data.agentanswered,
                                operator_name: data.operator === null ? "" : data.operator,
                                callstatus: data.callstatus,
                                direction: data.direction,
                                main_cateogry: data.tag1 === null ? "" : data.tag1,
                                sub_cat1: data.tag2 === null ? "" : data.tag2,
                                sub_cat2: data.tag3 === null ? "" : data.tag3,
                                sub_cat3: data.tag4 === null ? "" : data.tag4,
                                sub_cat4: data.tag5 === null ? "" : data.tag5,
                                call_duration: (data.callduration / 60).toFixed(2),
                                record: data.recordingurl,
                                uid: data.id,
                                uuid: data.uuid


                            })
                    }
                }
                if (this.state.domain === "General (Call-center agent)") {
                    if (data.dialledto === "6742621800" || data.dialledto === "6742621808" || data.dialledto === "9000000000") {
                        if (data.direction !== "short_call") {

                            maindata.push(
                                {


                                    call_date: data.callrecived_datetime.substring(0, 19), //+ " : " + moment(data.time1).add(330, 'minutes').format("hh:mm:ss"),


                                    farmer_ph_no: data.farmer_no,
                                    call_id: data.agentanswered,
                                    operator_name: data.operator === null ? "" : data.operator,
                                    callstatus: data.callstatus,
                                    direction: data.direction,
                                    main_cateogry: data.tag1 === null ? "" : data.tag1,
                                    sub_cat1: data.tag2 === null ? "" : data.tag2,
                                    sub_cat2: data.tag3 === null ? "" : data.tag3,
                                    sub_cat3: data.tag4 === null ? "" : data.tag4,
                                    sub_cat4: data.tag5 === null ? "" : data.tag5,
                                    call_duration: (data.callduration / 60).toFixed(2),
                                    record: data.recordingurl,
                                    uid: data.id,
                                    uuid: data.uuid


                                })
                        }
                    }
                }


                if (this.state.domain === "agriculture") {

                    if (data.dialledto === "1000000000") {
                        if (data.direction !== "short_call") {
                            maindata.push(
                                {


                                    call_date: data.callrecived_datetime.substring(0, 19), //+ " : " + moment(data.time1).add(330, 'minutes').format("hh:mm:ss"),


                                    farmer_ph_no: data.farmer_no,
                                    call_id: data.agentanswered,
                                    operator_name: data.operator === null ? "" : data.operator,
                                    callstatus: data.callstatus,
                                    direction: data.direction,
                                    main_cateogry: data.tag1 === null ? "" : data.tag1,
                                    sub_cat1: data.tag2 === null ? "" : data.tag2,
                                    sub_cat2: data.tag3 === null ? "" : data.tag3,
                                    sub_cat3: data.tag4 === null ? "" : data.tag4,
                                    sub_cat4: data.tag5 === null ? "" : data.tag5,
                                    call_duration: (data.callduration / 60).toFixed(2),
                                    record: data.recordingurl,
                                    uid: data.id,
                                    uuid: data.uuid


                                })
                        }
                    }


                }


                if (this.state.domain === "animal_husbandry") {

                    if (data.dialledto === "2000000000") {
                        if (data.direction !== "short_call") {
                            maindata.push(
                                {


                                    call_date: data.callrecived_datetime.substring(0, 19), //+ " : " + moment(data.time1).add(330, 'minutes').format("hh:mm:ss"),


                                    farmer_ph_no: data.farmer_no,
                                    call_id: data.agentanswered,
                                    operator_name: data.operator === null ? "" : data.operator,
                                    callstatus: data.callstatus,
                                    direction: data.direction,
                                    main_cateogry: data.tag1 === null ? "" : data.tag1,
                                    sub_cat1: data.tag2 === null ? "" : data.tag2,
                                    sub_cat2: data.tag3 === null ? "" : data.tag3,
                                    sub_cat3: data.tag4 === null ? "" : data.tag4,
                                    sub_cat4: data.tag5 === null ? "" : data.tag5,
                                    call_duration: (data.callduration / 60).toFixed(2),
                                    record: data.recordingurl,
                                    uid: data.id,
                                    uuid: data.uuid


                                })
                        }
                    }


                }


                if (this.state.domain === "fishery") {

                    if (data.dialledto === "3000000000") {
                        if (data.direction !== "short_call") {
                            maindata.push(
                                {


                                    call_date: data.callrecived_datetime.substring(0, 19), //+ " : " + moment(data.time1).add(330, 'minutes').format("hh:mm:ss"),


                                    farmer_ph_no: data.farmer_no,
                                    call_id: data.agentanswered,
                                    operator_name: data.operator === null ? "" : data.operator,
                                    callstatus: data.callstatus,
                                    direction: data.direction,
                                    main_cateogry: data.tag1 === null ? "" : data.tag1,
                                    sub_cat1: data.tag2 === null ? "" : data.tag2,
                                    sub_cat2: data.tag3 === null ? "" : data.tag3,
                                    sub_cat3: data.tag4 === null ? "" : data.tag4,
                                    sub_cat4: data.tag5 === null ? "" : data.tag5,
                                    call_duration: (data.callduration / 60).toFixed(2),
                                    record: data.recordingurl,
                                    uid: data.id,
                                    uuid: data.uuid


                                })
                        }
                    }


                }



            })



            this.setState({
                userdata: maindata, userdata1: maindata
            })


            this.setState({ islodding: false })





        } catch (error) {
            this.setState({ islodding: false })

        }
    }



    handleDomain = (e) => {


        let label = e.label
        this.state.domain = label


        let maindata = []

        const re = rdata.map(data => {
            if (label === "animal_husbandry") {
                if (data.dialledto === "2000000000") {
                    if (data.direction !== "short_call") {
                        maindata.push(
                            {


                                call_date: data.callrecived_datetime, //+ " : " + moment(data.time1).add(330, 'minutes').format("hh:mm:ss"),


                                farmer_ph_no: data.farmer_no,
                                call_id: data.agentanswered,
                                operator_name: data.operator === null ? "" : data.operator,
                                callstatus: data.callstatus,
                                direction: data.direction,
                                main_cateogry: data.tag1 === null ? "" : data.tag1,
                                sub_cat1: data.tag2 === null ? "" : data.tag2,
                                sub_cat2: data.tag3 === null ? "" : data.tag3,
                                sub_cat3: data.tag4 === null ? "" : data.tag4,
                                sub_cat4: data.tag5 === null ? "" : data.tag5,
                                call_duration: (data.callduration / 60).toFixed(2),
                                record: data.recordingurl,
                                uid: data.id,
                                uuid: data.uuid


                            })

                    }
                }
            }
            if (label === "fishery") {
                if (data.dialledto === "3000000000") {
                    if (data.direction !== "short_call") {
                        maindata.push(
                            {


                                call_date: data.callrecived_datetime, //+ " : " + moment(data.time1).add(330, 'minutes').format("hh:mm:ss"),


                                farmer_ph_no: data.farmer_no,
                                call_id: data.agentanswered,
                                operator_name: data.operator === null ? "" : data.operator,
                                callstatus: data.callstatus,
                                direction: data.direction,
                                main_cateogry: data.tag1 === null ? "" : data.tag1,
                                sub_cat1: data.tag2 === null ? "" : data.tag2,
                                sub_cat2: data.tag3 === null ? "" : data.tag3,
                                sub_cat3: data.tag4 === null ? "" : data.tag4,
                                sub_cat4: data.tag5 === null ? "" : data.tag5,
                                call_duration: (data.callduration / 60).toFixed(2),
                                record: data.recordingurl,
                                uid: data.id,
                                uuid: data.uuid


                            })

                    }
                }
            }
            if (label === "agriculture") {
                if (data.dialledto === "1000000000") {
                    if (data.direction !== "short_call") {

                        maindata.push(
                            {


                                call_date: data.callrecived_datetime, //+ " : " + moment(data.time1).add(330, 'minutes').format("hh:mm:ss"),


                                farmer_ph_no: data.farmer_no,
                                call_id: data.agentanswered,
                                operator_name: data.operator === null ? "" : data.operator,
                                callstatus: data.callstatus,
                                direction: data.direction,
                                main_cateogry: data.tag1 === null ? "" : data.tag1,
                                sub_cat1: data.tag2 === null ? "" : data.tag2,
                                sub_cat2: data.tag3 === null ? "" : data.tag3,
                                sub_cat3: data.tag4 === null ? "" : data.tag4,
                                sub_cat4: data.tag5 === null ? "" : data.tag5,
                                call_duration: (data.callduration / 60).toFixed(2),
                                record: data.recordingurl,
                                uid: data.id,
                                uuid: data.uuid


                            })

                    }
                }
            }
            if (label === "General (Call-center agent)") {
                if (data.dialledto === "6742621800" || data.dialledto === "6742621808" || data.dialledto === "9000000000") {
                    if (data.direction !== "short_call") {
                        maindata.push(
                            {


                                call_date: data.callrecived_datetime.substring(0, 18), //+ " : " + moment(data.time1).add(330, 'minutes').format("hh:mm:ss"),


                                farmer_ph_no: data.farmer_no,
                                call_id: data.agentanswered,
                                operator_name: data.operator === null ? "" : data.operator,
                                callstatus: data.callstatus,
                                direction: data.direction,
                                main_cateogry: data.tag1 === null ? "" : data.tag1,
                                sub_cat1: data.tag2 === null ? "" : data.tag2,
                                sub_cat2: data.tag3 === null ? "" : data.tag3,
                                sub_cat3: data.tag4 === null ? "" : data.tag4,
                                sub_cat4: data.tag5 === null ? "" : data.tag5,
                                call_duration: (data.callduration / 60).toFixed(2),
                                record: data.recordingurl,
                                uid: data.id,
                                uuid: data.uuid


                            })

                    }
                }
            }
        })

        this.setState({
            userdata: maindata, userdata1: maindata
        })
    }


    ExportCallWise(array) {
        const link = document.createElement('a');

        let fdate = moment(this.state.fdate).format("YYYY_MM_DD")
        const filename = 'callwise' + fdate + '.xls';
        const csv = new xlsexport(array, filename)
        csv.exportToXLS(filename)

    }
    GetDomain = async () => {
        let sql = ""



        sql = "select uid,name from   pad_domains where name<>'lcc-default' order by name"

        console.log(sql)
        const domainitem = {
            "stmt": sql

        }
        try {
            const domaindata = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: domainitem,

            });

            let domaindata1 = domaindata.msg.map(data => {
                return { label: data.name, value: data.name };
            });

            domaindata1.push({
                label: 'General (Call-center agent)',
                value: 'General (Call-center agent)'
            })

            this.setState({ domaindata: domaindata1 })

        } catch (error) {

        }


    }
    MakeFilterCallWise(e) {

        this.setState({ stext: e.target.value })



        const sresult = this.state.userdata1.filter(data => {
            if (data.call_id)
                return (
                    data.farmer_ph_no.match(e.target.value) ||
                    data.call_id.match(e.target.value) ||
                    data.operator_name.match(e.target.value) ||
                    data.callstatus.match(e.target.value) ||
                    data.direction.match(e.target.value) ||
                    data.main_cateogry.match(e.target.value) ||
                    data.sub_cat1.match(e.target.value) ||
                    data.sub_cat2.match(e.target.value) ||
                    data.sub_cat3.match(e.target.value) ||
                    data.sub_cat4.match(e.target.value) ||
                    data.call_duration.match(e.target.value)

                )
        })

        if (e.target.value.length > 0) {
            this.setState({ userdata: sresult })
        } else {
            this.setState({ userdata: this.state.userdata1 })
        }


    }


    AudioDownload = async (row, palybut_type) => {

        const link = document.createElement('a')
        let audio_path = backendHosts.CRM + '/api/lcc/PlayBack/LCC-recording/' + row.record
        //link.href = 
        let a = localStorage.getItem('lcc_token') || '{}'
        let b = JSON.parse(a)
        let livetocken = b.token

        const result = await fetch(audio_path, {
            headers: {
                Authorization: livetocken
            }
        });
        const blob = await result.blob();
        if (blob) {


            let objectUrl = window.URL.createObjectURL(blob);
            //   ringtone.parentElement.load();
            link.href = objectUrl;
            link.download = row.record
            link.click();
            window.URL.revokeObjectURL(objectUrl);
        }

        //link.href = "https://crm.amakrushi.in/api/lcc/PlayBack/IVR_Audio/IVRAudio_Odia_WhatsApp_Audio_2023-07-13_at_16.37.40_(mp3cut.net).wav"
        //document.body.appendChild(link);
        //link.click();
        //  document.body.removeChild(link);

    }

    palyAudio = async (row, palybut_type) => {
        let ringtone = document.getElementById("ringtone");
        console.log('row.uid', row.uid)
        if (palybut_type == 0) {
            let a = localStorage.getItem('lcc_token') || '{}'
            let b = JSON.parse(a)
            let livetocken = b.token
            this.setState({ palybut_type: 1, stopcount: row.uid })
            let audio_path = backendHosts.CRM + '/api/lcc/PlayBack/LCC-recording/' + row.record

            const result = await fetch(audio_path, {
                headers: {
                    Authorization: livetocken
                }
            });
            console.log(audio_path)
            const blob = await result.blob();
            if (blob) {
                console.log("Auido okk")
                ringtone.src = URL.createObjectURL(blob);
                //   ringtone.parentElement.load();

            }
            //ringtone.src = audio_path
            ringtone.play()
        } else {
            ringtone.pause()
            this.setState({ palybut_type: 0, stopcount: 0 })

        }


    }
    render() {
        const columns = [


            {
                name: 'Action*',

                sortable: true,
                width: '60px',
                cell: row =>

                    <IconButton


                        onClick={() => this.palyAudio(row, this.state.palybut_type)}
                    >

                        {


                            this.state.palybut_type == 0
                                ?
                                <PlayArrow style={{ color: '#0083CA', fontSize: '18px' }} /> :
                                this.state.palybut_type == 1 && row.uid == this.state.stopcount ?
                                    <Stop style={{ color: 'red', fontSize: '18px' }} /> : <PlayArrow style={{ color: '#0083CA', fontSize: '18px' }} />


                        }




                    </IconButton>







            },


            {
                name: 'DownLoad',
                width: '60px',
                sortable: true,
                cell: row =>

                    <IconButton

                        onClick={() => this.AudioDownload(row, this.state.palybut_type)}

                    >


                        <CloudDownload style={{ color: '#0083CA', fontSize: '18px' }} />



                    </IconButton>







            },


            {
                name: 'Date',
                selector: 'call_date',
                sortable: true,
                width: '160px',
                cell: row =>
                    row.call_date
            },
            {
                name: 'Duration',
                selector: 'call_duration',
                width: '80px',
                sortable: true,
                cell: row =>
                    row.call_duration
            },






            {
                name: 'Farmer Ph. No.',
                selector: 'farmer_ph_no',
                sortable: true,
                cell: row =>
                    row.farmer_ph_no
            },



            {
                name: 'Agent ID',

                selector: 'call_id',
                sortable: true,
                selector: row => row.call_id,

            },

            {
                name: 'Operator Name',
                selector: 'operator_name',
                sortable: true,
                cell: row =>
                    row.operator_name
            },

            {
                name: 'Call Status',
                selector: 'callstatus',
                sortable: true,
                cell: row =>
                    row.callstatus
            },


            {
                name: 'Main cateogry',
                selector: 'main_cateogry',
                sortable: true,
                cell: row =>
                    row.main_cateogry
            },
            {
                name: 'Sub Cat-1',
                selector: 'sub_cat1',
                sortable: true,
                cell: row =>
                    row.sub_cat1
            },
            {
                name: 'Sub Cat-2',
                selector: 'sub_cat2',
                sortable: true,
                cell: row =>
                    row.sub_cat2
            },
            {
                name: 'Sub Cat-3',
                selector: 'sub_cat3',
                sortable: true,
                cell: row =>
                    row.sub_cat3
            },












        ];
        return (
            <div style={{ marginTop: '0%' }}>
                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '1%', marginTop: '2%', }}>
                    <Grid style={{ width: '22%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Typography style={{ marginRight: '1%', color: '#999999', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>From</Typography>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider

                                style={{ height: '20px', }} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    error={false}
                                    helperText={null}

                                    value={this.state.fdate}
                                    onChange={date => { this.handledobChange(date) }}
                                    format="yyyy/MM/dd"

                                    InputProps={{


                                        style: {

                                            fontSize: 14,
                                            height: 38,
                                            color: '#999999',

                                            background: '#ffffff'

                                        }
                                    }}
                                    inputVariant="outlined"



                                    KeyboardButtonProps={{
                                        style: { marginLeft: -24 }
                                    }}

                                />
                            </MuiPickersUtilsProvider>
                        </ThemeProvider>
                    </Grid>

                    <Grid style={{ width: '22%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Typography style={{ marginRight: '1%', color: '#999999', fontSize: '14px', marginLeft: '1%', fontFamily: 'Roboto-Regular' }}>To</Typography>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider

                                style={{ height: '20px' }} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    error={false}
                                    helperText={null}

                                    value={this.state.tdate}
                                    onChange={date => { this.handledobChange1(date) }}
                                    format="yyyy/MM/dd"

                                    InputProps={{


                                        style: {

                                            fontSize: 14,
                                            height: 38,
                                            color: '#999999',

                                            background: '#ffffff'

                                        }
                                    }}
                                    inputVariant="outlined"



                                    KeyboardButtonProps={{
                                        style: { marginLeft: -24 }
                                    }}

                                />
                            </MuiPickersUtilsProvider>
                        </ThemeProvider>
                    </Grid>
                    <Grid style={{ width: '22%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Typography style={{ marginRight: '1%', color: '#999999', fontSize: '14px', marginLeft: '1%', fontFamily: 'Roboto-Regular' }}></Typography>Agent
                        <Grid style={{ width: '300px' }}>
                            <Select ref={(input) => this.menu = input} options={this.state.domaindata} placeholder="Select Domain"

                                inputProps={{
                                    style: { background: '#ffffff', fontSize: '14px', fontFamily: "Roboto-Regular", height: '20px', width: '300px' },


                                }}
                                defaultValue={this.state.domain.length > 0 &&
                                    { label: this.state.domain, value: 0 }
                                }
                                onChange={e => {
                                    //this.setState({ domain: e.label, userdata: [] });

                                    this.handleDomain(e)
                                }}
                            />

                        </Grid>

                    </Grid>
                    <Grid style={{ width: '15%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Button



                            variant="contained"
                            size="small"

                            style={{
                                color: "#ffffff",
                                fontFamily: "Roboto-Medium",
                                borderRadius: "4px",
                                backgroundColor: "#7DAB1C",
                                textTransform: 'none',
                                padding: '1.5%',
                                fontSize: "14px",
                                width: '125px',
                                height: '32px',
                                marginLeft: '5%'

                            }}


                            onClick={() => { this.GetCallWise() }}



                        >

                            Submit
                        </Button>
                    </Grid>
                    <Grid style={{ width: '15%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Button



                            variant="contained"
                            size="small"

                            style={{
                                color: "#ffffff",
                                fontFamily: "Roboto-Medium",
                                borderRadius: "4px",
                                backgroundColor: "#7DAB1C",
                                textTransform: 'none',
                                padding: '1.5%',
                                fontSize: "14px",
                                width: '125px',
                                height: '32px',
                                marginLeft: '5%'

                            }}

                            onClick={() => {
                                this.ExportCallWise(this.state.userdata)
                            }}


                        >

                            Export
                        </Button>
                    </Grid>

                </Grid>

                <Grid style={{ height: 'auto', overflow: 'auto', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', }}>


                    <DataTable

                        columns={columns}
                        data={this.state.userdata}
                        pagination
                        striped
                        responsive
                        paginationPerPage='100'
                        paginationRowsPerPageOptions={[100, 150, 200, 250]}

                        defaultSortFieldId
                        dense

                        customStyles={customStyles}
                        highlightOnHover
                        subHeader
                        subHeaderComponent={<CssTextField
                            autoFocus={true}
                            value={this.state.stext}
                            placeholder="Search"
                            size="small"
                            variant="outlined"
                            onChange={e =>
                                this.MakeFilterCallWise(e)
                            }
                        />}
                        subHeaderAlign='left'

                        conditionalRowStyles={conditionalRowStyles}
                    />


                </Grid>
                <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',
                            display: 'flex',
                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >
                        <Loader type="spinner-circle" bgColor={"#7DAB1C"} color={'#ffc31b'} size={75} />
                    </Box>

                </Modal>

            </div >
        )
    }
}

export class Attends extends Component {

    constructor(props) {
        super(props)


        this.state = {
            mysnack: snackeror.mysucc,
            showerr: false,
            errorMsg: '',
            domain: 'General (Call-center agent)',
            fdate: moment().subtract(1, 'days'),
            tdate: moment().subtract(1, 'days'),
            userdata: [],
            domaindata: [],
            userdata1: [],
            stext: '',
            attcount: 0,
            islodding: false
        }



    }

    handledobChange(date) {

        this.setState({ fdate: date })


    }
    handledobChange1(date) {

        this.setState({ tdate: date })


    }
    closeContactModal() {
        this.setState({ showerr: false })
    }
    componentDidMount = async () => {
        this.GetDomain()
    }

    GetDomain = async () => {
        let sql = ""



        sql = "select uid,name from  pad_domains order by name"
        console.log(sql)
        const domainitem = {
            "stmt": sql

        }
        this.setState({ islodding: true })
        try {
            const domaindata = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: domainitem,

            });
            this.setState({ islodding: false })

            console.log('domaindata.msg ', domaindata.msg)

            if (domaindata.success == true) {

                // this.setState({ domaindata: domaindata.msg, domain: 'lcc-default' })
            }

            const domaindata1 = domaindata.msg.map(data => {
                return { label: data.name, value: data.name };
            });

            this.setState({ domaindata: domaindata1 })
        } catch (error) {

        }


    }


    diff_minutes(dt2, dt1) {

        var diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= 60;
        return Math.abs(Math.round(diff));

    }

    timeConvert(n) {
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        if (rminutes <= 9) {
            rminutes = "0" + rminutes
        }

        if (rhours <= 9) {
            rhours = "0" + rhours
        }
        return rhours + ":" + rminutes + ":00";
    }
    NewAttRep = async () => {


        this.setState({ islodding: true })
        let fdate = moment(this.state.fdate).format("YYYY-MM-DD") + " 00:00:00"
        let tdate = moment(this.state.tdate).format("YYYY-MM-DD") + " 23:59:59"
        let item = {
            datetime_from: fdate,
            datetime_to: tdate,
            userlist: []
        }
        try {
            const roledata = await httpRequest({
                endPoint: `api/user/attendance`,
                method: "post",
                instance: "instanceOne",
                requestBody: item,

            });

            this.setState({ islodding: false })

            console.log("attt new data", roledata.msg)
            if (roledata.success == true) {

                let rdata = roledata.msg


                let re = rdata.map(data => {



                    return {
                        date: data.date,
                        aid: data.aid,
                        name: data.name,
                        domain: data.domain,
                        login: data.login.substring(11, 19),
                        logout: data.logout.substring(11, 19),
                        break_duration_hms: data.break_duration_hms.substring(0, 7),
                        calls_submit_duration_hms: data.calls_submit_duration_hms.substring(0, 7),
                        calls_count: data.calls_count,
                        avg_call: data.calls_count > 0 ? new Date(((data.calls_duration + data.calls_submit_duration) / data.calls_count) * 1000).toISOString().slice(11, 19) : "0"

                    };
                });



                this.setState({ userdata: re, userdata1: re, attcount: rdata.length })

            }






        } catch (error) {



            this.setState({ islodding: false })
            //  this.setState({ showerr: true, errorMsg: "Internal Server Error", mysnack: snackeror.myerror })

        }


    }


    handleDomain = (e) => {


        let label = e.target.value;
        this.setState({ domain: label });
    }


    ExportCallWise(array) {


        let fdate = moment(this.state.fdate).format("YYYY_MM_DD")
        let tdate = moment(this.state.tdate).format("YYYY_MM_DD")
        const filename = 'attdata_f_' + fdate + '_t_' + tdate + '.xls';
        const csv = new xlsexport(array, filename)
        csv.exportToXLS(filename)

    }

    MakeFilter(e) {

        this.setState({ stext: e.target.value })

        const sresult = this.state.userdata1.filter(data => {
            if (data.domain)
                return (
                    data.aid.match(e.target.value) ||
                    data.domain.match(e.target.value) ||
                    data.name.match(e.target.value) ||
                    data.date.match(e.target.value)

                )
        })

        if (e.target.value.length > 0) {
            this.setState({ userdata: sresult, attcount: sresult.length })
        } else {
            this.setState({ userdata: this.state.userdata1, attcount: this.state.userdata1.length })
        }


    }
    render() {
        const columns = [

            {
                name: '',
                width: '100px',
                cell: row =>


                    <WhiteBackgroundCheckbox

                        id="chk1"

                    />


            },

            {
                name: 'Date',
                selector: 'date',
                sortable: true,
                cell: row =>
                    row.date
            },

            {
                name: 'Agent ID',
                selector: 'aid',
                sortable: true,
                cell: row =>
                    row.aid
            },
            {
                name: 'Name',
                id: 'name',
                selector: 'name',
                sortable: true,
                cell: row =>
                    row.name
            },
            {
                name: 'Domain',
                selector: 'domain',
                sortable: true,
                cell: row =>
                    row.domain
            },


            {
                name: 'Login',
                sortable: true,
                selector: 'login',
                filterable: true,
                selector: row => row.login,

            },

            {
                name: 'LogOut',
                selector: 'logout',
                sortable: true,
                cell: row =>
                    row.logout
            },
            {
                name: 'Break',

                sortable: true,
                cell: row => row.break_duration_hms

            },

            {
                name: 'Wrap-up',

                sortable: true,
                cell: row => row.calls_submit_duration_hms

            },

            {
                name: 'Calls',
                selector: 'calls',
                sortable: true,
                cell: row =>
                    row.calls_count
            },
            {
                name: 'Avg Call Duration',
                selector: 'avg_total_duration',
                sortable: true,
                cell: row =>
                    row.avg_call
            },














        ];
        return (
            <div style={{ marginTop: '0%' }}>
                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '1%', marginTop: '2%', }}>
                    <Grid style={{ width: '22%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Typography style={{ marginRight: '1%', color: '#999999', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>From</Typography>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider

                                style={{ height: '20px', }} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    error={false}
                                    helperText={null}

                                    value={this.state.fdate}
                                    onChange={date => { this.handledobChange(date) }}
                                    format="yyyy/MM/dd"

                                    InputProps={{


                                        style: {

                                            fontSize: 14,
                                            height: 38,
                                            color: '#999999',

                                            background: '#ffffff'

                                        }
                                    }}
                                    inputVariant="outlined"



                                    KeyboardButtonProps={{
                                        style: { marginLeft: -24 }
                                    }}

                                />
                            </MuiPickersUtilsProvider>
                        </ThemeProvider>
                    </Grid>

                    <Grid style={{ width: '22%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Typography style={{ marginRight: '1%', color: '#999999', fontSize: '14px', marginLeft: '1%', fontFamily: 'Roboto-Regular' }}>To</Typography>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider

                                style={{ height: '20px' }} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    error={false}
                                    helperText={null}

                                    value={this.state.tdate}
                                    onChange={date => { this.handledobChange1(date) }}
                                    format="yyyy/MM/dd"

                                    InputProps={{


                                        style: {

                                            fontSize: 14,
                                            height: 38,
                                            color: '#999999',

                                            background: '#ffffff'

                                        }
                                    }}
                                    inputVariant="outlined"



                                    KeyboardButtonProps={{
                                        style: { marginLeft: -24 }
                                    }}

                                />
                            </MuiPickersUtilsProvider>
                        </ThemeProvider>
                    </Grid>

                    <Grid style={{ width: '15%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Button



                            variant="contained"
                            size="small"

                            style={{
                                color: "#ffffff",
                                fontFamily: "Roboto-Medium",
                                borderRadius: "4px",
                                backgroundColor: "#7DAB1C",
                                textTransform: 'none',
                                padding: '1.5%',
                                fontSize: "14px",
                                width: '125px',
                                height: '32px',
                                marginLeft: '5%'

                            }}


                            onClick={() => { this.NewAttRep() }}



                        >

                            Submit
                        </Button>
                    </Grid>
                    <Grid style={{ width: '15%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Button



                            variant="contained"
                            size="small"

                            style={{
                                color: "#ffffff",
                                fontFamily: "Roboto-Medium",
                                borderRadius: "4px",
                                backgroundColor: "#7DAB1C",
                                textTransform: 'none',
                                padding: '1.5%',
                                fontSize: "14px",
                                width: '125px',
                                height: '32px',
                                marginLeft: '5%'

                            }}

                            onClick={() => {
                                this.ExportCallWise(this.state.userdata)
                            }}


                        >

                            Export
                        </Button>
                    </Grid>

                </Grid>

                <Grid style={{ height: 'auto', overflow: 'auto', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', }}>


                    <DataTable

                        columns={columns}
                        data={this.state.userdata}
                        pagination
                        striped
                        responsive
                        paginationPerPage='100'
                        paginationRowsPerPageOptions={[100, 150, 200, 250]}
                        sortable
                        defaultSortFieldId
                        dense

                        customStyles={customStyles}
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                            <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                <CssTextField
                                    autoFocus={true}
                                    value={this.state.stext}
                                    placeholder="Search"
                                    size="small"
                                    variant="outlined"
                                    onChange={e =>
                                        this.MakeFilter(e)
                                    }
                                />

                                <Typography style={{ marginRight: '1%', color: '#333333', fontSize: '12px', marginLeft: '1%', fontFamily: 'Roboto-Regular' }}>Row Count:{" "} {this.state.attcount}</Typography>
                            </Grid>
                        }
                        subHeaderAlign='left'

                        conditionalRowStyles={conditionalRowStyles}
                    />


                </Grid>
                <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',
                            display: 'flex',
                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >
                        <Loader type="spinner-circle" bgColor={"#7DAB1C"} color={'#ffc31b'} size={75} />
                    </Box>

                </Modal>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "Roboto-Bold"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
            </div >
        )
    }
}

export default class reports extends Component {
    constructor(props) {
        super(props)


        this.state = {


            tabvalue: 0,
            butname: '',
            islodding: false


        }



    }
    handleTabChange = (event, value) => {
        this.setState({ tabvalue: value });
    };
    render() {
        return (
            <div>
                <Grid style={{ width: '100%', marginTop: '1%' }}>
                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Reports</Typography>
                </Grid>

                <Tabs value={this.state.tabvalue} onChange={this.handleTabChange} style={{ marginTop: '.5%', background: '#F5F5F5' }}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "#7DAB1C",

                        }
                    }}

                >
                    <Tab active='true' style={{ color: '#333333', textTransform: 'none', fontSize: '12', fontFamily: 'Roboto-Medium' }} label="District Wise" />
                    <Tab style={{ color: '#333333', textTransform: 'none', fontSize: '12', fontFamily: 'Roboto-Medium' }} label="Call Wise" />

                    <Tab style={{ color: '#333333', textTransform: 'none', fontSize: '12', fontFamily: 'Roboto-Medium' }} label="Attendance" />



                </Tabs>
                {this.state.tabvalue === 0 &&
                    <div>
                        <DistrictWise />
                    </div>
                }
                {this.state.tabvalue === 1 &&
                    <div>
                        <CallWise />
                    </div>
                }

                {this.state.tabvalue === 2 &&
                    <div>
                        <Attends />
                    </div>
                }



            </div>
        )
    }
}
