import React, { Component } from 'react'
import { TextField, Modal, Box, Typography, Grid, Button, Checkbox, IconButton, Snackbar, SnackbarContent } from '@material-ui/core'
import { withStyles, styled } from "@material-ui/core/styles";
import { httpRequest } from '../../../ui-utils/api'
import DataTable from 'react-data-table-component';
import { EditOutlined } from '@material-ui/icons';
import { ArrowBackIosOutlined } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close'

import sql_funcation from '../../../ui-utils/sql_funcation';


const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#41AB1C",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 10,
            width: 10,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#41AB1C'
        }
    },
    checked: { color: "#41AB1C", }
}))(Checkbox);
const conditionalRowStyles = [
    {
        when: row => row.account_status == "E",
        style: {
            backgroundColor: 'green',
            color: 'white',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    },

];

const customStyles = {
    title: {
        style: {
            fontSize: '14px', // override the row height
        },
    },
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
        stripedStyle: {

            //  background: 'red'



        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#E9F4FF',
            fontSize: '14px',
            color: '#333333',
            height: '45px',
            fontFamily: 'Roboto-Medium'
        },
    },
    cells: {
        style: {
            paddingLeft: '2px', // override the cell padding for data cells
            paddingRight: '2px',


        },
    },
    highlightOnHoverStyle: {
        style: {
            color: 'red',
            background: 'blue',
            backgroundColor: 'yellow'



        },
    },

};


const txtstyles = theme => ({




})


const CssTextField = styled(TextField)({
    borderRadius: '12px',


    '& label.Mui-focused': {
        color: '#999999',

    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#999999',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#999999',
        },
        '&:hover fieldset': {
            borderColor: '#999999',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#999999',
        },
    },
});
const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    }
};

class addgroup extends Component {
    constructor(props) {
        super(props)



        this.state = {

            roledata: [],

            agentdata: [],
            agentdata1: [],
            numofrows: 0,
            showaddroles: false,
            showaddgroup: '0',
            rolename: '',
            savetype: 0,
            agentlist: [],
            mysnack: snackeror.mysucc,
            stext: '',
            gname: '',
            gdisp: ''

        }
    }
    MakeFilter(e) {

        this.setState({ stext: e.target.value })

        const sresult = this.state.agentdata.filter(data => {
            if (data.aid)
                return (
                    data.aid.match(e.target.value) ||
                    data.name.match(e.target.value) ||
                    data.domain.match(e.target.value)

                )
        })

        if (e.target.value.length > 0) {
            this.setState({ agentdata: sresult })
        } else {
            this.setState({ agentdata: this.state.agentdata1 })
        }


    }
    EditGroup(row) {
        let aid = row.aid

        this.setState({ showaddgroup: '1', gname: row.group_name, gdisp: row.grp_disp, savetype: row.uid })


    }

    GetData = async () => {
        let sql = "select uid,aid,group_name,grp_disp,date(created_on)::text as created_on from  pad_group where history_id is null order by created_on"

        const item = {
            "stmt": sql

        }
        try {
            const roledata = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: item,

            });



            if (roledata.success == true) {

                this.setState({ roledata: roledata.msg, roledata1: roledata.msg })
            }
        } catch (error) {

        }

        let sqlagent = "select aid,name,domain  from  pad_agent where  status='Enabled' order by name"

        const itemagent = {
            "stmt": sqlagent

        }
        try {
            const roledata = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: itemagent,

            });



            if (roledata.success == true) {

                this.setState({ agentdata: roledata.msg, agentdata: roledata.msg })
            }
        } catch (error) {

        }
    }

    componentDidMount = async () => {
        this.GetData()
    }

    closeContactModal() {
        this.setState({ showerr: false })
    }

    UseCheck(row, e) {

        const { agentlist } = this.state

        if (e.target.checked == true) {
            this.state.agentlist.push(row.aid)
        }
        if (e.target.checked == false) {

            const nlist = agentlist.filter(function (person) {

                return person !== row.aid
            })


            this.setState({ agentlist: nlist })

        }

    }

    async GetAgentList() {
        const { gname, gdisp } = this.state
        let isok = 0
        if (!gname.length) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter Group Name", mysnack: snackeror.myerror })
        }

        if (!gdisp.length) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter Group Description", mysnack: snackeror.myerror })
        }


        if (!this.state.agentlist.length) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Select Agent to Add", mysnack: snackeror.myerror })
        }
        let aidlist = ""
        if (isok == 0) {

            this.state.agentlist.map((aid) => (

                aidlist = aidlist + aid + ","
            )

            )
            aidlist = aidlist.slice(0, -1)

            let sql = "insert into pad_group(grp_disp,group_name,aid) values('" + gname + "','" + gdisp + "','" + aidlist + "') RETURNING uid"
            const dataresult = await sql_funcation(sql)


            if (dataresult.success == true) {
                this.setState({ showaddgroup: '0', showerr: true, errorMsg: "New Group added successfully", mysnack: snackeror.mysucc })



                console.log("Save TYpe", this.state.savetype)

                if (this.state.savetype > 0) {
                    console.log("uid", dataresult.msg)
                    let uid = dataresult.msg[0].uid;
                    console.log("uid=", uid)

                    let updatesql = "update pad_group set updatedon=now(),history_id=" + uid + " where uid=" + this.state.savetype + " returning 1 as result"
                    console.log(updatesql)

                    const updateditem = {
                        "stmt": updatesql

                    }


                    try {
                        const newuserdataupdate = await httpRequest({
                            endPoint: `api/db/stmt`,
                            method: "post",
                            instance: "instanceOne",
                            requestBody: updateditem,

                        });
                        console.log("newuserdataupdate", newuserdataupdate)
                        this.GetData()
                    } catch (error) {

                    }
                }


            }
        }
    }
    render() {

        const agentcolumns = [

            {
                name: '',
                width: '100px',
                cell: row =>


                    <WhiteBackgroundCheckbox

                        id="chk1"
                        onClick={(e) => this.UseCheck(row, e)}
                    />


            },
            {
                name: 'Agent ID',

                sortable: true,
                cell: row =>
                    row.aid
            },



            {
                name: 'Name',

                sortable: true,
                cell: row =>
                    row.name
            },

            {
                name: 'Domain',

                sortable: true,
                cell: row =>
                    row.domain
            },




        ];
        const columns = [

            {
                name: '',
                width: '100px',
                cell: row =>


                    <WhiteBackgroundCheckbox

                        id="chk1"

                    />


            },
            {
                name: 'Created On',

                sortable: true,
                cell: row =>
                    row.created_on
            },



            {
                name: 'Group Name',

                sortable: true,
                cell: row =>
                    row.group_name
            },

            {
                name: 'Group Description',

                sortable: true,
                cell: row =>
                    row.grp_disp
            },
            {
                name: 'Action',

                sortable: true,
                cell: row =>
                    <IconButton


                        onClick={() => this.EditGroup(row)}
                    >
                        <EditOutlined style={{ color: '#0083CA', fontSize: '18px' }} />
                    </IconButton>
            },



        ];

        return (

            <div>
                {this.state.showaddgroup == "1" &&

                    <div>
                        <Grid style={{ width: '100%', marginTop: '1%', display: 'flex', alignItems: 'center', cursor: 'pointer' }}

                            onClick={() => { this.setState({ showaddgroup: '0' }) }}
                        >

                            <ArrowBackIosOutlined style={{ fontSize: '15px' }}></ArrowBackIosOutlined>
                            <Typography style={{ marginLeft: '1%', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Add Group</Typography>
                        </Grid>
                        <Grid style={{ padding: '2% 5%', display: 'flex', width: '100%', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', }}>
                            <Grid style={{ width: '30%', display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ marginRight: '1%', marginLeft: '1%', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Group Name</Typography>
                                <CssTextField focusColor="#999999"



                                    inputProps={{
                                        style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px', },


                                    }}
                                    size="small"

                                    variant="outlined"
                                    placeholder="Enter the Name"
                                    value={this.state.gname}

                                    onChange={e => {

                                        this.setState({ gname: e.target.value });

                                    }}
                                />
                            </Grid>
                            <Grid style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ marginLeft: '1%', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}> Description</Typography>
                                <CssTextField focusColor="#999999"

                                    fullWidth

                                    inputProps={{
                                        style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                    }}
                                    size="small"

                                    variant="outlined"
                                    placeholder="Enter the Description"
                                    value={this.state.gdisp}

                                    onChange={e => {

                                        this.setState({ gdisp: e.target.value });

                                    }}
                                />
                            </Grid>
                            <Grid style={{ width: '20%', display: 'flex', alignItems: 'center' }}>
                                <Button



                                    variant="contained"
                                    size="small"

                                    style={{
                                        color: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        borderRadius: "4px",
                                        backgroundColor: "#41AB1C",
                                        textTransform: 'none',
                                        padding: '1.5%',
                                        fontSize: "14px",
                                        width: '150px',
                                        height: '35px',
                                        marginLeft: '5%'

                                    }}

                                    onClick={() => { this.GetAgentList() }}


                                >

                                    Submit
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid style={{ height: 'auto', overflow: 'auto', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', }}>


                            <DataTable

                                columns={agentcolumns}
                                data={this.state.agentdata}
                                pagination
                                striped
                                responsive
                                paginationPerPage='30'

                                dense

                                customStyles={customStyles}
                                highlightOnHover
                                subHeader
                                subHeaderComponent={
                                    <Grid style={{ width: '100%', display: 'flex' }}>
                                        <Grid style={{ width: '50%', display: 'flex', justifyContent: 'flex-start' }}>
                                            <CssTextField
                                                autoFocus={true}
                                                value={this.state.stext}
                                                placeholder="Search"
                                                size="small"
                                                variant="outlined"
                                                onChange={e =>
                                                    this.MakeFilter(e)
                                                }
                                            />
                                        </Grid>


                                    </Grid>
                                }
                                subHeaderAlign='left'

                                conditionalRowStyles={conditionalRowStyles}
                            />


                        </Grid>
                        <Grid style={{ width: '90%', display: 'flex', justifyContent: 'flex-end' }}>

                        </Grid>
                    </div>
                }
                {this.state.showaddgroup == "0" &&
                    <Grid style={{ width: '100%', padding: '1%', justifyContent: 'center' }}>

                        <Grid style={{ width: '95%' }}>
                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Groups</Typography>
                        </Grid>

                        <Grid style={{ width: '95%', display: 'flex', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', padding: '2%' }}>
                            <Grid style={{ width: '75%', }}>
                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>{this.state.numofrows} Groups</Typography>
                            </Grid>
                            <Grid style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>

                                <Button



                                    variant="contained"
                                    size="small"

                                    style={{
                                        color: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        borderRadius: "4px",
                                        backgroundColor: "#41AB1C",
                                        textTransform: 'none',
                                        padding: '1.5%',
                                        fontSize: "14px",
                                        width: '150px',
                                        height: '32px',
                                        marginLeft: '5%'

                                    }}

                                    onClick={() => { this.setState({ showaddgroup: '1', savetype: 0 }) }}


                                >

                                    Add Groups
                                </Button>
                            </Grid>

                        </Grid>
                        <Grid style={{ height: 'auto', overflow: 'auto', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', }}>


                            <DataTable

                                columns={columns}
                                data={this.state.roledata}
                                pagination
                                striped
                                responsive
                                paginationPerPage='30'

                                dense

                                customStyles={customStyles}
                                highlightOnHover


                                conditionalRowStyles={conditionalRowStyles}
                            />


                        </Grid>




                    </Grid>

                }
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "Roboto-Bold"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>

            </div>

        )
    }
}
export default (withStyles(txtstyles)((addgroup)));