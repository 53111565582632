import { Typography, Grid, Modal, Box } from '@material-ui/core'
import Loader from "react-js-loader";
import React, { Component } from 'react'
import MoreHoriz from '@material-ui/icons/MoreHoriz'
import { PieChart } from 'react-minimal-pie-chart';
import moment from 'moment'
import { httpRequest } from '../../../ui-utils/api'
import { createTheme, ThemeProvider, MuiThemeProvider } from "@material-ui/core/styles";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';

const data = [
    { title: 'One', value: 0, color: '#D69C47' },
    { title: 'Two', value: 0, color: '#FFFFFF' },

]
const data1 = [
    { title: 'One', value: 0, color: '#E6A09B' },
    { title: 'Two', value: 0, color: '#D69C47' },
    { title: 'Three', value: 0, color: '#C6D592' },


]
const defaultMaterialTheme = createTheme({
    palette: {
        primary: {
            light: '#7DAB1C',
            main: '#7DAB1C',
            dark: '#7DAB1C',
            contrastText: '#fff',

        },
        secondary: {
            light: '#7DAB1C',
            main: '#7DAB1C',
            dark: '#7DAB1C',
            contrastText: '#000',
        },
    },
});
function drawPieBorder(chart) {
    var svg = chart.getElementsByTagName("svg")[0];
    var path = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "circle"
    );
    path.setAttribute("stroke", "#999999");
    path.setAttribute("stroke-width", .15);
    path.setAttribute("fill", "transparent");
    path.setAttribute("cx", 50);
    path.setAttribute("cy", 50);
    path.setAttribute("r", 46);
    svg.prepend(path);
}
export default class weekly_dashboard extends Component {
    constructor(props) {
        super(props)


        this.state = {


            totaluser: 0,
            totalans: 0,
            totalideal: 0,
            totdur: 0,
            totalmissed: 0,
            chatdata: data1,
            chatdatain: data,
            totincall: 0,
            totinavgtime: 0,

            totoutbound: 0,
            totoutboundavb: 0,
            tot1: 0,
            tot2: 0,

            totaloutans: 0,
            totaloutmissed: 0,
            totoutdur: 0,
            totoutcall: 0,
            totoutavgtime: 0,
            totincallper: 0,
            totoutcallper: 0,
            totnonworkcall: 0,
            outchartdate: [],
            avgouttime: 0,
            fdate: moment(new Date()).subtract(7, 'days').format("YYYY-MM-DD"),
            tdate: moment(new Date()).format("YYYY-MM-DD"),
            totavgoutcall: 0,
            totavgincall: 0

        }



    }
    date_diff_indays(date1, date2) {
        let dt1 = new Date(date1);
        let dt2 = new Date(date2);
        return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
    }


    componentDidMount = async () => {
        //this.GetUserInBound(this.state.fdate, this.state.tdate)
        this.NewDash(this.state.fdate, this.state.tdate)
        drawPieBorder(document.getElementById("piechart"));
        drawPieBorder(document.getElementById("piechart1"));
    }
    handledobChange(date) {

        this.setState({ fdate: date })
        //  this.GetUserInBound(date, this.state.tdate)
        this.NewDash(date, this.state.tdate)

    }

    handledobChange1(date) {

        this.setState({ tdate: date })
        //this.GetUserInBound(this.state.fdate, date)
        this.NewDash(this.state.fdate, date)

    }
    NewDash = async (fdate, tdate) => {

        let da = moment(fdate).format("YYYY-MM-DD")

        let da1 = moment(tdate).format("YYYY-MM-DD")

        let da4 = moment(fdate).format("YYYY-MM-DD")
        let da44 = moment(tdate).format("YYYY-MM-DD")


        let da_1 = moment(tdate).format("YYYY-MM-DD") + " 09:00:00"

        let basedate = new Date("2023-06-07");
        let basedate1 = new Date(tdate);

        let sysdate = new Date();

        //let diff = basedate1.getDate() - basedate.getDate()
        let diff = this.date_diff_indays(basedate, basedate1)
        let diff1 = sysdate.getDate() - basedate1.getDate()

        let ldate = "-"
        let outcount = 0
        let incount = 0
        let sql = ""
        this.setState({ islodding: true })

        sql = "SELECT distinct pad_agent_activate.aid,pad_agent.domain  from pad_agent_activate join pad_agent on pad_agent_activate.aid=pad_agent.aid where  pad_agent.status='Enabled' and date(date) between '" + da + "' and '" + da_1 + "' and (todate is null or date(todate)>='" + da + "') "
        const item_user = {
            "stmt": sql

        }

        try {
            const roledata = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: item_user,

            });


            if (roledata.success == true) {
                let rdata = roledata.msg

                for (var i = 0; i < rdata.length; i++) {
                    if (rdata[i].domain == "Outbound") {
                        outcount = outcount + 1
                    }
                    if (rdata[i].domain == "lcc-default") {
                        incount = incount + 1
                    }
                    if (rdata[i].domain == "agriculture") {
                        incount = incount + 1
                    }
                    if (rdata[i].domain == "fishery") {
                        incount = incount + 1
                    }
                    if (rdata[i].domain == "animal_husbandry") {
                        incount = incount + 1
                    }


                }

                this.setState({ totaluser: incount, totoutboundavb: outcount })

            }
        } catch (error) {

        }
        sql = "select aid from pad_agent where domain='Outbound' and status='Enabled' and (todate is null or date(todate)>='" + da + "')"

        const itemout = {
            "stmt": sql

        }

        try {
            const roledata = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: itemout,

            });


            if (roledata.success == true) {




                this.setState({ totoutbound: roledata.msg.length })
            }
        } catch (error) {

        }
        //Avable
        sql = "select aid from pad_agent where (domain='fishery' or  domain='animal_husbandry' or domain='agriculture' or domain='lcc-default') and status='Enabled' and (todate is null or date(todate)>='" + da + "') "

        const itemidel = {
            "stmt": sql

        }

        try {
            const roledata = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: itemidel,

            });


            if (roledata.success == true) {

                this.setState({ totalideal: roledata.msg.length })

                let tot2 = this.state.totoutbound + roledata.msg.length
                let tot1 = this.state.totoutboundavb + this.state.totaluser

                this.setState({ tot2: tot2, tot1: tot1 })
            }
        } catch (error) {



        }
        const item = {
            "datetime_from": da + " 00:00:00",
            "datetime_to": da1 + " 23:59:59",
            "workinghrs_from": "09:00:00",
            "workinghrs_to": "20:00:00",
            "data": false,
            "field_last_event": false,
            "direction": [
                "Inbound",
                "Outbound",
                "short_call"


            ],
            "domains": [
                "6742621800",
                "6742621808",
                "9000000000",
                "1000000000",
                "2000000000",
                "3000000000"
            ]
        }
        let totincall = 0
        let totalans = 0
        let totalmissed = 0
        let totdur = 0
        let totnonworkcall = 0
        let totoutdur = 0
        let totaloutans = 0
        let avgtime = 0
        let totoutcall = 0
        let pieval3 = 0
        console.log(item)
        try {

            const data_workinghrs = await httpRequest({
                endPoint: `api/lcc/reports/RawDump`,
                method: "post",
                instance: "instanceOne",
                requestBody: item,

            });

            this.setState({ islodding: false })


            console.log(' workinghrs dash Data', data_workinghrs.msg)


            for (var key in data_workinghrs.msg.Inbound.workinghrs.domains) {

                console.log("aaaa====", parseInt(data_workinghrs.msg.Inbound.workinghrs.domains[key]['total']))
                totincall = parseInt(totincall) + parseInt(data_workinghrs.msg.Inbound.workinghrs.domains[key]['total'])

                totalans = parseInt(totalans) + parseInt(data_workinghrs.msg.Inbound.workinghrs.domains[key]['answered'])
                totalmissed = parseInt(totalmissed) + parseInt(data_workinghrs.msg.Inbound.workinghrs.domains[key]['missed'])
                totdur = parseInt(totdur) + parseInt(data_workinghrs.msg.Inbound.workinghrs.domains[key]['duration_sec'])


            }


            this.setState({ totalans: totalans, totalmissed: totalmissed, totdur: totdur, })
            if (diff >= 0) {
                for (var key in data_workinghrs.msg.Outbound.workinghrs.domains) {

                    console.log("aaaa====", parseInt(data_workinghrs.msg.Outbound.workinghrs.domains[key]['total']))
                    totoutcall = parseInt(totoutcall) + parseInt(data_workinghrs.msg.Outbound.workinghrs.domains[key]['total'])
                    totaloutans = parseInt(totaloutans) + parseInt(data_workinghrs.msg.Outbound.workinghrs.domains[key]['answered'])



                    totoutdur = parseInt(totoutdur) + parseInt(data_workinghrs.msg.Outbound.workinghrs.domains[key]['duration_sec'])


                }
            }
            totoutdur = (parseInt(totoutdur) / 60).toFixed(0)

            this.setState({ totoutcall: totoutcall, totaloutans: totaloutans, totoutdur: totoutdur })

            //totnonworkcall = data_workinghrs.msg.short_call.non_workinghrs.total
            totnonworkcall = parseInt(data_workinghrs.msg.short_call.non_workinghrs.total) + parseInt(data_workinghrs.msg.Inbound.non_workinghrs.total)

            console.log(totincall)
            totdur = (parseInt(totdur) / 60).toFixed(0)
            this.setState({ totalmissed: totalmissed, totincall: totincall, totnonworkcall: totnonworkcall })



            avgtime = (totdur / totalans) * 60
            avgtime = avgtime.toFixed(0)
            this.setState({ totinavgtime: avgtime })
            let b = (totalans / this.state.totaluser) * avgtime
            b = b / 60
            b = b.toFixed(0)
            this.setState({ totavgincall: b })


            let gtotcall = totincall + totnonworkcall
            let pieval1 = ((100 * totalans) / gtotcall).toFixed(0)
            let avgouttime
            avgouttime = (totoutdur / totaloutans) * 60
            avgouttime = avgouttime.toFixed(0)

            this.setState({ totoutavgtime: avgouttime })
            let a = (totaloutans / this.state.totoutboundavb) * avgouttime
            a = a / 60
            a = a.toFixed(0)

            this.setState({ totavgoutcall: a })





            let pieval2 = ((100 * totalmissed) / gtotcall).toFixed(0)


            let pieval3 = 100 - (parseInt(pieval1) + parseInt(pieval2))

            const newState = this.state.chatdata.map(obj => {

                if (obj.title === "One") {

                    return { ...obj, value: parseInt(pieval1) };
                }
                if (obj.title === "Two") {

                    return { ...obj, value: parseInt(pieval2) };
                }
                if (obj.title === "Three") {

                    return { ...obj, value: parseInt(pieval3) };
                }

                return obj;
            });

            this.setState({ chatdata: newState })

        } catch (error) {

        }

        pieval3 = ((100 * totaloutans) / totoutcall).toFixed(0)
        let pieval44 = this.state.totnonworkcall
        let pieval4 = 100 - pieval3
        const newState = this.state.chatdatain.map(obj => {

            if (obj.title === "One") {

                return { ...obj, value: parseInt(pieval3) };
            }
            if (obj.title === "Two") {

                return { ...obj, value: parseInt(pieval4) };
            }


            return obj;
        });
        this.setState({ outchartdate: newState })
        this.setState({ islodding: false })
        if (diff < 0) {

            sql = "select uid from fs_cdr where  date(start_stamp) between '" + da4 + "' and '" + da44 + "' and caller_id_name <>caller_id_number and (destination_number <>'9196' and destination_number <>'9195')"

            console.log(sql)

            const totoutcall = {
                "stmt": sql

            }

            try {
                const roledata = await httpRequest({
                    endPoint: `api/db/stmt`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: totoutcall,

                });


                if (roledata.success == true) {

                    this.setState({ totoutcall: roledata.msg.length })
                }
            } catch (error) {

            }





            sql = "select count(billsec) as answered,sum(billsec/60)::text as duration from fs_cdr where billsec>0 and   caller_id_name <>caller_id_number and   date(start_stamp) between '" + da4 + "' and '" + da44 + "'"
            console.log(sql)

            const totaloutans_palload = {
                "stmt": sql

            }

            try {
                const roledata = await httpRequest({
                    endPoint: `api/db/stmt`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: totaloutans_palload,

                });


                if (roledata.success == true) {
                    totoutdur = roledata.msg[0].duration
                    totaloutans = roledata.msg[0].answered
                    this.setState({ totaloutans: roledata.msg[0].answered, totoutdur: totoutdur })

                    pieval3 = ((100 * roledata.msg[0].answered) / this.state.totoutcall).toFixed(0)
                    let pieval44 = this.state.totnonworkcall
                    let pieval4 = 100 - pieval3
                    const newState = this.state.chatdatain.map(obj => {

                        if (obj.title === "One") {

                            return { ...obj, value: parseInt(pieval3) };
                        }
                        if (obj.title === "Two") {

                            return { ...obj, value: parseInt(pieval4) };
                        }


                        return obj;
                    });
                    let avgouttime
                    avgouttime = (totoutdur / totaloutans).toFixed(0)


                    this.setState({ outchartdate: newState })

                    this.setState({ totoutavgtime: avgouttime })
                    let b = (totalans / this.state.totaluser) * avgtime
                    b = b.toFixed(0)
                    this.setState({ totavgincall: b })


                    let a = (totaloutans / outcount) * avgouttime
                    a = a.toFixed(0)

                    this.setState({ totavgoutcall: a })

                    this.setState({ islodding: false })

                }
            } catch (error) {

            }

        }

    }
    render() {

        return (
            <div>
                <Grid style={{ width: '100%', marginTop: '1%', display: 'flex', alignItems: 'center' }}>
                    <Grid style={{ width: '10%' }}>
                        <Typography style={{ textAlign: 'right', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>From Date</Typography>
                    </Grid>

                    <Grid style={{ width: '25%', marginLeft: '25px', width: '175px' }}>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider

                                style={{ height: '20px' }} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    error={false}
                                    helperText={null}

                                    value={this.state.fdate}
                                    onChange={date => { this.handledobChange(date) }}
                                    format="yyyy/MM/dd"

                                    InputProps={{


                                        style: {

                                            fontSize: 14,
                                            height: 38,
                                            color: '#999999',

                                            background: '#ffffff'

                                        }
                                    }}
                                    inputVariant="outlined"



                                    KeyboardButtonProps={{
                                        style: { marginLeft: -24 }
                                    }}

                                />
                            </MuiPickersUtilsProvider>
                        </ThemeProvider>
                    </Grid>
                    <Grid style={{ width: '10%' }}>
                        <Typography style={{ textAlign: 'right', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>To Date</Typography>
                    </Grid>

                    <Grid style={{ width: '25%', marginLeft: '25px', width: '175px' }}>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider

                                style={{ height: '20px' }} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    error={false}
                                    helperText={null}

                                    value={this.state.tdate}
                                    onChange={date => { this.handledobChange1(date) }}
                                    format="yyyy/MM/dd"

                                    InputProps={{


                                        style: {

                                            fontSize: 14,
                                            height: 38,
                                            color: '#999999',

                                            background: '#ffffff'

                                        }
                                    }}
                                    inputVariant="outlined"



                                    KeyboardButtonProps={{
                                        style: { marginLeft: -24 }
                                    }}

                                />
                            </MuiPickersUtilsProvider>
                        </ThemeProvider>
                    </Grid>
                </Grid>

                <Grid style={{ width: '95%', marginTop: '1%', padding: '2%', border: '1px solid #E7EAF0', borderRadius: '10px', justifyContent: 'space-between' }}>
                    <Grid style={{ width: '100%', display: 'flex' }}>
                        <Typography style={{ color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}>Weekly Stats</Typography>
                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>

                        <Grid style={{ width: '40%', padding: '2%', borderRadius: '10px', border: '1px solid #E7EAF0' }}>
                            <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', borderBottom: '1px solid #D1D9E2', padding: '5px' }}>
                                <Grid style={{ width: '25%', display: 'flex' }}>
                                    <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }}></Typography>
                                </Grid>
                                <Grid style={{ width: '25%', display: 'flex' }}>
                                    <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Answered</Typography>
                                </Grid>
                                <Grid style={{ width: '25%', display: 'flex' }}>
                                    <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Missed</Typography>
                                </Grid>
                                <Grid style={{ width: '25%', display: 'flex' }}>
                                    <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Duration(min)</Typography>
                                </Grid>


                            </Grid>
                            <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', borderBottom: '1px solid #D1D9E2', padding: '5px' }}>
                                <Grid style={{ width: '25%', display: 'flex' }}>
                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Inbound</Typography>
                                </Grid>
                                <Grid style={{ width: '25%', display: 'flex' }}>
                                    <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>{Number(this.state.totalans).toLocaleString('en-IN')}</Typography>
                                </Grid>
                                <Grid style={{ width: '25%', display: 'flex' }}>
                                    <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>{Number(this.state.totalmissed).toLocaleString('en-IN')}</Typography>
                                </Grid>
                                <Grid style={{ width: '25%', display: 'flex' }}>
                                    <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>{Number(this.state.totdur).toLocaleString('en-IN')}</Typography>
                                </Grid>


                            </Grid>
                        </Grid>
                        <Grid style={{ width: '40%', padding: '2%', borderRadius: '10px', border: '1px solid #E7EAF0' }}>
                            <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', borderBottom: '1px solid #D1D9E2', padding: '5px' }}>
                                <Grid style={{ width: '25%', display: 'flex' }}>
                                    <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }}></Typography>
                                </Grid>
                                <Grid style={{ width: '25%', display: 'flex' }}>
                                    <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Dialled</Typography>
                                </Grid>
                                <Grid style={{ width: '25%', display: 'flex' }}>
                                    <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Answered</Typography>
                                </Grid>
                                <Grid style={{ width: '25%', display: 'flex' }}>
                                    <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Duration(min)</Typography>
                                </Grid>


                            </Grid>
                            <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', borderBottom: '1px solid #D1D9E2', padding: '5px' }}>
                                <Grid style={{ width: '25%', display: 'flex' }}>
                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Outbound</Typography>
                                </Grid>
                                <Grid style={{ width: '25%', display: 'flex' }}>
                                    <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>{Number(this.state.totoutcall).toLocaleString('en-IN')} </Typography>
                                </Grid>
                                <Grid style={{ width: '25%', display: 'flex' }}>
                                    <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>{Number(this.state.totaloutans).toLocaleString('en-IN')}</Typography>
                                </Grid>
                                <Grid style={{ width: '25%', display: 'flex' }}>
                                    <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>{Number(this.state.totoutdur).toLocaleString('en-IN')}</Typography>
                                </Grid>


                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid style={{ width: '100%', marginTop: '1%', display: 'flex', justifyContent: 'space-between' }}>
                    <Grid style={{ width: '29.5%', padding: '2%', border: '1px solid #E7EAF0', borderRadius: '10px', background: '#ffffff' }}>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}> Inbound Call Status Summary</Typography>
                        </Grid>


                        <Grid id="piechart1" style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                            <PieChart
                                style={{ height: '200px' }}
                                animate="true"
                                animationDuration={500}
                                animationEasing="ease-in"
                                data={this.state.chatdata}
                                label={(props) => { return props.dataEntry.value + "%"; }}
                                labelPosition={50}
                                lengthAngle={360}

                                paddingAngle={0}

                                radius={46}
                                startAngle={100}
                                viewBoxSize={[100, 100]}


                                labelStyle={{
                                    fontSize: "7px",
                                    color: "red",

                                }}
                            />
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>

                            <Grid style={{ width: '33%', display: 'flex' }}>
                                <Grid style={{ width: '12%', borderRadius: '4px', background: '#E6A09B', paddingLeft: '1px', height: '3vh', marginTop: '15px' }}></Grid>
                                <Grid style={{ width: '88%', justifyContent: 'flex-start' }}>
                                    <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '24px', fontFamily: 'Roboto-Bold' }}>{Number(this.state.totalans).toLocaleString('en-IN')}</Typography>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Accepted</Typography>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '33%', display: 'flex' }}>
                                <Grid style={{ width: '12%', borderRadius: '4px', background: '#D69C47', paddingLeft: '1px', height: '3vh', marginTop: '15px' }}></Grid>
                                <Grid style={{ width: '88%', justifyContent: 'flex-start' }}>
                                    <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '24px', fontFamily: 'Roboto-Bold' }}>{Number(this.state.totalmissed).toLocaleString('en-IN')}</Typography>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Missed</Typography>
                                </Grid>
                            </Grid>

                            <Grid style={{ width: '33%', display: 'flex' }}>
                                <Grid style={{ width: '12%', borderRadius: '4px', background: '#C6D592', paddingLeft: '1px', height: '3vh', marginTop: '15px' }}></Grid>
                                <Grid style={{ width: '88%', justifyContent: 'flex-start' }}>
                                    <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '24px', fontFamily: 'Roboto-Bold' }}>{Number(this.state.totnonworkcall).toLocaleString('en-IN')}</Typography>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Non working</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid style={{ width: '29.5%', }}>
                        <Grid style={{ border: '1px solid #E7EAF0', background: '#ffffff', borderRadius: '10px', padding: '4% 8%' }}>
                            <Grid style={{ width: '100%', display: 'flex', }}>
                                <Typography style={{ color: '#000000', fontSize: '18px', fontFamily: 'Roboto-Bold' }}>Incoming Call Summary</Typography>
                            </Grid>
                            <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>

                                <Grid style={{ width: '50%', }}>
                                    <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}>{Number(this.state.totalans).toLocaleString('en-IN')}</Typography>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Number of Calls</Typography>
                                </Grid>
                                <Grid style={{ width: '50%', }}>
                                    <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}>{Number(this.state.totinavgtime).toLocaleString('en-IN')}</Typography>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Avg time per call(Sec)</Typography>
                                </Grid>
                                {/*  <Grid style={{ width: '50%', }}>
                                    <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}>{this.state.totincallper}</Typography>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Calls received per hour </Typography>
                                </Grid>
                                */}

                            </Grid>


                        </Grid>
                        <Grid style={{ border: '1px solid #E7EAF0', background: '#ffffff', borderRadius: '10px', padding: '4% 8%', marginTop: '3%' }}>
                            <Grid style={{ width: '100%', display: 'flex', }}>
                                <Typography style={{ color: '#000000', fontSize: '18px', fontFamily: 'Roboto-Bold' }}>Outgoing Call Summary</Typography>
                            </Grid>
                            <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>

                                <Grid style={{ width: '50%', }}>
                                    <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}>{Number(this.state.totaloutans).toLocaleString('en-IN')}</Typography>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Total # of Calls Dialled</Typography>
                                </Grid>
                                <Grid style={{ width: '50%', }}>
                                    <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}>{Number(this.state.totoutavgtime).toLocaleString('en-IN')}</Typography>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Avg time per call(Sec)</Typography>
                                </Grid>
                                {/*<Grid style={{ width: '50%', }}>
                                    <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}>{this.state.totoutcallper}</Typography>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Calls dialled per hour </Typography>
                            </Grid>*/}

                            </Grid>


                        </Grid>
                        <Grid style={{ border: '1px solid #E7EAF0', background: '#ffffff', borderRadius: '10px', padding: '4%', marginTop: '3%' }}>
                            <Grid style={{ width: '100%', display: 'flex', }}>
                                <Typography style={{ color: '#000000', fontSize: '16px', fontFamily: 'Roboto-Bold' }}>Average time spent by agent per week</Typography>
                            </Grid>
                            <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>

                                <Grid style={{ width: '50%' }}>

                                    <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}>{this.state.totavgincall.toLocaleString()}</Typography>
                                        <Typography style={{ marginTop: '5px', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular', marginLeft: '5px' }}>{" "} mins</Typography>
                                    </Grid>
                                    <Grid style={{ width: '100%' }}>
                                        <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Inbound</Typography>
                                    </Grid>
                                </Grid>
                                <Grid style={{ width: '50%', }}>
                                    <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                        <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}>{this.state.totavgoutcall.toLocaleString()}</Typography>
                                        <Typography style={{ marginTop: '5px', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular', marginLeft: '5px' }}>{" "} mins</Typography>

                                    </Grid>
                                    <Grid style={{ width: '100%' }}>
                                        <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>OutBound</Typography>
                                    </Grid>
                                </Grid>


                            </Grid>


                        </Grid>

                    </Grid>
                    <Grid style={{ width: '29.5%', padding: '2%', border: '1px solid #E7EAF0', borderRadius: '10px', background: '#ffffff' }}>

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}>Outbound Call Status Summary</Typography>

                        </Grid>


                        <Grid id="piechart" style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                            <PieChart
                                style={{ height: '200px' }}
                                animate="true"
                                animationDuration={500}
                                animationEasing="ease-in"
                                data={this.state.outchartdate}
                                label={(props) => { return props.dataEntry.value + "%"; }}
                                labelPosition={50}
                                lengthAngle={360}

                                paddingAngle={0}

                                radius={46}
                                startAngle={100}
                                viewBoxSize={[100, 100]}


                                labelStyle={{
                                    fill: '#FFFFFF',
                                    fontSize: '7px'
                                }}
                            />
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>

                            <Grid style={{ width: '50%', }}>
                                <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '32px', fontFamily: 'Roboto-Bold' }}>{Number(this.state.totoutcall).toLocaleString('en-IN')}</Typography>

                                <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Dialled</Typography>
                            </Grid>
                            <Grid style={{ width: '50%', display: 'flex' }}>
                                <Grid style={{ width: '12%', borderRadius: '4px', background: '#D69C47', paddingLeft: '1px', height: '3vh', marginTop: '15px' }}></Grid>
                                <Grid style={{ width: '88%', justifyContent: 'flex-start' }}>
                                    <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '32px', fontFamily: 'Roboto-Bold' }}>{Number(this.state.totaloutans).toLocaleString('en-IN')}</Typography>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Answered</Typography>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
                <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',
                            display: 'flex',
                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >
                        <Loader type="spinner-circle" bgColor={"#7DAB1C"} color={'#ffc31b'} size={75} />
                    </Box>

                </Modal>
            </div >
        )
    }
}
