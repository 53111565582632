import React, { Component } from 'react'
import {
    TextField, Modal, Box, Typography, Grid,
    Button, Checkbox, IconButton, Snackbar, SnackbarContent,
    Select, MenuItem, RadioGroup, Radio, FormControlLabel
} from '@material-ui/core'
import { withStyles, styled } from "@material-ui/core/styles";
import { httpRequest } from '../../../ui-utils/api'
import DataTable from 'react-data-table-component';
import { EditOutlined } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close'
import { ArrowBackIosOutlined } from '@material-ui/icons';
import sql_funcation from '../../../ui-utils/sql_funcation';
const Radioheckbox = withStyles(theme => ({
    root: {
        color: "#333333",
        background: "#ffffff",
        "@media(max-width:960px)": {
            background: "#080808",
        },

        '&$checked': {
            color: '#7DAB1C'
        }
    },
    checked: { color: "#7DAB1C", }
}))(Radio);
const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#41AB1C",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 10,
            width: 10,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#41AB1C'
        }
    },
    checked: { color: "#41AB1C", }
}))(Checkbox);
const conditionalRowStyles = [
    {
        when: row => row.account_status == "E",
        style: {
            backgroundColor: 'green',
            color: 'white',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    },

];

const customStyles = {
    title: {
        style: {
            fontSize: '14px', // override the row height
        },
    },
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
        stripedStyle: {

            //  background: 'red'



        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#E9F4FF',
            fontSize: '14px',
            color: '#333333',
            height: '45px',
            fontFamily: 'Roboto-Medium'
        },
    },
    cells: {
        style: {
            paddingLeft: '2px', // override the cell padding for data cells
            paddingRight: '2px',


        },
    },
    highlightOnHoverStyle: {
        style: {
            color: 'red',
            background: 'blue',
            backgroundColor: 'yellow'



        },
    },

};


const txtstyles = theme => ({




})


const CssTextField = styled(TextField)({
    borderRadius: '12px',


    '& label.Mui-focused': {
        color: '#999999',

    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#999999',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#999999',
        },
        '&:hover fieldset': {
            borderColor: '#999999',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#999999',
        },
    },
});
const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    }
};

class extrenalno extends Component {
    constructor(props) {
        super(props)



        this.state = {

            domaindata: [],
            groupdata: [],
            numberdata: [],
            pagetype: 0,
            numofrows: 0,
            showaddroles: false,
            name: '',
            savetype: 0,
            mysnack: snackeror.mysucc,
            domainid: 0,
            no1: '',
            no2: '',
            gname: '',
            calltype: 'External',
            showaddgroup: false,
            gid: 0,
            gname1: ''

        }
    }

    EditNumbers(row) {
        this.setState({ showaddroles: true, savetype: row.uid, name: row.name, no1: row.no1, no2: row.no2 })

    }
    handelExpertoption(e) {



        this.setState({ calltype: e.target.value })

    }
    GetNumbers = async () => {
        let sql = "select * from pad_externalno order by name"
        let roledata = await sql_funcation(sql)

        if (roledata.success == true) {

            this.setState({ numberdata: roledata.msg, numofrows: roledata.msg.length })
        }







    }

    GetGroups = async () => {
        let sql = "select uid,group_name from pad_externalgroup order by group_name"
        let roledata = await sql_funcation(sql)
        if (roledata.success == true) {

            this.setState({ groupdata: roledata.msg })
        }



    }


    componentDidMount = async () => {
        this.GetNumbers()
        this.GetGroups()

    }

    closeContactModal() {
        this.setState({ showerr: false })
    }
    EditGroup(row) {
        this.setState({ gname: row.group_name, savetype: row.uid })
    }
    EditNumber(row) {
        this.setState({ gname1: row.gname, name: row.name, no1: row.no1, savetype: row.uid })
    }
    AddGroup = async () => {
        const { gname, savetype } = this.state
        let isok = 0
        if (!gname.length) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter Group Name", mysnack: snackeror.myerror })
        }

        if (isok == 0 && savetype == 0) {
            let sql = "insert into pad_externalgroup(group_name) values('" + gname + "') returning 1 as result"
            let data = await sql_funcation(sql)
            if (data.success == true) {
                this.setState({ showerr: true, errorMsg: "New Group Added  successfully", mysnack: snackeror.mysucc })
                this.setState({ gname: '', savetype: 0 })
                this.GetGroups()
            }

        }
        if (isok == 0 && savetype > 0) {
            let sql = "update pad_externalgroup set group_name='" + gname + "' where uid=" + savetype + " returning 1 as result"
            let data = await sql_funcation(sql)
            console.log(data)
            if (data.success == true) {

                this.setState({ showerr: true, errorMsg: "Group Modifyed successfully", mysnack: snackeror.mysucc })
                this.setState({ gname: '', savetype: 0 })
                this.GetGroups()
            }

        }

    }
    AddNumbers = async () => {
        const { savetype, name, no1, gname1 } = this.state
        let isok = 0

        if (!gname1.length) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Select the Group  Name", mysnack: snackeror.myerror })
        }


        if (!name.length) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter  Name", mysnack: snackeror.myerror })
        }


        if (!no1.length) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter contact Number", mysnack: snackeror.myerror })
        }


        if (isok == 0 && savetype > 0) {
            let sql = "update pad_externalno set gname='" + gname1.toString().toLocaleLowerCase() + "', name='" + name.toString().toLocaleLowerCase() + "',no1='" + no1 + "' where uid=" + savetype + " returning 1 as result"
            let editrole = await sql_funcation(sql)
            if (editrole.success == true) {
                this.setState({ showerr: true, errorMsg: "Number Modifyed successfully", mysnack: snackeror.mysucc })
                this.setState({ gname1: '', name: '', no1: '', showaddroles: false, savetype: 0 })
                this.GetNumbers()
            }
            if (editrole.success == false) {
                this.setState({ showerr: true, errorMsg: "Something went wrong please try again", mysnack: snackeror.myerror })
                this.setState({ savetype: 0 })
            }

        }

        if (isok == 0 & savetype == 0) {
            let sql = "insert into pad_externalno(gname,name,no1) values('" + gname1.toString().toLocaleLowerCase() + "','" + name.toString().toLocaleLowerCase() + "','" + no1 + "') returning 1 as result"
            let addrole = await sql_funcation(sql)

            console.log(addrole)
            if (addrole.success == true) {
                this.setState({ showerr: true, errorMsg: "contact Number Added successfully", mysnack: snackeror.mysucc })
                this.setState({ rolename: '', showaddroles: false })
                this.GetNumbers()
            }
            if (addrole.success == false) {
                this.setState({ showerr: true, errorMsg: "Something went wrong please try again", mysnack: snackeror.myerror })
            }



        }

    }



    render() {
        const columns = [

            {
                name: '',
                width: '100px',
                cell: row =>


                    <WhiteBackgroundCheckbox

                        id="chk1"

                    />


            },




            {
                name: 'Name',

                sortable: true,
                cell: row =>
                    row.name
            },
            {
                name: 'Contact-1',

                sortable: true,
                cell: row =>
                    row.no1
            },
            {
                name: 'Contact-2',

                sortable: true,
                cell: row =>
                    row.no2
            },
            {
                name: 'Contact Type',

                sortable: true,
                cell: row =>
                    row.cont_type
            },
            {
                name: 'Action',

                sortable: true,
                cell: row =>
                    <IconButton


                        onClick={() => this.EditNumbers(row)}
                    >
                        <EditOutlined style={{ color: '#0083CA', fontSize: '18px' }} />
                    </IconButton>
            },



        ];

        const groupcolumns = [

            {
                name: '',
                width: '100px',
                cell: row =>


                    <WhiteBackgroundCheckbox

                        id="chk1"

                    />


            },




            {
                name: 'Name',

                sortable: true,
                cell: row =>
                    row.group_name
            },

            {
                name: 'Action',

                sortable: true,
                cell: row =>
                    <IconButton


                        onClick={() => this.EditGroup(row)}
                    >
                        <EditOutlined style={{ color: '#0083CA', fontSize: '18px' }} />
                    </IconButton>
            },



        ];

        const numbercolumns = [

            {
                name: '',
                width: '100px',
                cell: row =>


                    <WhiteBackgroundCheckbox

                        id="chk1"

                    />


            },




            {
                name: 'Group Name',

                sortable: true,
                cell: row =>
                    row.gname
            },

            {
                name: 'Name',

                sortable: true,
                cell: row =>
                    row.name
            },
            {
                name: 'Number',

                sortable: true,
                cell: row =>
                    row.no1
            },
            {
                name: 'Action',

                sortable: true,
                cell: row =>
                    <IconButton


                        onClick={() => this.EditNumber(row)}
                    >
                        <EditOutlined style={{ color: '#0083CA', fontSize: '18px' }} />
                    </IconButton>
            },



        ];
        const numbercolumns1 = [

            {
                name: '',
                width: '100px',
                cell: row =>


                    <WhiteBackgroundCheckbox

                        id="chk1"

                    />


            },




            {
                name: 'Group Name',

                sortable: true,
                cell: row =>
                    row.gname
            },

            {
                name: 'Name',

                sortable: true,
                cell: row =>
                    row.name
            },
            {
                name: 'Number',

                sortable: true,
                cell: row =>
                    row.no1
            },




        ];
        return (

            <div>


                {this.state.pagetype == 2 &&

                    <div>
                        <Grid style={{ width: '100%', marginTop: '1%', display: 'flex', alignItems: 'center', cursor: 'pointer' }}

                            onClick={() => { this.setState({ pagetype: 0 }) }}
                        >

                            <ArrowBackIosOutlined style={{ fontSize: '15px' }}></ArrowBackIosOutlined>
                            <Typography style={{ marginLeft: '1%', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Add Numbers</Typography>
                        </Grid>
                        <Grid style={{ padding: '2%', display: 'flex', width: '95%', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', }}>
                            <Grid style={{ width: '25%', display: 'flex', alignItems: 'center' }}>

                                <Typography style={{ marginRight: '1%', marginLeft: '1%', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Group</Typography>
                                <Select value={this.state.gname1}
                                    displayEmpty
                                    variant="outlined"
                                    fullWidth
                                    size="small"

                                    placeholder='Select'
                                    style={{ height: '41px', }}
                                    inputProps={{
                                        style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                    }}
                                    option
                                    onChange={e => {
                                        this.setState({ gname1: e.target.value });


                                    }}

                                >
                                    <MenuItem value={'0'}>Select</MenuItem>
                                    {this.state.groupdata.map(rname =>

                                        <option style={{ padding: '5px', color: '#333333', fontSize: '18px', fontFamily: 'Roboto-Regular' }} value={rname.group_name} label={rname.group_name}>{rname.group_name}</option>
                                    )};


                                </Select>

                            </Grid>

                            <Grid style={{ width: '35%', display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ marginRight: '1%', marginLeft: '3%', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Name</Typography>
                                <CssTextField focusColor="#999999"

                                    fullWidth

                                    inputProps={{
                                        style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px', },


                                    }}
                                    size="small"

                                    variant="outlined"
                                    placeholder="Enter the Name"
                                    value={this.state.name}

                                    onChange={e => {

                                        this.setState({ name: e.target.value });

                                    }}
                                />
                            </Grid>
                            <Grid style={{ width: '30%', display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ marginRight: '1%', marginLeft: '1%', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Number</Typography>
                                <Typography style={{ marginRight: '1%', marginLeft: '1%', color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>(without+91)</Typography>
                                <CssTextField focusColor="#999999"



                                    inputProps={{
                                        style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px', },


                                    }}
                                    size="small"

                                    variant="outlined"
                                    placeholder="Enter the Number"
                                    value={this.state.no1}

                                    onChange={e => {

                                        this.setState({ no1: e.target.value });

                                    }}
                                />
                            </Grid>
                            <Grid style={{ width: '15%', display: 'flex', alignItems: 'center' }}>
                                <Button



                                    variant="contained"
                                    size="small"

                                    style={{
                                        color: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        borderRadius: "4px",
                                        backgroundColor: "#41AB1C",
                                        textTransform: 'none',
                                        padding: '1.5%',
                                        fontSize: "14px",
                                        width: '150px',
                                        height: '35px',
                                        marginLeft: '5%'

                                    }}

                                    onClick={() => { this.AddNumbers() }}


                                >

                                    Submit
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid style={{ height: 'auto', overflow: 'auto', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', }}>


                            <DataTable

                                columns={numbercolumns}
                                data={this.state.numberdata}
                                pagination
                                striped
                                responsive
                                paginationPerPage='30'

                                dense

                                customStyles={customStyles}
                                highlightOnHover


                                conditionalRowStyles={conditionalRowStyles}
                            />


                        </Grid>
                    </div>

                }

                {this.state.pagetype == 1 &&

                    <div>
                        <Grid style={{ width: '100%', marginTop: '1%', display: 'flex', alignItems: 'center', cursor: 'pointer' }}

                            onClick={() => { this.setState({ pagetype: 0 }) }}
                        >

                            <ArrowBackIosOutlined style={{ fontSize: '15px' }}></ArrowBackIosOutlined>
                            <Typography style={{ marginLeft: '1%', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Add Group</Typography>
                        </Grid>
                        <Grid style={{ padding: '2% 5%', display: 'flex', width: '100%', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', }}>
                            <Grid style={{ width: '30%', display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ marginRight: '1%', marginLeft: '1%', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Group Name</Typography>
                                <CssTextField focusColor="#999999"



                                    inputProps={{
                                        style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px', },


                                    }}
                                    size="small"

                                    variant="outlined"
                                    placeholder="Enter the Name"
                                    value={this.state.gname}

                                    onChange={e => {

                                        this.setState({ gname: e.target.value });

                                    }}
                                />
                            </Grid>

                            <Grid style={{ width: '20%', display: 'flex', alignItems: 'center' }}>
                                <Button



                                    variant="contained"
                                    size="small"

                                    style={{
                                        color: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        borderRadius: "4px",
                                        backgroundColor: "#41AB1C",
                                        textTransform: 'none',
                                        padding: '1.5%',
                                        fontSize: "14px",
                                        width: '150px',
                                        height: '35px',
                                        marginLeft: '5%'

                                    }}

                                    onClick={() => { this.AddGroup() }}


                                >

                                    Submit
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid style={{ height: 'auto', overflow: 'auto', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', }}>


                            <DataTable

                                columns={groupcolumns}
                                data={this.state.groupdata}
                                pagination
                                striped
                                responsive
                                paginationPerPage='30'

                                dense

                                customStyles={customStyles}
                                highlightOnHover


                                conditionalRowStyles={conditionalRowStyles}
                            />


                        </Grid>
                    </div>

                }
                {this.state.pagetype == 0 &&

                    <Grid style={{ width: '100%', padding: '1%', justifyContent: 'center' }}>

                        <Grid style={{ width: '95%' }}>
                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>External No</Typography>
                        </Grid>

                        <Grid style={{ width: '95%', display: 'flex', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', padding: '2%' }}>
                            <Grid style={{ width: '75%', }}>
                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>{this.state.numofrows} External No</Typography>
                            </Grid>
                            <Grid style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>

                                <Button



                                    variant="contained"
                                    size="small"

                                    style={{
                                        color: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        borderRadius: "4px",
                                        backgroundColor: "#41AB1C",
                                        textTransform: 'none',
                                        padding: '1.5%',
                                        fontSize: "14px",
                                        width: '150px',
                                        height: '32px',
                                        marginLeft: '5%'

                                    }}

                                    onClick={() => { this.setState({ pagetype: 1, savetype: 0 }) }}


                                >

                                    Add New Group
                                </Button>
                            </Grid>
                            <Grid style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>

                                <Button



                                    variant="contained"
                                    size="small"

                                    style={{
                                        color: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        borderRadius: "4px",
                                        backgroundColor: "#41AB1C",
                                        textTransform: 'none',
                                        padding: '1.5%',
                                        fontSize: "14px",
                                        width: '150px',
                                        height: '32px',
                                        marginLeft: '5%'

                                    }}

                                    onClick={() => { this.setState({ pagetype: 2, savetype: 0 }) }}


                                >

                                    Add New Number
                                </Button>
                            </Grid>

                        </Grid>
                        <Grid style={{ height: 'auto', overflow: 'auto', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', }}>


                            <DataTable

                                columns={numbercolumns1}
                                data={this.state.numberdata}
                                pagination
                                striped
                                responsive
                                paginationPerPage='30'

                                dense

                                customStyles={customStyles}
                                highlightOnHover


                                conditionalRowStyles={conditionalRowStyles}
                            />


                        </Grid>
                    </Grid>
                }
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "Roboto-Bold"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
            </div>

        )
    }
}
export default (withStyles(txtstyles)((extrenalno)));