import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';


import MainLandingPage from './ui-routeing/mainroute'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <MainLandingPage />

);


