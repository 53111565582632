import React, { useState, useContext } from 'react'
import { FormContext } from '../../FormContext'
import { TextField } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';


const Select1 = ({ field_id, field_label, field_options }) => {
    const { handleJsonChange1 } = useContext(FormContext)

    const [name, setName] = useState(0);


    return (
        <>

            {/*   <select
                displayEmpty
                variant="outlined"
                placeholder="Age"
                onChange={event => handleJsonChange1(field_id, event)}
                style={{ fontSize: '16px', color: '#333333', fontFamily: 'Roboto-Regular', marginLeft: '2%', height: '40px', marginTop: '2%', width: '260px', background: '#ffffff', border: '1px solid #999999', borderRadius: '4px' }}
            >
                <option>{field_label}</option>
                {field_options.length > 0 && field_options.map((option, i) =>
                    <option value={option.option_label}
                        key={i}>{option.option_label}</option>

                )}
            </select>

                */}

            <Autocomplete

                variant="text"
                fullWidth
                style={{ fontSize: '14px', color: 'red', fontFamily: 'Roboto-Regular', marginLeft: '2%', height: '40px', marginTop: '2%', background: '#ffffff' }}
                sx={{ fontSize: '14px', color: 'red', boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 1 } }}
                id="combo-box-demo"

                onChange={(event, value) => handleJsonChange1(field_id, value)}
                options={field_options.map((option) => option.option_label)}
                renderInput={(params) => <TextField
                    style={{ fontSize: '14px', color: 'red', fontFamily: 'Roboto-Regular' }}


                    {...params} label={field_label} />}



            />



        </>
    )
}

export default Select1
