
import React, { Component } from 'react'
import { Typography, Grid, Button, Checkbox, Select, MenuItem } from '@material-ui/core'
import { withStyles, styled } from "@material-ui/core/styles";
const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#41AB1C",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 10,
            width: 10,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#41AB1C'
        }
    },
    checked: { color: "#41AB1C", }
}))(Checkbox);
export default class configurations extends Component {
    constructor(props) {
        super(props)


        this.state = {


            startaction: '0',

        }


    }
    render() {
        return (
            <div>
                <Grid style={{ width: '100%', marginTop: '1%' }}>
                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Dynamic Agent Routing</Typography>
                </Grid>

                <Grid style={{ width: '100%', marginLeft: '4%', marginTop: '1%', border: '1px solid #DFE0EB', borderRadius: '8px', padding: '2%' }}>
                    <Grid style={{ width: '100%', display: 'flex' }}>
                        <Grid style={{ width: '75%', display: 'flex' }}>
                            <Grid style={{ width: '35%', display: 'flex', alignItems: 'center' }}>
                                <Select value={this.state.startaction}
                                    displayEmpty
                                    variant="outlined"

                                    size="small"
                                    fullWidth

                                    style={{ height: '42px', }}

                                    onChange={e => {
                                        this.setState({ startaction: e.target.value });


                                    }}

                                >
                                    <MenuItem value={'0'}>Type</MenuItem>


                                </Select>
                            </Grid>
                            <Grid style={{ marginLeft: '5%', width: '50%', display: 'flex', padding: '1%' }}>
                                <Select value={this.state.startaction}
                                    displayEmpty
                                    variant="outlined"

                                    size="small"
                                    fullWidth

                                    style={{ height: '42px', }}

                                    onChange={e => {
                                        this.setState({ startaction: e.target.value });


                                    }}

                                >
                                    <MenuItem value={'0'}>Agent Order</MenuItem>


                                </Select>
                            </Grid>
                        </Grid>
                        <Grid style={{ width: '10%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

                            <Button



                                variant="contained"
                                size="small"

                                style={{
                                    color: "#ffffff",
                                    fontFamily: "Roboto-Medium",
                                    borderRadius: "4px",
                                    backgroundColor: "#41AB1C",
                                    textTransform: 'none',
                                    padding: '1.5%',
                                    fontSize: "14px",
                                    width: '150px',
                                    height: '32px',
                                    marginLeft: '5%'

                                }}

                                onClick={() => { /* */ }}


                            >

                                Apply
                            </Button>
                        </Grid>
                    </Grid>


                </Grid>
            </div>
        )
    }
}
