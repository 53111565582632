
import React, { Component } from 'react'
import { Typography, Grid, Button, Checkbox, Select, TextField } from '@material-ui/core'
import { withStyles, styled } from "@material-ui/core/styles";
const txtstyles = theme => ({




})
const CssTextField = styled(TextField)({
    borderRadius: '12px',


    '& label.Mui-focused': {
        color: '#999999',

    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#999999',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#999999',
        },
        '&:hover fieldset': {
            borderColor: '#999999',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#999999',
        },
    },
});
const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#41AB1C",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 10,
            width: 10,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#41AB1C'
        }
    },
    checked: { color: "#41AB1C", }
}))(Checkbox);
class domains extends Component {
    constructor(props) {
        super(props)


        this.state = {


            startaction: '0',
            cdid: '',
            trunk: '',
            routings: '',
            gateway: ''

        }


    }
    render() {
        return (
            <div>
                <Grid style={{ width: '100%', marginTop: '1%' }}>
                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Domains ( Teams)</Typography>
                </Grid>

                <Grid style={{ width: '100%', marginTop: '5%', marginLeft: '5%', display: 'flex', alignItems: 'center' }}>
                    <Grid style={{ width: '10%' }}>
                        <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Configure DID</Typography>
                    </Grid>
                    <Grid style={{ width: '50%' }}>
                        <CssTextField focusColor="#999999"


                            fullWidth
                            inputProps={{
                                style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                            }}
                            size="small"

                            variant="outlined"
                            placeholder="Enter Configure DID"
                            value={this.state.cdid}

                            onChange={e => {

                                this.setState({ cdid: e.target.value });

                            }}
                        />
                    </Grid>
                </Grid>


                <Grid style={{ width: '100%', marginTop: '3%', marginLeft: '5%', display: 'flex', alignItems: 'center' }}>
                    <Grid style={{ width: '10%' }}>
                        <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Trunk ID</Typography>
                    </Grid>
                    <Grid style={{ width: '50%' }}>
                        <CssTextField focusColor="#999999"


                            fullWidth
                            inputProps={{
                                style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                            }}
                            size="small"

                            variant="outlined"
                            placeholder="Enter Trunk ID"
                            value={this.state.trunk}

                            onChange={e => {

                                this.setState({ trunk: e.target.value });

                            }}
                        />
                    </Grid>
                </Grid>

                <Grid style={{ width: '100%', marginTop: '3%', marginLeft: '5%', display: 'flex', alignItems: 'center' }}>
                    <Grid style={{ width: '10%' }}>
                        <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Routings ID</Typography>
                    </Grid>
                    <Grid style={{ width: '50%' }}>
                        <CssTextField focusColor="#999999"


                            fullWidth
                            inputProps={{
                                style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                            }}
                            size="small"

                            variant="outlined"
                            placeholder="Enter Routings ID"
                            value={this.state.routings}

                            onChange={e => {

                                this.setState({ routings: e.target.value });

                            }}
                        />
                    </Grid>
                </Grid>
                <Grid style={{ width: '100%', marginTop: '3%', marginLeft: '5%', display: 'flex', alignItems: 'center' }}>
                    <Grid style={{ width: '10%' }}>
                        <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Gateway ID</Typography>
                    </Grid>
                    <Grid style={{ width: '50%' }}>
                        <CssTextField focusColor="#999999"


                            fullWidth
                            inputProps={{
                                style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                            }}
                            size="small"

                            variant="outlined"
                            placeholder="Enter Gateway ID"
                            value={this.state.gateway}

                            onChange={e => {

                                this.setState({ gateway: e.target.value });

                            }}
                        />
                    </Grid>
                </Grid>
                <Grid style={{ width: '100%', marginTop: '3%', marginLeft: '5%', display: 'flex', alignItems: 'center' }}>
                    <Grid style={{ width: '10%' }}>

                    </Grid>
                    <Grid style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button



                            variant="contained"
                            size="small"

                            style={{
                                color: "#ffffff",
                                fontFamily: "Roboto-Medium",
                                borderRadius: "4px",
                                backgroundColor: "#41AB1C",
                                textTransform: 'none',
                                padding: '1.5%',
                                fontSize: "14px",
                                width: '150px',
                                height: '32px',
                                marginLeft: '5%'

                            }}

                            onClick={() => { /* */ }}


                        >

                            Submit
                        </Button>
                    </Grid>
                </Grid>
                <Grid style={{ width: '100%', marginTop: '1%', marginLeft: '5%', display: 'flex' }}>




                    <Grid style={{ width: '15%', marginLeft: '5%' }}>

                    </Grid>
                </Grid>
            </div>
        )
    }
}
export default (withStyles(txtstyles)((domains)));