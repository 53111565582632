import React, { Component } from 'react'
import { Modal, Grid, Typography, Button, TextField, IconButton, Checkbox, Box, Select, MenuItem } from "@material-ui/core"
import jwt_decode from "jwt-decode";
import { withStyles, styled } from "@material-ui/core/styles";
import Dashbord_agent from './dashbord.js';
import Monitoring from '../../admin/dashbord/Monitoring';
import Adminform from '../../admin/dashbord/adminform.js';
import Reports from '../../admin/dashbord/reports.js';
import Campaigns from '../../admin/dashbord/camp.js'
import Configurations from '../../admin/dashbord/configurations.js'
import Domains from '../../admin/dashbord/domains.js'
import Newuser from '../../admin/dashbord/newuser.js';
import Apiendpoints from '../../admin/dashbord/apiendpoints'
import Roles from '../../admin/dashbord/roles.js';
import Externalno from '../../admin/dashbord/extrenalno'
import Newdomain from '../../admin/dashbord/newdomain.js';
import Dashbord from '../../admin/dashbord/dashbord.js'
import Addgroup from '../../admin/dashbord/addgroup';
import Rawreport from '../../admin/dashbord/rawreport';
import CloseIcon from "@material-ui/icons/Close";
import Ivr from '../../admin/dashbord/ivr/ivrmain.js'
import Obd from '../../admin/dashbord/ivr/obd.js'
import axios from "axios";
import moment from 'moment'
import backendHosts from '../../../ui-utils/apiConfig.js'
import { httpRequest } from '../../../ui-utils/api'
import ReactTimerStopwatch from 'react-stopwatch-timer';
import ReactStopwatch from 'react-stopwatch';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import sql_funcation from '../../../ui-utils/sql_funcation.js';
import Weekly_dashboard from '../../admin/dashbord/weekly_dashboard.js';
import { MicRounded, VolumeDown } from '@material-ui/icons';

const txtstyles = theme => ({

    mymenu: {
        color: '#333333',
        display: 'flex',
        justifyContent: 'center',
        justifyItems: 'center',
        paddingLeft: '25px',
        fontFamily: 'Roboto-Medium',
        fontSize: '14px',

    },
    menuback: {
        alignContent: 'center',
        alignItems: 'center',
        display: 'flex',
        height: '45px',
        marginTop: '5px',
        background: '#fffff',
        marginLeft: '15px',
        color: '#333333',
        fontFamily: 'Roboto-Medium',

        fontSize: '14px',
        "&:hover": {
            background: "#7DAB1C",
            color: '#ffffff',
            borderRight: "5px solid #DCD354",


        },
        "&:click": {
            background: "red",
            color: '#ffffff',
            borderRight: "5px solid #DCD354",


        },

    },

    menuback1: {
        alignContent: 'center',
        alignItems: 'center',
        display: 'flex',
        height: '45px',
        marginTop: '5px',

        marginLeft: '15px',

        fontFamily: 'Roboto-Medium',

        fontSize: '14px',

        background: "#7DAB1C",
        color: '#ffffff',
        borderRight: "5px solid #DCD354",




    },
    breaktimerst: {
        color: '#333333',
    }

});

const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#41AB1C",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 10,
            width: 10,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#41AB1C'
        }
    },
    checked: { color: "#41AB1C", }
}))(Checkbox);

const MyReactTimerStopwatch = withStyles(theme => ({
    root: {
        color: "#41AB1C",

    },
    "& .react-stopwatch-timer__table": {
        color: 'red'
    }

}))(ReactTimerStopwatch);


const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    }
};
const loadScript = (FILE_URL, async = true, type = "text/javascript") => {
    return new Promise((resolve, reject) => {
        try {
            const scriptEle = document.createElement("script");
            scriptEle.type = type;
            scriptEle.async = async;
            scriptEle.src = FILE_URL;

            scriptEle.addEventListener("load", (ev) => {
                resolve({ status: true });
            });

            scriptEle.addEventListener("error", (ev) => {
                reject({
                    status: false,
                    message: `Failed to load the script ＄{FILE_URL}`
                });
            });

            document.body.appendChild(scriptEle);
        } catch (error) {
            reject(error);
        }
    });
};
const CssTextField = styled(TextField)({

    '& label.Mui-focused': {
        color: '#999999',

    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#999999',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#999999',
        },
        '&:hover fieldset': {
            borderColor: '#999999',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#999999',
        },
    },
});

const fromTime = new Date(0, 0, 0, 0, 0, 0, 0);
let agentid, password, agentname, liginresp, menulist
let user_role = 0
let user_domain_id = 0
let intid
//let spkst = false

const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

class dashbord extends Component {
    constructor(props) {
        super(props)


        this.state = {


            menuno: '110',
            logindata: '',
            showBreak: false,
            showother: 0,
            reason: '0',
            reason1: '',
            ver: '1.0.5',
            agentid: '0',
            breakpage: 0,
            starttime: false,
            breackremark: '',
            showeditprofile: false,
            username: '',
            userpin: '',
            usercont: '',
            showlogout: false,
            starttime: false,
            logout_tit: '-',
            menuconfig: 0,
            configmenu: 0,
            b_button: "As Agent",
            b_button_type: 100,
            startaction: '0',
            sip_url: '',
            sip_port: '',
            menu1: false,
            menu2: false,
            menu3: false,
            menu4: false,
            menu5: false,
            menu6: false,
            menu7: false,
            menu8: false,
            menu9: false,
            menu10: false,
            menu11: false,
            menu12: false,
            menu13: false,
            menu14: false,
            menu15: false,
            menu1_1: false,
            micst: false,
            heartbeatid: 0,
            sipdiscountcount: 0,
            sipstatus: false,
            refreshid: 0,
            refresftime: 0,
            showtockenerror: false,


        }
        liginresp = props.location.state.liginresp
        this.state.logindata = props.location.state.liginresp
        agentid = props.location.state.liginresp.user_data.user_id
        user_role = props.location.state.liginresp.user_data.user_role
        user_domain_id = props.location.state.liginresp.user_data.user_domain_id

        menulist = props.location.state.liginresp.user_data.menu_id

    }

    MySqlUpdate = async (sql) => {

        console.log("New sql", sql)
        const item = {
            "stmt": sql

        }



        try {

            const submitdata = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: item,

            });
            //     console.log('responce', submitdata.success)


        } catch (error) {
            console.log("Error", error)

        }

    }
    MessageHandler1(data) {



        let status_data, logout_tit


        data = JSON.parse(data)


        for (var key in data) {
            status_data = data[key]['status']
            logout_tit = data[key]['call_id']
            console.log('status_data', status_data)


            if (status_data == 'LOGOUT') {
                this.ForceLogout()
                this.setState({ sipstatus: false, showlogout: true, starttime: true, logout_tit: logout_tit })



            }






        }



    }
    handelSipDisconn(e) {
        // Disconnected 

        console.log("Sip DISConnect")

        console.log('this.state.sipdiscountcount', this.state.sipdiscountcount)

        /* if (this.state.sipdiscountcount !== 100) {
             this.state.sipdiscountcount = this.state.sipdiscountcount + 1
             if (this.state.sipdiscountcount <= 1) {
 
                 this.setState({ sip_url: liginresp.user_data.fs_sip_websocket_proxy_sec_url })
                 let myport = liginresp.user_data.fs_sip_websocket_proxy_sec_url.split(':')[2]
                 this.state.sip_port = myport
 
                 this.mysipRegister()
             }
             if (this.state.sipdiscountcount > 1) {
 
                 this.setState({ showlogout: true, starttime: true, logout_tit: "You are disconnected from Voice Server, Logging Out" })
             }
         }*/
        if (this.state.sipdiscountcount !== 100) {
            this.setState({ showlogout: true, starttime: true, logout_tit: "You are disconnected from Voice Server, Logging Out" })
        }
    }
    handelSipEventStack(e) {



        switch (e.type) {


            case "i_new_message":
                {
                    e.newSession.accept(); // e.newSession.reject(); to reject the message
                    console.log('SMS-content = ' + e.getContentString() + ' and SMS-content-type = ' + e.getContentType());

                    this.MessageHandler1(e.getContentString())

                    break;
                }
            case "failed_to_start":
                {

                    break;
                }


            default:
                break;
        }
    }


    Logout = async (sql) => {
        this.setState({ sipdiscountcount: 100 })

        window.janus_sip.logout()

        clearInterval(intid)




        const logoutpayload = {
            "aid": agentid,
        }



        const item111 = {
            "aid": agentid,
            "activity": 'LOGOUT',
            "uuid": '-',
            "msg": '-',
            "remark": 'LOGOUT'

        }
        try {

            const submitdata = await httpRequest({
                endPoint: `api/user/activitylog`,
                method: "post",
                instance: "instanceOne",
                requestBody: item111,

            });


        } catch (error) {

        }



        try {

            const submitdata = await httpRequest({
                endPoint: `api/user/logout`,
                method: "post",
                instance: "instanceOne",
                requestBody: logoutpayload,

            });

            localStorage.removeItem('lcc_token')
        } catch (error) {

        }


        this.props.history.push('/', this.state);

    }

    ForceLogout = async () => {
        window.janus_sip.logout()
        localStorage.removeItem('lcc_token')
        clearInterval(intid)


        const item111 = {
            "aid": agentid,
            "activity": 'Force_LOGOUT',
            "uuid": '-',
            "msg": '-',
            "remark": 'Force_LOGOUT'

        }
        try {

            const submitdata = await httpRequest({
                endPoint: `api/user/activitylog`,
                method: "post",
                instance: "instanceOne",
                requestBody: item111,

            });


        } catch (error) {

        }
        const logoutpayload = {
            "aid": agentid,
        }


        try {

            const submitdata = await httpRequest({
                endPoint: `api/user/logout`,
                method: "post",
                instance: "instanceOne",
                requestBody: logoutpayload,

            });

            localStorage.removeItem('lcc_token')
        } catch (error) {

        }



    }

    SpackerState() {


        if (window.oSipSessionCall) {

            this.setState({ spkst: true, micst: true })
        } else {
            this.setState({ spkst: false, micst: false })

        }
    }

    componentWillUnmount() {
        clearInterval(intid)
    }

    componentDidMount = async () => {
        if (window.performance) {
            console.log('performance.navigation.type ', performance.navigation.type)
            if (performance.navigation.type == 1) {
                this.tockenrefresh()

            }
        }



        this.state.sip_url = liginresp.user_data.fs_sip_websocket_proxy_url

        let myport = liginresp.user_data.fs_sip_websocket_proxy_url.split(':')[2]
        this.state.sip_port = '8989'


        let a = localStorage.getItem('lcc_token') || '{}'
        let b = JSON.parse(a)


        console.log("exp time to set", b.exptime)
        intid = setInterval(() => {
            console.log('Call refresh tocken')
            this.tockenrefresh()
        }, (b.exptime * 1000));

        /*
         case "m_stream_audio_local_added":
    case "m_stream_audio_local_removed":
    case "m_stream_audio_remote_added":
    case "m_stream_audio_remote_removed":
        */
        window.JanusSipEvent = async (result) => {


            let s = JSON.stringify(result)
            s = JSON.parse(s)
            console.log('JanusSipEvent***', s)
            console.log('hasownproperty', s.hasOwnProperty('result'))

            if (s.hasOwnProperty('result') === true) {
                var r = result["result"]
                var event = r["event"];

                if (event === "message") {
                    let msg_data = r["content"]
                    console.log('Msg content', msg_data)
                    this.MessageHandler1(msg_data)
                }
                if (event === "registered") {
                    this.setState({ sipstatus: true })
                }
                if (event === "unregistered") {
                    this.handelSipDisconn()
                }
                if (event === "registration_failed") {
                    this.setState({ sipstatus: false })
                }
            }

        }




        window.JanusErrorEvent = async (msg) => {
            console.log(msg)
            if (msg === "Lost connection to the server (is it down?)") {
                console.log("Show Force Logout");
                this.setState({ sipstatus: false })
            }
            this.handelSipDisconn()

        }

        window.AudioStrem = async (stype) => {
            if (stype === 1) {
                this.setState({ micst: true, spkst: true })
            }
            if (stype === 0) {
                this.setState({ micst: false, spkst: false })
            }
        }
        window.myonSipEventSession = async (e) => {


            console.log('SIP Status', e.type)
            if (e.type === "m_stream_audio_local_added") {

                this.setState({ micst: true })


            }
            if (e.type === "m_stream_audio_local_removed") {
                this.setState({ micst: false })
            }

            if (e.type === "m_stream_audio_remote_added") {
                await sleep(1000)
                this.SpackerState()



            }
            if (e.type === "m_stream_audio_remote_removed") {
                this.setState({ spkst: false })
            }




            if (e.type === "transport_error") {


                //  this.setState({ sip_url: liginresp.user_data.fs_sip_websocket_proxy_url })
                this.setState({ sip_url: liginresp.user_data.fs_sip_websocket_proxy_sec_url })
                this.mysipRegister()
            }
            if (e.type === "connected") {


                this.setState({ sipstatus: true, showlogout: false, sipdiscountcount: 0 })
            }

        };
        window.myonSipEventStack = (e) => {

            this.handelSipEventStack(e)

        };

        window.myonSipEventStack1 = (e, oConfigCall) => {

            this.handelSipEventStack(e)

        };
        window.myonSipDisconnect = (e) => {

            this.handelSipDisconn(e)
        };


        if (user_domain_id == 11) {
            this.setState({ menuconfig: 1 })
        }

        const re = menulist.split(',').map(data => {


            if (data === '1') { this.setState({ menu1: true }) }
            if (data === '2') { this.setState({ menu2: true }) }
            if (data === '3') { this.setState({ menu3: true }) }
            if (data === '4') { this.setState({ menu4: true }) }
            if (data === '5') { this.setState({ menu5: true }) }
            if (data === '6') { this.setState({ menu6: true }) }
            if (data === '7') { this.setState({ menu7: true }) }
            if (data === '8') { this.setState({ menu8: true }) }
            if (data === '9') { this.setState({ menu9: true }) }
            if (data === '10') { this.setState({ menu10: true }) }
            if (data === '11') { this.setState({ menu11: true }) }
            if (data === '12') { this.setState({ menu12: true }) }
            if (data === '13') { this.setState({ menu13: true }) }
            if (data === '14') { this.setState({ menu14: true }) }
            if (data === '15') { this.setState({ menu15: true }) }
        })



        if (user_role == 3) {
            this.setState({ menuno: 100, b_button_type: 1, menu1: false, menu1_1: true })
        }
        if (user_role == 2) {
            this.setState({ menuno: 11, startaction: '1', b_button_type: 100, })
        }


    }



    mysipRegister() {
        console.log('sip url**', this.state.sip_url)
        window.sip_display_name = liginresp.user_data.fs_sip_display_name
        window.sip_realm = liginresp.user_data.fs_sip_realm
        window.sip_impi = liginresp.user_data.user_id
        window.sip_impu = liginresp.user_data.fs_sip_impu
        window.fs_sip_enable_rtcweb_breaker = false// liginresp.user_data.fs_sip_enable_rtcweb_breaker
        window.sip_websocket_proxy_url = this.state.sip_url//"wss://crm.amakrushi.in:9062" //liginresp.user_data.fs_sip_websocket_proxy_url
        window.sip_password = liginresp.user_data.fs_sip_cred
        window.sip_outbound_proxy_url = liginresp.user_data.fs_sip_outbound_proxy_url



        window.sipRegister();


    }

    tockenrefresh = async () => {

        console.log('Call refresh tocken***')
        let a = localStorage.getItem('lcc_token') || '{}'
        let b = JSON.parse(a)

        console.log('Call refresh tocken***', b.token)

        let axiosConfig = {
            headers: {

                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "accept": "application/json",
                "authorization ": b.token
            }
        };

        var postData = {

        };

        axios.post(backendHosts.CRM + 'api/users/refresh', postData, axiosConfig)
            .then((res) => {
                console.log("refresh RESPONSE RECEIVED: ", res.data);
                if (res.data.success === false) {
                    this.setState({ showtockenerror: true })
                }
                if (res.data.success === true) {
                    console.log("After ", res.data.token)
                    let exptime = jwt_decode(res.data.token)
                    let ptime = new Date();
                    let tdiff = (parseInt(exptime.exp) - parseInt(ptime.getTime() / 1000)) - 10

                    const lcc_token = {
                        token: res.data.token,
                        exptime: parseInt(tdiff),


                    }
                    localStorage.removeItem('lcc_token')

                    localStorage.setItem('lcc_token', JSON.stringify(lcc_token))

                }
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.setState({ showtockenerror: true })
            })
    }
    TakeBreak = async () => {



        this.setState({ showBreak: true, breakpage: 0 })


    }
    handelBreak = async (res) => {
        this.setState({ showrep: 1, breakpage: 1, starttime: true, breackremark: res })
        let da = moment(new Date()).format("YYYY-MM-DD")
        let btime = moment(new Date()).format("YYYY-MM-DD HH:MM:ss")
        console.log("btime", btime)

        this.MySqlUpdate("update pad_live  set status='Break',lastact_time=now() where date='" + da + "' and aid='" + agentid + "'  returning 1 as result")
        // this.MySqlUpdate("insert into pad_agent_activate(aid,activate,remark) values('" + agentid + "','Break','" + res + "')  returning 1 as result")
        const item111 = {
            "aid": agentid,
            "activity": 'Break',
            "uuid": '-',
            "msg": '-',
            "remark": res

        }
        try {

            const submitdata = await httpRequest({
                endPoint: `api/user/activitylog`,
                method: "post",
                instance: "instanceOne",
                requestBody: item111,

            });


        } catch (error) {

        }

    }

    EditProfile = async (res) => {
        let sql = "select name,pin,contact from pad_agent where aid='" + agentid + "' and status='Enabled'";
        console.log(sql)
        const item = {
            "stmt": sql

        }
        try {

            const submitdata = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: item,

            });
            console.log('responce', submitdata.msg)
            if (submitdata.success == true) {
                this.setState({ username: submitdata.msg[0].name, userpin: submitdata.msg[0].pin, showeditprofile: true })

            }


        } catch (error) {
            console.log("Error", error)

        }

    }

    BackToWork = async () => {
        this.setState({ showBreak: false })
        let da = moment(new Date()).format("YYYY-MM-DD")
        // updated SQL for lastact_time
        this.MySqlUpdate("update pad_live  set status='',lastact_time=now() where date='" + da + "' and aid='" + agentid + "'  returning 1 as result")
        //this.MySqlUpdate("insert into pad_agent_activate(aid,activate) values('" + agentid + "','Back To Work') returning 1 as result")

        const item111 = {
            "aid": agentid,
            "activity": 'Back To Work',
            "uuid": '-',
            "msg": '-',
            "remark": '-'

        }
        try {

            const submitdata = await httpRequest({
                endPoint: `api/user/activitylog`,
                method: "post",
                instance: "instanceOne",
                requestBody: item111,

            });


        } catch (error) {

        }

    }
    WorkStatus = async (e) => {
        console.log(e.target.value)
        let da = moment(new Date()).format("YYYY-MM-DD")
        if (e.target.value == 1) {
            user_role = 2
            this.setState({ menuno: 11, b_button_type: 100 })
            let sql = "update pad_live set callstatus='D',lastact_time=now()  where aid='" + agentid + "' and date(date)='" + da + "' returning 1 as result"
            let data = await sql_funcation(sql)

        }
        if (e.target.value == 0) {
            user_role = 3
            this.setState({ menuno: 100, b_button_type: 100 })
            let sql = "update pad_live set callstatus='A',lastact_time=now()  where aid='" + agentid + "' and date(date)='" + da + "' returning 1 as result"
            let data = await sql_funcation(sql)

        }
    }
    SaveEditProfile = async (res) => {

        const { username, usercont, userpin } = this.state
        const lcc_token = {
            0: agentid,
            1: usercont
        }

        this.setState({ usercontacts: JSON.stringify(lcc_token) })


        this.MySqlUpdate("update pad_agent set name='" + username + "',pin='" + userpin + "',contact='" + JSON.stringify(lcc_token) + "' where aid='" + agentid + "'returning 1 as result")
        this.setState({ showeditprofile: false })
    }

    render() {
        const {
            classes,

            history,

        } = this.props;

        const { menuno } = this.state

        return (
            <div >

                <Box style={{ marginTop: '.5%', background: '#ffffff', width: '100%', display: 'flex', alignItems: 'center', height: '68px', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)' }}>
                    <Grid style={{ width: '45%', marginLeft: '3%', marginTop: '5px', display: 'flex' }}>
                        <img src="ui-assests/images/icon.svg" width='152px' height="45px"
                            alt="login" />
                        <Typography style={{ textAlign: 'left', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold', marginLeft: '2%', marginTop: '5%' }}>{agentid} {"-"} {this.state.logindata.user_data.user_name} as {this.state.logindata.user_data.user_domain === "lcc-default" ? "General (Call-center agent)" : this.state.logindata.user_data.user_domain} {" "} on Port :{this.state.sip_port} </Typography>

                    </Grid>

                    <Grid style={{ width: '48%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Typography style={{ marginLeft: '2%', color: this.state.sipstatus ? '#333333' : '#999999', fontFamily: 'Roboto-Medium', fontSize: '14px' }}>Agent Status :</Typography>

                        <Grid style={{ display: 'flex', alignItems: 'center', background: '#F7F9FA', borderRadius: '4px' }}>
                            <div style={{ marginLeft: '1%', borderRadius: '50%', height: '15px', width: '15px', background: this.state.sipstatus ? '#41AB1C' : 'red' }}></div>
                            <MicRounded id="miccolour" style={{ color: this.state.micst ? '#41AB1C' : 'red' }} />
                            <VolumeDown style={{ color: this.state.spkst ? '#41AB1C' : 'red' }} />

                            <Button
                                variant="text"
                                size="small"

                                style={{
                                    color: "#333333",
                                    fontFamily: "Roboto-Medium",
                                    borderRadius: "4px",
                                    backgroundColor: "#F7F9FA",
                                    textTransform: 'none',
                                    padding: '.5%',
                                    fontSize: "14px",
                                    width: '100px',
                                    height: '32px',
                                    marginLeft: '10px'

                                }}

                                //onClick={() => { this.JoinCall(incomingCallEvent) }}
                                //onClick={() => { window.sipRegister() }}
                                onClick={() => { this.TakeBreak() }}
                            >
                                Take a break</Button>
                            {/*  <Select value={this.state.startaction}
                                displayEmpty
                                sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 1 } }}

                                size="small"
                                disableUnderline
                                placeholder='Start Work'
                                style={{ height: '30px', width: '150px', marginLeft: '1%', }}

                                onChange={e => {
                                    this.setState({ startaction: e.target.value });


                                }}

                                onClick={e => {
                                    this.WorkStatus(e)


                                }}

                            >
                                <option value={'0'} style={{ padding: '5px' }} >Online</option>
                                <option value={'1'} style={{ padding: '5px' }}>Take a Break</option>
                                {user_role == 2 &&
                                    <option value={'2'} style={{ padding: '5px' }}>{this.state.b_button}</option>
                                }


                            </Select>*/}
                        </Grid>

                        <Grid style={{ width: '50px', marginTop: '20px' }}>
                            <img src="ui-assests/images/profile.svg" width='24px' height="24px"
                                style={{ marginLeft: '2%', cursor: 'pointer' }}
                                alt="login"
                                onClick={() => { this.EditProfile() }}

                            />


                            <Select value={this.state.startaction}
                                displayEmpty
                                sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 1 } }}

                                size="small"
                                disableUnderline

                                style={{ height: '10px', width: '60px', fontSize: '12px' }}

                                onChange={e => {
                                    this.setState({ startaction: e.target.value });


                                }}

                                onClick={e => {
                                    this.WorkStatus(e)


                                }}

                            >

                                <option value={'0'} style={{ padding: '5px', fontSize: '12px' }}>Agent</option>
                                {this.state.b_button_type == 100 &&
                                    <option value={'1'} style={{ padding: '5px', fontSize: '12px' }}>Admin</option>
                                }


                            </Select>

                        </Grid>
                        <img src="ui-assests/images/logout.svg" width='24px' height="24px"
                            style={{ marginLeft: '2%', cursor: 'pointer' }}
                            alt="logout"
                            onClick={e =>

                                this.Logout()

                            }

                        />
                    </Grid>
                </Box>

                <Grid style={{ width: '100%', display: 'flex' }}>
                    <Box style={{ width: '15%', boxShadow: '0px 0px 12px  rgba(0, 0, 0, 0.2)', height: '100vh' }}>


                        {/*user_role == 2 &&*/
                            <div>
                                {this.state.menu1 === true &&
                                    <Grid className={menuno == 11 ? classes.menuback1 : classes.menuback} style={{
                                        marginTop: '2%',

                                        Visibility: 'hidden'
                                    }}
                                        onClick={() => {
                                            this.setState({ menuno: 11, configmenu: 0 })
                                        }}
                                    >


                                        <img src="ui-assests/images/dash.svg" width='25px' height="25px"
                                            alt="login" />

                                        <Typography className={classes.mymenu}>Dashboard</Typography>
                                    </Grid>
                                }

                                {this.state.menu1_1 === true &&
                                    <Grid className={menuno == 100 ? classes.menuback1 : classes.menuback} style={{
                                        marginTop: '2%',

                                        Visibility: 'hidden'
                                    }}
                                        onClick={() => {
                                            this.setState({ menuno: 100, configmenu: 0 })
                                        }}
                                    >


                                        <img src="ui-assests/images/dash.svg" width='25px' height="25px"
                                            alt="login" />

                                        <Typography className={classes.mymenu}>Dashboard</Typography>
                                    </Grid>
                                }
                                {this.state.menu2 === true &&
                                    <Grid className={menuno == 16 ? classes.menuback1 : classes.menuback} style={{
                                        marginTop: '2%',

                                    }}
                                        onClick={() => {
                                            this.setState({ menuno: 16, configmenu: 0 })
                                        }}
                                    >


                                        <img src="ui-assests/images/dash.svg" width='25px' height="25px"
                                            alt="login" />

                                        <Typography className={classes.mymenu}>Weekly Dashboard</Typography>
                                    </Grid>
                                }

                                {this.state.menu3 === true &&
                                    <Grid className={menuno == 0 ? classes.menuback1 : classes.menuback} style={{ marginTop: '2%' }}
                                        onClick={() => {
                                            this.setState({ menuno: 0, configmenu: 0 })
                                        }}
                                    >


                                        <img src="ui-assests/images/dash.svg" width='25px' height="25px"
                                            alt="login" />

                                        <Typography className={classes.mymenu}>Monitoring</Typography>
                                    </Grid>
                                }
                                {this.state.menu4 === true &&
                                    <Grid className={menuno == 2 ? classes.menuback1 : classes.menuback} style={{ marginTop: '1%' }}
                                        onClick={() => {
                                            this.setState({ menuno: 2, configmenu: 0 })
                                        }}
                                    >
                                        <img src="ui-assests/images/Group 13.svg" width='25px' height="25px"
                                            alt="login" />
                                        <Typography className={classes.mymenu}>Reports</Typography>
                                    </Grid>
                                }

                                {this.state.menu5 === true &&
                                    <Grid className={menuno == 13 ? classes.menuback1 : classes.menuback} style={{ marginTop: '1%' }}
                                        onClick={() => {
                                            this.setState({ menuno: 13, configmenu: 0 })
                                        }}
                                    >
                                        <img src="ui-assests/images/newuser.svg" width='25px' height="25px"
                                            alt="login" />
                                        <Typography className={classes.mymenu}>Raw Dump</Typography>


                                    </Grid>
                                }
                                {this.state.menu7 === true &&
                                    <Grid className={menuno == 3 ? classes.menuback1 : classes.menuback} style={{ marginTop: '1%' }}
                                        onClick={() => {
                                            this.setState({ menuno: 3, configmenu: 0 })
                                        }}
                                    >
                                        <img src="ui-assests/images/camp.svg" width='25px' height="25px"
                                            alt="login" />
                                        <Typography className={classes.mymenu}>Campaign</Typography>
                                    </Grid>

                                }
                                {this.state.menu6 === true &&
                                    <Grid className={menuno == 1 ? classes.menuback1 : classes.menuback} style={{ marginTop: '1%' }}
                                        onClick={() => {
                                            this.setState({ menuno: 1, configmenu: 0 })
                                        }}
                                    >
                                        <img src="ui-assests/images/reports.svg" width='25px' height="25px"
                                            alt="login" />
                                        <Typography className={classes.mymenu}>Forms</Typography>
                                    </Grid>
                                }

                                {this.state.menu8 === true &&
                                    <Grid className={menuno == 8 ? classes.menuback1 : classes.menuback} style={{ marginTop: '1%' }}
                                        onClick={() => {
                                            this.setState({ menuno: 8, configmenu: 0 })
                                        }}
                                    >
                                        <img src="ui-assests/images/roles.svg" width='25px' height="25px"
                                            alt="login" />
                                        <Typography className={classes.mymenu}>Roles</Typography>


                                    </Grid>}
                                {this.state.menu9 === true &&
                                    <Grid className={menuno == 9 ? classes.menuback1 : classes.menuback} style={{ marginTop: '1%' }}
                                        onClick={() => {
                                            this.setState({ menuno: 9, configmenu: 0 })
                                        }}
                                    >
                                        <img src="ui-assests/images/domain.svg" width='25px' height="25px"
                                            alt="login" />
                                        <Typography className={classes.mymenu}>Domain</Typography>


                                    </Grid>
                                }
                                {this.state.menu10 === true &&
                                    <Grid className={menuno == 7 ? classes.menuback1 : classes.menuback} style={{ marginTop: '1%' }}
                                        onClick={() => {
                                            this.setState({ menuno: 7, configmenu: 0 })
                                        }}
                                    >
                                        <img src="ui-assests/images/newuser.svg" width='25px' height="25px"
                                            alt="login" />
                                        <Typography className={classes.mymenu}>User</Typography>


                                    </Grid>}
                                {this.state.menu11 === true &&
                                    <Grid className={menuno == 10 ? classes.menuback1 : classes.menuback} style={{ marginTop: '1%' }}
                                        onClick={() => {
                                            this.setState({ menuno: 10, configmenu: 0 })
                                        }}
                                    >
                                        <img src="ui-assests/images/extno.svg" width='25px' height="25px"
                                            alt="login" />
                                        <Typography className={classes.mymenu}>Add  External No</Typography>


                                    </Grid>
                                }
                                {this.state.menu12 === true &&
                                    <Grid className={menuno == 12 ? classes.menuback1 : classes.menuback} style={{ marginTop: '1%' }}
                                        onClick={() => {
                                            this.setState({ menuno: 12, configmenu: 0 })
                                        }}
                                    >
                                        <img src="ui-assests/images/addgroup.svg" width='25px' height="25px"
                                            alt="login" />
                                        <Typography className={classes.mymenu}>Add Group</Typography>


                                    </Grid>
                                }
                                {this.state.menu13 === true &&

                                    <Grid className={menuno == 14 ? classes.menuback1 : classes.menuback} style={{ marginTop: '1%' }}
                                        onClick={() => {
                                            this.setState({ menuno: 14, configmenu: 0 })
                                        }}
                                    >
                                        <img src="ui-assests/images/newuser.svg" width='25px' height="25px"
                                            alt="login" />
                                        <Typography className={classes.mymenu}>IVR</Typography>


                                    </Grid>
                                }

                                {this.state.menu15 === true &&

                                    <Grid className={menuno == 15 ? classes.menuback1 : classes.menuback} style={{ marginTop: '1%' }}
                                        onClick={() => {
                                            this.setState({ menuno: 15, configmenu: 0 })
                                        }}
                                    >
                                        <img src="ui-assests/images/newuser.svg" width='25px' height="25px"
                                            alt="login" />
                                        <Typography className={classes.mymenu}>OBD</Typography>


                                    </Grid>
                                }


                                {/*  <Grid className={classes.menuback} style={{ marginTop: '1%' }}
                                    onClick={() => {
                                        this.setState({ configmenu: 1 })
                                    }}
                                >
                                    <img src="ui-assests/images/Group 13-1.svg" width='25px' height="25px"
                                        alt="login" />
                                    <Typography className={classes.mymenu}>Configurations</Typography>
                                </Grid>

                                {

                                    this.state.configmenu === 1 &&
                                    <Grid className={classes.menuback} style={{ marginTop: '1%', background: 'red' }}
                                        onClick={() => {
                                            this.setState({ configmenu: 1 })
                                        }}
                                    >
                                        <img src="ui-assests/images/Group 13-1.svg" width='20px' height="20px"
                                            alt="login" />
                                        <Typography className={classes.mymenu}>Trunk</Typography>
                                    </Grid>

                                }*/}
                            </div>
                        }


                        {/*   <Grid className={classes.menuback} style={{ marginTop: '1%' }}
                            onClick={() => {
                                this.setState({ menuno: 3 })
                            }}
                        >

                            <Typography className={classes.mymenu}>Call Test</Typography>
                        </Grid>




                        <Grid className={classes.menuback} style={{ marginTop: '1%' }}
                            onClick={() => {
                                this.setState({ menuno: 3 })
                            }}
                        >
                            <img src="ui-assests/images/Group 13-1.svg" width='25px' height="25px"
                                alt="login" />
                            <Typography className={classes.mymenu}>Campaigns</Typography>
                        </Grid>
                        <Grid className={classes.menuback} style={{ marginTop: '1%' }}
                            onClick={() => {
                                this.setState({ menuno: 4 })
                            }}
                        >
                            <img src="ui-assests/images/Group 13-1.svg" width='25px' height="25px"
                                alt="login" />
                            <Typography className={classes.mymenu}>Configurations</Typography>
                        </Grid>*/}
                        {this.state.b_button_type == 10000 &&




                            <Grid className={menuno == 15 ? classes.menuback1 : classes.menuback} style={{ marginTop: '1%' }}
                                onClick={() => {
                                    this.setState({ menuno: 15, configmenu: 0 })
                                }}
                            >
                                <img src="ui-assests/images/Group 13.svg" width='25px' height="25px"
                                    alt="login" />
                                <Typography className={classes.mymenu}>Miss Call</Typography>
                            </Grid>}

                    </Box>
                    <Grid style={{ width: '90%', padding: '1%' }}>
                        {this.state.menuno == 100 && <Dashbord_agent data={this.state} b_button_type={this.state.b_button_type} />}

                        {this.state.menuno == 0 && <Monitoring />}
                        {this.state.menuno == 1 && <Adminform />}
                        {this.state.menuno == 2 && <Reports />}
                        {this.state.menuno == 3 && <Campaigns />}
                        {this.state.menuno == 4 && <Configurations />}
                        {this.state.menuno == 5 && <Domains />}
                        {this.state.menuno == 6 && <Apiendpoints />}
                        {this.state.menuno == 7 && <Newuser />}
                        {this.state.menuno == 8 && <Roles />}
                        {this.state.menuno == 9 && <Newdomain />}
                        {this.state.menuno == 10 && <Externalno />}
                        {this.state.menuno == 11 && <Dashbord data={this.state} />}
                        {this.state.menuno == 12 && <Addgroup />}
                        {this.state.menuno == 13 && <Rawreport />}
                        {this.state.menuno == 14 && <Ivr />}
                        {this.state.menuno == 15 && <Obd />}
                        {this.state.menuno == 16 && <Weekly_dashboard />}

                    </Grid>

                </Grid>
                <Modal
                    sx={{ border: 'none' }}




                    open={this.state.showlogout}





                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '60%',
                            height: '25%',
                            background: '#fffbee',
                            outline: 'none',
                            p: 4,
                            borderRadius: '12px',
                            padding: '2%'
                        }}
                    >

                        <Grid style={{ width: '100%' }}>
                            <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <Typography style={{ color: '#333333', fontSize: '24px', fontFamily: 'Roboto-Bold' }}> {this.state.logout_tit}</Typography>
                            </Grid>
                            <Grid style={{ width: '100%', marginTop: '5px', display: 'flex', justifyContent: 'center' }}>



                                <CountdownCircleTimer
                                    isPlaying
                                    duration={15}
                                    size={100}
                                    colors={['#004777', '#F7B801', '#F7B801', 'red']}
                                    colorsTime={[15, 10, 5, 0]}
                                    onComplete={() => {
                                        this.props.history.push('/test', this.state);
                                    }}
                                >
                                    {({ remainingTime }) => remainingTime}
                                </CountdownCircleTimer>
                            </Grid>

                            <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <Typography style={{ color: '#999999', fontSize: '16px', fontFamily: 'Roboto-Regular' }}> Timer</Typography>
                            </Grid>


                        </Grid>


                    </Box>
                </Modal>
                <Modal
                    sx={{ border: 'none' }}




                    open={this.state.showeditprofile}





                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '35%',
                            height: 'auto',
                            background: '#fffbee',
                            outline: 'none',
                            p: 4,
                            borderRadius: '12px',
                            padding: '2%'
                        }}
                    >

                        <Grid container style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>



                            <Grid style={{ width: '90%', display: 'flex', justifyContent: 'center' }}>
                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}> {this.state.logindata.user_data.user_name}</Typography>
                            </Grid>
                            <Grid style={{ width: '10%', display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton
                                    aria-label="close"

                                    onClick={() => this.setState({ showeditprofile: false })}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>

                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Grid style={{ width: '90%', display: 'flex', alignItems: 'center' }}>
                                <Grid style={{ width: '25%' }} >
                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>User Name                         </Typography>
                                </Grid>
                                <Grid style={{ width: '60%' }} >
                                    <CssTextField focusColor="#999999"

                                        autoFocus={true}

                                        inputProps={{
                                            style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                        }}
                                        size="small"

                                        variant="outlined"
                                        placeholder="Enter User Name"
                                        value={this.state.username}
                                        style={{ width: '250px', marginLeft: '2%' }}
                                        onChange={e => {

                                            this.setState({ username: e.target.value });

                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>


                        <Grid style={{ width: '100%', marginTop: '2%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Grid style={{ width: '90%', display: 'flex', alignItems: 'center' }}>
                                <Grid style={{ width: '25%' }} >
                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>User Contacts                         </Typography>
                                </Grid>
                                <Grid style={{ width: '60%' }} >
                                    <CssTextField focusColor="#999999"

                                        autoFocus={true}

                                        inputProps={{
                                            style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                        }}
                                        size="small"

                                        variant="outlined"
                                        placeholder="Enter User Contacts"
                                        value={this.state.usercont}
                                        style={{ width: '250px', marginLeft: '2%' }}
                                        onChange={e => {

                                            this.setState({ usercont: e.target.value });

                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid style={{ width: '100%', marginTop: '2%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Grid style={{ width: '90%', display: 'flex', alignItems: 'center' }}>
                                <Grid style={{ width: '25%' }} >
                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>User Pin                         </Typography>
                                </Grid>
                                <Grid style={{ width: '60%' }} >
                                    <CssTextField focusColor="#999999"

                                        autoFocus={true}

                                        inputProps={{
                                            style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                        }}
                                        size="small"

                                        variant="outlined"
                                        placeholder="Enter User Pin"
                                        value={this.state.userpin}
                                        style={{ width: '250px', marginLeft: '2%' }}
                                        onChange={e => {

                                            this.setState({ userpin: e.target.value });

                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid style={{ width: '100%', marginTop: '4%', display: 'flex', alignItems: 'center', }}>
                            <Grid style={{ width: '90%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>

                                <Button



                                    variant="contained"
                                    size="small"

                                    style={{
                                        color: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        borderRadius: "4px",
                                        backgroundColor: "#7DAB1C",
                                        textTransform: 'none',
                                        padding: '1.5%',
                                        fontSize: "14px",
                                        width: '125px',
                                        height: '30px',


                                    }}

                                    //onClick={() => { this.JoinCall(incomingCallEvent) }}
                                    //onClick={() => { window.sipRegister() }}
                                    onClick={() => { this.SaveEditProfile() }}
                                >

                                    Submit

                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                <Modal
                    sx={{ border: 'none' }}




                    open={this.state.showBreak}





                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '50%',
                            height: '30%',
                            background: '#fffbee',
                            outline: 'none',
                            p: 4,
                            borderRadius: '12px',
                            padding: '2%'
                        }}
                    >
                        {this.state.breakpage == 1 &&
                            <div>
                                <Grid style={{ width: '100%' }}>
                                    <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <Typography style={{ color: '#333333', fontSize: '24px', fontFamily: 'Roboto-Bold' }}> Now you are in {this.state.breackremark} break</Typography>
                                    </Grid>
                                    <Grid style={{ width: '100%', marginTop: '5px', display: 'flex', justifyContent: 'center' }}>

                                        <ReactStopwatch
                                            seconds={0}
                                            minutes={0}
                                            hours={0}
                                            autoStart={this.state.starttime}
                                            onChange={({ hours, minutes, seconds }) => {
                                                // do something
                                            }}
                                            onCallback={() => console.log('Finish')}
                                            render={({ formatted, hours, minutes, seconds }) => {
                                                return (
                                                    <div>
                                                        <Typography style={{ color: '#00ACFE', fontSize: '32px', fontFamily: 'Roboto-Medium' }}>
                                                            {formatted}
                                                        </Typography>

                                                    </div>
                                                );
                                            }}
                                        />

                                    </Grid>

                                    <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <Typography style={{ color: '#999999', fontSize: '16px', fontFamily: 'Roboto-Regular' }}> Timer</Typography>
                                    </Grid>


                                </Grid>
                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '3%' }}>
                                    <Button



                                        variant="contained"
                                        size="small"

                                        style={{
                                            color: "#ffffff",
                                            fontFamily: "Roboto-Medium",
                                            borderRadius: "4px",
                                            backgroundColor: "#7DAB1C",
                                            textTransform: 'none',
                                            padding: '1.5%',
                                            fontSize: "14px",
                                            width: '200px',
                                            height: '32px',


                                        }}

                                        //onClick={() => { this.JoinCall(incomingCallEvent) }}
                                        //onClick={() => { window.sipRegister() }}
                                        onClick={() => { this.BackToWork() }}
                                    >

                                        Back to Work

                                    </Button>
                                </Grid>
                            </div>
                        }
                        {this.state.breakpage == 0 &&
                            <div>
                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>



                                    <Grid style={{ width: '90%', display: 'flex', justifyContent: 'center' }}>
                                        <Typography style={{ color: '#333333', fontSize: '24px', fontFamily: 'Roboto-Bold' }}> Reason for break</Typography>
                                    </Grid>
                                    <Grid style={{ width: '10%', display: 'flex', justifyContent: 'flex-end' }}>
                                        <IconButton
                                            aria-label="close"

                                            onClick={() => this.setState({ showBreak: false })}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>

                                </Grid>
                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', padding: '2%' }}>

                                    <Grid style={{ cursor: 'pointer', width: '20%', border: '1.5px solid #000000', borderRadius: '14px', padding: '5% 1%' }}

                                        onClick={() => { this.handelBreak("Tea") }}>

                                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src="ui-assests/images/Tea.svg" width='32px' height="29px"
                                                alt="login" style={{ marginLeft: '5px', width: '100%' }} />
                                        </Grid>
                                        <Grid style={{ marginTop: '15%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Typography style={{ textAlign: 'center', width: '100%', color: '#333333', fontSize: '16px', fontFamily: 'Roboto-Bold' }}>Tea</Typography>
                                        </Grid>
                                    </Grid>


                                    <Grid style={{ cursor: 'pointer', width: '20%', border: '1.5px solid #000000', borderRadius: '14px', padding: '5% 1%' }}

                                        onClick={() => { this.handelBreak("Lunch") }}>

                                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src="ui-assests/images/Lunch.svg" width='32px' height="29px"
                                                alt="login" style={{ marginLeft: '5px', width: '100%' }} />
                                        </Grid>
                                        <Grid style={{ marginTop: '15%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Typography style={{ textAlign: 'center', width: '100%', color: '#333333', fontSize: '16px', fontFamily: 'Roboto-Bold' }}>Lunch</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid style={{ cursor: 'pointer', width: '20%', border: '1.5px solid #000000', borderRadius: '14px', padding: '5% 1%' }}
                                        onClick={() => { this.handelBreak("Washroom") }}>
                                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src="ui-assests/images/Washroom.svg" width='32px' height="29px"
                                                alt="login" style={{ marginLeft: '5px', width: '100%' }} />
                                        </Grid>
                                        <Grid style={{ marginTop: '15%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Typography style={{ textAlign: 'center', width: '100%', color: '#333333', fontSize: '16px', fontFamily: 'Roboto-Bold' }}>Washroom</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid style={{ cursor: 'pointer', width: '20%', border: '1.5px solid #000000', borderRadius: '14px', padding: '5% 1%' }}
                                        onClick={() => { this.handelBreak("Other") }}>
                                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src="ui-assests/images/other.svg" width='32px' height="29px"
                                                alt="login" style={{ marginLeft: '5px', width: '100%' }} />
                                        </Grid>
                                        <Grid style={{ marginTop: '15%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Typography style={{ textAlign: 'center', width: '100%', color: '#333333', fontSize: '16px', fontFamily: 'Roboto-Bold' }}>Other</Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </div>
                        }
                    </Box>
                </Modal>
                <Modal
                    sx={{ border: 'none' }}




                    open={this.state.showtockenerror}





                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '30%',
                            height: '10%',
                            background: '#fffbee',
                            outline: 'none',
                            p: 4,
                            borderRadius: '12px',
                            padding: '5%'
                        }}
                    >
                        <div >
                            <Typography style={{ textAlign: 'center', width: '100%', color: '#333333', fontSize: '16px', fontFamily: 'Roboto-Bold' }}>Your session has expired, please re-login</Typography>
                            <div style={{ marginTop: '5%', display: 'flex', justifyContent: 'center' }}>
                                <Button



                                    variant="contained"
                                    size="small"

                                    style={{
                                        color: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        borderRadius: "4px",
                                        backgroundColor: "#7DAB1C",
                                        textTransform: 'none',
                                        padding: '1.5%',
                                        fontSize: "14px",
                                        width: '125px',
                                        height: '30px',


                                    }}

                                    //onClick={() => { this.JoinCall(incomingCallEvent) }}
                                    //onClick={() => { window.sipRegister() }}
                                    onClick={() => { this.Logout() }}
                                >

                                    Ok

                                </Button>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div >
        )
    }
}
export default (withStyles(txtstyles)((dashbord)));