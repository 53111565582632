
import React, { Component } from 'react'
import { Typography, Grid, Button, Checkbox, Select, TextField } from '@material-ui/core'
import { withStyles, styled } from "@material-ui/core/styles";
const txtstyles = theme => ({




})
const CssTextField = styled(TextField)({
    borderRadius: '12px',


    '& label.Mui-focused': {
        color: '#999999',

    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#999999',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#999999',
        },
        '&:hover fieldset': {
            borderColor: '#999999',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#999999',
        },
    },
});
const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#41AB1C",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 10,
            width: 10,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#41AB1C'
        }
    },
    checked: { color: "#41AB1C", }
}))(Checkbox);
class apiendpoints extends Component {
    constructor(props) {
        super(props)


        this.state = {


            startaction: '0',
            cdid: '',
            trunk: '',
            routings: '',
            gateway: ''

        }


    }
    render() {
        return (
            <div>
                <Grid style={{ width: '100%', marginTop: '1%' }}>
                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>API Endpoints</Typography>
                </Grid>

                <Grid style={{ width: '95%', marginTop: '1%', border: '1px solid #DFE0EB', borderRadius: '8px', padding: '2%' }}>
                    <Grid style={{ width: '100%', }}>
                        <Typography style={{ marginLeft: '2%', color: '#333333', fontSize: '13px', fontFamily: 'Roboto-Medium' }}>Predefined config variable</Typography>
                    </Grid>

                    <Grid style={{ width: '100%', marginTop: '1%', marginLeft: '5%', display: 'flex' }}>
                        <Grid style={{ width: '20%' }}>
                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Name</Typography>
                        </Grid>
                        <Grid style={{ width: '20%', marginLeft: '5%' }}>
                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Description</Typography>
                        </Grid>
                        <Grid style={{ width: '20%', marginLeft: '5%' }}>
                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Url</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', marginTop: '1%', marginLeft: '5%', display: 'flex' }}>
                        <Grid style={{ width: '20%' }}>
                            <CssTextField focusColor="#999999"


                                fullWidth
                                inputProps={{
                                    style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                }}
                                size="small"

                                variant="outlined"
                                placeholder="Enter Name"
                                value={this.state.cdid}

                                onChange={e => {

                                    this.setState({ cdid: e.target.value });

                                }}
                            />
                        </Grid>
                        <Grid style={{ width: '20%', marginLeft: '5%' }}>
                            <CssTextField focusColor="#999999"


                                fullWidth
                                inputProps={{
                                    style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                }}
                                size="small"

                                variant="outlined"
                                placeholder="Enter Description"
                                value={this.state.trunk}

                                onChange={e => {

                                    this.setState({ trunk: e.target.value });

                                }}
                            />
                        </Grid>
                        <Grid style={{ width: '20%', marginLeft: '5%' }}>

                            <CssTextField focusColor="#999999"


                                fullWidth
                                inputProps={{
                                    style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                }}
                                size="small"

                                variant="outlined"
                                placeholder="Enter URL"
                                value={this.state.routings}

                                onChange={e => {

                                    this.setState({ routings: e.target.value });

                                }}
                            />
                        </Grid>

                        <Grid style={{ width: '15%', marginLeft: '1%' }}>
                            <Button



                                variant="contained"
                                size="small"

                                style={{
                                    color: "#ffffff",
                                    fontFamily: "Roboto-Medium",
                                    borderRadius: "4px",
                                    backgroundColor: "#41AB1C",
                                    textTransform: 'none',
                                    padding: '1.5%',
                                    fontSize: "14px",
                                    width: '50px',
                                    height: '40px',
                                    marginLeft: '1%'

                                }}

                                onClick={() => { /* */ }}


                            >

                                +
                            </Button>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid style={{ width: '95%', marginTop: '2%', border: '1px solid #DFE0EB', borderRadius: '8px', padding: '2%' }}>
                    <Grid style={{ width: '100%', }}>
                        <Typography style={{ marginLeft: '2%', color: '#333333', fontSize: '13px', fontFamily: 'Roboto-Medium' }}>

                            Other config variables</Typography>
                    </Grid>

                    <Grid style={{ width: '100%', marginTop: '1%', marginLeft: '5%', display: 'flex' }}>
                        <Grid style={{ width: '20%' }}>
                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Name</Typography>
                        </Grid>
                        <Grid style={{ width: '20%', marginLeft: '5%' }}>
                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Description</Typography>
                        </Grid>
                        <Grid style={{ width: '20%', marginLeft: '5%' }}>
                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Url</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', marginTop: '1%', marginLeft: '5%', display: 'flex' }}>
                        <Grid style={{ width: '20%' }}>
                            <CssTextField focusColor="#999999"


                                fullWidth
                                inputProps={{
                                    style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                }}
                                size="small"

                                variant="outlined"
                                placeholder="Enter Name"
                                value={this.state.cdid}

                                onChange={e => {

                                    this.setState({ cdid: e.target.value });

                                }}
                            />
                        </Grid>
                        <Grid style={{ width: '20%', marginLeft: '5%' }}>
                            <CssTextField focusColor="#999999"


                                fullWidth
                                inputProps={{
                                    style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                }}
                                size="small"

                                variant="outlined"
                                placeholder="Enter Description"
                                value={this.state.trunk}

                                onChange={e => {

                                    this.setState({ trunk: e.target.value });

                                }}
                            />
                        </Grid>
                        <Grid style={{ width: '20%', marginLeft: '5%' }}>

                            <CssTextField focusColor="#999999"


                                fullWidth
                                inputProps={{
                                    style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                }}
                                size="small"

                                variant="outlined"
                                placeholder="Enter URL"
                                value={this.state.routings}

                                onChange={e => {

                                    this.setState({ routings: e.target.value });

                                }}
                            />
                        </Grid>

                        <Grid style={{ width: '15%', marginLeft: '1%' }}>
                            <Button



                                variant="contained"
                                size="small"

                                style={{
                                    color: "#ffffff",
                                    fontFamily: "Roboto-Medium",
                                    borderRadius: "4px",
                                    backgroundColor: "#41AB1C",
                                    textTransform: 'none',
                                    padding: '1.5%',
                                    fontSize: "14px",
                                    width: '50px',
                                    height: '40px',
                                    marginLeft: '1%'

                                }}

                                onClick={() => { /* */ }}


                            >

                                +
                            </Button>
                        </Grid>
                    </Grid>

                </Grid>
            </div>
        )
    }
}
export default (withStyles(txtstyles)((apiendpoints)));