import React, { Component } from 'react'
import { Modal, Box, Grid, Typography, Button, TextField, IconButton, Snackbar, SnackbarContent } from "@material-ui/core"
import jwt_decode from "jwt-decode";
import { withStyles, styled } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close'
import { httpRequest_login } from '../../ui-utils/login_api'
import { httpRequest } from '../../ui-utils/api'

const txtstyles = theme => ({




})
const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))
const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    }
};
const CssTextField = styled(TextField)({
    borderRadius: '8px',
    '& label.Mui-focused': {
        color: '#999999',

    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#999999',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#999999',
        },
        '&:hover fieldset': {
            borderColor: '#999999',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#999999',
        },
    },
});

class Login extends Component {
    constructor(props) {
        super(props)



        this.state = {

            agentid: '',
            password: '',
            showerr: false,
            errorMsg: '',
            mysnack: snackeror.mysucc,
            agentname: '',
            user_id: '',
            liginresp: '',
            user_domain_id: 0,
            showlogout: false
        }
    }


    LogClear = async () => {
        const { agentid } = this.state
        this.setState({ showlogout: false })
        const item = {
            "aid": agentid,
            "msg": "Self Logout by Admin"

        }
        try {
            const roledata = await httpRequest({
                endPoint: `api/fs/force_logout`,
                method: "post",
                instance: "instanceOne",
                requestBody: item,

            });



            if (roledata.success === true) {
                this.setState({ showerr: true, errorMsg: "Force Logout success", mysnack: snackeror.mysucc })

            }
        } catch (error) {

        }


    }

    AgentLogin = async () => {

        const { agentid, password } = this.state
        let isok = 0


        if (!agentid.length) {
            isok = 1;
            this.setState({ showerr: true, errorMsg: "Agent ID Required", mysnack: snackeror.myerror })


        }
        if (!password.length && isok === 0) {
            isok = 1;
            this.setState({ showerr: true, errorMsg: "Password Required", mysnack: snackeror.myerror })


        }

        if (isok === 0) {

            let userid = agentid
            let pin = password
            const body = { userid, pin }

            try {
                const login123 = await httpRequest_login({
                    endPoint: `api/users/login`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: body,
                });
                console.log('Login Data', login123)
                // console.log(login.user_data.user_role)

                if (login123.success === true) {
                    console.log("login done", login123)
                    this.state.liginresp = login123
                    this.state.agentname = login123.user_data.user_name
                    this.user_id = login123.user_id
                    this.user_domain_id = login123.user_domain_id

                    let b = login123.token


                    let exptime = jwt_decode(login123.token)



                    let ptime = new Date();
                    console.log(exptime.exp)
                    console.log(ptime.getTime() / 1000)

                    let tdiff = (parseInt(exptime.exp) - parseInt(ptime.getTime() / 1000)) - 10
                    const lcc_token = {
                        token: login123.token,
                        exptime: parseInt(tdiff),

                    }
                    localStorage.removeItem('lcc_token')
                    localStorage.setItem('lcc_token', JSON.stringify(lcc_token))




                    const item = {
                        "aid": userid,
                        "activity": "LOGIN",
                        "uuid": '-',
                        "msg": '-',
                        "remark": '-'

                    }
                    try {

                        const submitdata = await httpRequest({
                            endPoint: `api/user/activitylog`,
                            method: "post",
                            instance: "instanceOne",
                            requestBody: item,

                        });

                        if (submitdata.status === 200) { }

                    } catch (error) {

                    }

                    window.mycallst = 0
                    if (login123.user_data.user_role === 3) {
                        this.props.history.push('/dashbord', this.state);
                    }
                    if (login123.user_data.user_role === 2) {
                        this.props.history.push('/dashbord', this.state);
                    }
                    if (login123.user_data.user_domain_id === 11) {

                        this.props.history.push('/dashbord', this.state);
                    }
                }


                if (login123.success === false) {
                    console.log("login Error")
                    if (login123.msg.domain === "ADMIN 2" || login123.msg.domain === "ADMIN_DOMAIN") {
                        this.setState({ showlogout: true })

                    } else {
                        isok = 1;

                        if (login123.msg.hasOwnProperty("status")) {
                            this.setState({ showerr: true, errorMsg: login123.msg.status, mysnack: snackeror.myerror })
                        } else {
                            this.setState({ showerr: true, errorMsg: login123.msg, mysnack: snackeror.myerror })
                        }


                    }

                }

            } catch (error) {

            }


        }
    }

    closeContactModal() {
        this.setState({ showerr: false })
    }

    render() {


        return (
            <div
                style={{
                    height: '100vh',
                    backgroundImage: `url("ui-assests/images/login.webp")`,
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'


                }
                }
            >
                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', alignContent: 'center' }}>
                    <Grid style={{ width: '50%' }}>
                    </Grid>
                    <Grid style={{ width: '50%', display: 'flex', justifyContent: 'center', height: '100vh', alignItems: 'center' }}>
                        <Grid style={{ height: '50%', width: '60%', background: '#ffffff', borderRadius: '14px', padding: '5%' }}>
                            <Typography style={{ fontSize: '36px', color: '#333333', fontFamily: 'Roboto-Medium', textAlign: 'center' }}>Login</Typography>
                            <Grid style={{ marginTop: '32px' }}>
                                <CssTextField

                                    autoFocus={true}
                                    value={this.state.agentid}
                                    variant="outlined"
                                    placeholder="Agent ID"
                                    fullWidth
                                    inputProps={{
                                        style: { fontSize: '14px', fontFamily: "Roboto-Regular", height: '12px' },


                                    }}
                                    onChange={e =>

                                        this.setState({ agentid: e.target.value })

                                    }

                                />
                            </Grid>
                            <Grid style={{ marginTop: '16px' }}>
                                <CssTextField
                                    value={this.state.password}

                                    fullWidth
                                    variant="outlined"
                                    placeholder="Password"
                                    type="password"
                                    inputProps={{
                                        style: { fontSize: '14px', fontFamily: "Roboto-Medium", height: '12px' },


                                    }}

                                    onChange={e =>

                                        this.setState({ password: e.target.value })

                                    }

                                />
                            </Grid>
                            <Grid style={{ marginTop: '32px' }}>
                                <Button

                                    fullWidth

                                    variant="contained"
                                    size="small"

                                    style={{
                                        color: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        borderRadius: "8px",
                                        backgroundColor: "#41AB1C",
                                        textTransform: 'none',
                                        padding: '1%',
                                        fontSize: "14px",

                                        height: '48px',


                                    }}

                                    onClick={() => { this.AgentLogin() }}


                                >

                                    Login
                                </Button>
                            </Grid>
                            {/*  <Grid style={{ marginTop: '32px', display: 'flex', justifyContent: 'space-evenly' }}>
                                <Button

                                    fullWidth

                                    variant="contained"
                                    size="small"

                                    style={{
                                        color: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        borderRadius: "8px",
                                        backgroundColor: "#41AB1C",
                                        textTransform: 'none',
                                        padding: '1%',
                                        fontSize: "14px",

                                        height: '48px',


                                    }}

                                    onClick={() => { this.JanusTest() }}


                                >

                                    Janus Login
                                </Button>

                                <Button

                                    fullWidth

                                    variant="contained"
                                    size="small"

                                    style={{
                                        color: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        borderRadius: "8px",
                                        backgroundColor: "#41AB1C",
                                        textTransform: 'none',
                                        padding: '1%',
                                        fontSize: "14px",

                                        height: '48px',


                                    }}

                                    onClick={() => { this.JanusTestCall() }}


                                >

                                    Janus Test Call
                                </Button>
                                <Button

                                    fullWidth

                                    variant="contained"
                                    size="small"

                                    style={{
                                        color: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        borderRadius: "8px",
                                        backgroundColor: "#41AB1C",
                                        textTransform: 'none',
                                        padding: '1%',
                                        fontSize: "14px",

                                        height: '48px',


                                    }}

                                    onClick={() => { this.JanusHangup() }}


                                >

                                    Hangup
                                </Button>

                                <Button

                                    fullWidth

                                    variant="contained"
                                    size="small"

                                    style={{
                                        color: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        borderRadius: "8px",
                                        backgroundColor: "#41AB1C",
                                        textTransform: 'none',
                                        padding: '1%',
                                        fontSize: "14px",

                                        height: '48px',


                                    }}

                                    onClick={() => { this.JanusAnswer() }}


                                >

                                    Answer
                                </Button>
                                </Grid>*/}
                        </Grid>

                    </Grid>

                </Grid>

                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3500}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "Roboto-Bold"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>

                <Modal
                    sx={{ border: 'none' }}




                    open={this.state.showlogout}





                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '45%',
                            height: 'auto',
                            background: '#fffbee',
                            outline: 'none',
                            p: 4,
                            borderRadius: '12px',
                            padding: '3%'
                        }}
                    >


                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Grid style={{ width: '90%', display: 'flex', justifyContent: 'center' }}>

                                <Typography style={{ textAlign: 'center', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>There is already an active session exists. Do you want to logout from there and login here ?</Typography>
                            </Grid>
                        </Grid>


                        <Grid style={{ width: '100%', display: 'flex', marginTop: '4%', justifyContent: 'center' }}>
                            <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>

                                <Button



                                    variant="contained"
                                    size="small"

                                    style={{
                                        color: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        borderRadius: "4px",
                                        backgroundColor: "#7DAB1C",
                                        textTransform: 'none',
                                        padding: '1.5%',
                                        fontSize: "14px",
                                        width: '100px',
                                        height: '32px',
                                        marginLeft: '5%'

                                    }}

                                    onClick={(e) => { this.LogClear() }}


                                >

                                    Yes
                                </Button>
                                <Button



                                    variant="contained"
                                    size="small"

                                    style={{
                                        color: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        borderRadius: "4px",
                                        backgroundColor: "#7DAB1C",
                                        textTransform: 'none',
                                        padding: '1.5%',
                                        fontSize: "14px",
                                        width: '100px',
                                        height: '32px',
                                        marginLeft: '5%'

                                    }}

                                    onClick={(e) => { this.setState({ showlogout: false }) }}


                                >

                                    No
                                </Button>

                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            </div >
        )
    }
}
export default (withStyles(txtstyles)((Login)));