import {
    Typography, Grid, Tab, Tabs, Checkbox, Button,
    TextField, Modal, Box, Snackbar, SnackbarContent, IconButton,
} from '@material-ui/core'
import moment from 'moment'
import { httpRequest } from '../../../ui-utils/api'
import CloseIcon from '@material-ui/icons/Close'
import React, { Component } from 'react'
import DataTable from 'react-data-table-component';
import { withStyles, styled } from "@material-ui/core/styles";
import { RecordVoiceOver } from '@material-ui/icons'

import Loader from "react-js-loader";

let totinbound = 0
let totoutbound = 0
let totoutans = 0
let totinans = 0
let totinbreak = 0
let totoutbreak = 0

let totinideal = 0
let totoutideal = 0



const txtstyles = theme => ({

    tabst: {
        cursor: 'pointer', width: '13%', padding: '2%', background: '#ffffff', border: '1px solid #E7EAF0', borderRadius: '10px'
    },

    tabactst: {
        cursor: 'pointer', width: '13%', padding: '2%', background: '#ffffff', border: '2px solid #7DAB1C', borderRadius: '10px'
    },


    tabst_in: {
        cursor: 'pointer', width: '13%', padding: '2%', background: '#ffffff', border: '1px solid #E7EAF0', borderRadius: '10px'
    },

    tabactst_in: {
        cursor: 'pointer', width: '13%', padding: '2%', background: '#ffffff', border: '2px solid #7DAB1C', borderRadius: '10px'
    },


})




const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#41AB1C",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 10,
            width: 10,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#41AB1C'
        }
    },
    checked: { color: "#41AB1C", }
}))(Checkbox);
const conditionalRowStyles = [
    {
        when: row => row.account_status == "E",
        style: {
            backgroundColor: 'green',
            color: 'white',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    },

];

const StyledCell = styled(Grid)({

    '&.Registered': {

        color: '#205806',

    },
    '&.UnRegistered': {

        color: '#D62805',

    },


})

function getCssClass(value) {
    //value)

    if (value === "Registered") {

        return "Registered"
    };

    if (value === "UnRegistered") {


        return "UnRegistered"
    };


}
const customStyles = {
    title: {
        style: {
            fontSize: '14px', // override the row height
        },
    },
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
        stripedStyle: {

            //  background: 'red'



        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#E9F4FF',
            fontSize: '14px',
            color: '#333333',
            height: '45px',
            fontFamily: 'Roboto-Medium'
        },
    },
    cells: {
        style: {
            paddingLeft: '2px', // override the cell padding for data cells
            paddingRight: '2px',
            height: '30px',

        },
    },
    highlightOnHoverStyle: {
        style: {
            color: 'red',
            background: 'blue',
            backgroundColor: 'yellow'



        },
    },

};
const CssTextField = styled(TextField)({
    borderRadius: '23px',
    fontFamily: 'InterRegular',
    background: '#ffff',

    '& .MuiInput-underline:after': {
        borderBottomColor: '#dddddd',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#dddddd',
        },
        '&:hover fieldset': {
            borderColor: '#dddddd',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#dddddd',
        },
    },
});
let tab = 0
const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    }
};
/*
 {

        name: 'Spy',
        width: '130px',
        sortable: true,
        cell: row =>
            <Grid style={{ display: 'flex', alignItems: 'center', width: '150px', background: '#F7F9FA', borderRadius: '19px', padding: '1%' }}
                onClick={() => { this.spycall(row.aid) }} >


                <RecordVoiceOver style={{ color: '#971E04' }} />
            </Grid>




    },
*/
let intid
const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

class newdashbord extends Component {

    constructor(props) {
        super(props)


        this.state = {

            userdata: [],
            tabvalue: 0,
            butname: '',
            maindata: '',

            inboundall: [],
            inboundoncall: [],
            inboundideal: [],
            inboundbreack: [],


            outboundall: [],
            outboundoncall: [],
            outboundideal: [],
            outboundbreack: [],
            callwaitcount1: 0,
            callwaitcount: 0,
            callwaitlist: [],
            callwaitlist1: [],
            userdata1: [],
            callwait_userdata: [],
            callwait_userdata1: [],
            tabstval: 0,
            freeswitchstatus: ''

        }



    }
    handleTabChange = (event, value) => {
        if (value == 0) {
            this.ShowData(0)
        }
        if (value == 1) {
            this.ShowData(4)
        }

        if (value == 2) {
            this.ShowData(8)
        }

        this.setState({ tabvalue: value });
    };

    componentWillUnmount() {
        clearInterval(intid)
    }

    componentDidMount = async () => {

        this.GetNewDashBorardCound()
        await sleep(500)
        this.ShowData(0)



        intid = setInterval(() => {
            this.GetNewDashBorardCound()
            this.ShowData1()
        }, 6000);


        console.log('intid', intid)
    }

    removeA(arr) {
        var what, a = arguments, L = a.length, ax;
        while (L > 1 && arr.length) {
            what = a[--L];
            while ((ax = arr.indexOf(what)) !== -1) {
                arr.splice(ax, 1);
            }
        }
        return arr;
    }

    ShowData(newtab) {
        tab = newtab

        this.setState({ tabstval: tab })


        this.ShowData1()
    }
    ShowData1 = async () => {
        const { maindata } = this.state


        /* const re = maindata.map(data => {
 
 
 
             if (data.domain === "fishery" || data.domain === "agriculture" || data.domain === "lcc-default" || data.domain === "animal_husbandry") {
 
                 this.state.inboundall.push({
                     aid: data.aid === null ? "" : data.aid,
                     name: data.name === null ? "" : data.name,
                     domain: data.domain === null ? "" : data.domain,
                     status: data.status === null ? "" : data.status,
                     duration: ((data.total_duration) / 60).toFixed(0)
                 })
 
                 if (data.status === "Break") {
                     this.state.inboundbreack.push({
                         aid: data.aid === null ? "" : data.aid,
                         name: data.name === null ? "" : data.name,
                         domain: data.domain === null ? "" : data.domain,
                         status: data.status === null ? "" : data.status,
                         duration: ((data.total_duration) / 60).toFixed(0)
                     })
                 }
 
                 if (data.status === "Idle") {
                     this.state.inboundideal.push({
                         aid: data.aid === null ? "" : data.aid,
                         name: data.name === null ? "" : data.name,
                         domain: data.domain === null ? "" : data.domain,
                         status: data.status === null ? "" : data.status,
                         duration: ((data.total_duration) / 60).toFixed(0)
                     })
                 }
 
                 if (data.status === "HANGUP") {
                     this.state.inboundoncall.push({
                         aid: data.aid === null ? "" : data.aid,
                         name: data.name === null ? "" : data.name,
                         domain: data.domain === null ? "" : data.domain,
                         status: data.status === null ? "" : data.status,
                         duration: ((data.total_duration) / 60).toFixed(0)
                     })
                 }
 
                 if (data.status === "FORM_OPEN") {
                     this.state.inboundoncall.push({
                         aid: data.aid === null ? "" : data.aid,
                         name: data.name === null ? "" : data.name,
                         domain: data.domain === null ? "" : data.domain,
                         status: data.status === null ? "" : data.status,
                         duration: ((data.total_duration) / 60).toFixed(0)
                     })
                 }
                 if (data.status === "RINGING") {
                     this.state.inboundoncall.push({
                         aid: data.aid === null ? "" : data.aid,
                         name: data.name === null ? "" : data.name,
                         domain: data.domain === null ? "" : data.domain,
                         status: data.status === null ? "" : data.status,
                         duration: ((data.total_duration) / 60).toFixed(0)
                     })
                 }
                 if (data.status === "ACTIVE") {
                     this.state.inboundoncall.push({
                         aid: data.aid === null ? "" : data.aid,
                         name: data.name === null ? "" : data.name,
                         domain: data.domain === null ? "" : data.domain,
                         status: data.status === null ? "" : data.status,
                         duration: ((data.total_duration) / 60).toFixed(0)
                     })
                 }
 
 
 
             }
 
 
             if (data.domain === "Outbound") {
 
                 this.state.outboundall.push({
                     aid: data.aid === null ? "" : data.aid,
                     name: data.name === null ? "" : data.name,
                     domain: data.domain === null ? "" : data.domain,
                     status: data.status === null ? "" : data.status,
                     duration: ((data.total_duration) / 60).toFixed(0)
                 })
 
                 if (data.status === "Break") {
                     this.state.outboundbreack.push({
                         aid: data.aid === null ? "" : data.aid,
                         name: data.name === null ? "" : data.name,
                         domain: data.domain === null ? "" : data.domain,
                         status: data.status === null ? "" : data.status,
                         duration: ((data.total_duration) / 60).toFixed(0)
                     })
                 }
 
                 if (data.status === "Idle") {
                     this.state.outboundideal.push({
                         aid: data.aid === null ? "" : data.aid,
                         name: data.name === null ? "" : data.name,
                         domain: data.domain === null ? "" : data.domain,
                         status: data.status === null ? "" : data.status,
                         duration: ((data.total_duration) / 60).toFixed(0)
                     })
                 }
                 if (data.status === "FORM_OPEN") {
                     this.state.outboundoncall.push({
                         aid: data.aid === null ? "" : data.aid,
                         name: data.name === null ? "" : data.name,
                         domain: data.domain === null ? "" : data.domain,
                         status: data.status === null ? "" : data.status,
                         duration: ((data.total_duration) / 60).toFixed(0)
                     })
                 }
 
 
             }
 
 
         })*/





        if (tab === 0) {

            this.setState({ userdata: this.state.inboundall, userdata1: this.state.inboundall })
        }
        if (tab === 1) {

            this.setState({ userdata: this.state.inboundoncall, userdata1: this.state.inboundoncall })
        }
        if (tab === 2) {

            this.setState({ userdata: this.state.inboundideal, userdata1: this.state.inboundideal })
        }
        if (tab === 3) {

            this.setState({ userdata: this.state.inboundbreack, userdata1: this.state.inboundbreack })
        }

        if (tab === 4) {

            this.setState({ userdata: this.state.outboundall, userdata1: this.state.outboundall })
        }
        if (tab === 5) {

            this.setState({ userdata: this.state.outboundoncall, userdata1: this.state.outboundoncall })
        }
        if (tab === 6) {

            this.setState({ userdata: this.state.outboundideal, userdata1: this.state.outboundideal })
        }
        if (tab === 7) {

            this.setState({ userdata: this.state.outboundbreack, userdata1: this.state.outboundbreack })
        }

        if (tab === 100) {

            this.setState({ callwait_userdata: this.state.callwaitlist })
        }

        if (tab === 101) {

            this.setState({ callwait_userdata: this.state.callwaitlist1 })
        }

    }

    GetNewDashBorardCound = async () => {
        const { showtab } = this.state


        let admin2 = 0


        this.setState({ inboundbreack: [], inboundall: [], inboundoncall: [], inboundideal: [] })
        this.setState({ outboundbreack: [], outboundall: [], outboundoncall: [], outboundideal: [] })

        let da = moment(new Date()).format("YYYY-MM-DD")
        let calllist1 = []
        let callwaitcount = 0
        let callwaitcount1 = 0
        try {
            const motdata = await httpRequest({
                endPoint: `/api/fs/liveInfo`,

                method: "get",
                instance: "instanceOne",


            });

            console.log('registrations**', motdata.status.replace(/\n/g, ''))

            this.setState({ freeswitchstatus: motdata.status.replace(/\n/g, '') })


            if (motdata.success === true) {
                this.setState({ maindata: motdata.user_idle })

                totinideal = motdata.inbound.Idle.count
                totoutideal = motdata.outbound.Idle.count
                this.state.inboundideal = motdata.inbound.Idle.data
                this.state.outboundideal = motdata.outbound.Idle.data


                totinbreak = motdata.inbound.Break.count
                totoutbreak = motdata.outbound.Break.count
                this.state.inboundbreack = motdata.inbound.Break.data
                this.state.outboundbreack = motdata.outbound.Break.data

                totinans = motdata.inbound.Oncall.count
                totoutans = motdata.outbound.Oncall.count
                this.state.inboundoncall = motdata.inbound.Oncall.data
                this.state.outboundoncall = motdata.outbound.Oncall.data

                totinbound = parseInt(totinideal) + parseInt(totinans) + parseInt(totinbreak)
                totoutbound = parseInt(totoutideal) + parseInt(totoutans) + parseInt(totoutbreak)

                this.state.inboundall = motdata.inbound.Idle.data.concat(motdata.inbound.Oncall.data).concat(motdata.inbound.Break.data)
                this.state.outboundall = motdata.outbound.Idle.data.concat(motdata.outbound.Oncall.data).concat(motdata.outbound.Break.data)

                for (var key in motdata.calls.rows) {
                    let direction1 = "Outbound"

                    if (motdata.calls.rows.b_callee_name !== "Outbound Call") {
                        direction1 = "Inbound"
                    }



                    if (motdata.calls.rows[key].dest === "9000000000" || motdata.calls.rows[key].dest === "1000000000" || motdata.calls.rows[key].dest === "2000000000" || motdata.calls.rows[key].dest === "3000000000") {


                        if (motdata.calls.rows[key].b_dest === "") {
                            callwaitcount1 = callwaitcount1 + 1
                            calllist1.push({
                                created: motdata.calls.rows[key].created,
                                aid: motdata.calls.rows[key].cid_num,
                                callstate: motdata.calls.rows[key].callstate === null ? "" : motdata.calls.rows[key].callstate,
                                direction: direction1,
                                cid_name: motdata.calls.rows[key].cid_name === null ? "" : motdata.calls.rows[key].cid_name,
                                cid_num: motdata.calls.rows[key].cid_num === null ? "" : motdata.calls.rows[key].cid_num.toString(),
                                b_callee_name: motdata.calls.rows[key].b_callee_name === null ? "" : motdata.calls.rows[key].b_callee_name,
                                b_callee_num: motdata.calls.rows[key].b_callee_num === null ? "" : motdata.calls.rows[key].b_callee_num.toString(),
                                b_cid_name: motdata.calls.rows[key].b_cid_name === null ? "" : motdata.calls.rows[key].b_cid_name,
                                b_cid_num: motdata.calls.rows[key].b_cid_num === null ? "" : motdata.calls.rows[key].b_cid_num.toString(),
                                b_dest: motdata.calls.rows[key].b_dest === null ? "" : motdata.calls.rows[key].b_dest,
                                dest: motdata.calls.rows[key].dest === null ? "" : motdata.calls.rows[key].dest,

                            })
                        }
                    }

                }

                this.setState({ callwaitcount1: callwaitcount1, callwaitcount: callwaitcount, callwait_userdata: calllist1, callwaitlist1: calllist1 })

                this.setState({ butname: 'Refresh' })
                this.ShowData(tab)
            }
        }
        catch (error) {

        }
        console.log('Admin 2', admin2)

    }
    LogClear = async (row) => {

        const item = {
            "aid": row.aid,
            "msg": "Force Logout by Admin-please contact your administrator"

        }
        try {
            const roledata = await httpRequest({
                endPoint: `api/fs/force_logout `,
                method: "post",
                instance: "instanceOne",
                requestBody: item,

            });



            if (roledata.success == true) {
                this.setState({ showerr: true, errorMsg: "Force Logout success", mysnack: snackeror.mysucc })

            }
        } catch (error) {

        }


    }
    MakeFiltercallwait(e) {
        this.setState({ stext: e.target.value })



        const sresult = this.state.callwait_userdata1.filter(data => {

            return (
                data.callstate.match(e.target.value) ||
                data.direction.match(e.target.value) ||
                data.cid_name.match(e.target.value) ||
                data.cid_num.match(e.target.value) ||
                data.b_callee_name.match(e.target.value) ||
                data.b_callee_num.match(e.target.value) ||
                data.b_cid_name.match(e.target.value) ||
                data.b_cid_num.match(e.target.value) ||
                data.b_dest.match(e.target.value) ||

                data.dest.match(e.target.value)

            )
        })

        if (e.target.value.length > 0) {
            this.setState({ callwait_userdata: sresult })
        } else {
            this.setState({ callwait_userdata: this.state.callwait_userdata1 })
        }


    }

    spycall(aid) {
        let no
        no = "88" + aid
        console.log(no)
        document.getElementById('txtPhoneNumber').value = no

        window.sipCall('call-audio');


    }
    MakeFilter(e) {
        this.setState({ stext: e.target.value })



        const sresult = this.state.userdata.filter(data => {

            return (
                data.aid.match(e.target.value) ||
                data.name.match(e.target.value) ||
                data.domain.match(e.target.value) ||
                data.status.match(e.target.value)

            )
        })

        if (e.target.value.length > 0) {
            this.setState({ userdata: sresult })
        } else {
            this.setState({ userdata: this.state.userdata1 })
        }


    }
    /*
    
       {
                name: 'Spy',
                width: '130px',
                sortable: true,
                cell: row =>
                    <Grid style={{ display: 'flex', alignItems: 'center', width: '150px', background: '#F7F9FA', borderRadius: '19px', padding: '1%' }}>
                        <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>0:00/0:00</Typography>
                        <img src="ui-assests/images/Barge.svg" width='15px' height="15px"
                            alt="login" style={{ marginLeft: '5px' }} />
 
                    </Grid>
 
 
 
 
            },
 
            {
                name: 'Recording',
                width: '130px',
                sortable: true,
                cell: row =>
                    <Grid style={{ display: 'flex', alignItems: 'center', width: '150px', background: '#F7F9FA', borderRadius: '19px', padding: '1%' }}>
                        <img src="ui-assests/images/play.svg" width='15px' height="15px"
                            alt="login" />
 
                        <Typography style={{ marginLeft: '5px', color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>0:00/0:00</Typography>
                        <img src="ui-assests/images/volume.svg" width='15px' height="15px"
                            alt="login" style={{ marginLeft: '5px' }} />
 
                    </Grid>
 
            },
 
    */
    render() {
        const { tabvalue, tabstval } = this.state
        const { classes } = this.props;


        let columns = [

            {
                name: '',
                width: '40px',
                cell: row =>


                    <WhiteBackgroundCheckbox

                        id="chk1"

                    />


            },



            {
                name: 'User / Agent ID',
                width: '300px',
                sortable: true,
                selector: 'aid',
                cell: row =>
                    row.aid + "/" + row.name
            },

            {
                name: 'fs_status',
                width: '100px',
                sortable: true,
                selector: 'fs_status',
                cell: row =>

                    <StyledCell className={getCssClass(row.fs_status)} >
                        {row.fs_status}
                    </StyledCell >
            },


            {
                name: 'Domain',
                width: '130px',
                sortable: true,
                selector: 'domain',
                cell: row =>
                    row.domain
            },



            {
                name: 'Status',
                width: '130px',
                selector: 'status',
                sortable: true,
                cell: row =>
                    row.status
            },
            {
                name: 'Duration',
                width: '100px',
                sortable: true,
                selector: 'elapsed',
                cell: row =>
                    parseInt(row.elapsed)
            },




            {
                name: 'Action',
                width: '130px',
                sortable: true,
                cell: row =>
                    <Grid >
                        <img src="ui-assests/images/logout1.svg" width='32px' height="32px"
                            alt="login" title="Force Logout"
                            style={{ cursor: 'pointer' }}
                            onClick={() => { this.LogClear(row) }} />

                    </Grid>

            },



        ];


        const callcolumns = [

            {
                name: '',
                width: '40px',
                cell: row =>


                    <WhiteBackgroundCheckbox

                        id="chk1"

                    />


            },

            {
                name: 'created',
                width: '130px',
                selector: 'created',
                sortable: true,
                cell: row =>
                    row.created
            },


            {
                name: 'Caller/Farmer number',
                width: '200px',
                sortable: true,
                selector: 'aid',
                cell: row =>
                    row.aid
            },
            {
                name: 'dest',
                width: '130px',
                selector: 'dest',
                sortable: true,
                cell: row =>
                    row.dest
            },




            {
                name: 'cid_name',
                width: '130px',
                selector: 'cid_name',
                sortable: true,
                cell: row =>
                    row.cid_name
            },

            {
                name: 'cid_num',
                width: '130px',
                selector: 'cid_num',
                sortable: true,
                cell: row =>
                    row.cid_num
            },









        ];
        return (

            <div>
                <Grid style={{ width: '100%', display: 'flex' }}>
                    <Grid style={{ width: '90%' }}>
                        <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Monitoring</Typography>
                    </Grid>
                    <Grid style={{ width: '10%', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button



                            variant="contained"
                            size="small"

                            style={{
                                color: "#ffffff",
                                fontFamily: "Roboto-Medium",
                                borderRadius: "4px",
                                backgroundColor: "#41AB1C",
                                textTransform: 'none',
                                padding: '1.5%',
                                fontSize: "14px",
                                width: '100px',
                                height: '32px',
                                marginLeft: '5%'

                            }}

                            onClick={() => { this.GetNewDashBorardCound() }}


                        >

                            Refresh
                        </Button>
                    </Grid>

                </Grid>

                <Tabs value={this.state.tabvalue} onChange={this.handleTabChange} style={{ marginTop: '.5%', background: '#F5F5F5' }}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "#7DAB1C",

                        }
                    }}

                >
                    <Tab active='true' style={{ width: '50%', color: '#333333', textTransform: 'none', fontSize: '12', fontFamily: 'Roboto-Medium' }} label="Inbound" />
                    <Tab style={{ width: '50%', color: '#333333', textTransform: 'none', fontSize: '12', fontFamily: 'Roboto-Medium' }} label="Outbound" />
                    <Tab style={{ width: '50%', color: '#333333', textTransform: 'none', fontSize: '12', fontFamily: 'Roboto-Medium' }} label="Call Wait" />


                </Tabs>
                {tabvalue === 0 &&
                    <div>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', padding: '2%' }}


                        >

                            <Grid className={tabstval === 0 ? classes.tabactst_in : classes.tabst_in}

                                onClick={() => this.ShowData(0)}
                            >
                                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                    <Grid style={{ width: '30%', display: 'flex' }}>
                                        <img src="ui-assests/images/caller.svg" width='32px' height="32px"
                                            alt="login" />
                                    </Grid>
                                    <Grid style={{ width: '65%', display: 'flex', marginLeft: '5%' }}>
                                        <Typography style={{ color: '#000000', fontSize: '32px', fontFamily: 'Roboto-Regular' }}>{totinbound}</Typography>
                                    </Grid>

                                </Grid>
                                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Number of telecaller</Typography>


                                </Grid>
                            </Grid>



                            <Grid className={tabstval === 1 ? classes.tabactst_in : classes.tabst_in}

                                onClick={() => this.ShowData(1)}
                            >
                                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                    <Grid style={{ width: '30%', display: 'flex' }}>
                                        <img src="ui-assests/images/oncall.svg" width='32px' height="32px"
                                            alt="login" />
                                    </Grid>
                                    <Grid style={{ width: '65%', display: 'flex', marginLeft: '5%' }}>
                                        <Typography style={{ color: '#000000', fontSize: '32px', fontFamily: 'Roboto-Regular' }}>{totinans}</Typography>
                                    </Grid>

                                </Grid>
                                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>On Call</Typography>


                                </Grid>
                            </Grid>
                            <Grid className={tabstval === 2 ? classes.tabactst_in : classes.tabst_in}

                                onClick={() => this.ShowData(2)}
                            >
                                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                    <Grid style={{ width: '30%', display: 'flex' }}>
                                        <img src="ui-assests/images/ideal.svg" width='32px' height="32px"
                                            alt="login" />
                                    </Grid>
                                    <Grid style={{ width: '65%', display: 'flex', marginLeft: '5%' }}>
                                        <Typography style={{ color: '#000000', fontSize: '32px', fontFamily: 'Roboto-Regular' }}>{totinideal}</Typography>
                                    </Grid>

                                </Grid>
                                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Idle</Typography>


                                </Grid>
                            </Grid>


                            <Grid className={tabstval === 3 ? classes.tabactst : classes.tabst}
                                onClick={() => this.ShowData(3)}

                            >
                                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                    <Grid style={{ width: '30%', display: 'flex' }}>
                                        <img src="ui-assests/images/Break.svg" width='32px' height="32px"
                                            alt="login" />
                                    </Grid>
                                    <Grid style={{ width: '65%', display: 'flex', marginLeft: '5%' }}>
                                        <Typography style={{ color: '#000000', fontSize: '32px', fontFamily: 'Roboto-Regular' }}>{totinbreak}</Typography>
                                    </Grid>

                                </Grid>
                                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Break</Typography>


                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid style={{ width: '100%', borderTop: '1px solid #252956' }}></Grid>


                        <Grid style={{ height: 'auto', overflow: 'auto', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', }}>


                            <DataTable

                                columns={columns}
                                data={this.state.userdata}
                                pagination
                                striped
                                sortable
                                paginationPerPage='100'
                                paginationRowsPerPageOptions={[100, 150, 200, 250]}

                                defaultSortFieldId
                                dense
                                subHeader
                                subHeaderComponent={<CssTextField
                                    autoFocus={true}
                                    value={this.state.stext}
                                    placeholder="Search"
                                    size="small"
                                    variant="outlined"
                                    onChange={e =>
                                        this.MakeFilter(e)
                                    }
                                />}
                                subHeaderAlign='left'
                                customStyles={customStyles}
                                highlightOnHover


                                conditionalRowStyles={conditionalRowStyles}
                            />


                        </Grid>
                    </div>
                }
                {tabvalue === 1 &&
                    <div style={{ marginTop: '0%' }}>

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', padding: '2%' }}


                        >

                            <Grid className={tabstval === 4 ? classes.tabactst : classes.tabst}

                                onClick={() => this.ShowData(4)}
                            >
                                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                    <Grid style={{ width: '30%', display: 'flex' }}>
                                        <img src="ui-assests/images/caller.svg" width='32px' height="32px"
                                            alt="login" />
                                    </Grid>
                                    <Grid style={{ width: '65%', display: 'flex', marginLeft: '5%' }}>
                                        <Typography style={{ color: '#000000', fontSize: '32px', fontFamily: 'Roboto-Regular' }}>{totoutbound}</Typography>
                                    </Grid>

                                </Grid>
                                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Number of telecaller</Typography>


                                </Grid>
                            </Grid>



                            <Grid className={tabstval === 5 ? classes.tabactst : classes.tabst}

                                onClick={() => this.ShowData(5)}
                            >
                                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                    <Grid style={{ width: '30%', display: 'flex' }}>
                                        <img src="ui-assests/images/oncall.svg" width='32px' height="32px"
                                            alt="login" />
                                    </Grid>
                                    <Grid style={{ width: '65%', display: 'flex', marginLeft: '5%' }}>
                                        <Typography style={{ color: '#000000', fontSize: '32px', fontFamily: 'Roboto-Regular' }}>{totoutans}</Typography>
                                    </Grid>

                                </Grid>
                                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>On Call</Typography>


                                </Grid>
                            </Grid>
                            <Grid className={tabstval === 6 ? classes.tabactst : classes.tabst}

                                onClick={() => this.ShowData(6)}
                            >
                                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                    <Grid style={{ width: '30%', display: 'flex' }}>
                                        <img src="ui-assests/images/ideal.svg" width='32px' height="32px"
                                            alt="login" />
                                    </Grid>
                                    <Grid style={{ width: '65%', display: 'flex', marginLeft: '5%' }}>
                                        <Typography style={{ color: '#000000', fontSize: '32px', fontFamily: 'Roboto-Regular' }}>{totoutideal}</Typography>
                                    </Grid>

                                </Grid>
                                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Idle</Typography>


                                </Grid>
                            </Grid>


                            <Grid className={tabstval === 7 ? classes.tabactst : classes.tabst}
                                onClick={() => this.ShowData(7)}

                            >
                                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                    <Grid style={{ width: '30%', display: 'flex' }}>
                                        <img src="ui-assests/images/Break.svg" width='32px' height="32px"
                                            alt="login" />
                                    </Grid>
                                    <Grid style={{ width: '65%', display: 'flex', marginLeft: '5%' }}>
                                        <Typography style={{ color: '#000000', fontSize: '32px', fontFamily: 'Roboto-Regular' }}>{totoutbreak}</Typography>
                                    </Grid>

                                </Grid>
                                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Break</Typography>


                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid style={{ width: '100%', borderTop: '1px solid #252956' }}></Grid>



                        <Grid style={{ height: 'auto', overflow: 'auto', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', }}>


                            <DataTable
                                sortable
                                columns={columns}
                                data={this.state.userdata}
                                pagination
                                striped
                                responsive
                                paginationPerPage='200'

                                dense
                                subHeader
                                subHeaderComponent={<CssTextField
                                    autoFocus={true}
                                    value={this.state.stext}
                                    placeholder="Search"
                                    size="small"
                                    variant="outlined"
                                    onChange={e =>
                                        this.MakeFilter(e)
                                    }
                                />}
                                customStyles={customStyles}
                                highlightOnHover


                                conditionalRowStyles={conditionalRowStyles}
                            />


                        </Grid>
                    </div>
                }
                {tabvalue === 2 &&
                    <div>
                        <Grid style={{ width: '100%', display: 'flex', padding: '2%' }}


                        >


                            <Grid className={tabstval === 101 ? classes.tabactst : classes.tabst}

                                onClick={() => this.ShowData(101)}
                            >
                                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                    <Grid style={{ width: '30%', display: 'flex' }}>
                                        <img src="ui-assests/images/oncall.svg" width='32px' height="32px"
                                            alt="login" />
                                    </Grid>
                                    <Grid style={{ width: '65%', display: 'flex', marginLeft: '5%' }}>
                                        <Typography style={{ color: '#000000', fontSize: '32px', fontFamily: 'Roboto-Regular' }}>{this.state.callwaitcount1}</Typography>
                                    </Grid>

                                </Grid>
                                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Inbound Call Wait</Typography>


                                </Grid>
                            </Grid>

                            {/* <Grid style={{ marginLeft: '10px', cursor: 'pointer', width: '13%', padding: '2%', background: '#ffffff', border: '1px solid #E7EAF0', borderRadius: '10px' }}

                                onClick={() => this.ShowData(100)}
                            >
                                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                    <Grid style={{ width: '30%', display: 'flex' }}>
                                        <img src="ui-assests/images/caller.svg" width='32px' height="32px"
                                            alt="login" />
                                    </Grid>
                                    <Grid style={{ width: '65%', display: 'flex', marginLeft: '5%' }}>
                                        <Typography style={{ color: '#000000', fontSize: '32px', fontFamily: 'Roboto-Regular' }}>{this.state.callwaitcount}</Typography>
                                    </Grid>

                                </Grid>
                                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Total Live calls</Typography>


                                </Grid>
                </Grid>*/}
                        </Grid>



                        <Grid style={{ width: '100%', borderTop: '1px solid #252956' }}></Grid>


                        <Grid style={{ height: 'auto', overflow: 'auto', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', }}>


                            <DataTable

                                columns={callcolumns}
                                data={this.state.callwait_userdata}
                                pagination
                                striped
                                sortable
                                paginationPerPage='100'
                                paginationRowsPerPageOptions={[100, 150, 200, 250]}

                                defaultSortFieldId
                                dense
                                subHeader
                                subHeaderComponent={<CssTextField
                                    autoFocus={true}
                                    value={this.state.stext}
                                    placeholder="Search"
                                    size="small"
                                    variant="outlined"
                                    onChange={e =>
                                        this.MakeFiltercallwait(e)
                                    }
                                />}
                                subHeaderAlign='left'
                                customStyles={customStyles}
                                highlightOnHover


                                conditionalRowStyles={conditionalRowStyles}
                            />


                        </Grid>
                    </div>
                }

                <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',
                            display: 'flex',
                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >
                        <Loader type="spinner-circle" bgColor={"#7DAB1C"} color={'#ffc31b'} size={75} />
                    </Box>

                </Modal>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "Roboto-Bold"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>

            </div>
        )
    }
}
export default (withStyles(txtstyles)((newdashbord)));