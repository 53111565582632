
import React, { Component } from 'react'
import {
    Select, MenuItem, Typography,
    Grid, Button, Checkbox, Step, Stepper,
    StepLabel, TextField,
    Snackbar, SnackbarContent, IconButton
} from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete';
import { withStyles, styled } from "@material-ui/core/styles";
import { ArrowBackIosOutlined } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment'
import DataTable from 'react-data-table-component';


import * as XLSX from 'xlsx';

import { httpRequest } from '../../../ui-utils/api'
import CloseIcon from '@material-ui/icons/Close'
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import { createTheme, ThemeProvider, MuiThemeProvider } from "@material-ui/core/styles";

import sql_funcation from '../../../ui-utils/sql_funcation';

const txtstyles = theme => ({




})
const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    }
};
const defaultMaterialTheme = createTheme({
    palette: {
        primary: {
            light: '#7DAB1C',
            main: '#7DAB1C',
            dark: '#7DAB1C',
            contrastText: '#fff',

        },
        secondary: {
            light: '#7DAB1C',
            main: '#7DAB1C',
            dark: '#7DAB1C',
            contrastText: '#000',
        },
    },
});
const CssTextField = styled(TextField)({
    borderRadius: '12px',


    '& label.Mui-focused': {
        color: '#999999',

    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#999999',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#999999',
        },
        '&:hover fieldset': {
            borderColor: '#999999',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#999999',
        },
    },
});
const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#41AB1C",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 10,
            width: 10,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#41AB1C'
        }
    },
    checked: { color: "#41AB1C", }
}))(Checkbox);
const steppertheme = createTheme({


    overrides: {

        MuiStepConnector: {


            line: {
                borderColor: '#979797',
                border: "1px solid",
                borderTopColor: '#fffbed'
            },

        },


        MuiStepLabel: {
            active: {
                color: '#333333',
                fontSize: '12px',
                fontFamily: "Roboto-Bold",
            },
            label: {
                color: '#333333',
                fontSize: '12px',
                fontFamily: "Roboto-Regular",
                "@media(max-width:960px)": {
                    fontSize: '0px',
                }
            },

        },
        MuiStepIcon: {
            text: {
                fill: "#ffffff",
                fontFamily: "Roboto-Bold",
                fontSize: '12px',

            },
            root: {
                color: '#093265',


                '&$active': {
                    border: "2.5px solid",
                    borderColor: '#FBB72E',
                    padding: '2px',
                    borderRadius: 25,
                    fill: "093265",
                },

                '&$completed': {

                    border: "solid",
                    fill: "#7DAB1C",
                    borderColor: '#7DAB1C',
                    borderRadius: 25,

                }
            },
        },
    },
});
const customStyles = {
    title: {
        style: {
            fontSize: '14px', // override the row height
        },
    },
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
        stripedStyle: {

            //  background: 'red'



        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#E9F4FF',
            fontSize: '14px',
            color: '#333333',
            height: '45px',
            fontFamily: 'Roboto-Medium'
        },
    },
    cells: {
        style: {
            paddingLeft: '2px', // override the cell padding for data cells
            paddingRight: '2px',


        },
    },
    highlightOnHoverStyle: {
        style: {
            color: 'red',
            background: 'blue',
            backgroundColor: 'yellow'



        },
    },

};


const campcolumns = [




    {
        name: 'Campaign Name',
        width: '200px',
        sortable: true,
        cell: row =>
            row.camp_name
    },
    {
        name: 'Campaign Description',
        width: '200px',
        sortable: true,
        cell: row =>
            row.camp_disp
    },
    {
        name: 'Form',
        width: '200px',
        sortable: true,
        cell: row =>
            row.fname
    },


    {
        name: 'Start Date',
        width: '200px',
        sortable: true,
        cell: row =>
            row.fdate
    },

    {
        name: 'End Date',
        width: '200px',
        sortable: true,
        cell: row =>
            row.tdate
    },



];
const columns = [




    {
        name: 'Agent ID',
        width: '200px',
        sortable: true,
        cell: row =>
            row.aid
    },
    {
        name: 'Name',
        width: '400px',
        sortable: true,
        cell: row =>
            row.name
    },
    {
        name: 'Domain',
        width: '200px',
        sortable: true,
        cell: row =>
            row.domain
    },
    {
        name: 'Role',
        width: '230px',
        sortable: true,
        cell: row =>
            row.domain
    },







];
const steps = ['Create Campaign', 'Add Agents ', 'Add Customers'];
class camp extends Component {
    constructor(props) {
        super(props)


        this.state = {


            camppageno: '0',
            activeStep: 0,
            campname: '',
            fdate: moment().subtract(15, 'days'),
            tdate: moment().subtract(0, 'days'),
            campdisp: '',
            role: '0',
            roledata: [],
            fname: '',
            fdate1: '',
            tdate1: '',
            mysnack: snackeror.mysucc,
            groupdata: [],
            grouplist: [],
            agentlist: [],
            agentlist1: '',
            allagentlist: [],
            camplist: [],
            formlist: [],
            numberlist: [],
            groupname: '',
            indagentname: '',
            filename: '',
            numbercount: '',
            sql1: '',
            fid: 0

        }



    }
    handledobChange(date) {

        this.setState({ fdate1: date })


    }
    handledobChange1(date) {

        this.setState({ tdate1: date })


    }
    GetAgent = async (e) => {
        this.setState({ groupname: e.target.value });
        let sql = "select aid from  pad_group where group_name='" + e.target.value + "'"

        let roledata = await sql_funcation(sql)
        let agent = ''
        let agent1 = ''
        if (roledata.success == true) {

            let agentsplit = roledata.msg[0].aid
            {
                agentsplit.split(',').map((step) =>


                    agent = agent + "'" + step + "',"



                )
            }
            {
                agentsplit.split(',').map((step) =>



                    agent1 = agent1 + "" + step + ","


                )
            }
            agent = agent.substring(0, agent.length - 1);


            this.setState({ agentlist1: agent1 })
            let sql1 = "select pad_agent.aid,pad_agent.name,pad_agent.domain,pad_roles.description   from pad_agent join pad_roles on pad_agent.role=pad_roles.role_id  where pad_agent.status='Enabled' and aid in(" + agent + ")"
            console.log("*", sql1)
            let roledata1 = await sql_funcation(sql1)

            if (roledata1.success == true) {
                this.setState({ agentlist: roledata1.msg })
            }

        }

    }
    GetAllAgent = async (e) => {

        let sql = "select aid,name from  pad_agent where  status='Enabled' order by name"

        let roledata = await sql_funcation(sql)
        console.log('all agents', roledata.msg)
        if (roledata.success == true) {

            this.setState({ allagentlist: roledata.msg })

        }

    }
    GetGroup = async () => {
        let sql = "select uid,group_name from  pad_group order by group_name"
        console.log(sql)
        let roledata = await sql_funcation(sql)
        if (roledata.success == true) {

            this.setState({ grouplist: roledata.msg, numofrows: roledata.msg.length })
        }

        let sql1 = "select form_name,uid from  lcc_forms where form_type =1 and status ='Active'"
        console.log(sql)
        let roledata1 = await sql_funcation(sql1)
        if (roledata1.success == true) {

            this.setState({ formlist: roledata1.msg })
        }


    }
    handledobChange2(date) {

        this.setState({ fdate: date })


    }
    handledobChange3(date) {

        this.setState({ tdate: date })


    }
    addCampaign() {
        this.setState({ camppageno: '1' })

    }

    handelNext() {

        const { campdisp, campname, fdate1, tdate1, fname, fid } = this.state

        let isok = 0

        if (!campname.length) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter Campaign Name", mysnack: snackeror.myerror })
        }


        if (fid == 0) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Select  Campaign Form", mysnack: snackeror.myerror })
        }

        if (!campdisp.length) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter Campaign Description", mysnack: snackeror.myerror })
        }

        if (isok == 0) {
            this.setState({ activeStep: 1 })
        }
    }
    handelNext1() {

        const { agentlist } = this.state

        let isok = 0
        if (!agentlist.length) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Select the Agents", mysnack: snackeror.myerror })
        }
        if (isok == 0) {
            this.setState({ activeStep: 2 })
        }
    }
    componentDidMount = async () => {
        this.GetGroup()
        this.GetAllAgent()
        this.GetCampList()
    }
    closeContactModal() {
        this.setState({ showerr: false })
    }

    fileSelectHandler = async (e) => {

        this.setState({ filename: e.target.files[0].name })//e.target.files[0].name,

        const file = e.target.files[0];
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data);
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            defval: "",
        });



        this.setState({ numbercount: jsonData.length, numberlist: jsonData })
        console.log(jsonData);

    }

    GetIndAgent(value) {

        this.setState({ indagentname: value })

    }

    GetCampList = async () => {


        const { fdate, tdate } = this.state
        let fdate1 = moment(this.state.fdate1).format("YYYY-MM-DD")
        let tdate1 = moment(this.state.tdate2).format("YYYY-MM-DD")

        let sql = "select camp_name,camp_desc,fdate::text,tdate::text from  lcc_campaign order by uid"

        let data = await sql_funcation(sql)
        if (data.success == true) {
            this.setState({ camplist: data.msg })
        }

    }
    CampSave = async () => {
        const { agentlist, numberlist, agentlist1, fid } = this.state
        const { campdisp, campname, fdate1, tdate1, fname } = this.state
        let fdate = moment(this.state.fdate1).format("YYYY-MM-DD")
        let tdate = moment(this.state.tdate1).format("YYYY-MM-DD")

        let isok = 0
        let sql2 = ""
        if (!numberlist.length) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Select the Fromer Number", mysnack: snackeror.myerror })
        }

        if (isok == 0) {

            numberlist.map(no =>


                sql2 = sql2 + no + ","
            )

            sql2 = sql2.substring(0, sql2.length - 1);
            let agentlist3 = agentlist1.substring(0, agentlist1.length - 1);

            console.log('agentlist1===', agentlist3)

            const item111 = {

                fdate: fdate,
                tdate: tdate,
                camp_name: campname,
                camp_description: campdisp,
                FormId: fid,
                AgentCSV: agentlist3,
                farmerCSV: sql2,
                gateway_id: 0


            }


            try {

                const submitdata = await httpRequest({
                    endPoint: `api/lcc/CreateCampaign`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: item111,

                });

                this.setState({ camppageno: '0', showerr: true, errorMsg: "Campaign created Successfully", mysnack: snackeror.mysucc })
            } catch (error) {

            }






        }

    }
    AddIndAgent = async () => {
        const { agentlist, agentlist1 } = this.state
        let sql = "select name,aid,domain from pad_agent where name='" + this.state.indagentname + "'"
        let data = await sql_funcation(sql)
        if (data.success == true) {
            var joined = this.state.agentlist.concat(data.msg[0]);
            console.log('joined', joined)
            let agentlist2 = agentlist1 + data.msg[0].aid + ","

            this.setState({ agentlist: joined, agentlist1: agentlist2 })

            this.setState({ indagentname: '' })



        }
        console.log('camp agentlist', this.state.agentlist)

    }
    onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }

    getStepContent(step) {
        switch (step) {

            case 2:
                return (


                    <div>
                        <Grid style={{ width: '100%', marginTop: '3%', display: 'flex', marginLeft: '3%' }}>
                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Paste SQL</Typography>
                        </Grid>
                        <Grid style={{ width: '100%', marginTop: '1%', display: 'flex', marginLeft: '3%' }}>
                            <Grid style={{ width: '60%' }}>

                                <CssTextField focusColor="#999999"


                                    fullWidth
                                    inputProps={{
                                        style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                    }}
                                    size="small"

                                    variant="outlined"
                                    placeholder="Enter the SQL"
                                    value={this.state.sql1}

                                    onChange={e => {

                                        this.setState({ sql1: e.target.value });

                                    }}
                                />
                            </Grid>

                            <Grid style={{ width: '40%', marginLeft: '5%' }}>


                                <Grid style={{ width: '75%', display: 'flex', justifyContent: 'center' }}>
                                    <input

                                        name="img3"
                                        style={{ display: 'none' }}
                                        id="raised-button-file3"
                                        multiple
                                        type="file"
                                        onClick={e => e.target.value = ''}
                                        onChange={e => { this.fileSelectHandler(e) }}
                                    />
                                    <label htmlFor="raised-button-file3">
                                        <Button

                                            variant="text"
                                            component="span"
                                            style={{
                                                color: "#333333",
                                                fontFamily: "Roboto-Medium",
                                                borderRadius: "4px",
                                                backgroundColor: "#E7EAF0",
                                                textTransform: 'none',

                                                fontSize: "14px",
                                                width: '150px',
                                                height: '40px',

                                            }}



                                        >Upload XLS
                                        </Button>
                                    </label>

                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid style={{ width: '100%', marginTop: '1%', display: 'flex', marginLeft: '3%' }}>
                            <Grid style={{ width: '60%' }}>

                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>{this.state.numbercount} {" "} Farmers Number Found</Typography>


                            </Grid>
                            <Grid style={{ width: '40%' }}>

                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>{this.state.filename}</Typography>
                            </Grid>

                        </Grid>




                        <Grid style={{ width: '85%', display: 'flex', justifyContent: 'flex-end', marginTop: '28%' }} >

                            <Button



                                variant="contained"
                                size="small"

                                style={{
                                    color: "#333333",
                                    fontFamily: "Roboto-Medium",
                                    borderRadius: "4px",
                                    backgroundColor: "#E7EAF0",
                                    textTransform: 'none',
                                    padding: '1.5%',
                                    fontSize: "14px",
                                    width: '100px',
                                    height: '32px',
                                    marginLeft: '5%'

                                }}

                                onClick={() => { this.setState({ activeStep: 1 }) }}


                            >

                                Back
                            </Button>
                            <Button



                                variant="contained"
                                size="small"

                                style={{
                                    color: "#333333",
                                    fontFamily: "Roboto-Medium",
                                    borderRadius: "4px",
                                    backgroundColor: "#FBB72E",
                                    textTransform: 'none',
                                    padding: '1.5%',
                                    fontSize: "14px",
                                    width: '100px',
                                    height: '32px',
                                    marginLeft: '5%'

                                }}

                                onClick={() => { this.CampSave() }}


                            >

                                Complete
                            </Button>
                        </Grid>
                    </div >

                )
            case 1:
                return (


                    <div>
                        <Grid style={{ width: '100%', marginTop: '3%', display: 'flex' }}>

                            <Grid style={{ width: '25%' }}>
                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Add by Group</Typography>
                            </Grid>
                            <Grid style={{ width: '50%', marginLeft: '5%' }}>
                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Add by Individual</Typography>
                            </Grid>
                        </Grid>
                        <Grid style={{ width: '100%', marginTop: '5px', display: 'flex', alignItems: 'center' }}>

                            <Grid style={{ width: '25%' }}>
                                <Select value={this.state.groupname}
                                    displayEmpty
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    autoFocus={true}
                                    placeholder='Select'
                                    style={{ height: '50px', }}

                                    onChange={e => {
                                        this.GetAgent(e)


                                    }}

                                >
                                    <MenuItem value={'0'}>Select</MenuItem>
                                    {this.state.grouplist.map(rname =>

                                        <option style={{ padding: '5px', color: '#333333', fontSize: '18px', fontFamily: 'Roboto-Regular' }} value={rname.group_name} label={rname.group_name}>{rname.group_name}</option>
                                    )};

                                </Select>

                            </Grid>
                            <Grid style={{ width: '50%', marginLeft: '5%', zIndex: 1 }}>
                                <Autocomplete

                                    freeSolo
                                    autoSelect
                                    size="small"


                                    options={this.state.allagentlist.map((option) => option.name)}


                                    onChange={(event, value) => this.GetIndAgent(value)}

                                    renderInput={(params) => <TextField

                                        {...params} variant="outlined" />}



                                />
                            </Grid>
                            <Grid style={{ width: '25%', marginLeft: '5%' }}>
                                <Button



                                    variant="contained"
                                    size="small"

                                    style={{
                                        color: "#333333",
                                        fontFamily: "Roboto-Medium",
                                        borderRadius: "4px",
                                        backgroundColor: "#E7EAF0",
                                        textTransform: 'none',

                                        fontSize: "14px",
                                        width: '150px',
                                        height: '40px',


                                    }}

                                    onClick={() => { this.AddIndAgent() }}


                                >

                                    Add
                                </Button>
                            </Grid>


                        </Grid>

                        <Grid style={{ zIndex: 0, width: '100%', marginTop: '5px', height: 'auto', overflow: 'auto' }}>

                            <DataTable
                                style={{ zIndex: 0 }}
                                columns={columns}
                                data={this.state.agentlist}
                                pagination


                                paginationPerPage='10'
                                paginationRowsPerPageOptions={[10, 20, 30, 50]}


                                dense

                                customStyles={customStyles}
                                highlightOnHover



                            />
                        </Grid>

                        <Grid style={{ width: '85%', display: 'flex', justifyContent: 'flex-end', marginTop: '10%' }} >

                            <Button



                                variant="contained"
                                size="small"

                                style={{
                                    color: "#333333",
                                    fontFamily: "Roboto-Medium",
                                    borderRadius: "4px",
                                    backgroundColor: "#E7EAF0",
                                    textTransform: 'none',
                                    padding: '1.5%',
                                    fontSize: "14px",
                                    width: '100px',
                                    height: '32px',
                                    marginLeft: '5%'

                                }}

                                onClick={() => { this.setState({ activeStep: 0 }) }}


                            >

                                Back
                            </Button>
                            <Button



                                variant="contained"
                                size="small"

                                style={{
                                    color: "#333333",
                                    fontFamily: "Roboto-Medium",
                                    borderRadius: "4px",
                                    backgroundColor: "#FBB72E",
                                    textTransform: 'none',
                                    padding: '1.5%',
                                    fontSize: "14px",
                                    width: '100px',
                                    height: '32px',
                                    marginLeft: '5%'

                                }}

                                onClick={() => { this.handelNext1() }}


                            >

                                Next
                            </Button>

                        </Grid>
                    </div>

                )
            case 0:
                return (

                    <div>
                        <Grid style={{ width: '100%', marginTop: '1%' }}>
                            <Typography style={{ color: '#333333', fontSize: '16px', fontFamily: 'Roboto-Bold' }}>Campaign Name</Typography>
                        </Grid>

                        <Grid style={{ width: '100%', display: 'flex' }}>
                            <Grid style={{ width: '30%', display: 'flex', alignItems: 'center', marginTop: '1%' }}>
                                <CssTextField focusColor="#999999"
                                    autoFocus={true}


                                    inputProps={{
                                        style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff' },


                                    }}
                                    size="small"
                                    style={{ width: '100%', height: '48px' }}
                                    variant="outlined"
                                    placeholder="Enter Name"
                                    value={this.state.campname}

                                    onChange={e => {

                                        this.setState({ campname: e.target.value });

                                    }}
                                />
                            </Grid>
                            <Grid style={{ width: '30%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                                <Typography style={{ color: '#999999', fontSize: '14px', marginLeft: '1%', fontFamily: 'Roboto-Regular' }}>From</Typography>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider

                                        style={{ height: '20px' }} utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            error={false}
                                            helperText={null}
                                            minDate={moment().add(0, "day")}
                                            value={this.state.fdate1}
                                            onChange={date => { this.handledobChange(date) }}
                                            format="yyyy/MM/dd"

                                            InputProps={{


                                                style: {

                                                    fontSize: 14,
                                                    height: 38,
                                                    color: '#999999',

                                                    background: '#ffffff'

                                                }
                                            }}
                                            inputVariant="outlined"



                                            KeyboardButtonProps={{
                                                style: { marginLeft: -24 }
                                            }}

                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </Grid>
                            <Grid style={{ width: '30%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                                <Typography style={{ color: '#999999', fontSize: '14px', marginLeft: '1%', fontFamily: 'Roboto-Regular' }}>To</Typography>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider

                                        style={{ height: '20px' }} utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            error={false}
                                            helperText={null}
                                            minDate={moment().add(0, "day")}
                                            value={this.state.tdate1}
                                            onChange={date => { this.handledobChange1(date) }}
                                            format="yyyy/MM/dd"

                                            InputProps={{


                                                style: {

                                                    fontSize: 14,
                                                    height: 38,
                                                    color: '#999999',

                                                    background: '#ffffff'

                                                }
                                            }}
                                            inputVariant="outlined"



                                            KeyboardButtonProps={{
                                                style: { marginLeft: -24 }
                                            }}

                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </Grid>


                        </Grid>

                        <Grid style={{ width: '100%', marginTop: '1%' }}>
                            <Typography style={{ color: '#333333', fontSize: '16px', fontFamily: 'Roboto-Bold' }}>Campaign Form</Typography>
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex' }}>
                            <Grid style={{ width: '25%' }}>
                                <Select value={this.state.fname}
                                    displayEmpty
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    autoFocus={true}
                                    placeholder='Select'
                                    style={{ height: '50px', }}

                                    onChange={e => {
                                        this.setState({ fname: e.target.value, fid: e.target.value })


                                    }}

                                >
                                    <MenuItem value={'0'}>Select</MenuItem>
                                    {this.state.formlist.map(rname =>

                                        <option style={{ padding: '5px', color: '#333333', fontSize: '18px', fontFamily: 'Roboto-Regular' }} value={rname.uid} label={rname.form_name}>{rname.form_name}</option>
                                    )};

                                </Select>
                            </Grid>
                        </Grid>
                        <Grid style={{ width: '100%', marginTop: '1%' }}>
                            <Typography style={{ color: '#333333', fontSize: '16px', fontFamily: 'Roboto-Bold' }}>Description</Typography>
                        </Grid>
                        <Grid style={{ width: '100%', marginTop: '1%', display: 'flex' }}>
                            <Grid style={{ width: '89%', display: 'flex', alignItems: 'center', marginTop: '1%' }}>
                                <CssTextField focusColor="#999999"
                                    autoFocus={true}

                                    multiline
                                    maxRows={4}
                                    inputProps={{
                                        style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '75px' },


                                    }}
                                    size="small"
                                    style={{ width: '100%' }}
                                    variant="outlined"
                                    placeholder="Description"
                                    value={this.state.campdisp}

                                    onChange={e => {

                                        this.setState({ campdisp: e.target.value });

                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid style={{ width: '85%', display: 'flex', justifyContent: 'flex-end', marginTop: '10%' }} >


                            <Button



                                variant="contained"
                                size="small"

                                style={{
                                    color: "#333333",
                                    fontFamily: "Roboto-Medium",
                                    borderRadius: "4px",
                                    backgroundColor: "#FBB72E",
                                    textTransform: 'none',
                                    padding: '1.5%',
                                    fontSize: "14px",
                                    width: '100px',
                                    height: '32px',
                                    marginLeft: '5%'

                                }}

                                onClick={() => { this.handelNext() }}


                            >

                                Next
                            </Button>
                        </Grid>
                    </div >
                )


            default:
                return "Unknown step";
        }
    }
    render() {

        const { agentlist } = this.state
        return (
            <div>
                {this.state.camppageno == "1" &&
                    <div>
                        <Grid style={{ width: '100%', marginTop: '1%', display: 'flex', alignItems: 'center', cursor: 'pointer' }}

                            onClick={() => { this.setState({ camppageno: '0' }) }}
                        >

                            <ArrowBackIosOutlined style={{ fontSize: '15px' }}></ArrowBackIosOutlined>
                            <Typography style={{ marginLeft: '1%', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Add Campaign</Typography>
                        </Grid>
                        <Grid style={{ marginTop: '1%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <ThemeProvider theme={steppertheme}>
                                <Stepper style={{ width: '75%', height: '.75vh', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', }} activeStep={this.state.activeStep}  >
                                    {steps.map((label, index) => {
                                        return (
                                            <Step key={index}  >

                                                <StepLabel >{label}</StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            </ThemeProvider>
                        </Grid>
                        <Grid style={{


                            width: '100%', height: 'auto', marginLeft: '5%', marginTop: '2px', background: '#ffffff'


                        }}>

                            {this.getStepContent(this.state.activeStep)}

                        </Grid>
                    </div>
                }

                {this.state.camppageno == "0" &&
                    <div>
                        <Grid style={{ width: '100%', marginTop: '1%' }}


                        >
                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Campaigns</Typography>
                        </Grid>
                        <Grid style={{ width: '95%', marginTop: '1%', border: '1px solid #DFE0EB', borderRadius: '8px', padding: '2%', background: '#ffffff' }}>
                            <Grid style={{ width: '100%', display: 'flex' }}>
                                <Grid style={{ width: '75%', display: 'flex' }}>


                                    <Grid style={{ width: '50%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                                        <Typography style={{ marginRight: '1%', color: '#999999', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>From</Typography>
                                        <ThemeProvider theme={defaultMaterialTheme}>
                                            <MuiPickersUtilsProvider

                                                style={{ height: '20px', }} utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    error={false}
                                                    helperText={null}

                                                    value={this.state.fdate}
                                                    onChange={date => { this.handledobChange2(date) }}
                                                    format="yyyy/MM/dd"

                                                    InputProps={{


                                                        style: {

                                                            fontSize: 14,
                                                            height: 38,
                                                            color: '#999999',

                                                            background: '#ffffff'

                                                        }
                                                    }}
                                                    inputVariant="outlined"



                                                    KeyboardButtonProps={{
                                                        style: { marginLeft: -24 }
                                                    }}

                                                />
                                            </MuiPickersUtilsProvider>
                                        </ThemeProvider>

                                    </Grid>
                                    <Grid style={{ width: '50%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                                        <Typography style={{ marginRight: '1%', color: '#999999', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>To</Typography>
                                        <ThemeProvider theme={defaultMaterialTheme}>
                                            <MuiPickersUtilsProvider

                                                style={{ height: '20px', }} utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    error={false}
                                                    helperText={null}

                                                    value={this.state.tdate}
                                                    onChange={date => { this.handledobChange3(date) }}
                                                    format="yyyy/MM/dd"

                                                    InputProps={{


                                                        style: {

                                                            fontSize: 14,
                                                            height: 38,
                                                            color: '#999999',

                                                            background: '#ffffff'

                                                        }
                                                    }}
                                                    inputVariant="outlined"



                                                    KeyboardButtonProps={{
                                                        style: { marginLeft: -24 }
                                                    }}

                                                />
                                            </MuiPickersUtilsProvider>
                                        </ThemeProvider>

                                    </Grid>
                                </Grid>
                                <Grid style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>

                                    <Button



                                        variant="contained"
                                        size="small"

                                        style={{
                                            color: "#ffffff",
                                            fontFamily: "Roboto-Medium",
                                            borderRadius: "4px",
                                            backgroundColor: "#7DAB1C",
                                            textTransform: 'none',
                                            padding: '1.5%',
                                            fontSize: "14px",
                                            width: '150px',
                                            height: '32px',
                                            marginLeft: '5%'

                                        }}

                                        onClick={() => { this.GetCampList() }}


                                    >

                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid style={{ width: '99%', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', padding: '.5%' }}>
                            <Grid style={{ width: '95%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '10px', }}>

                                <Button



                                    variant="contained"
                                    size="small"

                                    style={{
                                        color: "#7DAB1C",
                                        fontFamily: "Roboto-Medium",
                                        borderRadius: "4px",
                                        backgroundColor: "#ffffff",
                                        border: '1px solid #7DAB1C',
                                        borderRadius: '4px',
                                        textTransform: 'none',
                                        padding: '1.5%',
                                        fontSize: "14px",
                                        width: '150px',
                                        height: '25px',
                                        marginLeft: '2%'

                                    }}

                                    onClick={() => { this.addCampaign() }}


                                >

                                    Add Campaign
                                </Button>

                            </Grid>



                        </Grid>
                        <Grid style={{ width: '100%', }}>
                            <DataTable
                                style={{ zIndex: 0 }}
                                columns={campcolumns}
                                data={this.state.camplist}
                                pagination


                                paginationPerPage='10'
                                paginationRowsPerPageOptions={[10, 20, 30, 50]}


                                dense

                                customStyles={customStyles}
                                highlightOnHover



                            />
                        </Grid>

                    </div>
                }
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "Roboto-Bold"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
            </div>
        )
    }
}
export default (withStyles(txtstyles)((camp)));