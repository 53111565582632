import React, { useContext } from 'react'
import { FormContext } from '../../FormContext';
import { TextField, Typography } from '@material-ui/core';
import { withStyles, styled } from "@material-ui/core/styles";
const Input = ({ field_id, field_label, filed_desp, field_value }) => {
    const { handleJsonChange1 } = useContext(FormContext)
    const CssTextField = styled(TextField)({
        borderRadius: '12px',


        '& label.Mui-focused': {
            color: '#999999',

        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#999999',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#999999',
            },
            '&:hover fieldset': {
                borderColor: '#999999',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#999999',
            },
        },
    });
    return (
        <div style={{ width: '100%' }}>
            <Typography style={{ marginLeft: '1.5%', fontSize: '16px', fontFamily: "Roboto-Regular", color: '#333333' }}>{field_label}</Typography>
            <Typography style={{ marginLeft: '1.8%', fontSize: '12px', fontFamily: "Roboto-Regular", color: '#999999', }}>{filed_desp}</Typography>
            <CssTextField variant="outlined"

                fullWidth

                style={{ marginLeft: '1.5%', marginTop: '.5%' }}
                inputProps={{
                    style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '5px' },


                }}
                onChange={event => handleJsonChange1(field_id, event, field_value)}
            />

        </div>
    )
}

export default Input
