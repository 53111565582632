import React, { Component } from 'react'
import {
    Typography, Grid, MenuItem, Select, Checkbox, TextField, Button, IconButton,
    Snackbar, SnackbarContent
} from '@material-ui/core'
import { httpRequest } from '../../../../ui-utils/api'
import DataTable from 'react-data-table-component';
import { withStyles, styled } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close'
import { EditOutlined, ArrowBackIosOutlined, PlayArrow, DeleteOutlineSharp, Stop } from '@material-ui/icons';
import backendHosts from '../../../../ui-utils/apiConfig'

const txtstyles = theme => ({




})

const customStyles = {
    title: {
        style: {
            fontSize: '14px', // override the row height
        },
    },
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
        stripedStyle: {

            //  background: 'red'



        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#E9F4FF',
            fontSize: '14px',
            color: '#333333',
            height: '45px',
            fontFamily: 'Roboto-Medium'
        },
    },
    cells: {
        style: {
            paddingLeft: '2px', // override the cell padding for data cells
            paddingRight: '2px',


        },
    },
    highlightOnHoverStyle: {
        style: {
            color: 'red',
            background: 'blue',
            backgroundColor: 'yellow'



        },
    },

};
const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    }
};
const keylist = ['None', '9', '*', '#']
let actionlist = ['Replay key', 'Return key', 'Terminators', 'Error', 'Time Out', 'Max Failures', 'Exit audio']



const CssTextField = styled(TextField)({
    borderRadius: '23px',
    fontFamily: 'InterRegular',
    background: '#ffff',

    '& .MuiInput-underline:after': {
        borderBottomColor: '#dddddd',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#dddddd',
        },
        '&:hover fieldset': {
            borderColor: '#dddddd',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#dddddd',
        },
    },
});


const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#41AB1C",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 8,
            width: 8,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#41AB1C'
        }
    },
    checked: { color: "#41AB1C", }
}))(Checkbox);
class ivrconfig extends Component {
    constructor(props) {
        super(props)


        this.state = {


            ivrconfiglist: [],
            pageno: 0,
            ivrconfiglist1: [],
            audiolist: [],
            ivraction: '',
            showerr: false,
            errorMsg: '',
            audiofilename: '',
            audiofiledata: '0',
            savetype: 0,
            mysnack: snackeror.mysucc,
            ivrkey: '',
            configuid: '',
            ivrname: '',
            lang: '',
            playbut1: false

        }



    }

    GetIVRConfList = async () => {

        try {
            const roledata1 = await httpRequest({
                endPoint: `/api/lcc/IVRConfList`,
                method: "get",
                instance: "instanceOne",


            });

            console.log('A====', roledata1.msg)
            if (roledata1.success == true) {
                this.setState({ ivrconfiglist: roledata1.msg })
            }


        } catch (error) {

        }


    }

    componentDidMount = async () => {

        this.GetIVRConfList()
        this.GetAudioFile()

    }

    removeA(arr) {
        var what, a = arguments, L = a.length, ax;
        while (L > 1 && arr.length) {
            what = a[--L];
            while ((ax = arr.indexOf(what)) !== -1) {
                arr.splice(ax, 1);
            }
        }
        return arr;
    }

    AddChild = async (e) => {
        let isok = 0
        const { ivraction, ivrkey, audiofiledata, configuid } = this.state

        if (!ivraction.length) {

            isok = 1
            this.setState({ showerr: true, errorMsg: "Select the Action", mysnack: snackeror.myerror })

        }


        if (!ivrkey.length) {

            isok = 1
            this.setState({ showerr: true, errorMsg: "Select the Action Key", mysnack: snackeror.myerror })

        }


        let item = {}



        if (isok === 0) {


            item = {
                'action': ivraction,
                'key': ivrkey,
                'audioname': configuid,
                'audiodata': audiofiledata,




            }

            if (ivrkey !== "None") {
                this.removeA(keylist, ivrkey);
            }

            this.removeA(actionlist, ivraction);


            this.state.ivrconfiglist1.push(item)

            this.setState({ ivrkey: '', ivraction: '', audiofiledata: '', configuid: '' })


        }

    }

    fileSelectHandler = async (e) => {
        e.preventDefault();
        let isok = 0





        let load = 0

        if (e.target.files[0].type == 'audio/wav') {
            load = 1

        }

        if (e.target.files[0].type == 'audio/mpeg') {
            load = 1

        }
        if (e.target.files[0].type == 'audio/x-gsm') {
            load = 1

        }
        if (e.target.files[0].type == 'video/mpeg') {
            load = 1

        }



        if (load === 0) {

            isok = 1
            this.setState({ showerr: true, errorMsg: "Only mpeg,pcm,mp3 and wav  files Allowed", mysnack: snackeror.myerror })

        }






        if (isok === 0) {

            let fn = e.target.files[0].name.replace(/ /g, "_");

            this.state.audiolist.push({ uid: e.target.files[0].name, audio_name: fn })
            this.setState({ audiofiledata: e.target.files[0], configuid: fn })

        }












    }
    closeContactModal() {
        this.setState({ showerr: false })
    }
    SaveConfig = async (e) => {
        const { ivrname, ivrdesp, lang } = this.state
        const { ivrconfiglist1 } = this.state
        let isok = 0
        let callaudiocreate = 0
        if (!ivrname.length) {

            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter the IVR Name", mysnack: snackeror.myerror })

        }
        if (!ivrdesp.length) {

            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter the IVR Description", mysnack: snackeror.myerror })

        }

        if (!lang.length) {

            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter the IVR Language", mysnack: snackeror.myerror })

        }

        let timeouto = 0
        let maxfail = 0
        if (isok == 0) {

            const newState = ivrconfiglist1.map(rname => {

                if (rname.action === "Time Out") {
                    timeouto = 1
                }
                if (rname.action === "Max Failures") {
                    maxfail = 1
                }



            })

            if (timeouto === 0) {
                isok = 1
                this.setState({ showerr: true, errorMsg: "Configure Time out  Action Audio", mysnack: snackeror.myerror })

            }
            if (maxfail === 0) {
                isok = 1
                this.setState({ showerr: true, errorMsg: "Configure Max Failures  Action Audio", mysnack: snackeror.myerror })

            }

        }
        console.log(ivrconfiglist1)



        if (isok === 0) {
            let payload = {}


            payload["timeout"] = 3000
            payload["max_digits"] = 1
            payload["min_digits"] = 1
            payload["max_attempts"] = 3


            const data = new FormData();

            const newState = ivrconfiglist1.map(rname => {

                let aname = ""
                if (rname.audioname.includes('VRAudio_') == 0) {

                    callaudiocreate = 1
                    aname = "IVRAudio_" + lang + "_" + rname.audioname
                    data.append('file', rname.audiodata, "IVRAudio_" + lang + "_" + rname.audioname);
                } else {
                    aname = rname.audioname
                }


                if (rname.audioname)






                    if (rname.action === "Jump Menu") {
                        payload['jump_key'] = rname.key
                        payload['jump_audio'] = aname //"IVRAudio_" + lang + "_" + rname.audioname
                    }

                if (rname.action === "Replay key") {
                    payload['replay_key'] = rname.key
                    payload['replay_audio'] = aname //"IVRAudio_" + lang + "_" + rname.audioname
                }

                if (rname.action === "Return key") {
                    payload['return_key'] = rname.key
                    payload['return_audio'] = aname// "IVRAudio_" + lang + "_" + rname.audioname
                }

                if (rname.action === "Terminators") {
                    payload['terminators'] = rname.key
                    payload['terminators_audio'] = aname //"IVRAudio_" + lang + "_" + rname.audioname
                }
                if (rname.action === "Error") {

                    payload['input_error_audio'] = aname //"IVRAudio_" + lang + "_" + rname.audioname
                }
                if (rname.action === "Time Out") {

                    payload['time_out_audio'] = aname //"IVRAudio_" + lang + "_" + rname.audioname
                }
                if (rname.action === "Max Failures") {

                    payload['max_attempts_audio'] = aname //"IVRAudio_" + lang + "_" + rname.audioname
                }

                if (rname.action === "Exit audio") {

                    payload['exit_audio'] = aname//"IVRAudio_" + lang + "_" + rname.audioname
                }
                return payload;
            });

            console.log('newState', newState)
            console.log('payload', payload)
            let a = 0
            if (callaudiocreate == 1) {
                let audio_path = backendHosts.CRM + 'api/lcc/CreateAudio'
                fetch(audio_path, {
                    method: 'POST',
                    body: data,
                }).then((response) => {
                    response.json().then((body) => {
                        let data = body.msg
                        console.log("Responce", data)

                        const newState = data.map(obj => {



                            console.log('name', obj.audio)

                            if (a == 0) {
                                if (obj.status === "failed") {
                                    a = 1
                                    this.setState({ showerr: true, errorMsg: obj.audio + " This file already exists", mysnack: snackeror.myerror })
                                }
                                if (obj.status === "success") {

                                }
                            }
                            return obj;
                        });





                    });
                });
            }
            const item = {
                "name": ivrname,
                "description": ivrdesp,
                "language": lang,
                "ivr_config": payload

            }

            try {

                const submitdata = await httpRequest({
                    endPoint: `api/lcc/CreateIVRConfig`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: item,

                });
                console.log('responce', submitdata.success)
                if (submitdata.success == true) {
                    this.GetIVRConfList()
                    this.setState({ ivrconfiglist1: '', ivrname: '', ivrdesp: '', lang: '', pageno: 0 })
                    this.setState({ showerr: true, errorMsg: "New IVR Config is Created", mysnack: snackeror.mysucc })

                }




            } catch (error) {

            }
        }

    }

    EditConfig(row) {
        console.log('Edit', row.ivr_config)
        this.setState({ pageno: 1, ivrname: row.name, ivrdesp: row.description })

        actionlist = []
        let item = {
            'action': 'Jump Menu',
            'key': row.ivr_config.jump_key,
            'audioname': row.ivr_config.jump_audio,
            'audiodata': '-',
        }

        this.state.ivrconfiglist1.push(item)
        item = {
            'action': 'Replay key',
            'key': row.ivr_config.replay_key,
            'audioname': row.ivr_config.replay_audio,
            'audiodata': '-',
        }

        this.state.ivrconfiglist1.push(item)


        item = {
            'action': 'Return key',
            'key': row.ivr_config.return_key,
            'audioname': row.ivr_config.return_audio,
            'audiodata': '-',
        }

        this.state.ivrconfiglist1.push(item)


        item = {
            'action': 'Terminators',
            'key': row.ivr_config.terminators,
            'audioname': row.ivr_config.terminators_audio,
            'audiodata': '-',
        }

        this.state.ivrconfiglist1.push(item)

        item = {
            'action': 'Error',
            'key': '-',
            'audioname': row.ivr_config.input_error_audio,
            'audiodata': '-',
        }

        this.state.ivrconfiglist1.push(item)
        //        [' 'Error', 'Time Out', 'Max Failures', 'Exit audio']
        item = {
            'action': 'Time Out',
            'key': '-',
            'audioname': row.ivr_config.time_out_audio,
            'audiodata': '-',
        }

        this.state.ivrconfiglist1.push(item)
        item = {
            'action': 'Max Failures',
            'key': '-',
            'audioname': row.ivr_config.max_attempts_audio,
            'audiodata': '-',
        }

        this.state.ivrconfiglist1.push(item)

        item = {
            'action': 'Exit audios',
            'key': '-',
            'audioname': row.ivr_config.exit_audio,
            'audiodata': '-',
        }

        this.state.ivrconfiglist1.push(item)

    }


    DeleteConfig(label, index) {

        console.log(label)
        actionlist.push(label.action)
        this.state.ivrconfiglist1.splice(index, 1);

        this.setState({ showerr: true, errorMsg: "Config Field is Deleted", mysnack: snackeror.myerror })


    }
    PalyAudio = async (e) => {

        const { configuid } = this.state
        let isok = 0
        if (configuid == 0) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Select the Audio File to Play", mysnack: snackeror.myerror })
        }


        if (isok == 0) {
            if (this.state.playbut1 == false) {
                /* let audio_path = backendHosts.CRM + '/api/lcc/PlayBack/IVR_Audio/' + welconeaudiouid
                 let ringtone = document.getElementById("ringtone");
                 ringtone.src = audio_path
                 ringtone.play()*/
                this.setState({ playbut1: true })
            } else {
                this.setState({ playbut1: false })

            }


        }

    }
    GetAudioFile = async (e) => {

        try {
            const roledata1 = await httpRequest({
                endPoint: `/api/lcc/AudioList`,
                method: "get",
                instance: "instanceOne",


            });



            if (roledata1.success == true) {

                this.setState({ audiolist: roledata1.msg })


            }
        } catch (error) {

        }







    }
    render() {
        const { audiolist } = this.state
        const columns = [

            {
                name: '',
                width: '100px',
                cell: row =>


                    <WhiteBackgroundCheckbox

                        id="chk1"

                    />


            },
            {
                name: 'Name',

                sortable: true,
                cell: row =>
                    row.name
            },



            {
                name: 'Description',

                sortable: true,
                cell: row =>
                    row.description
            },
            {
                name: 'Language',

                sortable: true,
                cell: row =>
                    row.lang
            },
            {
                name: 'From Date',

                sortable: true,
                cell: row =>
                    row.active_from
            },

            {
                name: 'Action',
                width: '75px',
                sortable: true,
                cell: row =>
                    <IconButton


                        onClick={() => this.EditConfig(row)}
                    >
                        <EditOutlined style={{ color: '#0083CA', fontSize: '18px' }} />
                    </IconButton>
            },



        ];
        const { pageno } = this.state
        return (

            <div>
                {
                    pageno == 1 &&
                    <div>
                        <Grid style={{ width: '100%', marginTop: '1%', display: 'flex', alignItems: 'center', cursor: 'pointer' }}

                            onClick={() => { this.setState({ pageno: 0 }) }}
                        >

                            <ArrowBackIosOutlined style={{ fontSize: '15px' }}></ArrowBackIosOutlined>
                            <Typography style={{ marginLeft: '1%', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Back</Typography>
                        </Grid>

                        <Grid style={{ width: '100%', marginTop: '1%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                            <Grid style={{ width: '30%' }}>
                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>IVR Name</Typography>
                            </Grid>
                            <Grid style={{ width: '30%' }}>
                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>Description</Typography>
                            </Grid>
                            <Grid style={{ width: '30%' }}>
                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>Language</Typography>
                            </Grid>
                        </Grid>

                        <Grid style={{ width: '100%', marginTop: '1%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>

                            <Grid style={{ width: '30%' }}>
                                <CssTextField focusColor="#999999"


                                    fullWidth
                                    inputProps={{
                                        style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                    }}
                                    size="small"

                                    variant="outlined"
                                    placeholder="Enter IVR Name"
                                    value={this.state.ivrname}

                                    onChange={e => {

                                        this.setState({ ivrname: e.target.value });

                                    }}
                                />
                            </Grid>
                            <Grid style={{ width: '30%' }}>
                                <CssTextField focusColor="#999999"


                                    fullWidth
                                    inputProps={{
                                        style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                    }}
                                    size="small"

                                    variant="outlined"
                                    placeholder="Enter IVR Description"
                                    value={this.state.ivrdesp}

                                    onChange={e => {

                                        this.setState({ ivrdesp: e.target.value });

                                    }}
                                />
                            </Grid>

                            <Grid style={{ width: '30%' }}>
                                <Select value={this.state.lang}
                                    displayEmpty
                                    variant="outlined"
                                    fullWidth
                                    size="small"

                                    placeholder='Select11'
                                    style={{ height: '41px', }}
                                    inputProps={{
                                        style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                    }}

                                    onChange={e => {
                                        this.setState({ lang: e.target.value, domain: e.target.lable });
                                        //this.handleDomain(e)

                                    }}

                                >
                                    <MenuItem value={'Odia'}>Odia</MenuItem>
                                    <MenuItem value={'Sambhalpuri'}>Sambhalpuri</MenuItem>



                                </Select>
                            </Grid>

                        </Grid>
                        <Grid style={{ width: '100%' }}>
                            <Grid style={{ padding: '2%', width: '95%', marginTop: '3%', borderRadius: '4px', border: '1px solid #999999' }}>
                                <Grid style={{ width: '100%', display: 'flex' }}>
                                    <Grid style={{ width: '20%' }}>
                                        <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>Action</Typography>
                                    </Grid>
                                    <Grid style={{ width: '20%', marginLeft: '2%' }}>
                                        <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>Key</Typography>
                                    </Grid>
                                    <Grid style={{ width: '10%', marginLeft: '2%' }}>
                                        <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>Audio File</Typography>
                                    </Grid>
                                    <Grid style={{ width: '75%', display: 'flex', justifyContent: 'flex-end' }}>

                                        <Button

                                            variant="text"
                                            component="span"
                                            style={{
                                                color: "#ffffff",
                                                fontFamily: "Roboto-Medium",
                                                borderRadius: "4px",
                                                backgroundColor: "#41AB1C",
                                                textTransform: 'none',


                                                fontSize: "12px",
                                                width: '100px',
                                                height: '25px',

                                            }}
                                            onClick={e => {

                                                this.AddChild()

                                            }}


                                        >+  Add Child
                                        </Button>

                                    </Grid>

                                </Grid>
                                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                    <Grid style={{ width: '20%', }}>
                                        <Select value={this.state.ivraction}
                                            displayEmpty
                                            variant="outlined"
                                            fullWidth
                                            size="small"


                                            style={{ height: '30px', }}
                                            inputProps={{
                                                style: { fontSize: '12px', fontFamily: "Roboto-Regular" },


                                            }}

                                            onChange={e => {
                                                this.setState({ ivraction: e.target.value, domain: e.target.lable });


                                            }}

                                        >
                                            {actionlist.map(rname =>

                                                <option style={{ padding: '5px', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }} value={rname} label={rname}>{rname}</option>
                                            )};



                                        </Select>
                                    </Grid>
                                    <Grid style={{ width: '10%', marginLeft: '2%' }}>
                                        <Select value={this.state.ivrkey}
                                            displayEmpty
                                            variant="outlined"
                                            fullWidth
                                            size="small"

                                            placeholder='Select11'
                                            style={{ height: '30px', }}
                                            inputProps={{
                                                style: { fontSize: '12px', fontFamily: "Roboto-Regular" },


                                            }}

                                            onChange={e => {
                                                this.setState({ ivrkey: e.target.value });
                                                //this.handleDomain(e)

                                            }}

                                        >

                                            {keylist.map(rname =>

                                                <option style={{ padding: '5px', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }} value={rname} label={rname}>{rname}</option>
                                            )};



                                        </Select>
                                    </Grid>

                                    <Grid style={{ width: '75%', marginLeft: '2%', display: 'flex', alignItems: 'center' }}>

                                        <Select value={this.state.configuid}
                                            displayEmpty
                                            variant="outlined"
                                            fullWidth
                                            size="small"

                                            placeholder='Select11'
                                            style={{ height: '30px', }}
                                            inputProps={{
                                                style: { fontSize: '12px', fontFamily: "Roboto-Regular" },


                                            }}

                                            onChange={e => {
                                                this.setState({ configuid: e.target.value });
                                                //this.handleDomain(e)

                                            }}

                                        >
                                            <MenuItem value={'0'}>Select</MenuItem>
                                            {audiolist.map(rname =>

                                                <option style={{ padding: '5px', color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }} value={rname.audio_name} label={rname.audio_name}>{rname.audio_name}</option>
                                            )};



                                        </Select>

                                        <input
                                            accept="audio/mp3"
                                            name="g9"
                                            style={{ display: 'none' }}
                                            id="g9"
                                            multiple
                                            type="file"
                                            onClick={e => e.target.value = ''}
                                            onChange={e => { this.fileSelectHandler(e) }}
                                        />
                                        <label htmlFor="g9">
                                            <Button

                                                variant="text"
                                                component="span"
                                                style={{
                                                    color: "#7DAB1C",
                                                    fontFamily: "Roboto-Medium",
                                                    borderRadius: "4px",
                                                    backgroundColor: "#ffffff",
                                                    textTransform: 'none',
                                                    border: '1px solid #7DAB1C',

                                                    fontSize: "12px",
                                                    marginLeft: '5px',
                                                    height: '30px',

                                                }}



                                            >+
                                            </Button>
                                        </label>
                                        <IconButton
                                            style={{
                                                width: '50px',
                                                height: '30px', borderRadius: "4px", marginLeft: '1px', fontSize: '10px'
                                            }}

                                            onClick={() => this.PalyAudio()}
                                        >

                                            {
                                                this.state.playbut1 ?
                                                    <Stop style={{
                                                        color: 'red', fontSize: '20px'
                                                    }} />
                                                    :
                                                    <PlayArrow style={{
                                                        color: '#92A4C1', fontSize: '20px'
                                                    }} />

                                            }
                                        </IconButton>


                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>





                        <Grid style={{ width: '100%' }}>
                            <Grid style={{ marginTop: '1%', width: '100%', background: '#E9F4FF', padding: '1%', display: 'flex', borderBottom: '1px solid #999999' }}>
                                <Grid style={{ width: '15%', marginLeft: '1%' }}>
                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Action</Typography>
                                </Grid>
                                <Grid style={{ width: '15%' }}>
                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Key</Typography>
                                </Grid>
                                <Grid style={{ width: '60%' }}>
                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Audio File</Typography>
                                </Grid>
                                <Grid style={{ width: '10%' }}>

                                </Grid>

                            </Grid>

                            {this.state.ivrconfiglist1.map((label, index) => {

                                return (
                                    <div>
                                        <Grid style={{ width: '100%', display: 'flex', borderBottom: '1px solid #999999', background: '#ffffff', alignItems: 'center' }}>
                                            <Grid style={{ width: '15%', marginLeft: '1%' }}>
                                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>{label.action}</Typography>
                                            </Grid>
                                            <Grid style={{ width: '15%' }}>
                                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>{label.key}</Typography>
                                            </Grid>
                                            <Grid style={{ width: '60%' }}>
                                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>{label.audioname}</Typography>
                                            </Grid>

                                            <Grid style={{ width: '10%' }}>
                                                <IconButton


                                                    onClick={() => this.DeleteForm(label, index)}
                                                >
                                                    <PlayArrow style={{ color: '#92A4C1', fontSize: '20px' }} />
                                                </IconButton>
                                                <IconButton


                                                    onClick={() => this.DeleteConfig(label, index)}
                                                >
                                                    <DeleteOutlineSharp style={{ color: 'red', fontSize: '18px' }} />
                                                </IconButton>

                                            </Grid>
                                        </Grid>
                                    </div>
                                )
                            })}

                        </Grid>


                        <Grid style={{ width: '95%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '2%' }}>



                            <Button



                                variant="contained"
                                size="small"

                                style={{
                                    color: "#ffffff",
                                    fontFamily: "Roboto-Medium",
                                    borderRadius: "4px",
                                    backgroundColor: "#41AB1C",
                                    textTransform: 'none',
                                    padding: '1.5%',
                                    fontSize: "14px",
                                    width: '150px',
                                    height: '32px',
                                    marginLeft: '3%'

                                }}

                                onClick={() => { this.SaveConfig() }}


                            >

                                Save
                            </Button>
                        </Grid>
                    </div>
                }

                {
                    pageno == 0 &&
                    <Grid style={{ width: '100%', padding: '1%', justifyContent: 'center' }}>


                        <Grid style={{ width: '95%', display: 'flex', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', padding: '2%' }}>
                            <Grid style={{ width: '75%', }}>
                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>{this.state.numofrows} IVR</Typography>
                            </Grid>
                            <Grid style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>

                                <Button



                                    variant="contained"
                                    size="small"

                                    style={{
                                        color: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        borderRadius: "4px",
                                        backgroundColor: "#41AB1C",
                                        textTransform: 'none',
                                        padding: '1.5%',
                                        fontSize: "14px",
                                        width: '150px',
                                        height: '32px',
                                        marginLeft: '5%'

                                    }}

                                    onClick={() => { this.setState({ pageno: 1 }) }}


                                >

                                    New Config
                                </Button>


                            </Grid>

                        </Grid>
                        <Grid style={{ height: 'auto', overflow: 'auto', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', }}>


                            <DataTable

                                columns={columns}
                                data={this.state.ivrconfiglist}
                                pagination
                                striped
                                responsive
                                paginationPerPage='30'

                                dense

                                customStyles={customStyles}
                                highlightOnHover



                            />


                        </Grid>
                    </Grid>
                }

                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "Roboto-Bold"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
            </div>
        )

    }
}
export default (withStyles(txtstyles)((ivrconfig)));