import axios from "axios";

import backendHosts from './apiConfig'
import jwt_decode from "jwt-decode";


const axiosApiInstance = axios.create(

  { baseURL: backendHosts.CRM, }
);
const axiosApiInstancepost = axios.create(

  {
    baseURL: backendHosts.CRM,

    method: 'post'
  }
);

axiosApiInstance.interceptors.request.use(

  async (config) => {
    let a = localStorage.getItem('lcc_token') || '{}'
    let b = JSON.parse(a)

    config.headers = {
      "Accept": "*/*",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "accept": "application/json",
      Authorization: gettok()
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);


var livetocken = ''

let hdata = ''




function gettok() {

  delete axios.defaults.headers.common["Authorization"];
  let a = localStorage.getItem('lcc_token') || '{}'
  let b = JSON.parse(a)
  livetocken = b.token
  return livetocken
}
//"Authorization": "Bearer " + gettok()
export const httpRequest = async ({
  method = "post",
  endPoint,
  timeout = {},
  params = {},
  requestBody = {},
  instance = "instanceOne",
  token = {},
  headers = hdata

}) => {




  //21500

  //params = JSON.stringify(params)


  requestBody = JSON.stringify(requestBody)





  var response1;

  response1 = "ERROR"
  try {
    switch (method) {
      case "post":

        response1 = await axiosApiInstance.post(endPoint, requestBody)
          .then(function (response) {

            return response
          })
          .catch(function (error) {
            console.log(error);
          });


        break;
      default:



        response1 = await axiosApiInstance.get(
          endPoint,
          params,




        );
    }




    let dedata = response1.data;

    console.log(dedata);
    return dedata;
  } catch (reason) {


    console.log("Error", reason.response1.data)

    return reason.response1.data;
  }

  return response1


}
