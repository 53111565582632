import React, { Component } from 'react'
import {
    Switch, Typography, Grid, Button, Checkbox, TextField, IconButton,
    FormGroup, FormControlLabel, Snackbar, SnackbarContent
} from '@material-ui/core'
import { withStyles, styled, createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ArrowBackIosOutlined } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close'
import { httpRequest } from '../../../ui-utils/api'
import DataTable from 'react-data-table-component';

import { EditOutlined, DeleteOutlineSharp } from '@material-ui/icons';
const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    }
};
const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#41AB1C",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 10,
            width: 10,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#41AB1C'
        }
    },
    checked: { color: "#41AB1C", }
}))(Checkbox);


const MySwitch = withStyles(theme => ({
    root: {
        '& .MuiSwitch-switchBase': {

            '&.Mui-checked': {
                color: '#7DAB1C',

                '& .MuiSwitch-thumb:before': {
                    backgroundColor: '#7DAB1C'
                },
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: "#7DAB1C"
                },
            },
        },

    },

}))(Switch);

const txtstyles = theme => ({




})
const CssTextField = styled(TextField)({
    borderRadius: '12px',


    '& label.Mui-focused': {
        color: '#999999',

    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#999999',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#999999',
        },
        '&:hover fieldset': {
            borderColor: '#999999',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#999999',
        },
    },
});
const customStyles = {
    title: {
        style: {
            fontSize: '14px', // override the row height
        },
    },
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
        stripedStyle: {

            //  background: 'red'



        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#E9F4FF',
            fontSize: '14px',
            color: '#333333',
            height: '45px',
            fontFamily: 'Roboto-Medium'
        },
    },
    cells: {
        style: {
            paddingLeft: '2px', // override the cell padding for data cells
            paddingRight: '2px',


        },
    },
    highlightOnHoverStyle: {
        style: {
            color: 'red',
            background: 'blue',
            backgroundColor: 'yellow'



        },
    },

};
const theme = createTheme({
    status: {
        danger: '#e53e3e',
    },
    palette: {
        primary: {
            main: '#0971f1',
            darker: '#053e85',
        },
        neutral: {
            main: 'red',
            background: 'red',
            contrastText: '#fff',
        },
    },
});
var obj = {}
var obj1 = []
let submitdata = []
class adminform extends Component {
    constructor(props) {
        super(props)


        this.state = {
            showerr: false,
            errorMsg: '',
            mysnack: snackeror.mysucc,
            addformpage: '0',
            showoption: '0',
            callbutt_st1: false,
            callbutt_st2: false,
            callbutt_st3: false,
            callbutt_st4: false,
            labelname: 'Text Field',
            filedname: '',
            filed_id: '',
            filed_desp: '',
            formname: '',
            field_options: '',
            formdisp: '',
            formdata: [],
            numofrows: 0,
            reqactive: true,
            fstatus: true,
            savetype: 0,
            butname: 'Save'

        }



    }
    GetFormDetailes = async () => {

        let sql = "select uid,form_name,form_disp,form_fields,created_by,status,substring(created_on::text,1,16) as created_no from  lcc_forms where form_type=1 order by uid"
        console.log(sql)



        const item = {
            "stmt": sql

        }







        try {
            const domaindata = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: item,

            });


            if (domaindata.success == true) {

                this.setState({ formdata: domaindata.msg, numofrows: domaindata.msg.length })
            }
        } catch (error) {

        }



    }
    componentDidMount = async () => {
        this.GetFormDetailes()
    }
    closeContactModal() {
        this.setState({ showerr: false })
    }
    SaveForm = async () => {

        const { formname, formdisp, fstatus } = this.state
        let isok = 0
        let st = "Active"

        if (!formname.length) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter Form Name", mysnack: snackeror.myerror })
        }

        if (!formdisp.length) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter Form Description", mysnack: snackeror.myerror })
        }

        if (isok == 0) {
            console.log(obj1)
            let submitdata1 = []

            if (fstatus == true) {
                st = "Active"
            } else {
                st = "InActive"
            }


            submitdata.push({ 'page_label': formname, 'fields': obj1 })

            submitdata1.push(JSON.parse(JSON.stringify(submitdata)))
            console.log('submitdata', submitdata)
            if (this.state.savetype > 0) {
                let newformname = formname + "_" + this.state.savetype


                let sql = "update lcc_forms set status='Inactive',form_name='" + newformname + "' where uid=" + this.state.savetype + " returning 1 as result"
                console.log(sql)
                const newuseritem = {
                    "stmt": sql

                }
                try {
                    const get_domintid1 = await httpRequest({
                        endPoint: `api/db/stmt`,
                        method: "post",
                        instance: "instanceOne",
                        requestBody: newuseritem,

                    });


                } catch (error) {

                }

            }

            let sql = "insert into lcc_forms(form_name,form_disp,form_type,domain_id,form_fields,created_on,status) values('" + formname + "','" + formdisp + "',1,0,'" + JSON.stringify(submitdata) + "','now()','" + st + "') returning 1 as result"
            console.log(sql)
            const newuseritem = {
                "stmt": sql

            }
            try {
                const get_domintid1 = await httpRequest({
                    endPoint: `api/db/stmt`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: newuseritem,

                });

                if (get_domintid1.success == true) {

                    this.setState({ filed_desp: '', addformpage: '0', obj1: '', formdisp: '', formname: '', showerr: true, errorMsg: "Dynamic Form is Saved", mysnack: snackeror.mysucc })
                    this.GetFormDetailes()

                }
            } catch (error) {

            }

        }

    }

    EditForm(row) {



        console.log(row.form_fields[0].fields)
        obj1 = row.form_fields[row.form_fields.length - 1].fields


        this.setState({ butname: 'Update', savetype: row.uid, addformpage: '1', formname: row.form_name, formdisp: row.form_disp })


    }
    DeleteForm(data, index) {
        console.log('index', index)
        obj1.splice(index, 1);
        this.setState({ showerr: true, errorMsg: "Form Field is Deleted", mysnack: snackeror.myerror })
        console.log('Agter', obj1)

    }
    AddFields() {

        let field_type = ''
        const { labelname, filedname, filed_id, formname, reqactive, filed_desp } = this.state
        let isok = 0
        if (!formname.length) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter Form Name", mysnack: snackeror.myerror })
        }

        if (!filedname.length) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter Filed Name", mysnack: snackeror.myerror })
        }
        if (!filed_id.length) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter Filed ID", mysnack: snackeror.myerror })
        }


        if (isok === 0) {
            if (labelname === "Drop down field") {
                field_type = "select"
            }
            if (labelname === "Text Field") {
                field_type = "text"
            }

            if (labelname === "Checkbox") {
                field_type = "checkbox"
            }

            if (labelname === "Cascading Drop down field") {
                field_type = "cascading"
            }




            let opti = {}
            let field_optionslist = []
            obj = {}
            if (isok == 0) {

                obj['field_id'] = filed_id
                obj['field_label'] = filedname;
                obj['field_value'] = "";
                obj['filed_desp'] = filed_desp
                if (reqactive == true) {
                    obj['field_mandatory'] = 'yes'
                } else {
                    obj['field_mandatory'] = 'no'
                }

                obj['field_options1'] = this.state.field_options

                if (labelname === "Drop down field") {
                    this.state.field_options.split(',').map((step) => {

                        field_optionslist.push({ 'option_label': step })

                    }
                    )
                    console.log(field_optionslist)
                    obj['field_options'] = (field_optionslist)
                }


            }
            //{str.split(',').map((step)=> <Text>{step}{",\n"}</Text>)}

            obj['field_type'] = field_type
            obj1.push(obj)
            console.log(obj)
            this.setState({ filed_id: '', filedname: '', field_options: '', filed_desp: '' })
        }
    }
    render() {
        const { callbutt_st1, callbutt_st2, callbutt_st3, callbutt_st4, labelname, filedname, filed_id, formname } = this.state
        const { classes } = this.props

        const columns = [

            {
                name: '',
                width: '40px',
                cell: row =>


                    <WhiteBackgroundCheckbox

                        id="chk1"

                    />


            },



            {
                name: 'Form Name',
                width: '250px',
                sortable: true,
                cell: row =>
                    row.form_name
            },

            {
                name: 'Form Description',
                width: '250px',
                sortable: true,
                cell: row =>
                    row.form_disp
            },




            {
                name: 'Created on',
                width: '130px',
                sortable: true,
                cell: row =>
                    row.created_no
            },
            {
                name: 'Created by',
                width: '130px',
                sortable: true,
                cell: row =>
                    row.created_by
            },

            {
                name: 'Status',
                width: '100px',
                sortable: true,
                cell: row =>
                    row.status
            },
            {
                name: 'Action',

                sortable: true,
                cell: row =>
                    <IconButton


                        onClick={() => this.EditForm(row)}
                    >
                        <EditOutlined style={{ color: '#0083CA', fontSize: '18px' }} />
                    </IconButton>
            }









        ];
        return (


            <div>
                {this.state.addformpage === '1' &&

                    <div>
                        <Grid style={{ width: '100%', marginTop: '1%', display: 'flex', alignItems: 'center', cursor: 'pointer' }}

                            onClick={() => { this.setState({ addformpage: '0' }) }}
                        >

                            <ArrowBackIosOutlined style={{ fontSize: '15px' }}></ArrowBackIosOutlined>
                            <Typography style={{ marginLeft: '1%', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Create new Form</Typography>
                        </Grid>
                        <Grid style={{ width: '100%', background: '#ffffff', padding: '1%', marginTop: '2%', display: 'flex' }}>
                            <Grid style={{ width: '35%' }}>
                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Name your form</Typography>
                            </Grid>
                            <Grid style={{ width: '45%' }}>
                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Description</Typography>
                            </Grid>

                            <Grid style={{ width: '5%' }}>
                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Status</Typography>
                            </Grid>


                        </Grid>
                        <Grid style={{
                            width: '100%', background: '#F7F9FA', display: 'flex'
                        }}>
                            <Grid style={{ width: '30%' }}>
                                < CssTextField focusColor="#999999"

                                    autoFocus={true}

                                    inputProps={{
                                        style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '15px', width: '250px' },


                                    }}
                                    size="small"

                                    variant="outlined"

                                    value={this.state.formname}
                                    style={{ padding: '.5% 3%' }}
                                    onChange={e => {

                                        this.setState({ formname: e.target.value });

                                    }}
                                />
                            </Grid>
                            <Grid style={{ width: '45%' }}>
                                < CssTextField focusColor="#999999"


                                    fullWidth
                                    inputProps={{
                                        style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '15px' },


                                    }}
                                    size="small"

                                    variant="outlined"

                                    value={this.state.formdisp}
                                    style={{ padding: '.5% 3%' }}
                                    onChange={e => {

                                        this.setState({ formdisp: e.target.value });

                                    }}
                                />
                            </Grid>
                            <Grid style={{ width: '13%', display: 'flex', justifyContent: 'flex-end' }}>

                                <MySwitch

                                    checked={this.state.fstatus}



                                    onClick={() => this.setState({ fstatus: !this.state.fstatus })}
                                />

                            </Grid>
                        </Grid>

                        <Grid style={{
                            width: '98%', background: '#ffffff', marginTop: '1%', display: 'flex', padding: '2%', borderRadius: '8px'
                        }}>

                            <Grid style={{

                                width: '20%', background: '#fffff', padding: '1%', display: 'flex'

                            }}>


                                <Grid style={{
                                    width: '100%', padding: '1%', background: '#EDEDED', borderTopLeftRadius: '12px', borderBottomLeftRadius: '12px'

                                }}>

                                    <Grid style={{ cursor: 'pointer', width: '100%', background: callbutt_st1 ? '#7DAB1C' : '#EDEDED', padding: '1%' }}

                                    >



                                        <Typography

                                            onMouseLeave={e =>

                                                this.setState({ callbutt_st1: false })
                                            }
                                            onMouseOver={e =>

                                                this.setState({ callbutt_st1: true })
                                            }
                                            style={{ color: callbutt_st1 ? '#ffffff' : '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}

                                            onClick={() => { this.setState({ labelname: 'Text Field', showoption: '0' }) }}

                                        >Text Field</Typography>
                                    </Grid>
                                    <Grid style={{ cursor: 'pointer', width: '100%', background: callbutt_st2 ? '#7DAB1C' : '#EDEDED', padding: '1%' }}

                                    >



                                        <Typography

                                            onMouseLeave={e =>

                                                this.setState({ callbutt_st2: false })
                                            }
                                            onMouseOver={e =>

                                                this.setState({ callbutt_st2: true })
                                            }
                                            style={{ color: callbutt_st2 ? '#ffffff' : '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}

                                            onClick={() => { this.setState({ labelname: 'Drop down field', showoption: '1' }) }}

                                        >Drop down field</Typography>
                                    </Grid>

                                    <Grid style={{ cursor: 'pointer', width: '100%', background: callbutt_st3 ? '#7DAB1C' : '#EDEDED', padding: '1%' }}

                                    >



                                        <Typography

                                            onMouseLeave={e =>

                                                this.setState({ callbutt_st3: false })
                                            }
                                            onMouseOver={e =>

                                                this.setState({ callbutt_st3: true })
                                            }
                                            style={{ color: callbutt_st3 ? '#ffffff' : '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}

                                            onClick={() => { this.setState({ labelname: 'Cascading Drop down field', showoption: '1' }) }}

                                        >Cascading Drop down</Typography>
                                    </Grid>
                                    <Grid style={{ cursor: 'pointer', width: '100%', background: callbutt_st4 ? '#7DAB1C' : '#EDEDED', padding: '1%' }}

                                    >



                                        <Typography

                                            onMouseLeave={e =>

                                                this.setState({ callbutt_st4: false })
                                            }
                                            onMouseOver={e =>

                                                this.setState({ callbutt_st4: true })
                                            }
                                            style={{ color: callbutt_st4 ? '#ffffff' : '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}

                                            onClick={() => { this.setState({ labelname: 'Checkbox', showoption: '1' }) }}

                                        >Checkbox</Typography>
                                    </Grid>

                                </Grid>


                            </Grid>
                            <Grid style={{
                                marginLeft: '1%',
                                width: '80%', background: '#fffff', padding: '1%', border: '1px solid #999999', borderRadius: '12px'

                            }}>
                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>{labelname}</Typography>
                                </Grid>

                                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                    <Grid style={{ width: '10%' }}>
                                        <Typography style={{ color: '#999999', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Question</Typography>
                                    </Grid>
                                    <Grid style={{ width: '80%' }}>
                                        < CssTextField focusColor="#999999"

                                            fullWidth

                                            inputProps={{
                                                style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '15px' },


                                            }}
                                            size="small"

                                            variant="outlined"

                                            value={this.state.filedname}
                                            style={{ padding: '.5% 3%' }}
                                            onChange={e => {

                                                this.setState({ filedname: e.target.value });

                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                    <Grid style={{ width: '10%' }}>
                                        <Typography style={{ color: '#999999', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Description</Typography>
                                    </Grid>
                                    <Grid style={{ width: '80%' }}>

                                        < CssTextField focusColor="#999999"

                                            fullWidth

                                            inputProps={{
                                                style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '15px' },


                                            }}
                                            size="small"

                                            variant="outlined"

                                            value={this.state.filed_desp}
                                            style={{ padding: '.5% 3%' }}
                                            onChange={e => {

                                                this.setState({ filed_desp: e.target.value });

                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                    <Grid style={{ width: '10%' }}>
                                        <Typography style={{ color: '#999999', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Field ID </Typography>
                                    </Grid>
                                    <Grid style={{ width: '60%' }}>
                                        < CssTextField focusColor="#999999"



                                            inputProps={{
                                                style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '15px' },


                                            }}
                                            size="small"

                                            variant="outlined"

                                            value={this.state.filed_id}
                                            style={{ padding: '1% 4.25%' }}
                                            onChange={e => {

                                                this.setState({ filed_id: e.target.value });

                                            }}
                                        />
                                    </Grid>
                                    <Grid style={{ width: '30%' }}>
                                        <FormGroup>
                                            <FormControlLabel style={{ color: '#999999', fontSize: '10px', fontFamily: 'Roboto-Medium' }}
                                                control={<MySwitch
                                                    checked={this.state.reqactive}
                                                    inputProps={{ color: 'yellow' }}



                                                    onClick={() => this.setState({ reqactive: !this.state.reqactive })}
                                                />} label="Required" />

                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                {this.state.showoption == "1" &&
                                    <div>
                                        <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                            <Grid style={{ width: '10%' }}>
                                                <Typography style={{ color: '#999999', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Options</Typography>
                                            </Grid>
                                            <Grid style={{ width: '80%' }}>

                                                < CssTextField focusColor="#999999"

                                                    style={{ padding: '.5% 3%' }}
                                                    fullWidth
                                                    inputProps={{
                                                        style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '15px' },


                                                    }}
                                                    size="small"

                                                    variant="outlined"

                                                    value={this.state.field_options}

                                                    onChange={e => {

                                                        this.setState({ field_options: e.target.value });

                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid style={{ width: '100%', display: 'flex' }}>
                                            <Grid style={{ width: '10%' }}>

                                            </Grid>
                                            <Grid style={{ width: '80%' }}>
                                                <Typography style={{ color: '#999999', fontSize: '10px', fontFamily: 'Roboto-Medium' }}>Enter values in Comma  separated string</Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                }
                            </Grid>
                            <Grid style={{
                                width: '12%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'
                            }}>
                                <Button



                                    variant="contained"
                                    size="small"

                                    style={{
                                        color: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        borderRadius: "4px",
                                        backgroundColor: "#7DAB1C",
                                        textTransform: 'none',
                                        padding: '1.5%',
                                        fontSize: "14px",
                                        width: '75px',
                                        height: '32px',
                                        marginLeft: '1%'

                                    }}

                                    onClick={() => { this.AddFields() }}


                                >

                                    +
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid style={{
                            width: '98%', marginTop: '2%', display: 'flex', justifyContent: 'flex-end'
                        }}>

                            <Button



                                variant="contained"
                                size="small"

                                style={{
                                    color: "#ffffff",
                                    fontFamily: "Roboto-Medium",
                                    borderRadius: "4px",
                                    backgroundColor: "#7DAB1C",
                                    textTransform: 'none',
                                    padding: '1.5%',
                                    fontSize: "14px",
                                    width: '125px',
                                    height: '32px',
                                    marginLeft: '1%'

                                }}

                                onClick={() => { this.SaveForm() }}


                            >

                                {this.state.butname}
                            </Button>
                        </Grid>

                        <Grid style={{ marginTop: '1%', width: '100%', background: '#E9F4FF', padding: '1%', display: 'flex', borderBottom: '1px solid #999999' }}>
                            <Grid style={{ width: '10%', marginLeft: '1%' }}>
                                <Typography style={{ color: '#999999', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Field Type</Typography>
                            </Grid>
                            <Grid style={{ width: '10%' }}>
                                <Typography style={{ color: '#999999', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Field ID</Typography>
                            </Grid>
                            <Grid style={{ width: '25%' }}>
                                <Typography style={{ color: '#999999', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Field Name</Typography>
                            </Grid>
                            <Grid style={{ width: '50%' }}>
                                <Typography style={{ color: '#999999', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Field Options</Typography>
                            </Grid>
                            <Grid style={{ width: '10%' }}>
                                <Typography style={{ color: '#999999', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Action</Typography>
                            </Grid>

                        </Grid>
                        <Grid style={{ width: '100%' }}>

                            {obj1.map((label, index) => {

                                return (
                                    <div>
                                        <Grid style={{ width: '100%', display: 'flex', borderBottom: '1px solid #999999', background: '#ffffff' }}>
                                            <Grid style={{ width: '10%', marginLeft: '1%' }}>
                                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>{label.field_type}</Typography>
                                            </Grid>
                                            <Grid style={{ width: '10%' }}>
                                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>{label.field_id}</Typography>
                                            </Grid>
                                            <Grid style={{ width: '30%' }}>
                                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>{label.field_label}</Typography>
                                            </Grid>
                                            <Grid style={{ width: '50%' }}>
                                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>{label.field_options1}</Typography>
                                            </Grid>
                                            <Grid style={{ width: '10%' }}>
                                                <IconButton


                                                    onClick={() => this.DeleteForm(label, index)}
                                                >
                                                    <DeleteOutlineSharp style={{ color: 'red', fontSize: '18px' }} />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )
                            })}
                        </Grid>




                    </div>
                }
                {
                    this.state.addformpage === '0' &&
                    <div>
                        <Grid style={{ width: '100%', marginTop: '1%' }}>
                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Forms</Typography>
                        </Grid>
                        <Grid style={{ width: '95%', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', padding: '2%' }}>
                            <Grid style={{ width: '100%', display: 'flex' }}>
                                <Grid style={{ width: '75%', display: 'flex' }}>
                                    <Typography style={{ color: '#333333', fontSize: '16px', fontFamily: 'Roboto-Bold' }}>{this.state.numofrows} Forms</Typography>
                                </Grid>
                                <Grid style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button



                                        variant="contained"
                                        size="small"

                                        style={{
                                            color: "#333333",
                                            fontFamily: "Roboto-Medium",
                                            borderRadius: "4px",
                                            backgroundColor: "#E7EAF0",
                                            textTransform: 'none',
                                            padding: '1.5%',
                                            fontSize: "14px",
                                            width: '150px',
                                            height: '32px',


                                        }}

                                        onClick={() => { /* */ }}


                                    >

                                        Download
                                    </Button>
                                    <Button



                                        variant="contained"
                                        size="small"

                                        style={{
                                            color: "#ffffff",
                                            fontFamily: "Roboto-Medium",
                                            borderRadius: "4px",
                                            backgroundColor: "#41AB1C",
                                            textTransform: 'none',
                                            padding: '1.5%',
                                            fontSize: "14px",
                                            width: '150px',
                                            height: '32px',
                                            marginLeft: '5%'

                                        }}

                                        onClick={() => { this.setState({ addformpage: '1' }) }}


                                    >

                                        Add New Forms
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid style={{ height: 'auto', overflow: 'auto', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', }}>


                            <DataTable

                                columns={columns}
                                data={this.state.formdata}
                                pagination
                                striped
                                responsive
                                paginationPerPage='100'
                                paginationRowsPerPageOptions={[100, 150, 200, 250]}

                                defaultSortFieldId
                                dense

                                customStyles={customStyles}
                                highlightOnHover



                            />


                        </Grid>
                    </div>
                }
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "Roboto-Bold"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
            </div >
        )
    }
}
export default (withStyles(txtstyles)((adminform)));