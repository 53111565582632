import React, { useState, useContext } from 'react'
import { FormContext } from '../../FormContext'
import { Grid, Select } from '@material-ui/core';



const Cascading = ({ field_id, field_label, field_placeholder, field_value, field_value1, field_options, field_options1 }) => {
    const { handleJsonChange1 } = useContext(FormContext)
    const [name, setName] = useState(0);
    const [opt1, setOpt1] = useState('');
    const [opt, setOpt] = useState('');


    function onChangeHandler (index) {
       
        console.log('AA',index)
      }
 
    function SelectOpt(event) {
        
        setOpt("")
        let index1
        field_options1.split(',').map((step,index) =>
{
        if(step===event.target.value)
        index1=index+1
    }
        )

      
        
       
        
        setOpt(event.target.value)
       setOpt1(field_options[index1][event.target.value])






    }
    return (
        <>
   <Grid container style={{width:'100%'}}>
     <Select
                displayEmpty
                
                placeholder="Age"
                fullWidth
              
                onClick={(event) => SelectOpt(event)}
                onChange={event => handleJsonChange1(field_id, event)}      
                
                style={{ width:'100%',fontSize: '16px', color: '#333333', fontFamily: 'Roboto-Regular', marginLeft: '2%', height: '40px', marginTop: '2%', background: '#ffffff' }}
            >
                <option>{field_label}</option>

                {

                    field_options1.split(',').map((step,index) =>


                        <option value={step} key={index}
                            style={{ fontSize: '16px', color: '#333333', fontFamily: 'Roboto-Regular', padding: '5px' }}
                        
                           
                        >{step}</option>



                    )
                }

            </Select>
            </Grid>

            <Select
                displayEmpty
              
                fullWidth
                placeholder="Age"
                onChange={event => handleJsonChange1(field_id, event,1)}
                style={{ width:'100%',fontSize: '16px', color: '#333333', fontFamily: 'Roboto-Regular', marginLeft: '2%', height: '40px', marginTop: '3%', background: '#ffffff' }}
            >
                <option>{field_options[0].field_label}</option>

                {

                    opt1.split(',').map((step) =>


                        <option value={step}
                            style={{ fontSize: '16px', color: '#333333', fontFamily: 'Roboto-Regular', padding: '5px' }}
                        >{step}</option>



                    )
                }

            </Select>


        </>
    )
}

export default Cascading
