import React, { Component } from 'react'
import {
    MenuItem, Typography,
    Grid, Button, Checkbox, IconButton,
    Snackbar, SnackbarContent, TextField
} from '@material-ui/core'

import Select from 'react-select'
import { withStyles, styled } from "@material-ui/core/styles";
import { ArrowBackIosOutlined } from '@material-ui/icons';
import { httpRequest } from '../../../ui-utils/api'
import DataTable from 'react-data-table-component';
import { EditOutlined } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close'

const txtstyles = theme => ({




})
const CssTextField = styled(TextField)({
    borderRadius: '12px',


    '& label.Mui-focused': {
        color: '#999999',

    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#999999',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#999999',
        },
        '&:hover fieldset': {
            borderColor: '#999999',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#999999',
        },
    },
});
const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#41AB1C",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 10,
            width: 10,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#41AB1C'
        }
    },
    checked: { color: "#41AB1C", }
}))(Checkbox);
const conditionalRowStyles = [
    {
        when: row => row.account_status == "E",
        style: {
            backgroundColor: 'green',
            color: 'white',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    },

];

const customStyles = {
    title: {
        style: {
            fontSize: '14px', // override the row height
        },
    },
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
        stripedStyle: {

            //  background: 'red'



        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#E9F4FF',
            fontSize: '14px',
            color: '#333333',
            height: '45px',
            fontFamily: 'Roboto-Medium'
        },
    },
    cells: {
        style: {
            paddingLeft: '2px', // override the cell padding for data cells
            paddingRight: '2px',


        },
    },
    highlightOnHoverStyle: {
        style: {
            color: 'red',
            background: 'blue',
            backgroundColor: 'yellow'



        },
    },

};


const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    }
};


class newuser extends Component {
    constructor(props) {
        super(props)


        this.state = {


            userpage: '0',
            aid: '',
            userpin: '',
            mysnack: snackeror.mysucc,
            username: '',
            usercontacts: '',
            domain: '0',
            domainid: '0',
            role: '0',
            savetype: 0,
            userdata: [],
            roledata: [],
            domaindata: [],
            additionalrole: [],
            menulist: {},
            allItems: 'Dashboard,Weekly Dashboard'

        }



    }

    closeContactModal() {
        this.setState({ showerr: false })
    }


    handleAgentID(e) {
        const re = /^[0-9\b]+$/;
        if (e.target.value.length <= 10) {
            if (e.target.value === '' || re.test(e.target.value)) {

                this.setState({ aid: e.target.value })

            }
        }

    }

    SaveNewUser = async () => {
        const { aid, userpin, username, usercontacts, domain, role, domainid, menulist } = this.state
        const lcc_token = {
            0: aid

        }


        console.log('domain==', domain.value)

        let menuname = ""
        let menuidlist = ""
        for (var key in menulist) {

            menuname = menuname + menulist[key]['value'] + ","


        }
        menuname = menuname.substring(0, menuname.length - 1);
        console.log('menulist', menuname)


        this.setState({ usercontacts: JSON.stringify(lcc_token) })

        let isok = 0


        let sql_domintid1 = "select string_agg(id::text, ',')  AS id from pad_modules where menu_name in('" + menuname + "')"
        console.log(sql_domintid1)

        const get_domintid1 = {
            "stmt": sql_domintid1

        }


        try {
            const menuid = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: get_domintid1,

            });

            if (menuid.success == true) {
                menuidlist = menuid.msg[0].id

            }
        } catch (error) {

        }


        let sql_domintid = "select uid from pad_domains where name='" + domain.value + "'"
        console.log(sql_domintid)






        const get_domintid = {
            "stmt": sql_domintid

        }


        try {
            const get_domintid1 = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: get_domintid,

            });
            console.log('get_domintid.msg.uid', get_domintid1.msg[0].uid)
            if (get_domintid1.success == true) {

                this.state.domainid = get_domintid1.msg[0].uid
            }
        } catch (error) {

        }



        if (!aid.length) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter User/Agent ID", mysnack: snackeror.myerror })
        }


        if (aid.length < 10) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "User/Agent ID Must be 10-digit", mysnack: snackeror.myerror })
        }

        if (!username.length) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter User Name", mysnack: snackeror.myerror })
        }

        if (!userpin.length) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter User PIN", mysnack: snackeror.myerror })
        }







        if (domain == "0") {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter User Domain", mysnack: snackeror.myerror })
        }

        if (role == "0") {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter User Role", mysnack: snackeror.myerror })
        }

        if (isok == 0) {



            if (this.state.savetype == 0) {

                let sql = "insert into pad_agent(aid,name,contact,pin,domain,role,status,domain_id,menuidlist) values('" + aid + "','" + username + "','" + JSON.stringify(lcc_token) + "','" + userpin + "','" + domain.label + "'," + role.value + ",'Enabled'," + domain.value + ",'" + menuname + "') RETURNING uid"
                console.log(sql)
                const newuseritem = {
                    "stmt": sql

                }
                try {
                    const newuserdata = await httpRequest({
                        endPoint: `api/db/stmt`,
                        method: "post",
                        instance: "instanceOne",
                        requestBody: newuseritem,

                    });



                    if (newuserdata.success == true) {

                        this.setState({ showerr: true, errorMsg: "New User is Created ", mysnack: snackeror.mysucc })

                        this.setState({ userpage: '0' })
                        this.GetUser()



                    }
                } catch (error) {

                }

            }

            if (this.state.savetype > 0) {




                let updatesql = "update pad_agent  set aid='" + aid + "',name='" + username + "',contact='" + JSON.stringify(lcc_token) + "',pin='" + userpin + "',status='Enabled',menuidlist='" + menuname + "',domain='" + domain.label + "',role=" + role.value + ",domain_id=" + domain.value + "  where uid=" + this.state.savetype + " returning 1 as result"
                console.log(updatesql)

                const updateditem = {
                    "stmt": updatesql

                }


                try {
                    const newuserdataupdate = await httpRequest({
                        endPoint: `api/db/stmt`,
                        method: "post",
                        instance: "instanceOne",
                        requestBody: updateditem,

                    });

                    if (newuserdataupdate.success == true) {

                        this.setState({ showerr: true, errorMsg: "User is Updated ", mysnack: snackeror.mysucc })

                        this.setState({ userpage: '0' })
                        this.GetUser()



                    }

                } catch (error) {

                }

            }
        }

    }
    GetDomain = async () => {
        let sql = ""
        sql = "select role_id,description from  pad_roles order by description"
        console.log(sql)
        const roleitem = {
            "stmt": sql

        }
        try {
            const roledata = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: roleitem,

            });



            if (roledata.success == true) {

                this.setState({ roledata: roledata.msg })
            }
        } catch (error) {

        }



        sql = "select uid,name from  pad_domains order by name"
        console.log(sql)
        const domainitem = {
            "stmt": sql

        }
        try {
            const domaindata = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: domainitem,

            });


            console.log('domaindata.msg ', domaindata.msg)

            if (domaindata.success == true) {

                this.setState({ domaindata: domaindata.msg })
            }
        } catch (error) {

        }

        sql = "select id,menu_name from  pad_modules order by id"
        console.log(sql)
        const domainitem1 = {
            "stmt": sql

        }
        try {
            const domaindata1 = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: domainitem1,

            });


            console.log('additionalrole.msg ', domaindata1.msg)

            if (domaindata1.success == true) {

                this.setState({ additionalrole: domaindata1.msg })
            }
        } catch (error) {

        }



    }
    addUser = async () => {
        this.setState({ userpage: '1' })


    }


    GetUser = async () => {
        let sql = "select uid,aid,name,pin,domain,role,menuidlist,contact,pad_roles.description,domain_id  from  pad_agent JOIN pad_roles ON pad_roles.role_id  = pad_agent.role  where  pad_agent.name<>'superuser' and pad_agent.history_id is null order by uid"
        console.log(sql)



        const item = {
            "stmt": sql

        }







        try {
            const userdata = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: item,

            });


            console.log('User Data', userdata)
            if (userdata.success == true) {

                this.setState({ userdata: userdata.msg })
            }
        } catch (error) {

        }
    }

    componentDidMount = async () => {
        this.GetUser()
        this.GetDomain()

    }
    removeA(arr) {
        var what, a = arguments, L = a.length, ax;
        while (L > 1 && arr.length) {
            what = a[--L];
            while ((ax = arr.indexOf(what)) !== -1) {
                arr.splice(ax, 1);
            }
        }
        return arr;
    }
    EditUser = async (row) => {
        console.log(row)

        let sql = ""
        sql = "select modules from pad_roles where role_id=" + row.role

        const item = {
            "stmt": sql

        }


        try {
            const userdata = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: item,

            });


            if (userdata.success === true) {
                let finalmenu = userdata.msg[0].modules + ',' + row.menuidlist
                finalmenu = this.removeA(finalmenu)


                sql = "select * from pad_modules where id in(" + finalmenu + ")"
                console.log(sql)
                const item = {
                    "stmt": sql

                }
                try {
                    const menulist = await httpRequest({
                        endPoint: `api/db/stmt`,
                        method: "post",
                        instance: "instanceOne",
                        requestBody: item,

                    });
                    if (menulist.success === true) {
                        console.log(' menulist from db', menulist.msg)

                        let menuname1 = []
                        menulist.msg.map(rname =>

                            menuname1.push({
                                label: rname.menu_name,
                                value: rname.id,
                                key: rname.id
                            })


                        )
                        console.log('menuname1******', menuname1)
                        this.setState({ menulist: menuname1 })

                    }
                } catch (error) {

                }
            }
        } catch (error) {

        }

        console.log("Row Data==", row)
        this.setState({
            domain: { label: row.domain, value: row.domain_id }
            , role: { label: row.description, value: row.role }, savetype: row.uid, username: row.name, userpage: '1', aid: row.aid, usercontacts: row.contact[0], userpin: row.pin
        })



    }

    handleDomain = (e) => {


        let label = e.target.value;
        this.setState({ domain: label });
    }

    handleSelect(data) {
        console.log(data)
    }
    AddMenuList(e) {
        let menuname1 = []
        this.setState({ menulist: [] })
        e.map(rname =>

            menuname1.push({
                label: rname.label,
                value: rname.value,
                key: rname.key
            })


        )
        console.log('menuname1******', menuname1)
        this.setState({ menulist: menuname1 })
    }
    render() {
        const columns = [

            {
                name: '',
                width: '100px',
                cell: row =>


                    <WhiteBackgroundCheckbox

                        id="chk1"

                    />


            },



            {
                name: 'User / Agent ID',

                sortable: true,
                cell: row =>
                    row.aid
            },
            {
                name: 'Name',

                sortable: true,
                cell: row =>
                    row.name
            },

            {
                name: 'PIN',

                sortable: true,
                cell: row =>
                    row.pin
            },



            {
                name: 'Active Domain',

                sortable: true,
                cell: row =>
                    row.domain
            },
            {
                name: 'Role',

                sortable: true,
                cell: row =>
                    row.description
            },


            {
                name: 'Action',

                sortable: true,
                cell: row =>
                    <IconButton


                        onClick={() => this.EditUser(row)}
                    >
                        <EditOutlined style={{ color: '#0083CA', fontSize: '18px' }} />
                    </IconButton>
            },



        ];

        return (
            <div>


                {this.state.userpage == "1" &&

                    <div style={{ height: '90vh' }}>
                        <Grid style={{ width: '100%', marginTop: '1%', display: 'flex', alignItems: 'center', cursor: 'pointer' }}

                            onClick={() => { this.setState({ userpage: '0' }) }}
                        >

                            <ArrowBackIosOutlined style={{ fontSize: '15px' }}></ArrowBackIosOutlined>
                            <Typography style={{ marginLeft: '1%', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Add User</Typography>
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', height: '80%' }}


                        >
                            <Grid style={{ width: '95%', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', padding: '2%', height: '70%' }}>
                                <Grid style={{ width: '100%', display: 'flex' }}>
                                    <Grid style={{ width: '35%' }}>
                                        <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}></Typography>User / Agent ID
                                        <CssTextField focusColor="#999999"

                                            autoFocus={true}
                                            fullWidth
                                            inputProps={{
                                                style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                            }}
                                            size="small"

                                            variant="outlined"
                                            placeholder="Enter User / Agent ID"
                                            value={this.state.aid}
                                            style={{ marginTop: '3%' }}
                                            onChange={e => {

                                                this.setState({ aid: e.target.value });

                                            }}
                                        />
                                    </Grid>
                                    <Grid style={{ width: '35%', marginLeft: '5%' }}>
                                        <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>PIN</Typography>
                                        <CssTextField focusColor="#999999"


                                            fullWidth
                                            inputProps={{
                                                style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                            }}
                                            size="small"

                                            variant="outlined"
                                            placeholder="Enter PIN"
                                            value={this.state.userpin}
                                            style={{ marginTop: '3%' }}
                                            onChange={e => {

                                                this.setState({ userpin: e.target.value });

                                            }}
                                        />
                                    </Grid>



                                </Grid>
                                <Grid style={{ width: '100%', display: 'flex', marginTop: '2%' }}>
                                    <Grid style={{ width: '35%' }}>
                                        <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Name</Typography>
                                        <CssTextField focusColor="#999999"


                                            fullWidth
                                            inputProps={{
                                                style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                            }}
                                            size="small"

                                            variant="outlined"
                                            placeholder="Party 1 name "
                                            value={this.state.username}
                                            style={{ marginTop: '3%' }}
                                            onChange={e => {

                                                this.setState({ username: e.target.value });

                                            }}
                                        />
                                    </Grid>




                                </Grid>
                                <Grid style={{ width: '100%', display: 'flex', marginTop: '2%' }}>
                                    <Grid style={{ width: '35%' }}>
                                        <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Domain</Typography>
                                        {/*  <Select value={this.state.domain}
                                            displayEmpty
                                            variant="outlined"
                                            fullWidth
                                            size="small"

                                            placeholder='Select'
                                            style={{ height: '41px', }}
                                            inputProps={{
                                                style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                            }}
                                            option
                                            onChange={e => {
                                                //  this.setState({ domainid: e.target.value, domain: e.target.lable });
                                                this.handleDomain(e)

                                            }}

                                        >
                                            <MenuItem value={'0'}>Select</MenuItem>
                                            {this.state.domaindata.map(rname =>

                                                <option style={{ padding: '5px', color: '#333333', fontSize: '18px', fontFamily: 'Roboto-Regular' }} value={rname.name} label={rname.name}>{rname.name}</option>
                                            )};


                                        </Select>*/}
                                        <Select


                                            value={this.state.domain}

                                            options={
                                                this.state.domaindata.map((guest, index) => {
                                                    return {
                                                        label: guest.name,
                                                        value: guest.uid,
                                                        key: index
                                                    }
                                                })
                                            }

                                            onChange={e => {
                                                this.setState({ domain: e });


                                            }}
                                        >


                                        </Select>

                                    </Grid>
                                    <Grid style={{ width: '35%', marginLeft: '5%' }}>
                                        <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}></Typography>Role


                                        <Select

                                            value={this.state.role}
                                            options={
                                                this.state.roledata.map((guest, index) => {
                                                    return {
                                                        label: guest.description,
                                                        value: guest.role_id,
                                                        key: index
                                                    }
                                                })
                                            }

                                            onChange={e => {
                                                this.setState({ role: e });


                                            }}
                                        >
                                        </Select>

                                        {/*   <Select value={this.state.role}
                                            displayEmpty
                                            variant="outlined"
                                            fullWidth
                                            size="small"

                                            placeholder='Select'
                                            style={{ height: '41px', background: '#ffffff' }}

                                            onChange={e => {
                                                this.setState({ role: e.target.value });


                                            }}

                                        >
                                            <MenuItem value={'0'}>Select</MenuItem>
                                            {this.state.roledata.map(rname =>

                                                <MenuItem value={rname.role_id}>{rname.description}</MenuItem>
                                            )};



                                        </Select>*/}

                                    </Grid>



                                </Grid>

                                <Grid style={{ width: '100%', display: 'flex', marginTop: '2%', alignItems: 'center' }}>
                                    <Grid style={{ width: '12%' }}>
                                        <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Additional role</Typography>
                                    </Grid>
                                    <Grid style={{ width: '63%' }}>

                                        <Select

                                            placeholder="Select color"
                                            isMulti
                                            isSearchable={true}
                                            value={this.state.menulist}
                                            options={
                                                this.state.additionalrole.map((guest, index) => {
                                                    return {
                                                        label: guest.menu_name,
                                                        value: guest.id,
                                                        key: index
                                                    }
                                                })
                                            }

                                            onChange={e => {
                                                //   this.setState({ menulist: e.value });

                                                this.AddMenuList(e)


                                            }}
                                        >
                                        </Select>

                                    </Grid>
                                </Grid>

                            </Grid>



                        </Grid>


                        <Grid style={{ width: '95%', marginTop: '1%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}


                        >


                            <Button



                                variant="contained"
                                size="small"

                                style={{
                                    color: "#333333",
                                    fontFamily: "Roboto-Medium",
                                    borderRadius: "4px",
                                    backgroundColor: "#FBB72E",
                                    textTransform: 'none',
                                    padding: '1.5%',
                                    fontSize: "14px",
                                    width: '100px',
                                    height: '32px',
                                    marginLeft: '5%'

                                }}

                                onClick={() => { this.SaveNewUser() }}


                            >

                                Save
                            </Button>

                        </Grid>
                    </div>
                }

                {
                    this.state.userpage == "0" &&
                    <div>
                        <Grid style={{ width: '100%', marginTop: '1%', display: 'flex', marginLeft: '3%' }}>
                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>User</Typography>
                        </Grid>
                        <Grid style={{ width: '100%', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', padding: '.5%' }}>
                            <Grid style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '10px', }}>
                                <Button



                                    variant="contained"
                                    size="small"

                                    style={{
                                        color: "#333333",
                                        fontFamily: "Roboto-Medium",
                                        borderRadius: "4px",
                                        backgroundColor: "#E7EAF0",
                                        border: '1px solid #E7EAF0',
                                        borderRadius: '4px',
                                        textTransform: 'none',
                                        padding: '1.5%',
                                        fontSize: "14px",
                                        width: '100px',
                                        height: '25px',


                                    }}

                                    onClick={() => { /* */ }}


                                >

                                    Download
                                </Button>
                                <Button



                                    variant="contained"
                                    size="small"

                                    style={{
                                        color: "#7DAB1C",
                                        fontFamily: "Roboto-Medium",
                                        borderRadius: "4px",
                                        backgroundColor: "#ffffff",
                                        border: '1px solid #7DAB1C',
                                        borderRadius: '4px',
                                        textTransform: 'none',
                                        padding: '1.5%',
                                        fontSize: "14px",
                                        width: '150px',
                                        height: '25px',
                                        marginLeft: '2%'

                                    }}

                                    onClick={() => { this.addUser() }}


                                >

                                    Add User
                                </Button>

                            </Grid>

                            <Grid style={{ height: 'auto', overflow: 'auto', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', }}>


                                <DataTable

                                    columns={columns}
                                    data={this.state.userdata}
                                    pagination
                                    striped
                                    responsive
                                    paginationPerPage='30'

                                    dense

                                    customStyles={customStyles}
                                    highlightOnHover


                                    conditionalRowStyles={conditionalRowStyles}
                                />


                            </Grid>




                        </Grid>
                    </div>
                }
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "Roboto-Bold"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>

            </div >
        )
    }
}
export default (withStyles(txtstyles)((newuser)));