import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import login from '../ui-pages/login/index'
import dashbord from '../ui-pages/agent/dashbord/index'


import { withOneTabEnforcer } from "react-one-tab-enforcer"

function MainLandingPage() {

  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/" component={login} />
          <Route exact path="/dashbord" component={dashbord} />




          <Route component={login} />
        </Switch>
      </Router>
    </div>

  )
}
//export default withOneTabEnforcer()(MainLandingPage)
export default (MainLandingPage)