import axios from "axios";

import backendHosts from './apiConfig'


var response;




let axiosInstances = {

    instanceOne: axios.create({

        baseURL: backendHosts.CRM,

    }),




};



//"Authorization": "Bearer " + gettok()
export const httpRequest_login = async ({
    method = "post",
    endPoint,
    timeout = {},
    params = {},
    requestBody = {},
    instance = "instanceOne",
    token = {},
    headers = {
        "Accept": "*/*",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "accept": "application/json",

    }

}) => {


    var headerConfig = {
        headers
    };

    //21500

    //params = JSON.stringify(params)


    requestBody = JSON.stringify(requestBody)







    response = "ERROR"
    try {
        switch (method) {
            case "post":
                response = await axiosInstances[instance].post(
                    endPoint,
                    requestBody,
                    headerConfig
                );
                break;
            default:



                response = await axiosInstances[instance].get(
                    endPoint,
                    params,

                    headerConfig,


                );
        }




        let dedata = response.data;




        return dedata;
    } catch (reason) {


        console.log("Error", reason.response.data)

        return reason.response.data;
    }
    return response
}
