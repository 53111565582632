import React, { Component } from 'react'
import { httpRequest } from '../../../../ui-utils/api'
import DataTable from 'react-data-table-component';
import {
    Grid, Button, Checkbox, Modal, TextField, Box, Snackbar, FormControlLabel, SnackbarContent, IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles, styled } from "@material-ui/core/styles";
import Loader from "react-js-loader";
import moment from 'moment'

const CssTextField = styled(TextField)({
    borderRadius: '23px',
    fontFamily: 'InterRegular',
    background: '#ffff',

    '& .MuiInput-underline:after': {
        borderBottomColor: '#dddddd',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#dddddd',
        },
        '&:hover fieldset': {
            borderColor: '#dddddd',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#dddddd',
        },
    },
});
const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#41AB1C",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 8,
            width: 8,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#41AB1C'
        }
    },
    checked: { color: "#41AB1C", }
}))(Checkbox);
const customStyles = {
    title: {
        style: {
            fontSize: '14px', // override the row height
        },
    },
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
        stripedStyle: {

            //  background: 'red'



        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#E9F4FF',
            fontSize: '14px',
            color: '#333333',
            height: '45px',
            fontFamily: 'Roboto-Medium'
        },
    },
    cells: {
        style: {
            paddingLeft: '2px', // override the cell padding for data cells
            paddingRight: '2px',


        },
    },
    highlightOnHoverStyle: {
        style: {
            color: 'red',
            background: 'blue',
            backgroundColor: 'yellow'



        },
    },

};
const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    }
};
export default class ivrcancel extends Component {
    constructor(props) {
        super(props)


        this.state = {

            ivrshudellist: [],
            resume: false,
            mysnack: snackeror.mysucc,
            showerr: false,
            errorMsg: '',
            islodding: false,
            filterdata: [],
            comp: true,
            inpro: true
        }



    }
    handelfilter(fdata) {

        let maindata = []
        if (this.state.comp === true && this.state.inpro === true) {
            const re = fdata.map(data => {

                let status1 = data.status


                maindata.push(
                    {

                        start_time: data.start_time,
                        uid: data.uid,
                        summary: data.summary,
                        description: data.description,
                        call_count: data.call_count,
                        processed: data.processed,
                        answered: data.answered,
                        first_attempt: data.first_attempt,
                        retries: data.retries,
                        under_process: data.under_process,
                        complete_count: data.complete_count,

                        status: status1
                    })

            })

        }

        if (this.state.comp === true && this.state.inpro === false) {

            const re = fdata.map(data => {

                let status1 = data.status

                if (data.status !== "Processing") {

                    maindata.push(
                        {

                            start_time: data.start_time,
                            uid: data.uid,
                            summary: data.summary,
                            description: data.description,
                            call_count: data.call_count,
                            processed: data.processed,
                            answered: data.answered,
                            first_attempt: data.first_attempt,
                            retries: data.retries,
                            under_process: data.under_process,
                            complete_count: data.complete_count,

                            status: status1
                        })

                }
            })
        }
        if (this.state.comp === false && this.state.inpro === true) {
            const re = fdata.map(data => {

                let status1 = data.status

                if (data.status === "Processing") {

                    maindata.push(
                        {

                            start_time: data.start_time,
                            uid: data.uid,
                            summary: data.summary,
                            description: data.description,
                            call_count: data.call_count,
                            processed: data.processed,
                            answered: data.answered,
                            first_attempt: data.first_attempt,
                            retries: data.retries,
                            under_process: data.under_process,
                            complete_count: data.complete_count,

                            status: status1
                        })

                }
            })
        }

        this.setState({ ivrshudellist: maindata })


    }
    GetShudelList = async () => {
        this.setState({ islodding: true })

        let fdate = moment(new Date()).subtract(90, 'days').format("YYYY-MM-DD")
        let tdate = moment(new Date()).add(30, 'days').format("YYYY-MM-DD")


        try {
            const roledata1 = await httpRequest({
                endPoint: "/api/lcc/OBDScheduledList/" + fdate + "/" + tdate,
                method: "get",
                instance: "instanceOne",


            });


            this.setState({ islodding: false })
            console.log('ivrshudellist', roledata1.data)
            let maindata = []
            if (roledata1.success == true) {


                const re = roledata1.data.map(data => {

                    let status1 = data.status

                    if (data.status === "under process") {
                        status1 = "In Progress"
                    }
                    maindata.push(
                        {

                            start_time: data.start_time,
                            uid: data.uid,
                            summary: data.summary,
                            description: data.description,
                            call_count: data.call_count,
                            processed: data.processed,
                            answered: data.answered,
                            first_attempt: data.first_attempt,
                            retries: data.retries,
                            under_process: data.under_process,
                            complete_count: data.complete_count,

                            status: status1
                        })

                })


                this.setState({ ivrshudellist: maindata, filterdata: maindata })


            }
        } catch (error) {

        }
    }
    componentDidMount = async () => {

        this.GetShudelList()


    }
    closeContactModal() {
        this.setState({ showerr: false })
    }
    StartIVR = async () => {

        try {
            const roledata1 = await httpRequest({
                endPoint: `/api/fs/scheduleDeamon/start`,

                method: "get",
                instance: "instanceOne",


            });





            if (roledata1.success == true) {

                if (roledata1.hasOwnProperty("msg")) {

                    this.setState({ showerr: true, errorMsg: roledata1.msg, mysnack: snackeror.myerror })

                }
                if (roledata1.hasOwnProperty("status")) {

                    this.setState({ showerr: true, errorMsg: roledata1.status, mysnack: snackeror.myerror })

                }


            }
        } catch (error) {

        }


    }

    chk11(e) {


        this.state.comp = !this.state.comp
        this.handelfilter(this.state.filterdata)
    }

    chk2(e) {

        this.state.inpro = !this.state.inpro
        this.handelfilter(this.state.filterdata)
    }
    StopIVR = async () => {

        try {
            const roledata1 = await httpRequest({
                endPoint: `/api/fs/scheduleDeamon/stop`,

                method: "get",
                instance: "instanceOne",


            });





            if (roledata1.success == true) {


                if (roledata1.hasOwnProperty("msg")) {



                    this.setState({ showerr: true, errorMsg: roledata1.msg, mysnack: snackeror.myerror })
                }

                if (roledata1.hasOwnProperty("status")) {



                    this.setState({ showerr: true, errorMsg: roledata1.status, mysnack: snackeror.myerror })
                }


            }
        } catch (error) {

        }


    }
    render() {
        const columns = [

            {
                name: '',
                width: '50px',
                cell: row =>


                    <WhiteBackgroundCheckbox

                        id="chk1"

                    />


            },
            {
                name: 'Start Time',

                sortable: true,
                cell: row =>
                    row.start_time
            },

            {
                name: 'UID',

                sortable: true,
                cell: row =>
                    row.uid
            },
            {
                name: 'Summary',

                sortable: true,
                cell: row =>
                    row.summary
            },

            {
                name: 'Description',

                sortable: true,
                cell: row =>
                    row.description
            },
            {
                name: 'Scheduled Calls',

                sortable: true,
                cell: row =>
                    row.call_count
            },
            {
                name: 'Dialled',

                sortable: true,
                cell: row =>
                    row.first_attempt
            },

            {
                name: 'Answered',

                sortable: true,
                cell: row =>
                    row.answered
            },

            {
                name: 'Retries',

                sortable: true,
                cell: row =>
                    row.retries
            },



            {
                name: 'Status',

                sortable: true,
                cell: row =>
                    row.status
            },





        ];

        {/*  {
                name: 'Resume/Pause',

                sortable: true,
                cell: row =>




                    <FormGroup>
                        <FormControlLabel style={{ color: '#999999', fontSize: '10px', fontFamily: 'Roboto-Medium' }}
                            control={<MySwitch
                                checked={this.state.resume}
                                onClick={() => this.setState({ resume: !this.state.resume })}
                            />} />

                    </FormGroup>

            },

            {
                name: 'Action',
                width: '75px',
                sortable: true,
                cell: row =>
                    <Button



                        variant="contained"
                        size="small"

                        style={{
                            color: "#ffffff",
                            fontFamily: "Roboto-Medium",
                            borderRadius: "4px",
                            backgroundColor: "#7DAB1C",
                            textTransform: 'none',
                            padding: '1.5%',
                            fontSize: "14px",
                            width: '80px',
                            height: '20px',
                            marginRight: '5%'

                        }}

                        onClick={(e) => { this.SaveGroup(e) }}


                    >

                        Cancel
                    </Button>
            },*/}
        return (
            <div>
                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '1%', marginTop: '2%', }}>




                    <Grid style={{ width: '95%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Button



                            variant="contained"
                            size="small"

                            style={{
                                color: "#ffffff",
                                fontFamily: "Roboto-Medium",
                                borderRadius: "4px",
                                backgroundColor: "#7DAB1C",
                                textTransform: 'none',
                                padding: '1.5%',
                                fontSize: "14px",
                                width: '125px',
                                height: '32px',
                                marginLeft: '5%'

                            }}

                            //    onClick={() => { this.GetDistWise() }}
                            onClick={() => { this.StartIVR() }}



                        >

                            Start
                        </Button>
                    </Grid>
                    <Grid style={{ width: '15%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Button



                            variant="contained"
                            size="small"

                            style={{
                                color: "#ffffff",
                                fontFamily: "Roboto-Medium",
                                borderRadius: "4px",
                                backgroundColor: "#7DAB1C",
                                textTransform: 'none',
                                padding: '1.5%',
                                fontSize: "14px",
                                width: '125px',
                                height: '32px',
                                marginLeft: '5%'

                            }}

                            onClick={() => {
                                this.StopIVR()
                            }}


                        >

                            Stop
                        </Button>
                    </Grid>

                </Grid>
                <Grid style={{ height: 'auto', overflow: 'auto', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', }}>


                    <DataTable

                        columns={columns}
                        data={this.state.ivrshudellist}
                        pagination
                        striped
                        responsive
                        paginationPerPage='30'

                        dense

                        customStyles={customStyles}
                        highlightOnHover
                        subHeader
                        subHeaderAlign='left'
                        subHeaderComponent={
                            <div style={{ display: 'flex' }}>
                                <CssTextField
                                    autoFocus={true}
                                    value={this.state.stext}
                                    placeholder="Search"
                                    size="small"
                                    variant="outlined"
                                    onChange={e =>
                                        this.MakeFilter(e)
                                    }
                                />



                                <FormControlLabel style={{ marginLeft: '10px' }}
                                    control={<WhiteBackgroundCheckbox

                                        checked={this.state.comp}



                                        onChange={(e) =>
                                            this.chk11(e)
                                        }





                                    />} label="Complete"

                                />
                                <FormControlLabel control={<WhiteBackgroundCheckbox
                                    checked={this.state.inpro}
                                    onChange={(e) =>
                                        this.chk2(e)
                                    } />} label="Processing" />


                            </div>
                        }


                    />



                </Grid>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "Roboto-Bold"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
                <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',
                            display: 'flex',
                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >
                        <Loader type="spinner-circle" bgColor={"#7DAB1C"} color={'#ffc31b'} size={75} />
                    </Box>

                </Modal>
            </div >
        )
    }
}
