
import {
    Modal, Grid, Typography, Button,
    TextField, IconButton, Checkbox, Box,
    MenuItem, Select,
    FormControlLabel, RadioGroup, Radio, Snackbar, SnackbarContent
} from "@material-ui/core"
import Autocomplete from '@mui/material/Autocomplete';
import ReactStopwatch from 'react-stopwatch';
import React, { Component } from 'react'
import { withStyles, styled } from "@material-ui/core/styles";
import { httpRequest } from '../../../ui-utils/api'
import Element from '../components/Element';
import formJSON from '../formElement.json';
import { FormContext } from "../FormContext";
import ReactTimerStopwatch from 'react-stopwatch-timer';
import Select1 from 'react-select'

import PropTypes from "prop-types";
//incommingphone.svg
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import sql_funcation from '../../../ui-utils/sql_funcation';
import MissCall from '../../admin/dashbord/missing'
import { ReactComponent as MySvg } from '../../../ui-assests/images/incommingphone.svg';
import { ReactComponent as Mymusic } from '../../../ui-assests/images/music.svg';
import moment from 'moment'

import CloseIcon from '@material-ui/icons/Close'

var oSipSessionCall;
let hangip_res = ""
window.sipcalltermanited = 0
const data = [
    { title: 'One', value: 5, color: '#C6D592' },
    { title: 'Two', value: 34, color: '#C6D592' },

]
const data1 = [
    { title: 'One', value: 5, color: '#E6A09B' },
    { title: 'Two', value: 34, color: '#C6D592' },
    { title: 'Two', value: 12, color: '#D69C47' },

]
const fromTime = new Date(0, 0, 0, 0, 0, 0, 0);

const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))
let agentid, password, agentname, liginresp, user_domain, user_domain_id

let janus_server = ""
let janus_proxy_url = ""
const txtstyles = theme => ({
    mySvgStyle: {
        fillColor: 'red'
    },

    callbutt: {
        height: '34px', width: '144px', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Bold', textTransform: "none",

    },
    callbutt1: {
        height: '34px', width: '144px', color: '#043068', fontSize: '12px', fontFamily: 'Roboto-Bold', textTransform: "none",
        backgroundColor: '#ffffff', borderRadius: '4px',

        boxShadow: '0px 1px 4px 1px rgba(0, 0, 0, 0.4)'
    }


})



const Radioheckbox = withStyles(theme => ({
    root: {
        color: "#333333",
        background: "#ffffff",
        "@media(max-width:960px)": {
            background: "#080808",
        },

        '&$checked': {
            color: '#043068'
        }
    },
    checked: { color: "#043068", }
}))(Radio);

const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#41AB1C",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 10,
            width: 10,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#41AB1C'
        }
    },
    checked: { color: "#41AB1C", }
}))(Checkbox);
const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    }
};
const CssTextField = styled(TextField)({
    borderRadius: '12px',


    '& label.Mui-focused': {
        color: '#999999',

    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#999999',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#999999',
        },
        '&:hover fieldset': {
            borderColor: '#999999',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#999999',
        },
    },
});

const StyledButton = withStyles({
    root: {

        height: '48px', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Bold', textTransform: "none",
        float: "right",
        position: "relative",
        transform: "translateY(-50%)",
        '&:hover': {
            height: '48px', color: '#ffffff', fontSize: '12px', fontFamily: 'Roboto-Bold', textTransform: "none",
            backgroundColor: '#7DAB1C',

            boxShadow: '0px 1px 4px 1px rgba(0, 0, 0, 0.4)'

        },

        '&:disabled': {
            border: '1px solid #999999',
            color: '#999999',
        }
    }
})(Button);

let elements = ''
let makeref = 0

const handleJsonChange1 = (id, event,type=0) => {
    const newElements = { ...elements }


    console.log('newElements', elements)
    newElements.fields.forEach(field => {

        const { field_type, field_id,field_id1 } = field;
        if (id === field_id) {
            console.log('field_id1******',field_type)
            switch (field_type) {
                case 'checkbox':
                    field['field_value'] = event;
                    break;
                case 'text':
                    field['field_value'] = event.target.value;
                    break;
                case 'cascading':
                    if(type===0)
                    {field['field_value'] =  event.target.value;}
                    if(type===1)
                    {field['field_value1'] =  event.target.value;}
                    
                    break;
                default:
                    field['field_value'] = event;
                    break;
            }


        }
        //  console.log('newElements==', newElements)

        elements = newElements
    });

}
const handleJsonChange2 = (id, event,opt,opt1) => {
    const newElements = { ...elements }


    console.log('newElements', elements)
    newElements.fields.forEach(field => {

        const { field_type, field_id } = field;
        if (id === field_id) {
            switch (field_type) {
                case 'checkbox':
                    field['field_value'] = event;
                    break;
                case 'text':
                    field['field_value'] = event.target.value;
                    break;
                    case 'cascading':
                        field['field_value'] =  event.target.value;
                        break;
                    

                default:
                    field['field_value'] = event;
                    break;
            }


        }
        //  console.log('newElements==', newElements)

        elements = newElements
    });

}
let uuid = ''
let menuno = 0
const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random()
        * (max - min + 1)) + min;
};

class dashbord extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props, context) {
        super(props, context)

        menuno = props.b_button_type

        this.state = {

            showerr: false,
            errorMsg: '',
            mysnack: snackeror.mysucc,
            fdate: '',
            callnumber: '--',

            startaction: '0',
            callerform: false,
            outform: false,
            campform: false,
            butname: "Answer",
            butst: true,
            trafbutst: true,
            callstlabel: "Last call",
            mysip: '',
            styleno: '0',
            headstyle: '0',
            iconcolour1: '#999999',
            callbutt_st: false,
            callbutt_st1: false,
            echotest: false,
            echotestpage: 0,
            errorMsg: '',
            myval: '0',
            uuid: '-',
            transfervalue: '0',
            transferpagetitel: '0',
            transferpagetotel: '',
            ivrname: '',
            dominname: '',
            expertname: '',
            expertoption: '',
            profiledata: [0],
            hangupbutst: true,
            callstatus: '0',
            showdropdown: '0',
            comment: '',
            user_domain1: '',
            outboundnumber: '',
            outboundbutname: "Call",
            outboundbutst: true,
            c_seconds: '60',
            c_minutes: '60',
            starttime: false,
            showsiperror: false,
            showedit: false,
            farmer_name: '',
            farmer_district: '',
            farmer_subdistrict: '',
            farmer_panchayat: '',
            farmer_village: '',
            outbount_display: '0',
            submitbutstatus: true,
            shownofity: false,
            testbutname1: 'Test',
            testbutname2: 'Test',
            testbutst1: false,
            testbutst2: false,
            newmsg: 'Form is loading....',
            info: false,
            campbutton: "Start Campaign",
            campbuttontype: 0,
            showcamp: false,
            camplist: [],
            campid: 0,
            distlist: [],
            subdistlist: [],
            callbuttonst: false,
            savebutvis: true,
            showans: true,
            callformsubmit_type: 0,
            makeformvalidation: 0,
            heartbeatid: 0,
            page_label: '',
            editprofile: 0,
            advisory_specific_info:'',
            advisory_primary:''

        }
        agentname = props.data.logindata.user_data.user_name
        liginresp = props.data.logindata
        user_domain = props.data.logindata.user_data.user_domain
        this.state.user_domain1 = props.data.logindata.user_data.user_domain
        agentid = props.data.logindata.user_data.user_id
        user_domain_id = props.data.logindata.user_data.user_domain_id

        this.setState({ user_domain1: user_domain })

        console.log('props', props)

    }


    HeartBeat = async () => {

        try {

            const heartdata = await httpRequest({
                endPoint: `api/users/HeartBeat/` + agentid,
                method: "get",
                instance: "instanceOne",


            });
            if (heartdata.success === true) {
                if (heartdata.hasOwnProperty("action")) {
                    if (heartdata.action === "sipRegister") {
                        window.sipRegister()
                    }

                }
            }

            console.log('heartdata***', heartdata)
        } catch (error) {

        }

    }
    GetSubDist1 = async (distname) => {

        console.log('GetSubDist1', distname)

        this.setState({ farmer_district: distname.value })
        const item = {
            "district_name": distname.value
        }
        try {
            const roledata1 = await httpRequest({
                endPoint: `/api/lcc/getSubDistrict`,
                method: "post",
                instance: "instanceOne",

                requestBody: item

            });



            console.log(roledata1.msg)

            if (roledata1.success == true) {


                this.setState({ subdistlist: roledata1.msg })


            }
        } catch (error) {

        }


    }

    GetSubDist = async (event, value) => {



        this.setState({ farmer_district: value })
        const item = {
            "district_name": value
        }
        try {
            const roledata1 = await httpRequest({
                endPoint: `/api/lcc/getSubDistrict`,
                method: "post",
                instance: "instanceOne",

                requestBody: item

            });



            console.log(roledata1.msg)

            if (roledata1.success == true) {


                this.setState({ subdistlist: roledata1.msg })


            }
        } catch (error) {

        }


    }

    echotest = async () => {

        //console.log(' color ==', document.getElementById('miccolour').style.color)

        if (this.state.testbutst1 == false) {

            this.setState({ testbutst1: true, testbutname1: "HangUp", echotestpage: 1 })

            window.janus_sip.call('9196', false)


            const item = {
                "aid": agentid,
                "activity": "ECHOTEST",
                "uuid": "-",
                "msg": 'ECHOTEST',
                "remark": 'ECHOTEST'

            }
            try {

                const submitdata = await httpRequest({
                    endPoint: `api/user/activitylog`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: item,

                });


            } catch (error) {

            }

        } else {

            window.janus_sip.hangup()
            window.oSipSessionCall = null
            const echotest = {

                echotest: '1'

            }

            localStorage.removeItem('echotest')
            localStorage.setItem('echotest', JSON.stringify(echotest))


            this.setState({ testbutst1: false, testbutname1: "Test", echotest: false })

            const item = {
                "aid": agentid,
                "activity": "ECHOTEST_HANGUP",
                "uuid": "-",
                "msg": 'ECHOTEST_HANGUP',
                "remark": 'ECHOTEST_HANGUP'

            }
            try {

                const submitdata = await httpRequest({
                    endPoint: `api/user/activitylog`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: item,

                });


            } catch (error) {

            }

        }
    }
    delayechotest = async () => {
        if (this.state.testbutst2 == false) {
            this.setState({ testbutst2: true, testbutname2: "HangUp" })
            const item = {
                "aid": agentid,
                "activity": "DELAYECHOTEST",
                "uuid": "-",
                "msg": 'DELAYECHOTEST',
                "remark": 'DELAYECHOTEST'

            }
            try {

                const submitdata = await httpRequest({
                    endPoint: `api/user/activitylog`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: item,

                });


            } catch (error) {

            }
            window.janus_sip.call("9195", false)
        } else {
            // window.sipHangUp()
            window.janus_sip.hangup()
            window.oSipSessionCall = null
            this.setState({ testbutst2: false, testbutname2: "Test" })

            const item = {
                "aid": agentid,
                "activity": "DELAYECHOTEST_HANGUP",
                "uuid": "-",
                "msg": 'DELAYECHOTEST_HANGUP',
                "remark": 'DELAYECHOTEST_HANGUP'

            }
            try {

                const submitdata = await httpRequest({
                    endPoint: `api/user/activitylog`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: item,

                });


            } catch (error) {

            }


        }
    }
    testcall() {
        document.getElementById('txtPhoneNumber').value = "incoming_calltest";
        window.sipCall('call-audio');
    }
    JanusSIPRegister() {


        janus_server = liginresp.user_data.fs_sip_websocket_proxy_sec_url.split(':')[0] + ':' + liginresp.user_data.fs_sip_websocket_proxy_sec_url.split(':')[1] + ':8989/janus'
        janus_proxy_url = 'sip:' + liginresp.user_data.fs_sip_outbound_proxy_url.split('://')[1]
        window.janus_sip.initAndLogin({
            server: janus_server,
            proxy: janus_proxy_url,
            name: liginresp.user_data.fs_sip_display_name,
            exten: liginresp.user_data.fs_sip_impi,
            password: liginresp.user_data.fs_sip_cred,
            audioId: "remote-stream-audio",
            localVideoId: 'local-stream-video',
            remoteVideoId: 'remote-stream-video',
            iceServers: [{ urls: "stun:stun.l.google.com:19302" }]
        });
    }


    MySqlUpdate = async (sql) => {

        console.log("New sql", sql)
        const item = {
            "stmt": sql

        }



        try {

            const submitdata = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: item,

            });
            console.log('responce****', submitdata.success)
            if (submitdata.success == true) {

            } else {

            }

        } catch (error) {

        }

    }
    closeContactModal() {
        this.setState({ showerr: false })
    }
    closeContactModal1() {
        this.setState({ shownofity: false })
    }
    componentDidMount = async () => {


        elements = formJSON[0]



        this.setState({ myval: '1' })

        if (window.performance) {
            console.log('performance.navigation.type ', performance.navigation.type)
            if (performance.navigation.type == 1) {


                let a = localStorage.getItem('uuid') || '{}'
                let b = JSON.parse(a)

                const item = {
                    "aid": agentid,
                    "activity": "REFRESH",
                    "uuid": b.uuid,
                    "msg": 'REFRESH',
                    "remark": 'REFRESH'

                }
                try {

                    const submitdata = await httpRequest({
                        endPoint: `api/user/activitylog`,
                        method: "post",
                        instance: "instanceOne",
                        requestBody: item,

                    });


                } catch (error) {

                }
            }
        }

        window.JanusEventforDashBoard = async (result, msg) => {
            console.log('Janus Dash Board event', result)
            var event = result["event"];
            console.log('Janus Dash Board event', event)
            if (event === "message") {
                let msg_data = result["content"]
                console.log('Msg content', msg_data)
                this.MessageHandler(msg_data)

            }
            if (event === "incomingcall") {

                this.setState({ headstyle: '1' })
                //  this.MySqlUpdate("update pad_live set status='NEW_CALL', lastact_time=now() where aid='" + agentid + "' and  date='" + moment(new Date()).format("YYYY-MM-DD") + "'  returning 1 as result")


                //   var sRemoteNumber = msg.result.username.split('@')[0].split(':')[1];
                // console.log("New Call", sRemoteNumber)
                //this.setState({ callnumber: sRemoteNumber })
            }
            if (event === "hangingup") {
                this.setState({ headstyle: '0' })
            }


            if (event === "hangup") {
                this.setState({ headstyle: '0' })
                hangip_res = result["reason"] + "-" + result["reason_header"];
                console.log(hangip_res)
                this.setState({ uuid: uuid, butst: true, shownofity: true, errorMsg: 'HANGUP', mysnack: snackeror.mysucc })
                this.setState({ callstlabel: "Last Call", submitbutstatus: false })

                this.setState({ starttime: false, hangupbutst: true, butname: 'Answer', butst: true, headstyle: '0', styleno: '0' })
                this.setState({ starttime: false, outboundbutname: 'Call', outboundbutst: true, outboundnumber: "", starttime: false })

                if (result["reason_header"] === "NO_ANSWER") {
                    let a = localStorage.getItem('uuid') || '{}'
                    let b = JSON.parse(a)

                    const item = {
                        "aid": agentid,
                        "activity": "NO_ANSWER",
                        "uuid": b.uuid,
                        "msg": 'NO_ANSWER',
                        "remark": 'NO_ANSWER'

                    }
                    try {

                        const submitdata = await httpRequest({
                            endPoint: `api/user/activitylog`,
                            method: "post",
                            instance: "instanceOne",
                            requestBody: item,

                        });


                    } catch (error) {

                    }


                    // this.MySqlUpdate("update pad_live set status='',lastact_time=now()   where aid='" + agentid + "' and  date='" + moment(new Date()).format("YYYY-MM-DD") + "'  returning 1 as result")
                }


            }
        }

        window.myonSipEventStack = (e) => {

            this.handelSipEventStack(e)

        };



        console.log('user_domain', user_domain)
        if (user_domain == "Outbound") {
            this.setState({ outbount_display: '1' })
        } else {
            this.setState({ outbount_display: '0' })
        }

        this.GetDistricts()

        let time = randomNumberInRange(10000, 50000);
        console.log('HeartBeat***', time)
        var hid = setInterval(() => {
            console.log('HeartBeat***', time)
            time = randomNumberInRange(10000, 50000);
            if (this.state.callerform === false) {
                this.HeartBeat()
            }

        }, time);
        this.setState({ heartbeatid: hid })

        this.JanusSIPRegister()
    }


    MessageHandler = async (data) => {


        //let data = '{"2000000001":{"caller_number":"9876543210","status":"PROFILE","call_id":"6a832a22-b745-41f8-afa6-b2a06fa9ed11","caller_profile":{"number":9876543210,"old_number":null,"id_farmer":null,"active":true,"surveyor_name":null,"surveyor_id":null,"profile_source":5,"profile_source_other":"dafe_krushak_od","datecreated":"2022-12-27T08:05:13.471451+00:00","dateupdated":"2022-12-27T08:05:13.471456+00:00","profiling_date":"2019-08-23T18:30:00+00:00","reprofiling_date":"2022-12-12T18:30:00+00:00","farmer_name":"PAKSHI MAJHI","female":0,"dialect":"sambalpuri","awareness_source":null,"smartphone":null,"whatsapp_has":null,"whatsapp_optin":null,"designation":null,"producer_group":null,"time_preference":null,"district":"Kalahandi","subdistrict":null,"panchayat":null,"village":null,"lat":null,"lon":null,"locationid":null,"locationgoogle":null,"advisory_optin_crops":true,"advisory_optin_livestock":false,"advisory_optin_fisheries":false,"advisory_primary":"livestock","kitchen_garden":null,"kitchen_garden_messages":null,"land_size_acre":null,"land_size_bharan":null,"land_size_guntha":null,"land_size_descimal":null,"land_size_ha":3.3700001000000004,"land_type":null,"irrigation_kharif":1,"full_irrigation_kharif":1,"irrigation_rabi":1,"kharif_crops":"paddy","kharif_crops_other":null,"rabi_crops":null,"rabi_crops_other":null,"ls_animal_type":null,"ls_cow_num":null,"ls_buffalo_num":null,"ls_bull_num":null,"ls_bullock_num":null,"ls_horse_num":null,"ls_cow_breed":null,"ls_poultry_type":null,"ls_poultry_num":null,"ls_own_feed":null,"in_rct":false,"id_farmer_external":"APQM63338444","fs_boat_type":null,"fs_bw_area":null,"fs_fishery_type":null,"fs_pond_area":null,"fs_species_bw":null,"fs_species_fresh":null,"date_deactivated":null,"date_reactivated":null,"land_risk":null,"land_unit_preference":null,"seed_type":null,"transplanting_type":null,"date_deactivated_manually":null,"date_reactivated_manually":null,"deactivated_manually":false,"irrigation_status":null}}}'

        let status_data
        console.log('Msg Data**', data)

        // let data1 = data
        //data = JSON.stringify(data)



        data = JSON.parse(data)


        for (var key in data) {
            status_data = data[key]['status'] !== "undefined" ? data[key]['status'] : '-'

            console.log('status_data', status_data)



            if (status_data === 'DIALING') {
                uuid = data[key]['call_id']
                const localuuid = {
                    uuid: data[key]['call_id']

                }

                localStorage.setItem('uuid', JSON.stringify(localuuid))


                this.setState({ submitbutstatus: true, butst: true, shownofity: true, errorMsg: 'DIALING', mysnack: snackeror.mysucc })
                this.setState({ callstlabel: status_data, callnumber: data[key]['caller_number'] })



                const item = {
                    "aid": agentid,
                    "activity": "DIALING",
                    "uuid": uuid,
                    "msg": this.urlencode(JSON.stringify(data)),
                    "remark": 'MessageHandler'

                }
                try {

                    const submitdata = await httpRequest({
                        endPoint: `api/user/activitylog`,
                        method: "post",
                        instance: "instanceOne",
                        requestBody: item,

                    });


                } catch (error) {

                }


            }

            if (status_data == 'RINGING') {
                uuid = data[key]['call_id']
                console.log('RINGING UUID', uuid)

                this.state.uuid = data[key]['call_id']
                this.setState({ callnumber: data[key]['caller_number'] })
                makeref = 1
                this.setState({ shownofity: true, errorMsg: 'RINGING', mysnack: snackeror.mysucc })
                this.setState({ callstlabel: status_data })

                const item = {
                    "aid": agentid,
                    "activity": "RINGING",
                    "uuid": uuid,
                    "msg": this.urlencode(JSON.stringify(data)),
                    "remark": 'MessageHandler'

                }
                try {

                    const submitdata = await httpRequest({
                        endPoint: `api/user/activitylog`,
                        method: "post",
                        instance: "instanceOne",
                        requestBody: item,

                    });


                } catch (error) {

                }
            }

            if (status_data == 'HANGUP') {

                uuid = data[key]['call_id']

                if (uuid.length < 5) { uuid = "N/A" }
                this.setState({ uuid: uuid, butst: true, shownofity: true, errorMsg: 'HANGUP (' + hangip_res + ')', mysnack: snackeror.mysucc })
                this.setState({ callstlabel: "Last Call", submitbutstatus: false })
                // window.sipHangUp();
                window.janus_sip.hangup();
                window.oSipSessionCall = null
                this.setState({ starttime: false, hangupbutst: true, butname: 'Answer', butst: true, headstyle: '0', styleno: '0' })
                this.setState({ starttime: false, outboundbutname: 'Call', outboundbutst: true, outboundnumber: "", starttime: false })


                const item = {
                    "aid": agentid,
                    "activity": 'HANGUP',
                    "uuid": uuid,
                    "msg": this.urlencode(JSON.stringify(data)),
                    "remark": hangip_res,

                }
                try {

                    const submitdata = await httpRequest({
                        endPoint: `api/user/activitylog`,
                        method: "post",
                        instance: "instanceOne",
                        requestBody: item,

                    });


                } catch (error) {

                }
                if (makeref === 1) {
                    this.setState({ newmsg: 'Call could not established' })

                    await sleep(30000)
                    //  this.MySqlUpdate("update pad_live set status='',lastact_time=now()   where aid='" + agentid + "' and  date='" + moment(new Date()).format("YYYY-MM-DD") + "'  returning 1 as result")

                    //  window.location.reload(false);
                }

            }
            if (status_data === 'LOGOUT') {
                this.setState({ shownofity: true, errorMsg: 'LOGOUT', mysnack: snackeror.mysucc })


            }

            if (status_data === 'ACTIVE') {
                uuid = data[key]['call_id']

                console.log('UUID ACTIVE', uuid)
                console.log('UUID ACTIVE=', data[key]['call_id'])
                if (user_domain === "Outbound") {
                    uuid = data[key]['call_id']
                    this.setState({ uuid: data[key]['call_id'] })
                    this.setState({ callnumber: data[key]['caller_number'] })
                    makeref = 0
                    this.setState({ newmsg: '', shownofity: true, errorMsg: 'ACTIVE', mysnack: snackeror.mysucc })
                    this.setState({ callstlabel: status_data })



                    let form_data = status_data = data[key]['form_fields']

                    console.log('form_fields', form_data)
                    if (form_data.length > 10) {
                        this.setState({ makeformvalidation: 1 })
                    }

                    if (form_data !== 'NA') {
                        elements = form_data[0]
                        let page_label = elements.page_label;

                        this.setState({ page_label: page_label })
                        handleJsonChange1()
                    }


                    this.setState({ starttime: true })

                    const item = {
                        "aid": agentid,
                        "activity": "ACTIVE",
                        "uuid": uuid,
                        "msg": this.urlencode(JSON.stringify(data)),
                        "remark": 'MessageHandler'

                    }
                    try {

                        const submitdata = await httpRequest({
                            endPoint: `api/user/activitylog`,
                            method: "post",
                            instance: "instanceOne",
                            requestBody: item,

                        });


                    } catch (error) {

                    }
                }
                if (user_domain !== "Outbound") {

                    uuid = data[key]['call_id']

                    this.setState({ uuid: data[key]['call_id'] })
                    this.setState({ callnumber: data[key]['caller_number'] })
                    makeref = 0
                    this.setState({ newmsg: '', shownofity: true, errorMsg: 'ACTIVE', mysnack: snackeror.mysucc })
                    this.setState({ callstlabel: status_data })



                    let form_data = status_data = data[key]['form_fields']
                    console.log('form_fields', form_data)
                    if (form_data.length > 10) {
                        this.setState({ makeformvalidation: 1 })
                    }


                    elements = form_data[0]
                    handleJsonChange1()

                    this.setState({ hangupbutst: true, starttime: true })

                    const item = {
                        "aid": agentid,
                        "activity": "ACTIVE",
                        "uuid": uuid,
                        "msg": this.urlencode(JSON.stringify(data)),
                        "remark": 'MessageHandler'

                    }
                    try {

                        const submitdata = await httpRequest({
                            endPoint: `api/user/activitylog`,
                            method: "post",
                            instance: "instanceOne",
                            requestBody: item,

                        });


                    } catch (error) {

                    }

                }
            }

            if (status_data === 'PROFILE') {


                uuid = data[key]['call_id']
                let form_data = status_data = data[key]['caller_profile']

                if (form_data === "NA") {
                    this.GetDistricts()
                    this.setState({ editprofile: 1 })
                }
                this.state.profiledata = form_data

                this.setState({ farmer_name: form_data.farmer_name, farmer_district: form_data.district })

                this.setState({ farmer_subdistrict: form_data.subdistrict, farmer_panchayat: form_data.panchayat })
                this.setState({ farmer_village: form_data.village,advisory_primary:form_data.advisory_primary,advisory_specific_info:form_data.advisory_specific_info })
                this.setState({ shownofity: true, errorMsg: 'PROFILE', mysnack: snackeror.mysucc })
                // this.setState({ hangupbutst: false })


                const item = {
                    "aid": agentid,
                    "activity": "PROFILE",
                    "uuid": uuid,
                    "msg": this.urlencode(JSON.stringify(data)),
                    "remark": 'MessageHandler'

                }
                try {

                    const submitdata = await httpRequest({
                        endPoint: `api/user/activitylog`,
                        method: "post",
                        instance: "instanceOne",
                        requestBody: item,

                    });


                } catch (error) {

                }




            }


        }



    }
    handleJsonChange = (id, event) => {
        const newElements = { ...elements }
        newElements.fields.forEach(field => {

            const { field_type, field_id } = field;
            if (id === field_id) {
                switch (field_type) {
                    case 'checkbox':
                        field['field_value'] = event.target.checked;
                        break;

                    default:
                        field['field_value'] = event.target.value;
                        break;
                }


            }

            elements = newElements
            this.setState({ myval: '1' })
        });
    }

    handelExpertoption(e) {


        let wpaymentvalue = e.target.value;
        this.setState({ expertoption: e.target.value })

    }
    handelSipEventSession(e) {


        if (e.type === "connected") {
            //this.setState({ butname: 'Hangup', callerform: true, butst: true });
        }
        if (e.type === "terminated") {
            this.setState({ headstyle: '0', styleno: '0' });
        }
        /*  switch (e.type) {
              case "connected": {
     
                  break;
              }
              case "terminated": {
                  this.setState({ headstyle: '0', styleno: '0' });
                  break;
              }
              default:
                  break;
          }*/

    }

    handelTransferOption(e) {

        /*
         <MenuItem value={'Transfer to IVR'}>Transfer to IVR</MenuItem>
                                            <MenuItem value={'Transfer to Expert'}>Transfer to Expert</MenuItem>
                                            <MenuItem value={'ransfer to other Domain'}>Transfer to other Domain</MenuItem>
    
    */
        this.setState({ transfervalue: e.target.value, trafbutst: true });
        if (e.target.value === 'Transfer to IVR') {
            this.setState({ transferpagevalue: '1', transferpagetitel: e.target.value })
        }
        if (e.target.value === 'Transfer to Expert') {
            this.setState({ transferpagevalue: '2', transferpagetitel: e.target.value })
        }
        if (e.target.value === 'Transfer to other Domain') {
            this.setState({ transferpagevalue: '3', transferpagetitel: e.target.value })
        }
        if (e.target.value === 'Transfer to VOTI') {
            this.setState({ transferpagevalue: '4', transferpagetitel: e.target.value })
        }



    }
    componentWillUnmount() {
        clearInterval(this.state.heartbeatid)
    }


    handelSipEventStack(e) {
        console.log("Stack==**", e.type)
        switch (e.type) {
            case "connected": {

                break;
            }

            case "i_new_call": {



                if (this.state.callerform === true || this.state.headstyle === '1') {
                    //   e.newSession.reject()
                    var sRemoteNumber = e.newSession.getRemoteFriendlyName() || "unknown";
                    console.log("Breacking New Call", sRemoteNumber)
                    break;
                }
                if (this.state.callerform === false) {

                    this.setState({ headstyle: '1' })
                    //  this.MySqlUpdate("update pad_live set status='NEW_CALL', lastact_time=now() where aid='" + agentid + "' and  date='" + moment(new Date()).format("YYYY-MM-DD") + "'  returning 1 as result")

                    window.ringtone.play();
                    oSipSessionCall = e.newSession;
                    console.log('oSipSessionCall**', oSipSessionCall)
                    var sRemoteNumber = oSipSessionCall.getRemoteFriendlyName() || "unknown";
                    console.log("New Call", sRemoteNumber)
                    this.setState({ callnumber: sRemoteNumber })
                    break;
                }
            }

            case "i_new_message":
                {
                    e.newSession.accept(); // e.newSession.reject(); to reject the message
                    console.info('SMS-content = ' + e.getContentString() + ' and SMS-content-type = ' + e.getContentType());

                    if (e.getContentString().indexOf("v=") !== 0) {

                        this.MessageHandler(e.getContentString())

                    }

                    break;
                }
            case "failed_to_start":
                {

                    break;
                }


            default:
                break;
        }
    }
    myJoin = async () => {


        if (this.state.butst) {
            window.janus_sip.answer()
            // window.answercall();

            //window.ringtone.pause();
            if (user_domain != "Outbound") {
                // this.MySqlUpdate("update pad_live set status='FORM_OPEN', lastact_time=now() where aid='" + agentid + "' and  date='" + moment(new Date()).format("YYYY-MM-DD") + "'  returning 1 as result")
                this.setState({ callerform: true, trafbutst: true, hangupbutst: false, butst: false, starttime: true, ivrname: '0', expertoption: '0', dominname: '0' });



            }
            if (user_domain === "Outbound") {
                //   this.MySqlUpdate("update pad_live set status='FORM_OPEN', lastact_time=now() where aid='" + agentid + "' and  date='" + moment(new Date()).format("YYYY-MM-DD") + "'  returning 1 as result")
                this.setState({ hangupbutst: false, campform: true, butst: false, starttime: true, ivrname: '0', expertoption: '0', dominname: '0' });
            }



            const item = {
                "aid": agentid,
                "activity": "Answer Click",
                "uuid": uuid,
                "msg": '-',
                "remark": 'ANSWER'

            }
            try {

                const submitdata = await httpRequest({
                    endPoint: `api/user/activitylog`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: item,

                });


            } catch (error) {

            }

        } else {
            //window.sipHangUp();
            window.janus_sip.hangup()
            window.oSipSessionCall = null
            this.setState({ starttime: false, hangupbutst: true, butname: 'Answer', butst: true, headstyle: '0', styleno: '0', callstlabel: 'Last Call...' })


            const item = {
                "aid": agentid,
                "activity": "HangUp Click",
                "uuid": uuid,
                "msg": '-',
                "remark": 'HANGUP'

            }
            try {

                const submitdata = await httpRequest({
                    endPoint: `api/user/activitylog`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: item,

                });


            } catch (error) {

            }

        }

    }
    myHangUp() {

        this.setState({ starttime: false, hangupbutst: true, butname: 'Answer', butst: true, headstyle: '0', styleno: '0', callstlabel: 'Last Call...' })
        this.setState({ outboundbutname: 'Call', outboundbutst: true, outboundnumber: "", starttime: false, submitbutstatus: false })
        document.getElementById('txtPhoneNumber').value = ""

        // window.sipHangUp()
        window.janus_sip.hangup()
        window.oSipSessionCall = null


    }

    mysipRegister() {

        window.sip_display_name = liginresp.user_data.fs_sip_display_name
        window.sip_realm = liginresp.user_data.fs_sip_realm
        window.sip_impi = liginresp.user_data.user_id
        window.sip_impu = liginresp.user_data.fs_sip_impu
        window.sip_websocket_proxy_url = janus_proxy_url
        window.sip_password = liginresp.user_data.fs_sip_cred
        //        window.sip_outbound_proxy_url = janus_outbound_proxy_url



        window.sipRegister();


    }


    handelCallStatus(e) {

        this.setState({ callstatus: e.target.value })
        this.setState({ showdropdown: '0' })
        let curr_date_time
        if (e.target.value === "Answered") {

            this.setState({ showdropdown: '1' })
        }

    }
    handleMobileNumberChange(e) {
        const re = /^[0-9\b]+$/;
        if (e.target.value.length <= 13) {
            if (e.target.value === '' || re.test(e.target.value)) {

                this.setState({ outboundnumber: e.target.value })

            }
        }

    }
    urlencode(str) {
        str = (str + '').toString();

        // Tilde should be allowed unescaped in future versions of PHP (as reflected below), but if you want to reflect current
        // PHP behavior, you would need to add ".replace(/~/g, '%7E');" to the following.
        return encodeURIComponent(str)
            .replace('!', '%21')
            .replace('\'', '%27')
            .replace('(', '%28')
            .replace(')', '%29')
            .replace('*', '%2A')
            .replace('%20', '+');
    }

    formOutSubmit = async (callst) => {
        const { callstatus, campid, callnumber, callformsubmit_type } = this.state




        let isok = 0
        console.log('callstatus', callstatus)

        if (callstatus === "0") {


            this.setState({ showerr: true, errorMsg: "Select Call Status", mysnack: snackeror.myerror })
            isok = 1

        }

        if (callformsubmit_type === 0) {
            let no = ""
            if (isok === 0) {
                this.setState({ starttime: false })
                let submitdata = {}
                let dd = {}
                var obj = {};
                var obj2 = {};
                var obj1 = {};
                var obj3 = []
                let curr_date_time = ""

                if (this.state.editprofile === 1) {
                    const { farmer_village, farmer_panchayat, farmer_subdistrict, farmer_district, farmer_name, caller_number } = this.state
                    no = this.state.callnumber == null ? 'NA' : this.state.callnumber.toString()
                    no = parseInt(this.state.callnumber)

                    const item = {
                        number: no,
                        name: farmer_name,
                        district: farmer_district,
                        subdistrict: farmer_subdistrict,
                        panchayat: farmer_panchayat,
                        village: farmer_village

                    }
                    try {

                        const submitdata1 = await httpRequest({
                            endPoint: `api/lcc/SetProfile`,
                            method: "post",
                            instance: "instanceOne",
                            requestBody: item,

                        });
                        console.log('responce', submitdata1.msg)

                        if (submitdata1.success === true) {


                            //this.setState({ campbutton: 'Start Campaign', campbuttontype: 0 })
                            //this.setState({ showedit: false })



                        }

                    } catch (error) {

                    }
                }
                const item111 = {
                    "aid": agentid,
                    "activity": "Form Submit Click",
                    "uuid": uuid,
                    "msg": '-',
                    "remark": 'FORM_SUBMIT'

                }
                try {

                    const submitdata = await httpRequest({
                        endPoint: `api/user/activitylog`,
                        method: "post",
                        instance: "instanceOne",
                        requestBody: item111,

                    });


                } catch (error) {

                }

                curr_date_time = new Date()
                obj['call_datetime'] = curr_date_time
                obj['callstatus'] = callstatus;
                obj['comment'] = this.urlencode(this.state.comment).replaceAll('%', 'per');

                obj['process'] = callst;

                obj['campid'] = campid;
                obj['aid'] = agentid;
                obj['uuid'] = uuid;

                obj1['farmer_name'] = this.state.farmer_name == null ? 'NA' : this.state.farmer_name;
                obj1['number'] = no//this.state.profiledata.number == null ? 'NA' : this.state.profiledata.number.toString();
                obj1['district'] = this.state.farmer_district == null ? 'NA' : this.state.farmer_district;
                obj1['subdistrict'] = this.state.farmer_subdistrict == null ? '' : this.state.farmer_subdistrict;
                obj1['panchayat'] = this.state.farmer_panchayat == null ? '' : this.state.farmer_panchayat;
                obj1['village'] = this.state.farmer_village == null ? '' : this.state.farmer_village;

                obj["profile"] = obj1


                this.state.farmer_name = this.state.farmer_name == null ? '' : this.state.farmer_name;
                this.state.farmer_district = this.state.farmer_district == null ? '' : this.state.farmer_district;
                this.state.farmer_subdistrict = this.state.farmer_subdistrict == null ? '' : this.state.farmer_subdistrict;
                this.state.farmer_panchayat = this.state.farmer_panchayat == null ? '' : this.state.farmer_panchayat;
                this.state.farmer_village = this.state.farmer_village == null ? '' : this.state.farmer_village;

                let sqlnew = ""
                let sqlnew1 = ""
                // console.log("***", JSON.stringify(obj1))

                if (callstatus != "Answered") {

                    submitdata = obj;
                }
                if (callstatus === "Answered") {



                    const newElements = { ...elements }

                    newElements.fields.forEach(field => {
                        //  console.log("a")
                        const { field_mandatory, field_options, field_type, field_value, field_id, field_label, filed_desp } = field;


                        obj2['field_id'] = field_id;
                        obj2['field_label'] = field_label
                        obj2['field_value'] = field_value
                        obj2['filed_desp'] = filed_desp
                        obj2['field_mandatory'] = field_mandatory
                        obj2['field_options'] = field_options
                        obj2['field_type'] = field_type


                        obj3.push(obj2)

                        obj2 = {}

                    });

                    obj["form_data"] = (obj3)
                    submitdata = obj;
                }

                console.log(obj3)


                if (isok === 0) {





                    const item = {
                        "aid": parseInt(agentid),
                        "called": parseInt(callnumber),
                        "uuid": uuid,
                        "campid": parseInt(campid),
                        "call_report": submitdata

                    }



                    try {

                        const submitdataresp = await httpRequest({
                            endPoint: `api/lcc/CampaignFormSubmit`,
                            method: "post",
                            instance: "instanceOne",
                            requestBody: item,

                        });
                        console.log('responce', submitdataresp.success)
                        window.oSipSessionCall = null
                        if (submitdataresp.success === false) {
                            this.setState({ showerr: true, errorMsg: "Something went wrong please try again", mysnack: snackeror.myerror })
                            this.setState({ callstatus: '0', comment: '', callnumber: '' })
                            this.setState({ butst: true, outform: false, campform: false, showdropdown: '0', submitbutstatus: true, transferpagevalue: '0', transfervalue: '0', ivrname: '0', expertoption: '0', dominname: '0' })
                            this.setState({ farmer_name: '', farmer_district: '', farmer_subdistrict: '', farmer_panchayat: '', farmer_village: '' })

                        }

                        if (submitdataresp.success === true) {
                            // this.MySqlUpdate("update pad_live set status='', lastact_time=now() where aid='" + agentid + "' and  date='" + moment(new Date()).format("YYYY-MM-DD") + "' returning 1 as result")
                            this.setState({ showerr: true, errorMsg: "Data is Saved", mysnack: snackeror.mysucc })
                            this.setState({ callstatus: '0', comment: '' })
                            this.setState({ callnumber: '', butst: true, outform: false, campform: false, showdropdown: '0', submitbutstatus: true, transferpagevalue: '0', transfervalue: '0', ivrname: '0', expertoption: '0', dominname: '0' })
                            this.setState({ farmer_name: '', farmer_district: '', farmer_subdistrict: '', farmer_panchayat: '', farmer_village: '' })

                        } else {
                            this.setState({ showerr: true, errorMsg: "Something went wrong please try again", mysnack: snackeror.myerror })
                            this.setState({ butst: true, campform: false, outform: false, showdropdown: '0', submitbutstatus: true, transferpagevalue: '0', transfervalue: '0', ivrname: '0', expertoption: '0', dominname: '0' })
                            this.setState({ farmer_name: '', farmer_district: '', farmer_subdistrict: '', farmer_panchayat: '', farmer_village: '' })

                        }
                        uuid = ""
                    } catch (error) {

                    }

                }
            }
        }
        if (callformsubmit_type === 1) {
            const item111 = {
                "aid": agentid,
                "activity": "Form Submit Click",
                "uuid": uuid,
                "msg": '-',
                "remark": 'FORM_SUBMIT'

            }
            try {

                const submitdata = await httpRequest({
                    endPoint: `api/user/activitylog`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: item111,

                });


            } catch (error) {

            }


            let submitdata = {}
            let dd = {}
            var obj = {};
            var obj2 = {};
            var obj1 = {};
            var obj3 = []
            let curr_date_time = ""



            curr_date_time = new Date()

            obj1['farmer_name'] = this.state.farmer_name == null ? 'Unknown' : this.state.farmer_name;
            obj1['number'] = callnumber == null ? '0' : callnumber.toString();
            obj1['district'] = this.state.farmer_district == null ? 'Unknown' : this.state.farmer_district;
            obj1['subdistrict'] = this.state.farmer_subdistrict == null ? 'Unknown' : this.state.farmer_subdistrict;
            obj1['panchayat'] = this.state.farmer_panchayat == null ? 'Unknown' : this.state.farmer_panchayat;
            obj1['village'] = this.state.farmer_village == null ? 'Unknown' : this.state.farmer_village;

            obj["profile"] = obj1
            let callnumber1 = callnumber.substring(3, callnumber.length);

            const item1 = {

                disposition: callstatus,
                comment: this.urlencode(this.state.comment).replaceAll('%', 'per'),
                "profile": obj1

            }
            const item = {
                "aid": parseInt(agentid),
                "dialed_num": parseInt(callnumber1),
                "uuid": this.state.uuid,
                "domain": "outbound",
                "farmer_num": parseInt(callnumber1),
                "form_data": item1,


            }

            try {
                const submitdata = await httpRequest({
                    endPoint: `api/lcc/SubmitCallForm`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: item,

                });

                window.oSipSessionCall = null ///Clear prsent sip call if any
                // this.MySqlUpdate("update pad_live set status='', lastact_time=now() where aid='" + agentid + "' and  date='" + moment(new Date()).format("YYYY-MM-DD") + "' returning 1 as result")
                this.setState({ showerr: true, errorMsg: "Data is Saved", mysnack: snackeror.mysucc })
                this.setState({ callstatus: '0', comment: '', callnumber: '' })
                this.setState({ butst: true, outform: false, showdropdown: '0', submitbutstatus: true, transferpagevalue: '0', transfervalue: '0', ivrname: '0', expertoption: '0', dominname: '0' })
                this.setState({ farmer_name: '', farmer_district: '', farmer_subdistrict: '', farmer_panchayat: '', farmer_village: '' })

            } catch (error) {

            }
            uuid = ""
        }
    }
    formsubmit1 = async () => {

        const { callstatus, user_domain1, makeformvalidation, callnumber } = this.state

        let isok = 0
        console.log('callstatus', callstatus)

        if (callstatus === "0") {


            this.setState({ showerr: true, errorMsg: "Select Call Status", mysnack: snackeror.myerror })
            isok = 1

        }

        if (isok === 0) {
            this.setState({ starttime: false })
            let submitdata = ''
            let dd = {}
            var obj = {};
            var obj1 = {};

            let curr_date_time = ""

            let tab_name = "pad_lcc_log"
            if (user_domain === "agriculture") {
                tab_name = "pad_agri_log"
            }
            if (user_domain === "lcc-default") {
                tab_name = "pad_lcc_log"
            }
            if (user_domain === "animal_husbandry") {
                tab_name = "pad_ah_log"
            }
            if (user_domain === "fishery") {
                tab_name = "pad_fishery_log"
            }

            curr_date_time = new Date()
            obj['call_datetime'] = curr_date_time
            obj['callstatus'] = callstatus;
            obj['call_type'] = callstatus;
            obj['comment'] = this.urlencode(this.state.comment.toString().toLowerCase()).replaceAll('%', 'per')
            obj['call_domain'] = user_domain1;
            obj["uuid"] = uuid;
            obj["aid"] = parseInt(agentid);
            obj["farmer_num"] = parseInt(callnumber == null ? '0' : callnumber.toString())
            obj["domain"] = user_domain


            obj['farmer_name'] = this.state.farmer_name == null ? 'Unknown' : this.state.farmer_name;
            obj['number'] = callnumber == null ? '0' : callnumber.toString();
            obj['district'] = this.state.farmer_district == null ? 'Unknown' : this.state.farmer_district;
            obj['subdistrict'] = this.state.farmer_subdistrict == null ? 'Unknown' : this.state.farmer_subdistrict;
            obj['panchayat'] = this.state.farmer_panchayat == null ? 'Unknown' : this.state.farmer_panchayat;
            obj['village'] = this.state.farmer_village == null ? 'Unknown' : this.state.farmer_village;
            obj['advisory_primary']=this.state.advisory_primary == null ? 'Unknown' : this.state.advisory_primary;
            obj['advisory_specific_info']=this.state.advisory_specific_info == null ? 'Unknown' : this.state.advisory_specific_info;

            obj1['call_datetime'] = curr_date_time
            obj1['callstatus'] = callstatus;
            obj1['comment'] = this.urlencode(this.state.comment.toString().toLowerCase()).replaceAll('%', 'per')
            obj1['call_domain'] = user_domain1;
            obj1["uuid"] = uuid;
            obj1["aid"] = parseInt(agentid);
            obj1["farmer_num"] = parseInt(callnumber == null ? '0' : callnumber.toString())
            obj1["domain"] = user_domain


            obj1['farmer_name'] = this.state.farmer_name == null ? 'Unknown' : this.state.farmer_name;
            obj1['number'] = callnumber == null ? '0' : callnumber.toString();
            obj1['district'] = this.state.farmer_district == null ? 'Unknown' : this.state.farmer_district;
            obj1['subdistrict'] = this.state.farmer_subdistrict == null ? 'Unknown' : this.state.farmer_subdistrict;
            obj1['panchayat'] = this.state.farmer_panchayat == null ? 'Unknown' : this.state.farmer_panchayat;
            obj1['village'] = this.state.farmer_village == null ? 'Unknown' : this.state.farmer_village;
            obj1['advisory_specific_info'] = this.state.advisory_specific_info == null ? 'Unknown' : this.state.advisory_specific_info;
            obj1['advisory_primary'] = this.state.advisory_primary == null ? 'Unknown' : this.state.advisory_primary;
            


            obj["callform"] = JSON.stringify(obj1)


            this.state.farmer_name = this.state.farmer_name == null ? 'Unknown' : this.state.farmer_name;
            this.state.farmer_district = this.state.farmer_district == null ? 'Unknown' : this.state.farmer_district;
            this.state.farmer_subdistrict = this.state.farmer_subdistrict == null ? 'Unknown' : this.state.farmer_subdistrict;
            this.state.farmer_panchayat = this.state.farmer_panchayat == null ? 'Unknown' : this.state.farmer_panchayat;
            this.state.farmer_village = this.state.farmer_village == null ? 'Unknown' : this.state.farmer_village;
            this.state.advisory_specific_info = this.state.advisory_specific_info == null ? 'Unknown' : this.state.advisory_specific_info;
            this.state.advisory_primary = this.state.advisory_primary == null ? 'Unknown' : this.state.advisory_primary;
        
            let sqlnew = ""
            let sqlnew1 = ""
            // console.log("***", JSON.stringify(obj1))

            if (callstatus != "Answered") {

                submitdata = obj;
            }
            if (callstatus === "Answered") {



                const newElements = { ...elements }

                newElements.fields.forEach(field => {
                    //  console.log("a")
                    const { field_value, field_id, field_label,field_value1 } = field;

console.log('field_value1=====',field_value1)
                    obj[field_id] = field_value;
                    if(field_value1.length>1)
                    {
                        obj["sub_cat1"] = field_value1;
                    }
                    console.log('field_id', field_id)
                    console.log('field_value', field_id)


                    sqlnew = sqlnew + "," + field_id
                    sqlnew1 = sqlnew1 + ",'" + field_value + "'"

                    if (field_id === "main_cat" && makeformvalidation === 1) {
                        //  if (field_value === "Main-Option" || field_value === "Crop") {
                        if (field_value === field_label) {

                            this.setState({ showerr: true, errorMsg: "Select the Main Option", mysnack: snackeror.myerror })
                            isok = 1
                        }

                    }


                });
                submitdata = obj;
            }

            //'' console.log('submitdata with out  stringify ', obj)
            //' console.log('submitdata with   stringify ', JSON.stringify(JSON.stringify(obj)))
            //'' console.log('encode', this.urlencode(submitdata))
            // submitdata = JSON.stringify(submitdata)


            if (isok === 0) {

                // this.MySqlUpdate("update pad_live set status='', lastact_time=now() where aid='" + agentid + "' and  date='" + moment(new Date()).format("YYYY-MM-DD") + "' returning 1 as result")


                const item111 = {
                    "aid": agentid,
                    "activity": "Form Submit Click",
                    "uuid": uuid,
                    "msg": '-',
                    "remark": 'FORM_SUBMIT'

                }
                try {

                    const submitdata_act = await httpRequest({
                        endPoint: `api/user/activitylog`,
                        method: "post",
                        instance: "instanceOne",
                        requestBody: item111,

                    });


                } catch (error) {

                }

                const inboundsubmitpayload = {
                    "uuid": uuid,
                    "aid": parseInt(agentid),
                    "farmer_num": parseInt(callnumber == null ? '0' : callnumber.toString()),
                    "domain": user_domain,
                    "form_data": submitdata,


                }

                console.log("Main Payload",inboundsubmitpayload)
                try {

                    const submitdata_form = await httpRequest({
                        endPoint: `api/lcc/SubmitCallForm`,
                        method: "post",
                        instance: "instanceOne",
                        requestBody: inboundsubmitpayload,

                    });
                    if (submitdata_form.success === true) {

                        this.setState({ showerr: true, errorMsg: "Data is Saved", mysnack: snackeror.mysucc })
                        this.setState({ callstatus: '0', comment: '', callnumber: '', })
                        this.setState({ butst: true, callerform: false, showdropdown: '0', submitbutstatus: true, transferpagevalue: '0', transfervalue: '0', ivrname: '0', expertoption: '0', dominname: '0' })
                        this.setState({advisory_primary:'',advisory_specific_info:'', farmer_name: '', farmer_district: '', farmer_subdistrict: '', farmer_panchayat: '', farmer_village: '' })

                    } else {

                        this.setState({ callnumber: '', showerr: true, errorMsg: "Something went wrong please try again", mysnack: snackeror.myerror })
                        this.setState({ butst: true, callerform: false, showdropdown: '0', submitbutstatus: true, transferpagevalue: '0', transfervalue: '0', ivrname: '0', expertoption: '0', dominname: '0', })
                        this.setState({advisory_primary:'',advisory_specific_info:'', farmer_name: '', farmer_district: '', farmer_subdistrict: '', farmer_panchayat: '', farmer_village: '' })

                    }
                    uuid = ''

                } catch (error) {

                }

                /*   let sql = "insert into " + tab_name + "(agentid,dispo,comment,callstatus,call_domain,farmer_name,number,subdistrict,panchayat,village,district,uuid,call_datetime" + sqlnew + ") values('" + agentid + "','" + callstatus.toString().toLowerCase() + "','" + this.urlencode(this.state.comment.toString().toLowerCase()) + "','" + callstatus.toString().toLowerCase() + "','" + user_domain1 + "','" + this.state.farmer_name + "','" + this.state.profiledata.number + "','" + this.state.farmer_subdistrict + "','" + this.state.farmer_panchayat + "','" + this.state.farmer_village + "','" + this.state.farmer_district + "','" + uuid + "',now()" + sqlnew1 + ") returning uid as result"
                   console.log(sql)
   
   
                   const item = {
                       "stmt": sql
   
                   }
   
   
   
                   try {
   
                       const submitdata = await httpRequest({
                           endPoint: `api/db/stmt`,
                           method: "post",
                           instance: "instanceOne",
                           requestBody: item,
   
                       });
                       console.log('responce', submitdata.success)
                       window.oSipSessionCall = null
   
                       if (submitdata.success === true) {
   
                           this.setState({ showerr: true, errorMsg: "Data is Saved", mysnack: snackeror.mysucc })
                           this.setState({ callstatus: '0', comment: '', callnumber: '', })
                           this.setState({ butst: true, callerform: false, showdropdown: '0', submitbutstatus: true, transferpagevalue: '0', transfervalue: '0', ivrname: '0', expertoption: '0', dominname: '0' })
                           this.setState({ farmer_name: '', farmer_district: '', farmer_subdistrict: '', farmer_panchayat: '', farmer_village: '' })
   
                       } else {
   
                           this.setState({ callnumber: '', showerr: true, errorMsg: "Something went wrong please try again", mysnack: snackeror.myerror })
                           this.setState({ butst: true, callerform: false, showdropdown: '0', submitbutstatus: true, transferpagevalue: '0', transfervalue: '0', ivrname: '0', expertoption: '0', dominname: '0', })
                           this.setState({ farmer_name: '', farmer_district: '', farmer_subdistrict: '', farmer_panchayat: '', farmer_village: '' })
   
                       }
                       uuid = ''
                   } catch (error) {
   
                   }*/

            }
        }
    }

    handelOutBoundCall = async () => {

        if (this.state.outboundbutst === true) {

            let isok = 0

            if (this.state.outboundnumber.length < 13) {


                this.setState({ showerr: true, errorMsg: "Kindly enter 13 digit Number", mysnack: snackeror.myerror })
                isok = 1

            }
            if (isok === 0) {

                let no = "+91" + this.state.outboundnumber.slice(0, 10)
                this.setState({ starttime: true })
                console.log("Outbouind Numbewr", no)
                document.getElementById('txtPhoneNumber').value = no
                this.state.callnumber = no
                // window.sipCall('call-audio');
                window.janus_sip.call(no, false)
                this.setState({ callformsubmit_type: 1, showans: false, savebutvis: false, outboundbutname: 'Disconnect', outboundbutst: false, outform: true, })


                // this.MySqlUpdate("update pad_live set status='FORM_OPEN', lastact_time=now() where aid='" + agentid + "' and  date='" + moment(new Date()).format("YYYY-MM-DD") + "'  returning 1 as result")

                const item = {
                    "aid": agentid,
                    "activity": "Answer Click",
                    "uuid": uuid,
                    "msg": '-',
                    "remark": 'ANSWER'

                }
                try {

                    const submitdata = await httpRequest({
                        endPoint: `api/user/activitylog`,
                        method: "post",
                        instance: "instanceOne",
                        requestBody: item,

                    });


                } catch (error) {

                }

            }
        } else {
            this.setState({ starttime: false, outboundbutname: 'Call', outboundbutst: true, outboundnumber: "", starttime: false })
            document.getElementById('txtPhoneNumber').value = ""
            window.oSipSessionCall = null
            //window.sipHangUp()
            window.janus_sip.hangup()

        }

    }

    handelCallTransfer = async () => {
        this.setState({ trafbutst: false })
        let da = moment(new Date()).format("YYYY-MM-DD")
        console.log("Call Transfer", this.state.transfervalue)
        let dd = this.state.transfervalue
        if (this.state.transfervalue === "Transfer to VOTI") {
            let voitno = "+91" + this.state.ivrname
            console.log(voitno)
            document.getElementById('txtPhoneNumber').value = voitno




            const item111 = {
                "aid": agentid,
                "activity": dd,
                "uuid": uuid,
                "msg": '-',
                "remark": 'TRANSFER'

            }
            try {

                const submitdata = await httpRequest({
                    endPoint: `api/user/activitylog`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: item111,

                });


            } catch (error) {

            }

            //   window.sipTransfer();
            window.janus_sip.transfer(voitno, '')



        }
        if (this.state.transfervalue === "Transfer to other Domain") {
            document.getElementById('txtPhoneNumber').value = this.state.dominname
            let dname = "0"
            if (this.state.dominname === "1000000000") {
                dname = "agriculture"

            }
            if (this.state.dominname === "2000000000") {
                dname = "animal_husbandry"

            }
            if (this.state.dominname === "3000000000") {
                dname = "fishery"

            }
            if (dname != "0") {

                ///api/fs/registrations

                /*  let sql = "select aid from pad_live where callstatus='A' and date(date)='" + da + "' and domain='" + dname + "' and CHARACTER_LENGTH(pad_live.status)=0"
               console.log(sql)
               let data = await sql_funcation(sql)
               console.log(data)
             if (data.msg.length == 0) {
                    this.setState({ showerr: true, errorMsg: "No Agent is Found for  " + dname, mysnack: snackeror.myerror })
 
 
                }*/

                //  if (data.msg.length > 0) {


                const item111 = {
                    "aid": agentid,
                    "activity": dd,
                    "uuid": uuid,
                    "msg": '-',
                    "remark": 'TRANSFER'

                }
                try {

                    const submitdata = await httpRequest({
                        endPoint: `api/user/activitylog`,
                        method: "post",
                        instance: "instanceOne",
                        requestBody: item111,

                    });


                } catch (error) {

                }
                // window.sipTransfer();
                window.janus_sip.transfer(this.state.dominname, '')
                //  }


            }
            if (dname === "0") {


                const item111 = {
                    "aid": agentid,
                    "activity": dd,
                    "uuid": uuid,
                    "msg": '-',
                    "remark": 'TRANSFER'

                }
                try {

                    const submitdata = await httpRequest({
                        endPoint: `api/user/activitylog`,
                        method: "post",
                        instance: "instanceOne",
                        requestBody: item111,

                    });


                } catch (error) {

                }
                //   window.sipTransfer();
                window.janus_sip.transfer('9195', '')
            }
        }
    }
    handelMOH() {
        //window.sipToggleMute()
        window.janus_sip.hold_call()
        this.setState({ callbutt_st: true })
    }
    handelResumeCall() {
        //window.sipToggleMute()
        window.janus_sip.unhold_call()
        this.setState({ callbutt_st: false, callbutt_st1: true })

    }


    SubmitCamp = async () => {
        const { campid } = this.state
        const item = {
            aid: parseInt(agentid),
            campid: parseInt(campid)

        }



        try {

            const submitdata = await httpRequest({
                endPoint: `api/lcc/CampaignStart`,
                method: "post",
                instance: "instanceOne",
                requestBody: item,

            });
            console.log('responce', submitdata.msg)

            if (submitdata.success == true) {


                //this.setState({ campbutton: 'Start Campaign', campbuttontype: 0 })

                this.setState({ campbutton: 'Stop Campaign', campbuttontype: 1, showcamp: false, callstatus: false })



            }

        } catch (error) {

        }
    }

    StartCamp = async () => {

        if (this.state.campbuttontype === 1) {
            const { campid } = this.state
            const item = {
                aid: parseInt(agentid),
                campid: parseInt(campid)

            }
            try {
                const submitdata = await httpRequest({
                    endPoint: `api/lcc/CampaignStop`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: item,

                });
                this.setState({ campbutton: 'Start Campaign', campbuttontype: 0, callbuttonst: false })
            } catch (error) {

            }

        }
        if (this.state.campbuttontype === 0) {

            const item = {
                aid: parseInt(agentid)

            }

            this.setState({ campbutton: 'Stop Campaign', campbuttontype: 1, showcamp: false, callbuttonst: true })

            try {

                const submitdata = await httpRequest({
                    endPoint: `api/lcc/CampaignList`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: item,

                });
                console.log('responce camp===', submitdata)

                if (submitdata.success === true) {


                    if (submitdata.msg.length <= 1) {


                        this.setState({ campid: submitdata.msg[0].uid })
                        const item = {
                            aid: parseInt(agentid),
                            campid: submitdata.msg[0].uid

                        }



                        try {

                            const submitdata1 = await httpRequest({
                                endPoint: `api/lcc/CampaignStart`,
                                method: "post",
                                instance: "instanceOne",
                                requestBody: item,

                            });
                            console.log('responce', submitdata1.msg)

                            if (submitdata1.success === true) {


                                //this.setState({ campbutton: 'Start Campaign', campbuttontype: 0 })
                                this.setState({ campbutton: 'Stop Campaign', campbuttontype: 1, showcamp: false, callbuttonst: false })



                            }

                        } catch (error) {

                        }

                    }

                    if (submitdata.msg.length > 1) {

                        const domaindata1 = submitdata.msg.map(data => {
                            return { label: data.camp_name, value: data.uid };
                        });

                        console.log('domaindata1', domaindata1)

                        this.setState({ camplist: domaindata1, showcamp: true })
                    }
                }
            } catch (error) {

            }



        }

    }

    SetFormerProfile = async () => {

        const { farmer_village, farmer_panchayat, farmer_subdistrict, farmer_district, farmer_name, caller_number } = this.state
        let no = this.state.profiledata.number == null ? 'NA' : this.state.profiledata.number.toString()
        no = parseInt(this.state.callnumber)

        const item = {
            number: no,
            name: farmer_name,
            district: farmer_district,
            subdistrict: farmer_subdistrict,
            panchayat: farmer_panchayat,
            village: farmer_village

        }
        try {

            const submitdata1 = await httpRequest({
                endPoint: `api/lcc/SetProfile`,
                method: "post",
                instance: "instanceOne",
                requestBody: item,

            });
            console.log('responce', submitdata1.msg)

            if (submitdata1.success === true) {


                //this.setState({ campbutton: 'Start Campaign', campbuttontype: 0 })
                this.setState({ showedit: false })



            }

        } catch (error) {

        }


    }
    GetDistricts = async () => {

        try {
            const roledata1 = await httpRequest({
                endPoint: `/api/lcc/getDistricts`,
                method: "get",
                instance: "instanceOne",


            });

            console.log('distlist', roledata1.msg)
            // if (roledata1.success == true) {
            this.setState({ distlist: roledata1.msg })
            //}


        } catch (error) {

        }


    }
    DomainHangup() {



        this.setState({ hangupbutst: false })


    }
    render() {
        const { classes } = this.props;


        const { editprofile } = this.state
        const { fields, page_label } = elements ?? {}
        return (
            <div>


                <Grid style={{ width: '100%', marginTop: '1%', display: 'flex' }}>
                    <Grid style={{ width: '30%', }}>
                        <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Dashboard</Typography>
                    </Grid>
                    <Grid style={{ width: '65%', display: 'flex', justifyContent: 'flex-end' }}>

                    </Grid>

                </Grid>

                <Grid style={{ width: '100%', marginTop: '1%', display: 'flex' }}>

                    <Grid style={{ display: 'flex', justifyContent: 'space-evenly', width: '70%', padding: '2%', border: '1px solid #E7EAF0', borderRadius: '10px' }}>

                        <Grid style={{ width: '40%', padding: '2%', border: '1px solid #E7EAF0', borderRadius: '10px' }}>
                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Echo Test</Typography>
                            <Button



                                variant="contained"
                                size="small"

                                style={{
                                    color: "#ffffff",
                                    fontFamily: "Roboto-Medium",
                                    borderRadius: "4px",
                                    backgroundColor: this.state.testbutst1 ? "#F74542" : "#41AB1C",
                                    textTransform: 'none',
                                    padding: '1.5%',
                                    fontSize: "14px",
                                    width: '150px',
                                    height: '32px',
                                    marginLeft: '10%',
                                    marginTop: '2%',


                                }}

                                onClick={() => { this.echotest() }}


                            >

                                {this.state.testbutname1}
                            </Button>
                        </Grid>
                        <Grid style={{ width: '40%', padding: '2%', border: '1px solid #E7EAF0', borderRadius: '10px' }}>
                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Delay Echo Test</Typography>
                            <Button



                                variant="contained"
                                size="small"

                                style={{
                                    color: "#ffffff",
                                    fontFamily: "Roboto-Medium",
                                    borderRadius: "4px",
                                    backgroundColor: this.state.testbutst2 ? "#F74542" : "#41AB1C",
                                    textTransform: 'none',
                                    padding: '1.5%',
                                    fontSize: "14px",
                                    width: '150px',
                                    height: '32px',
                                    marginLeft: '10%',
                                    marginTop: '2%',


                                }}

                                onClick={() => { this.delayechotest() }}


                            >

                                {this.state.testbutname2}
                            </Button>


                        </Grid>


                    </Grid>
                    <Grid style={{ width: '27%', padding: '1%', border: '1px solid #00ACFE', borderRadius: '8px', marginLeft: '3%' }}>
                        {this.state.headstyle == "0" &&
                            <div>
                                <Grid style={{ width: '95%', padding: '2%', background: '#92A4C1', borderRadius: '8px' }}>
                                    <Grid style={{ width: '100%', display: 'flex' }}>
                                        <Grid style={{ width: '70%' }}>
                                            <Typography style={{ color: '#ffffff', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>{this.state.callstlabel}</Typography>
                                        </Grid>
                                        <Grid style={{ width: '30%' }}>
                                            <Typography style={{ color: '#ffffff', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Duration</Typography>
                                        </Grid>

                                    </Grid>
                                    <Grid style={{ width: '100%', display: 'flex' }}>
                                        <Grid style={{ width: '70%' }}>
                                            <Typography style={{ color: '#ffffff', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>{this.state.callnumber}</Typography>
                                        </Grid>
                                        <Grid style={{ width: '20%', marginTop: '5px' }}>

                                            <ReactStopwatch
                                                seconds={0}
                                                minutes={0}
                                                hours={0}
                                                autoStart={this.state.starttime}
                                                limit="00:00:15"
                                                onChange={({ hours, minutes, seconds }) => {

                                                }}
                                                // onCallback={() => this.ForceLogout()}
                                                render={({ formatted, hours, minutes, seconds }) => {
                                                    return (
                                                        <div>
                                                            <Typography style={{ color: '#ffffff', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>
                                                                {formatted}
                                                            </Typography>

                                                        </div>
                                                    );
                                                }}
                                            />
                                        </Grid>

                                    </Grid>


                                </Grid>
                            </div>
                        }
                        {this.state.headstyle == "1" &&
                            <div>

                                <Grid style={{ width: '95%', padding: '2%', background: 'linear-gradient(180deg, #00ACFE 0%, #093265 165.71%)', borderRadius: '8px' }}>
                                    <Grid style={{ width: '100%', display: 'flex' }}>
                                        <Grid style={{ width: '50%' }}>
                                            <Typography style={{ color: '#ffffff', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>{this.state.callstlabel}</Typography>
                                            <Typography style={{ color: '#ffffff', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>{this.state.callnumber}</Typography>

                                        </Grid>
                                        <Grid style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
                                            <Button


                                                disabled={!this.state.butst}
                                                variant="contained"
                                                size="small"

                                                style={{
                                                    color: this.state.butst ? "#41AB1C" : "#999999",
                                                    fontFamily: "Roboto-Medium",
                                                    borderRadius: "4px",
                                                    backgroundColor: this.state.butst ? "#ffffff" : "#ffffff",
                                                    textTransform: 'none',
                                                    padding: '1.5%',
                                                    fontSize: "14px",
                                                    width: '100px',
                                                    height: '32px',


                                                }}

                                                //onClick={() => { this.JoinCall(incomingCallEvent) }}
                                                //onClick={() => { window.sipRegister() }}
                                                onClick={() => { this.myJoin() }}
                                            >

                                                {this.state.butname}

                                            </Button>


                                        </Grid>

                                    </Grid>



                                </Grid>
                            </div>
                        }
                        <Grid style={{ width: '100%', display: 'flex' }}>
                            {this.state.outbount_display == "1" &&

                                <div>

                                    <Grid style={{ width: '100%', marginTop: '3%', display: 'flex', alignItems: 'center' }}>
                                        <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '16px', fontFamily: 'Roboto-Regular' }}>Number:</Typography>
                                        <CssTextField focusColor="#999999"
                                            fullWidth
                                            inputProps={{
                                                style: { height: '10px', maxLength: 10, fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },
                                            }}
                                            size="small"

                                            variant="outlined"
                                            placeholder="Enter Phone Number"
                                            value={this.state.outboundnumber}
                                            onChange={e => {
                                                this.handleMobileNumberChange(e)

                                            }}
                                        />



                                    </Grid>


                                    <Grid style={{ width: '100%', marginTop: '3%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                        <Button



                                            variant="contained"
                                            size="small"

                                            style={{
                                                color: "#ffffff",
                                                fontFamily: "Roboto-Medium",
                                                borderRadius: "4px",
                                                backgroundColor: this.state.campbuttontype == 0 ? "#7DAB1C" : "#F74542",
                                                textTransform: 'none',
                                                padding: '1.5%',
                                                fontSize: "14px",
                                                width: '150px',
                                                height: '32px',



                                            }}

                                            onClick={(e) => { this.StartCamp() }}


                                        >

                                            {this.state.campbutton}
                                        </Button>

                                        <Button


                                            disabled={this.state.callbuttonst}
                                            variant="contained"
                                            size="small"

                                            style={{
                                                color: this.state.outboundbutst ? "#ffffff" : "#ffffff",
                                                fontFamily: "Roboto-Medium",
                                                borderRadius: "4px",
                                                backgroundColor: this.state.outboundbutst ? "#7DAB1C" : "#F74542",
                                                textTransform: 'none',
                                                padding: '1.5%',
                                                fontSize: "14px",
                                                width: '100px',
                                                height: '32px',



                                            }}

                                            onClick={(e) => { this.handelOutBoundCall() }}


                                        >

                                            {this.state.outboundbutname}
                                        </Button>



                                    </Grid>

                                </div>

                            }

                            {this.state.outbount_display === "0" &&
                                <div>
                                    <Grid style={{ width: '100%', marginTop: '3%' }}>
                                        <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '16px', fontFamily: 'Roboto-Bold' }}>Hey!</Typography>

                                    </Grid>
                                    <Grid style={{ width: '100%', marginTop: '3%' }}>
                                        <Typography style={{ textAlign: 'center', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}> we are expecting a client call in a while</Typography>
                                    </Grid>

                                </div>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                {
                    user_domain_id === 11 &&
                    <div>
                        <MissCall aid={agentid} />
                    </div>

                }

                {/*        <Grid style={{ width: '100%', marginTop: '3%', display: 'flex', justifyContent: 'space-evenly' }}>


                    <Grid style={{ width: '28%', padding: '2%', border: '1px solid #E7EAF0', borderRadius: '10px' }}>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}>Call Status Summary</Typography>
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                            <PieChart
                                style={{ height: '200px' }}
                                animate="true"
                                animationDuration={500}
                                animationEasing="ease-in"
                                data={data1}
                                label={(props) => { return props.dataEntry.value + "%"; }}
                                labelPosition={50}
                                lengthAngle={360}

                                paddingAngle={0}

                                radius={35}
                                startAngle={100}
                                viewBoxSize={[100, 100]}


                                labelStyle={{
                                    fontSize: "7px",
                                    color: "red",

                                }}
                            />
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>

                            <Grid style={{ width: '33%', }}>
                                <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '32px', fontFamily: 'Roboto-Bold' }}>34</Typography>

                                <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Accepted</Typography>
                            </Grid>
                            <Grid style={{ width: '33%', }}>
                                <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '32px', fontFamily: 'Roboto-Bold' }}>5</Typography>

                                <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Rejected</Typography>
                            </Grid>
                            <Grid style={{ width: '33%', }}>
                                <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '32px', fontFamily: 'Roboto-Bold' }}>12</Typography>

                                <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Escalated</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid style={{ width: '28%', }}>
                        <Grid style={{ border: '1px solid #E7EAF0', borderRadius: '10px', padding: '10%' }}>
                            <Grid style={{ width: '100%', display: 'flex', }}>
                                <Typography style={{ color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}>Avg Call time</Typography>
                            </Grid>
                            <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>

                                <Grid style={{ width: '50%', }}>
                                    <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '32px', fontFamily: 'Roboto-Bold' }}>34</Typography>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Number of Calls</Typography>
                                </Grid>
                                <Grid style={{ width: '50%', }}>
                                    <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '32px', fontFamily: 'Roboto-Bold' }}>5</Typography>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Total Duration</Typography>
                                </Grid>
                            </Grid>


                        </Grid>
                        <Grid style={{ border: '1px solid #E7EAF0', borderRadius: '10px', marginTop: '5%', padding: '10%' }}>
                            <Grid style={{ width: '100%', display: 'flex', }}>
                                <Typography style={{ color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}>Call Statistics - default -</Typography>
                            </Grid>

                            <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>

                                <Grid style={{ width: '50%', }}>
                                    <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '32px', fontFamily: 'Roboto-Bold' }}>50</Typography>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Calls received per hour</Typography>
                                </Grid>
                                <Grid style={{ width: '50%', }}>
                                    <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '32px', fontFamily: 'Roboto-Bold' }}>25</Typography>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>30 mins</Typography>
                                </Grid>
                            </Grid>

                        </Grid>

                    </Grid>
                    <Grid style={{ width: '28%', padding: '2%', border: '1px solid #E7EAF0', borderRadius: '10px' }}>

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}>Call History</Typography>
                        </Grid>

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '25%' }}>

                            <Grid style={{ width: '50%', }}>
                                <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '32px', fontFamily: 'Roboto-Bold' }}>34</Typography>

                                <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Incoming Call</Typography>
                            </Grid>
                            <Grid style={{ width: '50%', }}>
                                <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '32px', fontFamily: 'Roboto-Bold' }}>5</Typography>

                                <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Outgoing Call</Typography>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
                            */}

                <Modal

                    sx={{ border: 'none' }}




                    open={this.state.callerform}

                >


                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '95%',
                            height: 'auto',

                            outline: 'none',
                            background: '#ffffff',

                            borderRadius: '8px',
                            boxShadow: '0px 0px 12px  rgba(0, 0, 0, 0.4)',

                        }}
                    >
                        <Grid style={{ width: '100%', display: 'flex' }}>

                            <Grid container style={{ width: '25%', borderRight: '1px solid #043068', padding: '2%', }}>
                                <Grid style={{ width: '100%', background: '#043068', borderRadius: '4px', padding: '15.5px', height: '11vh' }}>
                                    <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <Grid style={{ width: '70%', paddingLeft: '4%' }}>
                                            <Typography style={{ color: '#ffffff', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Incoming Call</Typography>
                                            <Typography style={{ color: '#ffffff', fontSize: '16px', fontFamily: 'Roboto-Regular' }}>{this.state.callnumber}</Typography>
                                        </Grid>
                                        <Grid style={{ width: '30%' }}>
                                            <Typography style={{ color: '#ffffff', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Duration</Typography>

                                            <ReactTimerStopwatch

                                                isOn={this.state.starttime} watchType="stopwatch"
                                                displayCricle={true} fromTime={fromTime}

                                            />

                                        </Grid>
                                    </Grid>
                                    <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <Grid class="fake-legend1" style={{ zIndex: 1, marginTop: '-20px' }}>
                                            <img src="ui-assests/images/phone.svg" width='68px' height="26px"
                                                alt="login" style={{ background: '#043068', zIndex: 3, marginTop: '14px' }} />

                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <Grid style={{ width: '95%', marginTop: '3%' }}>
                                        <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Transfer calls</Typography>
                                        <Select value={this.state.transfervalue}
                                            displayEmpty
                                            variant="outlined"

                                            size="small"
                                            fullWidth

                                            style={{ height: '42px', }}

                                            onChange={e => {

                                                this.handelTransferOption(e)

                                            }}

                                        >
                                            <MenuItem disabled value={'0'}>Select</MenuItem>
                                            <MenuItem value={'Transfer to IVR'}>Transfer to IVR</MenuItem>
                                            <MenuItem value={'Transfer to Expert'}>Transfer to Expert</MenuItem>
                                            <MenuItem value={'Transfer to other Domain'}>Transfer to other Domain</MenuItem>



                                            {this.state.user_domain1 == "lcc-default" ? <MenuItem value={'Transfer to VOTI'}>Transfer to VOTI</MenuItem> : ''}



                                        </Select>

                                        {
                                            this.state.transferpagevalue == '1' &&
                                            <div style={{ marginTop: '3%' }}>
                                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>{this.state.transferpagetitel}</Typography>
                                                <Select value={this.state.ivrname}
                                                    displayEmpty
                                                    variant="outlined"

                                                    size="small"
                                                    fullWidth

                                                    style={{ height: '42px', }}

                                                    onChange={e => {

                                                        this.setState({ ivrname: e.target.value })

                                                    }}

                                                >
                                                    <MenuItem disabled value={'0'}>Select</MenuItem>
                                                    <MenuItem value={'1'}>IVR-1</MenuItem>
                                                    <MenuItem value={'2'}>IVR-2</MenuItem>
                                                    <MenuItem value={'3'}>IVR-3</MenuItem>




                                                </Select>
                                            </div>
                                        }
                                        {
                                            this.state.transferpagevalue == '2' &&
                                            <div style={{ marginTop: '3%' }}>
                                                <RadioGroup
                                                    row
                                                    defaultValue="Internal"
                                                    value={this.state.expertoption}


                                                    onChange={e =>
                                                        this.handelExpertoption(e)


                                                    }
                                                >
                                                    <FormControlLabel value="Internal" control={<Radioheckbox size='small' style={{ fontSize: '21px', }} />} label={<Typography style={{ color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Internal</Typography>} />

                                                    <FormControlLabel value="External" control={<Radioheckbox size='small' style={{}} />} label={<Typography style={{ color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>External</Typography>} />







                                                </RadioGroup>
                                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>{this.state.transferpagetitel}</Typography>
                                                <Select value={this.state.dominname}
                                                    displayEmpty
                                                    variant="outlined"

                                                    size="small"
                                                    fullWidth

                                                    style={{ height: '42px', }}

                                                    onChange={e => {

                                                        this.setState({ dominname: e.target.value })

                                                    }}

                                                >
                                                    <MenuItem disabled value={'0'}>Select</MenuItem>


                                                    <MenuItem value={'AG'}>Name-1</MenuItem>
                                                    <MenuItem value={'AH'}>Name-2</MenuItem>
                                                    <MenuItem value={'BK'}>Name-3</MenuItem>





                                                </Select>
                                            </div>
                                        }
                                        {
                                            this.state.transferpagevalue == '3' &&
                                            <div style={{ marginTop: '3%' }}>
                                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>{this.state.transferpagetitel}</Typography>
                                                <Select value={this.state.dominname}
                                                    displayEmpty
                                                    variant="outlined"

                                                    size="small"
                                                    fullWidth

                                                    style={{ height: '42px', }}

                                                    onChange={e => {

                                                        this.setState({ dominname: e.target.value })

                                                    }}

                                                >
                                                    <MenuItem disabled value={'0'}>Select</MenuItem>


                                                    <MenuItem value={'1000000000'} style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Agriculture</MenuItem>
                                                    <MenuItem value={'2000000000'} style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Animal Husbandry</MenuItem>
                                                    <MenuItem value={'9196'} style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Abusive</MenuItem>
                                                    <MenuItem value={'3000000000'} style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Fishery</MenuItem>
                                                    {/*<MenuItem value={'GS'} style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Govt Scheme</MenuItem>
                                                    <MenuItem value={'KO'} style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Krushak Odisha</MenuItem>
                                                    <MenuItem value={'SF'} style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>SAFAL</MenuItem>
                                                    <MenuItem value={'SG'} style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>GO Sugam</MenuItem>
                                                    <MenuItem value={'TC'} style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Test Call</MenuItem>
                                                    <MenuItem value={'W'} style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Weather</MenuItem>
                                            */}



                                                </Select>


                                            </div>
                                        }
                                        {
                                            this.state.transferpagevalue == '4' &&
                                            <div style={{ marginTop: '3%' }}>
                                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>{this.state.transferpagetitel}</Typography>
                                                <Select value={this.state.ivrname}
                                                    displayEmpty
                                                    variant="outlined"

                                                    size="small"
                                                    fullWidth

                                                    style={{ height: '42px', }}

                                                    onChange={e => {

                                                        this.setState({ ivrname: e.target.value })

                                                    }}

                                                >
                                                    <MenuItem disabled value={'0'}>Select</MenuItem>
                                                    <MenuItem value={'6742913962'}>VOIT1-3962</MenuItem>
                                                    <MenuItem value={'6742577965'}>VOIT2-7965</MenuItem>
                                                    <MenuItem value={'6742912962'}>VOIT3-2962</MenuItem>


                                                </Select>
                                            </div>
                                        }
                                        <Grid style={{ width: '100%', marginTop: '15%', display: 'flex' }}>

                                            <Grid className={this.state.callbutt_st ? classes.callbutt1 : classes.callbutt} style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}

                                                onClick={(e) => { this.handelResumeCall() }}

                                            ><MySvg fill={this.state.callbutt_st ? '#043068' : '#999999'} />
                                                <Typography style={{ marginLeft: '8%', color: this.state.callbutt_st ? '#043068' : '#999999', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Resume Call</Typography>

                                            </Grid>
                                            <Grid className={this.state.callbutt_st1 ? classes.callbutt1 : classes.callbutt} style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}

                                                onMouseLeave={e =>
                                                    this.setState({ callbutt_st1: false })
                                                }
                                                onMouseOver={e =>

                                                    this.setState({ callbutt_st1: true })
                                                }
                                                onClick={(e) => { this.handelMOH() }}
                                            ><Mymusic fill={this.state.callbutt_st1 ? '#043068' : '#999999'} />
                                                <Typography style={{ marginLeft: '8%', color: this.state.callbutt_st1 ? '#043068' : '#999999', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Pause</Typography>

                                            </Grid>


                                        </Grid>
                                        <Grid style={{ width: '100%', marginTop: '25%', display: 'flex' }}>


                                            <Button



                                                variant="contained"
                                                size="small"
                                                disabled={!this.state.trafbutst}
                                                style={{
                                                    color: "#ffffff",
                                                    fontFamily: "Roboto-Medium",
                                                    borderRadius: "4px",
                                                    backgroundColor: this.state.trafbutst ? "#7DAB1C" : "#999999",
                                                    textTransform: 'none',
                                                    padding: '1.5%',
                                                    fontSize: "14px",
                                                    width: '100px',
                                                    height: '32px',
                                                    marginLeft: '5%'

                                                }}

                                                onClick={(e) => { this.handelCallTransfer() }}


                                            >

                                                Transfer
                                            </Button>


                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* Dy form*/}
                            <Grid style={{ width: '55%' }}>
                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                                    <Grid style={{ width: '95%', justifyContent: 'center', background: '#F0F6ED', borderRadius: '4px', padding: '1%' }}>

                                        <Typography style={{ textAlign: 'center', color: '#333333', fontSize: '18px', fontFamily: 'Roboto-Regular' }}>Please fill bellow form with caller details</Typography>
                                        <Typography style={{ textAlign: 'center', color: '#333333', fontSize: '18px', fontFamily: 'Roboto-Bold' }}>{this.state.newmsg}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid style={{ width: '100%', display: 'flex', paddingTop: '10px' }}>
                                    <Grid style={{ width: '45%', marginLeft: '1%' }}>


                                        <Select value={this.state.callstatus}
                                            displayEmpty
                                            variant="outlined"

                                            size="small"
                                            fullWidth

                                            style={{ height: '42px', }}

                                            onChange={e => {

                                                this.handelCallStatus(e)

                                            }}

                                        >
                                            <MenuItem disabled value={'0'}>Call Status</MenuItem>
                                            <MenuItem value={'Answered'}>Answered</MenuItem>
                                            <MenuItem value={'Transfer to Agri'}>Transfer to Agri</MenuItem>
                                            <MenuItem value={'Transfer to AH'}>Transfer to AH</MenuItem>
                                            <MenuItem value={'Transfer to Fishery'}>Transfer to Fishery</MenuItem>
                                            <MenuItem value={'Transfer to abusive'}>Transfer to abusive</MenuItem>
                                            <MenuItem value={'Transfer to abusive'}>Call could not established</MenuItem>

                                            {this.state.user_domain1 == "lcc-default" ?
                                                <MenuItem value={'Call Transfre to VOTI'}>Call Transfer to VOTI</MenuItem> : ''}

                                            <MenuItem value={'Irrelevant'}>Irrelevant</MenuItem>
                                            <MenuItem value={'Unproductive call'}>Unproductive call</MenuItem>
                                            <MenuItem value={'Audio Issue'}>Audio Issue</MenuItem>
                                            <MenuItem value={'Test Call'}>Test Call</MenuItem>



                                        </Select>





                                    </Grid>
                                    <Grid style={{ width: '45%', marginLeft: '3%' }}>
                                        <CssTextField focusColor="#999999"


                                            fullWidth
                                            inputProps={{
                                                style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                            }}
                                            size="small"

                                            variant="outlined"
                                            placeholder="Enter comment"
                                            value={this.state.comment}

                                            onChange={e => {

                                                this.setState({ comment: e.target.value });

                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '2%', height: '55vh' }}>




                                    {
                                        this.state.showdropdown == "1" &&
                                        <div style={{ width: '90%' }}>
                                            <FormContext.Provider value={{ handleJsonChange1 }}>
                                                <form style={{ width: '90%' }}>


                                                    {fields ? fields.map((field, i) =>
                                                        <Grid style={{ marginTop: '3%', width: '90%' }}>
                                                            <Element key={i} field={field} />
                                                        </Grid>

                                                    ) : null}


                                                </form>
                                            </FormContext.Provider>
                                        </div>
                                    }


                                </Grid>
                                <Grid style={{ width: '85%', display: 'flex', justifyContent: 'flex-end', marginBottom: '4%' }}>




                                    <Button



                                        variant="contained"
                                        size="small"
                                        disabled={this.state.hangupbutst}
                                        style={{
                                            color: this.state.hangupbutst ? '#999999' : "#ffffff",
                                            fontFamily: "Roboto-Medium",
                                            borderRadius: "4px",
                                            backgroundColor: this.state.hangupbutst ? '#E7EAF0' : "#7DAB1C",
                                            textTransform: 'none',
                                            padding: '1.5%',
                                            fontSize: "14px",
                                            width: '100px',
                                            height: '32px',
                                            marginLeft: '5%'

                                        }}

                                        onClick={(e) => {
                                            //window.sipHangUp()
                                            window.janus_sip.hangup()
                                        }}


                                    >

                                        Hangup
                                    </Button>
                                    <Button



                                        variant="contained"
                                        size="small"
                                        disabled={this.state.submitbutstatus}
                                        style={{
                                            color: this.state.submitbutstatus ? '#999999' : "#ffffff",
                                            fontFamily: "Roboto-Medium",
                                            borderRadius: "4px",
                                            backgroundColor: this.state.submitbutstatus ? '#E7EAF0' : "#7DAB1C",
                                            textTransform: 'none',
                                            padding: '1.5%',
                                            fontSize: "14px",
                                            width: '100px',
                                            height: '32px',
                                            marginLeft: '5%'

                                        }}

                                        onClick={(e) => { this.formsubmit1(e) }}


                                    >

                                        Submit
                                    </Button>


                                </Grid>

                            </Grid>
                            <Grid style={{ width: '25%', borderLeft: '1px solid #043068', marginTop: '2%' }}>
                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                                    <Grid style={{ width: '80%', background: '#043068', borderRadius: '4px', padding: '15.5px', height: '5vh' }}>
                                        <Grid style={{ width: '100%' }}>
                                            <Typography style={{ textAlign: 'center', color: '#ffffff', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Farmer Profiles</Typography>


                                        </Grid>
                                    </Grid>




                                </Grid>
                                <Grid style={{ width: '100%' }}>
                                    <Grid style={{ width: '80%', marginLeft: '5%', marginTop: '1%' }}>
                                        <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Farmer Name:{this.state.farmer_name}</Typography>
                                    </Grid>
                                    <Grid style={{ width: '80%', marginLeft: '5%', marginTop: '1%' }}>
                                        <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Phone Number:{this.state.callnumber}</Typography>
                                    </Grid>
                                    <Grid style={{ width: '80%', marginLeft: '5%', marginTop: '1%' }}>
                                        <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Advisory primary:{this.state.advisory_primary}</Typography>
                                    </Grid>
                                    <Grid style={{ width: '80%', marginLeft: '5%', marginTop: '1%' }}>
                                        <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Advisory Specific Info:{this.state.advisory_specific_info}</Typography>
                                    </Grid>
                                    
                                    <Grid style={{ width: '80%', marginLeft: '5%', marginTop: '1%' }}>
                                        <Typography style={{ color: this.state.farmer_district == null ? 'red' : '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>District:{this.state.farmer_district}</Typography>
                                    </Grid>
                                    <Grid style={{ width: '80%', marginLeft: '5%', marginTop: '1%' }}>
                                        <Typography style={{ color: this.state.farmer_subdistrict == null ? 'red' : '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Sub District:{this.state.farmer_subdistrict}</Typography>
                                    </Grid>
                                    <Grid style={{ width: '80%', marginLeft: '5%', marginTop: '1%' }}>
                                        <Typography style={{ color: this.state.farmer_panchayat == null ? 'red' : '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Panchayat:{this.state.farmer_panchayat}</Typography>
                                    </Grid>
                                    <Grid style={{ width: '80%', marginLeft: '5%', marginTop: '1%' }}>
                                        <Typography style={{ color: this.state.farmer_village == null ? 'red' : '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>village:{this.state.farmer_village}</Typography>
                                    </Grid>
                                    <Grid style={{ width: '80%', marginLeft: '15%', display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button



                                            variant="contained"
                                            size="small"

                                            style={{
                                                color: "#ffffff",
                                                fontFamily: "Roboto-Medium",
                                                borderRadius: "4px",
                                                backgroundColor: "#7DAB1C",
                                                textTransform: 'none',
                                                padding: '1.5%',
                                                fontSize: "14px",
                                                width: '80px',
                                                height: '20px',
                                                marginLeft: '5%'

                                            }}

                                            onClick={(e) => { this.setState({ showedit: true }) }}


                                        >

                                            Edit
                                        </Button>
                                    </Grid>
                                    <div style={{ visibility: 'hidden' }}>
                                        <CountdownCircleTimer
                                            isPlaying={this.state.starttime}
                                            style={{ visibility: 'hidden' }}
                                            duration={5}
                                            size={100}
                                            colors={['#004777', '#F7B801', '#F7B801', 'red']}
                                            colorsTime={[15, 10, 5, 0]}
                                            onComplete={() => {
                                                this.DomainHangup()
                                            }}
                                        >
                                            {({ remainingTime }) => remainingTime}
                                        </CountdownCircleTimer>
                                    </div>
                                </Grid>

                            </Grid>

                        </Grid>
                    </Box>


                </Modal >
                <Modal

                    sx={{ border: 'none' }}




                    open={this.state.outform}

                >


                    <Box
                        style={{
                            position: 'absolute',
                            top: '53%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '95%',
                            height: '84%',

                            outline: 'none',
                            background: '#ffffff',

                            borderRadius: '8px',
                            boxShadow: '0px 0px 12px  rgba(0, 0, 0, 0.4)',

                        }}
                    >
                        <Grid style={{ width: '100%', display: 'flex' }}>

                            <Grid container style={{ width: '25%', borderRight: '1px solid #043068', padding: '2%', }}>
                                <Grid style={{ width: '100%', background: '#043068', borderRadius: '4px', padding: '15.5px', height: '11vh' }}>
                                    <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <Grid style={{ width: '70%', paddingLeft: '4%' }}>
                                            <Typography style={{ color: '#ffffff', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Outcoming Call</Typography>
                                            <Typography style={{ color: '#ffffff', fontSize: '16px', fontFamily: 'Roboto-Regular' }}>{this.state.callnumber}</Typography>
                                        </Grid>
                                        <Grid style={{ width: '30%' }}>
                                            <Typography style={{ color: '#ffffff', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Duration</Typography>
                                            <ReactTimerStopwatch isOn={this.state.starttime} watchType="stopwatch"
                                                displayCricle={true} fromTime={fromTime}


                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <Grid class="fake-legend1" style={{ zIndex: 1, marginTop: '-20px' }}>
                                            <img src="ui-assests/images/phone.svg" width='68px' height="26px"
                                                alt="login" style={{ background: '#043068', zIndex: 3, marginTop: '14px' }} />

                                        </Grid>
                                    </Grid>
                                </Grid>


                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <Grid style={{ width: '95%', marginTop: '3%' }}>
                                        <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Transfer calls</Typography>
                                        <Select value={this.state.transfervalue}
                                            displayEmpty
                                            variant="outlined"

                                            size="small"
                                            fullWidth

                                            style={{ height: '42px', }}

                                            onChange={e => {

                                                this.handelTransferOption(e)

                                            }}

                                        >
                                            <MenuItem disabled value={'0'}>Select</MenuItem>
                                            <MenuItem value={'Transfer to IVR'}>Transfer to IVR</MenuItem>
                                            <MenuItem value={'Transfer to Expert'}>Transfer to Expert</MenuItem>
                                            <MenuItem value={'Transfer to other Domain'}>Transfer to other Domain</MenuItem>



                                            {this.state.user_domain1 == "lcc-default" ? <MenuItem value={'Transfer to VOTI'}>Transfer to VOTI</MenuItem> : ''}



                                        </Select>

                                        {
                                            this.state.transferpagevalue == '1' &&
                                            <div style={{ marginTop: '3%' }}>
                                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>{this.state.transferpagetitel}</Typography>
                                                <Select value={this.state.ivrname}
                                                    displayEmpty
                                                    variant="outlined"

                                                    size="small"
                                                    fullWidth

                                                    style={{ height: '42px', }}

                                                    onChange={e => {

                                                        this.setState({ ivrname: e.target.value })

                                                    }}

                                                >
                                                    <MenuItem disabled value={'0'}>Select</MenuItem>
                                                    <MenuItem value={'1'}>IVR-1</MenuItem>
                                                    <MenuItem value={'2'}>IVR-2</MenuItem>
                                                    <MenuItem value={'3'}>IVR-3</MenuItem>




                                                </Select>
                                            </div>
                                        }
                                        {
                                            this.state.transferpagevalue == '2' &&
                                            <div style={{ marginTop: '3%' }}>
                                                <RadioGroup
                                                    row
                                                    defaultValue="Internal"
                                                    value={this.state.expertoption}


                                                    onChange={e =>
                                                        this.handelExpertoption(e)


                                                    }
                                                >
                                                    <FormControlLabel value="Internal" control={<Radioheckbox size='small' style={{ fontSize: '21px', }} />} label={<Typography style={{ color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Internal</Typography>} />

                                                    <FormControlLabel value="External" control={<Radioheckbox size='small' style={{}} />} label={<Typography style={{ color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>External</Typography>} />







                                                </RadioGroup>
                                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>{this.state.transferpagetitel}</Typography>
                                                <Select value={this.state.dominname}
                                                    displayEmpty
                                                    variant="outlined"

                                                    size="small"
                                                    fullWidth

                                                    style={{ height: '42px', }}

                                                    onChange={e => {

                                                        this.setState({ dominname: e.target.value })

                                                    }}

                                                >
                                                    <MenuItem disabled value={'0'}>Select</MenuItem>


                                                    <MenuItem value={'AG'}>Name-1</MenuItem>
                                                    <MenuItem value={'AH'}>Name-2</MenuItem>
                                                    <MenuItem value={'BK'}>Name-3</MenuItem>





                                                </Select>
                                            </div>
                                        }
                                        {
                                            this.state.transferpagevalue == '3' &&
                                            <div style={{ marginTop: '3%' }}>
                                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>{this.state.transferpagetitel}</Typography>
                                                <Select value={this.state.dominname}
                                                    displayEmpty
                                                    variant="outlined"

                                                    size="small"
                                                    fullWidth

                                                    style={{ height: '42px', }}

                                                    onChange={e => {

                                                        this.setState({ dominname: e.target.value })

                                                    }}

                                                >
                                                    <MenuItem disabled value={'0'}>Select</MenuItem>


                                                    <MenuItem value={'1000000000'} style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Agriculture</MenuItem>
                                                    <MenuItem value={'2000000000'} style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Animal Husbandry</MenuItem>
                                                    <MenuItem value={'9196'} style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Abusive</MenuItem>
                                                    <MenuItem value={'3000000000'} style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Fishery</MenuItem>



                                                </Select>


                                            </div>
                                        }
                                        {
                                            this.state.transferpagevalue == '4' &&
                                            <div style={{ marginTop: '3%' }}>
                                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>{this.state.transferpagetitel}</Typography>
                                                <Select value={this.state.ivrname}
                                                    displayEmpty
                                                    variant="outlined"

                                                    size="small"
                                                    fullWidth

                                                    style={{ height: '42px', }}

                                                    onChange={e => {

                                                        this.setState({ ivrname: e.target.value })

                                                    }}

                                                >
                                                    <MenuItem disabled value={'0'}>Select</MenuItem>
                                                    <MenuItem value={'6742913962'}>VOIT1-3962</MenuItem>
                                                    <MenuItem value={'6742577965'}>VOIT2-7965</MenuItem>
                                                    <MenuItem value={'6742912962'}>VOIT3-2962</MenuItem>


                                                </Select>
                                            </div>
                                        }
                                        <Grid style={{ width: '100%', marginTop: '15%', display: 'flex' }}>

                                            <Grid className={this.state.callbutt_st ? classes.callbutt1 : classes.callbutt} style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}

                                                onClick={(e) => { this.handelResumeCall() }}

                                            ><MySvg fill={this.state.callbutt_st ? '#043068' : '#999999'} />
                                                <Typography style={{ marginLeft: '8%', color: this.state.callbutt_st ? '#043068' : '#999999', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Resume Call</Typography>

                                            </Grid>
                                            <Grid className={this.state.callbutt_st1 ? classes.callbutt1 : classes.callbutt} style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}

                                                onMouseLeave={e =>
                                                    this.setState({ callbutt_st1: false })
                                                }
                                                onMouseOver={e =>

                                                    this.setState({ callbutt_st1: true })
                                                }
                                                onClick={(e) => { this.handelMOH() }}
                                            ><Mymusic fill={this.state.callbutt_st1 ? '#043068' : '#999999'} />
                                                <Typography style={{ marginLeft: '8%', color: this.state.callbutt_st1 ? '#043068' : '#999999', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Pause</Typography>

                                            </Grid>


                                        </Grid>
                                        <Grid style={{ width: '100%', marginTop: '25%', display: 'flex' }}>


                                            <Button



                                                variant="contained"
                                                size="small"
                                                disabled={!this.state.trafbutst}
                                                style={{
                                                    color: "#ffffff",
                                                    fontFamily: "Roboto-Medium",
                                                    borderRadius: "4px",
                                                    backgroundColor: this.state.trafbutst ? "#7DAB1C" : "#999999",
                                                    textTransform: 'none',
                                                    padding: '1.5%',
                                                    fontSize: "14px",
                                                    width: '100px',
                                                    height: '32px',
                                                    marginLeft: '5%'

                                                }}

                                                onClick={(e) => { this.handelCallTransfer() }}


                                            >

                                                Transfer
                                            </Button>


                                        </Grid>
                                    </Grid>
                                </Grid>


                                {/*   <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>

                                    <Grid style={{ width: '95%', marginTop: '3%' }}>



                                        <Grid style={{ width: '100%', marginTop: '15%', display: 'flex' }}>

                                            <Grid className={this.state.callbutt_st ? classes.callbutt1 : classes.callbutt} style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}

                                                onClick={(e) => { this.handelResumeCall() }}

                                            ><MySvg fill={this.state.callbutt_st ? '#043068' : '#999999'} />
                                                <Typography style={{ marginLeft: '8%', color: this.state.callbutt_st ? '#043068' : '#999999', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Resume Call</Typography>

                                            </Grid>
                                            <Grid className={this.state.callbutt_st1 ? classes.callbutt1 : classes.callbutt} style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}

                                                onMouseLeave={e =>
                                                    this.setState({ callbutt_st1: false })
                                                }
                                                onMouseOver={e =>

                                                    this.setState({ callbutt_st1: true })
                                                }
                                                onClick={(e) => { this.handelMOH() }}
                                            ><Mymusic fill={this.state.callbutt_st1 ? '#043068' : '#999999'} />
                                                <Typography style={{ marginLeft: '8%', color: this.state.callbutt_st1 ? '#043068' : '#999999', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Pause</Typography>

                                            </Grid>


                                        </Grid>

                                    </Grid>
                                            </Grid>*/}

                            </Grid>
                            {/* Dy form*/}
                            <Grid style={{ width: '55%' }}>
                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                                    <Grid style={{ width: '95%', justifyContent: 'center', background: '#F0F6ED', borderRadius: '4px', padding: '1%' }}>

                                        <Typography style={{ textAlign: 'center', color: '#333333', fontSize: '18px', fontFamily: 'Roboto-Regular' }}>Please fill bellow form with caller details</Typography>
                                        <Typography style={{ textAlign: 'center', color: '#333333', fontSize: '18px', fontFamily: 'Roboto-Bold' }}>{this.state.newmsg}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid style={{ width: '100%', display: 'flex', paddingTop: '10px' }}>
                                    <Grid style={{ width: '45%', marginLeft: '1%' }}>


                                        <Select value={this.state.callstatus}
                                            displayEmpty
                                            variant="outlined"

                                            size="small"
                                            fullWidth

                                            style={{ height: '42px', }}

                                            onChange={e => {

                                                this.handelCallStatus(e)

                                            }}

                                        >
                                            <MenuItem disabled value={'0'}>Call Status</MenuItem>
                                            <MenuItem value={'Profile Complete'}>Profile Complete</MenuItem>
                                            <MenuItem value={'Wrong Number'}>Wrong Number</MenuItem>
                                            <MenuItem value={'Switched Off'}>Switched Off</MenuItem>
                                            <MenuItem value={'Not Reachable'}>Not Reachable</MenuItem>
                                            <MenuItem value={'Answered'}>Answered</MenuItem>

                                            <MenuItem value={'No Answer'}>No Answer</MenuItem>

                                            <MenuItem value={'Disconnect Number'}>Disconnect Number</MenuItem>
                                            <MenuItem value={'Invalid Number'}>Invalid Number</MenuItem>
                                            <MenuItem value={'Respondent has no phone'}>Respondent has no phone</MenuItem>
                                            <MenuItem value={'Not Interested'}>Not Interested</MenuItem>
                                            <MenuItem value={'Respondent Died'}>Respondent Died</MenuItem>
                                            <MenuItem value={'Dialect Issue'}>Dialect Issue</MenuItem>
                                            <MenuItem value={'Call Back'}>Call Back</MenuItem>
                                            <MenuItem value={'Number Busy'}>Number Busy</MenuItem>
                                            <MenuItem value={'Inprocess requested call later'}>Inprocess requested call later</MenuItem>
                                            <MenuItem value={'Incomplete Withdraw Consent'}>Incomplete Withdraw Consent</MenuItem>

                                            <MenuItem value={'Not a Farmer'}>Not a Farmer</MenuItem>
                                            <MenuItem value={'Wrong respondent but a farmer'}>Wrong respondent but a farmer</MenuItem>
                                            <MenuItem value={'Not available during working hours'}>Not available during working hours</MenuItem>
                                            <MenuItem value={'Already profiled'}>Already profiled</MenuItem>




                                        </Select>





                                    </Grid>
                                    <Grid style={{ width: '45%', marginLeft: '3%' }}>
                                        <CssTextField focusColor="#999999"


                                            fullWidth
                                            inputProps={{
                                                style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                            }}
                                            size="small"

                                            variant="outlined"
                                            placeholder="Enter comment"
                                            value={this.state.comment}

                                            onChange={e => {

                                                this.setState({ comment: e.target.value });

                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid style={{ width: '100%', display: 'flex', marginTop: '2%', padding: '1%', height: '55vh' }}>




                                    {
                                        this.state.callformsubmit_type === 0 &&
                                        this.state.showdropdown == "1" &&

                                        this.state.showans == true &&

                                        <div style={{ width: '100%', display: 'flex', height: 'auto', overflowX: 'auto' }}>
                                            <FormContext.Provider value={{ handleJsonChange1 }}>
                                                <form style={{ width: '90%' }}>


                                                    {fields ? fields.map((field, i) =>
                                                        <Grid style={{ marginTop: '1%', width: '100%' }}>
                                                            <Element key={i} field={field} />
                                                        </Grid>

                                                    ) : null}


                                                </form>
                                            </FormContext.Provider>
                                        </div>
                                    }


                                </Grid>
                                <Grid style={{ width: '85%', display: 'flex', justifyContent: 'flex-end', marginBottom: '4%' }}>


                                    <Button



                                        variant="contained"
                                        size="small"

                                        style={{
                                            color: this.state.hangupbutst ? '#ffffff' : "#ffffff",
                                            fontFamily: "Roboto-Medium",
                                            borderRadius: "4px",
                                            backgroundColor: this.state.hangupbutst ? '#F74542' : "#F74542",
                                            textTransform: 'none',
                                            padding: '1.5%',
                                            fontSize: "14px",
                                            width: '100px',
                                            height: '32px',


                                        }}
                                        onClick={(e) => { this.myHangUp() }}


                                    >

                                        Hangup
                                    </Button>
                                    <Button



                                        variant="contained"
                                        size="small"
                                        disabled={this.state.submitbutstatus}
                                        style={{
                                            color: this.state.submitbutstatus ? '#999999' : "#ffffff",
                                            fontFamily: "Roboto-Medium",
                                            borderRadius: "4px",
                                            backgroundColor: this.state.submitbutstatus ? '#E7EAF0' : "#7DAB1C",
                                            textTransform: 'none',
                                            padding: '1.5%',
                                            fontSize: "14px",
                                            width: '100px',
                                            height: '32px',
                                            marginLeft: '5%',
                                            visibility: this.state.savebutvis ? 'visible' : 'hidden'
                                        }}

                                        onClick={(e) => { this.formOutSubmit("pending") }}


                                    >

                                        Save
                                    </Button>
                                    <Button



                                        variant="contained"
                                        size="small"
                                        disabled={this.state.submitbutstatus}
                                        style={{
                                            color: this.state.submitbutstatus ? '#999999' : "#ffffff",
                                            fontFamily: "Roboto-Medium",
                                            borderRadius: "4px",
                                            backgroundColor: this.state.submitbutstatus ? '#E7EAF0' : "#7DAB1C",
                                            textTransform: 'none',
                                            padding: '1.5%',
                                            fontSize: "14px",
                                            width: '100px',
                                            height: '32px',
                                            marginLeft: '5%'

                                        }}

                                        onClick={(e) => { this.formOutSubmit("complete") }}


                                    >

                                        Submit
                                    </Button>


                                </Grid>

                            </Grid>
                            <Grid style={{ width: '25%', borderLeft: '1px solid #043068', marginTop: '2%' }}>
                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                                    <Grid style={{ width: '80%', background: '#043068', borderRadius: '4px', padding: '15.5px', height: '5vh' }}>
                                        <Grid style={{ width: '100%' }}>
                                            <Typography style={{ textAlign: 'center', color: '#ffffff', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Farmer Profiles</Typography>


                                        </Grid>
                                    </Grid>




                                </Grid>
                                <Grid style={{ width: '100%' }}>
                                    <Grid style={{ width: '80%', marginLeft: '5%', marginTop: '1%' }}>
                                        <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Farmer Name:{this.state.farmer_name}</Typography>
                                    </Grid>
                                    <Grid style={{ width: '80%', marginLeft: '5%', marginTop: '1%' }}>
                                        <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Phone Number:{this.state.callnumber}</Typography>
                                    </Grid>
                                    <Grid style={{ width: '80%', marginLeft: '5%', marginTop: '1%' }}>
                                        <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Advisory primary:{this.state.advisory_primary}</Typography>
                                    </Grid>
                                    <Grid style={{ width: '80%', marginLeft: '5%', marginTop: '1%' }}>
                                        <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Advisory Specific Info:{this.state.advisory_specific_info}</Typography>
                                    </Grid>
                                    

                                    <Grid style={{ width: '80%', marginLeft: '5%', marginTop: '1%' }}>
                                        <Typography style={{ color: this.state.farmer_district == null ? 'red' : '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>District:{this.state.farmer_district}</Typography>
                                    </Grid>
                                    <Grid style={{ width: '80%', marginLeft: '5%', marginTop: '1%' }}>
                                        <Typography style={{ color: this.state.farmer_subdistrict == null ? 'red' : '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Sub District:{this.state.farmer_subdistrict}</Typography>
                                    </Grid>
                                    <Grid style={{ width: '80%', marginLeft: '5%', marginTop: '1%' }}>
                                        <Typography style={{ color: this.state.farmer_panchayat == null ? 'red' : '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Panchayat:{this.state.farmer_panchayat}</Typography>
                                    </Grid>
                                    <Grid style={{ width: '80%', marginLeft: '5%', marginTop: '1%' }}>
                                        <Typography style={{ color: this.state.farmer_village == null ? 'red' : '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>village:{this.state.farmer_village}</Typography>
                                    </Grid>
                                    <Grid style={{ width: '80%', marginLeft: '15%', display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button



                                            variant="contained"
                                            size="small"

                                            style={{
                                                color: "#ffffff",
                                                fontFamily: "Roboto-Medium",
                                                borderRadius: "4px",
                                                backgroundColor: "#7DAB1C",
                                                textTransform: 'none',
                                                padding: '1.5%',
                                                fontSize: "14px",
                                                width: '80px',
                                                height: '20px',
                                                marginLeft: '5%'

                                            }}

                                            onClick={(e) => { this.setState({ showedit: true }) }}


                                        >

                                            Edit
                                        </Button>
                                    </Grid>
                                </Grid>

                            </Grid>

                        </Grid>
                    </Box>


                </Modal >
                <Modal

                    sx={{ border: 'none' }}




                    open={this.state.campform}

                >


                    <Box
                        style={{
                            position: 'absolute',
                            top: '53%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '95%',
                            height: '83%',

                            outline: 'none',
                            background: '#ffffff',

                            borderRadius: '8px',
                            boxShadow: '0px 0px 12px  rgba(0, 0, 0, 0.4)',

                        }}
                    >
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>


                            {/* Dy form*/}
                            <Grid style={{ width: '98%', }}>
                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                                    <Grid style={{ width: '95%', background: '#F0F6ED', borderRadius: '4px', padding: '1%' }}>

                                        <Grid style={{ width: '100%', }}>
                                            <Typography style={{ textAlign: 'center', color: '#333333', fontSize: '18px', fontFamily: 'Roboto-Bold' }}>Please fill up the below questionnaires related to:{this.state.page_label}</Typography>
                                        </Grid>
                                        <Grid style={{ width: '100%', display: 'flex' }}>
                                            <Grid style={{ width: '33%' }}>

                                                {editprofile === 0 &&
                                                    <Typography style={{ color: '#333333', fontSize: '18px', fontFamily: 'Roboto-Regular' }}>Name: {"  "} {this.state.farmer_name}</Typography>
                                                }
                                                {editprofile === 1 &&
                                                    <div style={{ display: 'flex', width: '100%' }}>
                                                        <div style={{ width: '30%' }}>
                                                            <Typography style={{ color: '#333333', fontSize: '18px', fontFamily: 'Roboto-Regular' }}>Name: {"  "} </Typography>
                                                        </div>
                                                        <div style={{ width: '70%' }}>
                                                            <CssTextField focusColor="#999999"

                                                                inputProps={{
                                                                    style: { width: '225px', maxLength: 10, fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '15px' },
                                                                }}
                                                                size="small"
                                                                autoFocus
                                                                variant="outlined"
                                                                placeholder="Enter Farmer Name"
                                                                value={this.state.farmer_name}
                                                                onChange={e => {

                                                                    this.setState({ farmer_name: e.target.value });

                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                }

                                                <Typography style={{ color: '#333333', fontSize: '18px', fontFamily: 'Roboto-Regular' }}>Farmer Number: {"  "} {this.state.callnumber}</Typography>
                                            </Grid>
                                            <Grid style={{ width: '33%' }}>
                                                {editprofile === 0 &&
                                                    <Typography style={{ color: '#333333', fontSize: '18px', fontFamily: 'Roboto-Regular' }}>District: {"  "}{this.state.farmer_district}</Typography>
                                                }
                                                {editprofile === 1 &&
                                                    <div style={{ display: 'flex', width: '100%' }}>
                                                        <div style={{ width: '30%' }}>
                                                            <Typography style={{ color: '#333333', fontSize: '18px', fontFamily: 'Roboto-Regular' }}>District: {"  "}</Typography>
                                                        </div>
                                                        <div style={{ width: '70%' }}>

                                                            <Select1


                                                                options={
                                                                    this.state.distlist.map((guest, index) => {
                                                                        return {
                                                                            label: guest.district,
                                                                            value: guest.district,
                                                                            key: index
                                                                        }
                                                                    })
                                                                }

                                                                onChange={e => {
                                                                    this.GetSubDist1(e)


                                                                }}
                                                            >
                                                            </Select1>


                                                        </div>
                                                    </div>
                                                }

                                                {editprofile === 0 &&
                                                    <Typography style={{ color: '#333333', fontSize: '18px', fontFamily: 'Roboto-Regular' }}>Sub district:{"  "}  {this.state.farmer_subdistrict}</Typography>
                                                }
                                                {editprofile === 1 &&
                                                    <div style={{ display: 'flex', width: '100%', marginTop: '5px' }}>
                                                        <div style={{ width: '30%' }}>
                                                            <Typography style={{ color: '#333333', fontSize: '18px', fontFamily: 'Roboto-Regular' }}>Sub district:{"  "}  </Typography>
                                                        </div>
                                                        <div style={{ width: '70%' }}>

                                                            <Select1


                                                                options={
                                                                    this.state.subdistlist.map((guest, index) => {
                                                                        return {
                                                                            label: guest.sub_dist,
                                                                            value: guest.sub_dist,
                                                                            key: index
                                                                        }
                                                                    })
                                                                }

                                                                onChange={e => {
                                                                    this.setState({ farmer_subdistrict: e.value })


                                                                }}
                                                            >
                                                            </Select1>

                                                        </div>
                                                    </div>
                                                }

                                            </Grid>

                                            <Grid style={{ width: '33%' }}>
                                                {editprofile === 0 &&
                                                    <Typography style={{ color: '#333333', fontSize: '18px', fontFamily: 'Roboto-Regular' }}>Panchayat : {"  "} {this.state.farmer_panchayat}</Typography>
                                                }
                                                {editprofile === 1 &&
                                                    <div style={{ display: 'flex', width: '100%' }}>
                                                        <div style={{ width: '30%' }}>
                                                            <Typography style={{ color: '#333333', fontSize: '18px', fontFamily: 'Roboto-Regular' }}>Panchayat :</Typography>
                                                        </div>
                                                        <div style={{ width: '70%' }}>
                                                            <CssTextField focusColor="#999999"

                                                                inputProps={{
                                                                    style: { maxLength: 10, fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '15px', width: '225px', marginLeft: '5px' },
                                                                }}
                                                                size="small"

                                                                variant="outlined"
                                                                placeholder="Enter Farmer Panchayat"
                                                                value={this.state.farmer_panchayat}
                                                                onChange={e => {

                                                                    this.setState({ farmer_panchayat: e.target.value });

                                                                }}
                                                            />
                                                        </div>

                                                    </div>
                                                }
                                                {editprofile === 0 &&
                                                    <Typography style={{ color: '#333333', fontSize: '18px', fontFamily: 'Roboto-Regular' }}>village : {"  "} {this.state.farmer_village}</Typography>
                                                }
                                                {editprofile === 1 &&
                                                    <div style={{ display: 'flex', width: '100%', marginTop: '5px' }}>
                                                        <div style={{ width: '30%' }}>
                                                            <Typography style={{ color: '#333333', fontSize: '18px', fontFamily: 'Roboto-Regular' }}>village : {"  "} </Typography>
                                                        </div>
                                                        <div style={{ width: '70%' }}>
                                                            <CssTextField focusColor="#999999"

                                                                inputProps={{
                                                                    style: { maxLength: 10, fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '15px', width: '225px', marginLeft: '5px', marginTop: '5px' },
                                                                }}
                                                                size="small"

                                                                variant="outlined"
                                                                placeholder="Enter Farmer Village"
                                                                value={this.state.farmer_village}
                                                                onChange={e => {

                                                                    this.setState({ farmer_village: e.target.value });

                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                }

                                            </Grid>

                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Grid style={{ width: '90%', display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                                    <Grid style={{ width: '45%', marginLeft: '1%' }}>


                                        <Select value={this.state.callstatus}
                                            displayEmpty
                                            variant="outlined"

                                            size="small"
                                            fullWidth

                                            style={{ height: '42px', }}

                                            onChange={e => {

                                                this.handelCallStatus(e)

                                            }}



                                        >

                                            <MenuItem disabled value={'0'}>Call Status</MenuItem>
                                            <MenuItem value={'Profile Complete'}>Profile Complete</MenuItem>
                                            <MenuItem value={'Wrong Number'}>Wrong Number</MenuItem>
                                            <MenuItem value={'Switched Off'}>Switched Off</MenuItem>
                                            <MenuItem value={'Not Reachable'}>Not Reachable</MenuItem>
                                            <MenuItem value={'Answered'}>Answered</MenuItem>

                                            <MenuItem value={'No Answer'}>No Answer</MenuItem>

                                            <MenuItem value={'Disconnect Number'}>Disconnect Number</MenuItem>
                                            <MenuItem value={'Invalid Number'}>Invalid Number</MenuItem>
                                            <MenuItem value={'Respondent has no phone'}>Respondent has no phone</MenuItem>
                                            <MenuItem value={'Not Interested'}>Not Interested</MenuItem>
                                            <MenuItem value={'Respondent Died'}>Respondent Died</MenuItem>
                                            <MenuItem value={'Dialect Issue'}>Dialect Issue</MenuItem>
                                            <MenuItem value={'Call Back'}>Call Back</MenuItem>
                                            <MenuItem value={'Number Busy'}>Number Busy</MenuItem>
                                            <MenuItem value={'Inprocess requested call later'}>Inprocess requested call later</MenuItem>
                                            <MenuItem value={'Incomplete Withdraw Consent'}>Incomplete Withdraw Consent</MenuItem>

                                            <MenuItem value={'Not a Farmer'}>Not a Farmer</MenuItem>
                                            <MenuItem value={'Wrong respondent but a farmer'}>Wrong respondent but a farmer</MenuItem>
                                            <MenuItem value={'Not available during working hours'}>Not available during working hours</MenuItem>
                                            <MenuItem value={'Already profiled'}>Already profiled</MenuItem>




                                        </Select>





                                    </Grid>
                                    <Grid style={{ width: '45%', marginLeft: '3%' }}>
                                        <CssTextField focusColor="#999999"


                                            fullWidth
                                            inputProps={{
                                                style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                            }}
                                            size="small"

                                            variant="outlined"
                                            placeholder="Enter comment"
                                            value={this.state.comment}

                                            onChange={e => {

                                                this.setState({ comment: e.target.value });

                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid style={{ width: '98%', display: 'flex', marginTop: '2%', padding: '1%', height: '40vh' }}>




                                    {
                                        this.state.callformsubmit_type === 0 &&
                                        this.state.showdropdown == "1" &&

                                        this.state.showans == true &&

                                        <div style={{ width: '100%', display: 'flex', overflowX: 'auto' }}>
                                            <FormContext.Provider value={{ handleJsonChange1 }}>
                                                <form style={{ width: '95%' }}>


                                                    {fields ? fields.map((field, i) =>
                                                        <Grid style={{ marginTop: '2%', width: '100%' }}>
                                                            <Element key={i} field={field} />
                                                        </Grid>

                                                    ) : null}


                                                </form>
                                            </FormContext.Provider>
                                        </div>
                                    }


                                </Grid>
                                <Grid style={{ width: '85%', display: 'flex', justifyContent: 'flex-end', marginBottom: '4%' }}>


                                    <Button



                                        variant="contained"
                                        size="small"

                                        style={{
                                            color: this.state.hangupbutst ? '#ffffff' : "#ffffff",
                                            fontFamily: "Roboto-Medium",
                                            borderRadius: "4px",
                                            backgroundColor: this.state.hangupbutst ? '#F74542' : "#F74542",
                                            textTransform: 'none',
                                            padding: '1.5%',
                                            fontSize: "14px",
                                            width: '100px',
                                            height: '32px',


                                        }}
                                        onClick={(e) => { this.myHangUp() }}


                                    >

                                        Hangup
                                    </Button>
                                    <Button



                                        variant="contained"
                                        size="small"
                                        disabled={this.state.submitbutstatus}
                                        style={{
                                            color: this.state.submitbutstatus ? '#999999' : "#ffffff",
                                            fontFamily: "Roboto-Medium",
                                            borderRadius: "4px",
                                            backgroundColor: this.state.submitbutstatus ? '#E7EAF0' : "#7DAB1C",
                                            textTransform: 'none',
                                            padding: '1.5%',
                                            fontSize: "14px",
                                            width: '100px',
                                            height: '32px',
                                            marginLeft: '5%',
                                            visibility: this.state.savebutvis ? 'visible' : 'hidden'
                                        }}

                                        onClick={(e) => { this.formOutSubmit("pending") }}


                                    >

                                        Save
                                    </Button>
                                    <Button



                                        variant="contained"
                                        size="small"
                                        disabled={this.state.submitbutstatus}
                                        style={{
                                            color: this.state.submitbutstatus ? '#999999' : "#ffffff",
                                            fontFamily: "Roboto-Medium",
                                            borderRadius: "4px",
                                            backgroundColor: this.state.submitbutstatus ? '#E7EAF0' : "#7DAB1C",
                                            textTransform: 'none',
                                            padding: '1.5%',
                                            fontSize: "14px",
                                            width: '100px',
                                            height: '32px',
                                            marginLeft: '5%'

                                        }}

                                        onClick={(e) => { this.formOutSubmit("complete") }}


                                    >

                                        Submit
                                    </Button>


                                </Grid>

                            </Grid>


                        </Grid>
                    </Box>


                </Modal >
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "Roboto-Bold"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.shownofity}
                    autoHideDuration={2000}
                    onClose={() => this.closeContactModal1()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "Roboto-Bold"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ shownofity: false }) }}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
                <Modal
                    sx={{ border: 'none' }}




                    open={this.state.showsiperror}





                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '45%',
                            height: '65%',
                            background: '#fffbee',
                            outline: 'none',
                            p: 4,
                            borderRadius: '12px',
                            padding: '2%'
                        }}
                    >

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton
                                aria-label="close"

                                onClick={() => this.setState({ showsiperror: false })}
                            >
                                <CloseIcon />
                            </IconButton>

                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>

                            <Button



                                variant="contained"
                                size="small"

                                style={{
                                    color: "#ffffff",
                                    fontFamily: "Roboto-Medium",
                                    borderRadius: "4px",
                                    backgroundColor: "#7DAB1C",
                                    textTransform: 'none',
                                    padding: '1.5%',
                                    fontSize: "14px",
                                    width: '100px',
                                    height: '32px',
                                    marginLeft: '5%'

                                }}

                                onClick={(e) => { this.takebreak(e) }}


                            >

                                Retry
                            </Button>


                        </Grid>
                    </Box>
                </Modal>
                <Modal
                    sx={{ border: 'none' }}




                    open={this.state.showedit}





                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '45%',
                            height: '58%',
                            background: '#ffffff',
                            outline: 'none',
                            p: 4,
                            borderRadius: '12px',
                            padding: '2%'
                        }}
                    >

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>

                            <Grid style={{ width: '90%', display: 'flex', justifyContent: 'center' }}>
                                <Typography style={{ textAlign: 'center', color: '#333333', fontSize: '16px', fontFamily: 'Roboto-Bold' }}>Edit Farmer Profile</Typography>
                            </Grid>
                            <Grid style={{ width: '10%', display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton
                                    aria-label="close"

                                    onClick={() => this.setState({ showedit: false })}
                                >
                                    <CloseIcon />
                                </IconButton>

                            </Grid>
                        </Grid>


                        <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>

                            <Grid style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>
                                <Typography style={{ textAlign: 'center', color: '#333333', fontSize: '16px', fontFamily: 'Roboto-Regular' }}>Farmer Name:</Typography>
                            </Grid>
                            <Grid style={{ width: '60%', marginLeft: '2%' }}>
                                <CssTextField focusColor="#999999"
                                    fullWidth
                                    inputProps={{
                                        style: { height: '10px', maxLength: 10, fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },
                                    }}
                                    size="small"
                                    autoFocus
                                    variant="outlined"
                                    placeholder="Enter Farmer Name"
                                    value={this.state.farmer_name}
                                    onChange={e => {

                                        this.setState({ farmer_name: e.target.value });

                                    }}
                                />

                            </Grid>
                        </Grid>

                        <Grid style={{ marginTop: '2%', width: '100%', display: 'flex', alignItems: 'center' }}>

                            <Grid style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>
                                <Typography style={{ textAlign: 'center', color: '#333333', fontSize: '16px', fontFamily: 'Roboto-Regular' }}>District:</Typography>
                            </Grid>
                            <Grid style={{ width: '60%', marginLeft: '2%' }}>



                                <Select1

                                    defaultValue={{ label: this.state.farmer_district, value: this.state.farmer_district }}

                                    options={
                                        this.state.distlist.map((guest, index) => {
                                            return {
                                                label: guest.district,
                                                value: guest.district,
                                                key: index
                                            }
                                        })
                                    }

                                    onChange={e => {
                                        this.GetSubDist1(e)


                                    }}

                                >

                                </Select1>
                            </Grid>



                        </Grid>
                        <Grid style={{ marginTop: '2%', width: '100%', display: 'flex', alignItems: 'center' }}>

                            <Grid style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>
                                <Typography style={{ textAlign: 'center', color: '#333333', fontSize: '16px', fontFamily: 'Roboto-Regular' }}>Sub District:</Typography>
                            </Grid>
                            <Grid style={{ width: '60%', marginLeft: '2%' }}>
                                <Select1


                                    defaultValue={{ label: this.state.farmer_subdistrict, value: this.state.farmer_subdistrict }}
                                    options={
                                        this.state.subdistlist.map((guest, index) => {
                                            return {
                                                label: guest.sub_dist,
                                                value: guest.sub_dist,
                                                key: index
                                            }
                                        })
                                    }

                                    onChange={e => {
                                        this.setState({ farmer_subdistrict: e.value })


                                    }}
                                >
                                </Select1>

                            </Grid>
                        </Grid>


                        <Grid style={{ marginTop: '2%', width: '100%', display: 'flex', alignItems: 'center' }}>

                            <Grid style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>
                                <Typography style={{ textAlign: 'center', color: '#333333', fontSize: '16px', fontFamily: 'Roboto-Regular' }}>Panchayat:</Typography>
                            </Grid>
                            <Grid style={{ width: '60%', marginLeft: '2%' }}>
                                <CssTextField focusColor="#999999"
                                    fullWidth
                                    inputProps={{
                                        style: { height: '10px', maxLength: 10, fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },
                                    }}
                                    size="small"

                                    variant="outlined"
                                    placeholder="Enter Farmer Panchayat"
                                    value={this.state.farmer_panchayat}
                                    onChange={e => {

                                        this.setState({ farmer_panchayat: e.target.value });

                                    }}
                                />

                            </Grid>
                        </Grid>
                        <Grid style={{ marginTop: '2%', width: '100%', display: 'flex', alignItems: 'center' }}>

                            <Grid style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>
                                <Typography style={{ textAlign: 'center', color: '#333333', fontSize: '16px', fontFamily: 'Roboto-Regular' }}>Village:</Typography>
                            </Grid>
                            <Grid style={{ width: '60%', marginLeft: '2%' }}>
                                <CssTextField focusColor="#999999"
                                    fullWidth
                                    inputProps={{
                                        style: { height: '10px', maxLength: 10, fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },
                                    }}
                                    size="small"

                                    variant="outlined"
                                    placeholder="Enter Farmer Village"
                                    value={this.state.farmer_village}
                                    onChange={e => {

                                        this.setState({ farmer_village: e.target.value });

                                    }}
                                />

                            </Grid>
                        </Grid>
                        <Grid style={{ width: '90%', marginTop: '3%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Button



                                variant="contained"
                                size="small"

                                style={{
                                    color: "#ffffff",
                                    fontFamily: "Roboto-Medium",
                                    borderRadius: "4px",
                                    backgroundColor: "#7DAB1C",
                                    textTransform: 'none',
                                    padding: '1.5%',
                                    fontSize: "14px",
                                    width: '120px',
                                    height: '35px',
                                    marginLeft: '5%'

                                }}

                                onClick={(e) => { this.SetFormerProfile() }}


                            >

                                Save
                            </Button>
                        </Grid>
                    </Box>
                </Modal>

                <Modal
                    sx={{ border: 'none' }}




                    open={this.state.info}





                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '45%',
                            height: '15%',
                            background: '#7f7f7f',
                            outline: 'none',
                            p: 4,

                            padding: '2%'
                        }}
                    >
                        <Grid style={{ width: '100' }}>
                            <Typography style={{ textAlign: 'center', color: '#3E550E', fontSize: '18px', fontFamily: 'Roboto-Bold' }}>{this.state.newmsg}</Typography>
                        </Grid>

                    </Box>
                </Modal>

                <Modal
                    sx={{ border: 'none' }}




                    open={this.state.echotest}





                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '55%',
                            height: 'auto',
                            background: '#ffffff',
                            outline: 'none',
                            p: 4,
                            borderRadius: '8px',
                            padding: '2%',

                        }}
                    >
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <img src="ui-assests/images/echo.svg" width='35px' height="35px"
                                alt="login" ></img>

                            <Typography style={{ marginLeft: '2%', textAlign: 'center', color: '#000000', fontSize: '24px', fontFamily: 'Roboto-Bold' }}>Echo/ Sound Test</Typography>
                        </Grid>

                        {this.state.echotestpage == 2 &&
                            <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '3%' }}>

                                <Grid style={{ width: '95%', padding: '2%', display: 'flex', justifyContent: 'center', border: '1px solid #DFE0EB', borderRadius: '12px' }}>

                                    <Grid style={{ cursor: 'pointer', width: '20%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}

                                        onClick={() => { this.echotest() }}
                                    >
                                        <img src="ui-assests/images/echoplay.svg" width='60px' height="60px"
                                            alt="login" ></img>
                                    </Grid>
                                    <Grid style={{ marginLeft: '3%', width: '75%' }}>
                                        <Grid style={{ width: '100%' }}>
                                            <Typography style={{ color: '#000000', fontSize: '18px', fontFamily: 'Roboto-Bold' }}>Voice Test Results</Typography>
                                        </Grid>

                                        <Grid style={{ width: '100%' }}>
                                            <Typography style={{ color: '#000000', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Echo test has failed! please connect with IT team to get this resolved.</Typography>
                                        </Grid>
                                        <Grid style={{ width: '100%' }}>
                                            <Typography style={{ color: '#000000', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Click on Play button to repeat the voice test.</Typography>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        {this.state.echotestpage == 0 &&
                            <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '3%' }}>

                                <Grid style={{ width: '95%', padding: '2%', display: 'flex', justifyContent: 'center', border: '1px solid #DFE0EB', borderRadius: '12px' }}>

                                    <Grid style={{ cursor: 'pointer', width: '20%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}

                                        onClick={() => { this.echotest() }}
                                    >
                                        <img src="ui-assests/images/echoplay.svg" width='60px' height="60px"
                                            alt="login" ></img>
                                    </Grid>
                                    <Grid style={{ marginLeft: '3%', width: '75%' }}>
                                        <Grid style={{ width: '100%' }}>
                                            <Typography style={{ color: '#000000', fontSize: '18px', fontFamily: 'Roboto-Bold' }}>Voice Test</Typography>
                                        </Grid>

                                        <Grid style={{ width: '100%' }}>
                                            <Typography style={{ color: '#000000', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Please speak and test your voice by pressing Play button</Typography>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        }

                        {this.state.echotestpage == 1 &&
                            <div>
                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '3%' }}>

                                    <Grid style={{ width: '95%', padding: '2%', display: 'flex', justifyContent: 'center', border: '1px solid #DFE0EB', borderRadius: '12px' }}>

                                        <Grid style={{ cursor: 'pointer', width: '20%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}


                                        >
                                            <img src="ui-assests/images/echoplay1.svg" width='60px' height="60px"
                                                alt="login" ></img>
                                        </Grid>
                                        <Grid style={{ marginLeft: '3%', width: '75%' }}>
                                            <Grid style={{ width: '100%' }}>
                                                <Typography style={{ color: '#000000', fontSize: '18px', fontFamily: 'Roboto-Bold' }}>Speak now</Typography>
                                            </Grid>

                                            <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                                                <Grid style={{ width: '16%', background: '#D64A47', height: '1vh' }}>
                                                </Grid>
                                                <Grid style={{ width: '16%', background: '#D67A47', height: '1vh' }}>
                                                </Grid>
                                                <Grid style={{ width: '16%', background: '#D6B747', height: '1vh' }}>
                                                </Grid>
                                                <Grid style={{ width: '16%', background: '#C2D647', height: '1vh' }}>
                                                </Grid>
                                                <Grid style={{ width: '16%', background: '#97D647', height: '1vh' }}>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid style={{ width: '100%', marginTop: '1%' }}>
                                    <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}>Select below option</Typography>
                                </Grid>
                                <Grid style={{ width: '100%', marginTop: '2%', display: 'flex', justifyContent: 'center' }}>
                                    <Grid style={{ width: '80%', display: 'flex', justifyContent: 'space-evenly' }}>
                                        <Button



                                            variant="contained"
                                            size="small"

                                            style={{
                                                color: "#ffffff",
                                                fontFamily: "Roboto-Medium",
                                                borderRadius: "4px",
                                                backgroundColor: "#41AB1C",
                                                textTransform: 'none',
                                                padding: '1.5%',
                                                fontSize: "14px",
                                                width: '250px',
                                                height: '40px',




                                            }}


                                            onClick={() => { this.echotest() }}

                                        >

                                            Clear Voice
                                        </Button>

                                        <Button



                                            variant="contained"
                                            size="small"

                                            style={{
                                                color: "#ffffff",
                                                fontFamily: "Roboto-Medium",
                                                borderRadius: "4px",
                                                backgroundColor: "#F74542",
                                                textTransform: 'none',
                                                padding: '1.5%',
                                                fontSize: "14px",
                                                width: '250px',
                                                height: '40px',




                                            }}


                                            onClick={() => { this.echotest1() }}

                                        >

                                            Unclear Voice
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                    </Box>
                </Modal>
                <Modal
                    sx={{ border: 'none' }}




                    open={this.state.showcamp}





                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '30%',
                            height: 'auto',
                            background: '#fffbee',
                            outline: 'none',
                            p: 4,
                            borderRadius: '12px',
                            padding: '2%'
                        }}
                    >

                        <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>



                            <Grid style={{ width: '90%' }}>
                                <Typography style={{ textAlign: 'center', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Select the Campaign</Typography>
                            </Grid>
                            <Grid style={{ width: '10%' }}>
                                <IconButton
                                    aria-label="close"

                                    onClick={() => this.setState({ showcamp: false })}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>

                        </Grid>
                        <Grid style={{ marginTop: '2%', width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Grid style={{ width: '80%', display: 'flex', justifyContent: 'center' }}>

                                <Select

                                    value={this.state.campid}
                                    fullWidth
                                    inputProps={{
                                        style: { background: '#ffffff', fontSize: '14px', fontFamily: "Roboto-Regular", height: '20px', width: '300px' },


                                    }}

                                    onChange={e => {
                                        this.setState({ campid: e.target.value })
                                    }}

                                >
                                    <MenuItem style={{ padding: '5px', color: '#333333', fontSize: '18px', fontFamily: 'Roboto-Regular' }} value={'0'} >Select</MenuItem>
                                    {this.state.camplist.map(rname =>

                                        <MenuItem style={{ padding: '5px', color: '#333333', fontSize: '18px', fontFamily: 'Roboto-Regular', width: '300px' }} value={rname.value} >{rname.label}</MenuItem>
                                    )};
                                </Select>

                            </Grid>
                        </Grid>

                        <Grid style={{ marginTop: '3%', width: '100%', display: 'flex', justifyContent: 'center' }}>

                            <Button



                                variant="contained"
                                size="small"

                                style={{
                                    color: "#ffffff",
                                    fontFamily: "Roboto-Medium",
                                    borderRadius: "4px",
                                    backgroundColor: "#7DAB1C",
                                    textTransform: 'none',
                                    padding: '1.5%',
                                    fontSize: "14px",
                                    width: '100px',
                                    height: '32px',
                                    marginLeft: '5%'

                                }}

                                onClick={(e) => { this.SubmitCamp() }}


                            >

                                Submit
                            </Button>


                        </Grid>
                    </Box>
                </Modal>
            </div >
        )
    }
}
export default (withStyles(txtstyles)((dashbord)));