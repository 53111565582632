
import React, { Component } from 'react'
import { FormGroup, FormControlLabel, Typography, Grid, Button, Checkbox, Tab, Tabs, TextField, Modal, Box, IconButton } from '@material-ui/core'
import Loader from "react-js-loader";

import { withStyles, styled } from "@material-ui/core/styles";
import DataTable from 'react-data-table-component';
import moment from 'moment'
import { httpRequest } from '../../../ui-utils/api'

import xlsexport from 'xlsexport'
import { createTheme, ThemeProvider, MuiThemeProvider } from "@material-ui/core/styles";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
    KeyboardTimePicker
} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { PlayArrow, Stop } from '@material-ui/icons';
import backendHosts from '../../../ui-utils/apiConfig';

function DistinctRecords(MYJSON, prop) {
    return MYJSON.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    })
}

const defaultMaterialTheme = createTheme({
    palette: {
        primary: {
            light: '#7DAB1C',
            main: '#7DAB1C',
            dark: '#7DAB1C',
            contrastText: '#fff',

        },
        secondary: {
            light: '#7DAB1C',
            main: '#7DAB1C',
            dark: '#7DAB1C',
            contrastText: '#000',
        },
    },
});
const data1 = [
    { title: 'district', value: '' },
    { title: 'subdistrict', value: '' },
    { title: 'domain', value: 'General (Call-center agent)' },
    { title: 'transfer to agri', value: '' },


]
const conditionalRowStyles = [
    {
        when: row => row.account_status == "E",
        style: {
            backgroundColor: 'green',
            color: 'white',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    },

];
const customStyles = {
    title: {
        style: {
            fontSize: '14px', // override the row height
        },
    },
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
        stripedStyle: {

            //  background: 'red'



        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#E9F4FF',
            fontSize: '14px',
            color: '#333333',
            height: '45px',
            fontFamily: 'Roboto-Medium'
        },
    },
    cells: {
        style: {
            paddingLeft: '2px', // override the cell padding for data cells
            paddingRight: '2px',


        },
    },
    highlightOnHoverStyle: {
        style: {
            color: 'red',
            background: 'blue',
            backgroundColor: 'yellow'



        },
    },

};
const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#41AB1C",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 10,
            width: 10,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#41AB1C'
        }
    },
    checked: { color: "#41AB1C", }
}))(Checkbox);
const CssTextField = styled(TextField)({
    borderRadius: '23px',
    fontFamily: 'InterRegular',
    background: '#ffff',

    '& .MuiInput-underline:after': {
        borderBottomColor: '#dddddd',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#dddddd',
        },
        '&:hover fieldset': {
            borderColor: '#dddddd',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#dddddd',
        },
    },
});
let distcolumns = []

function TimeDiff(timestamp1, timestamp2) {
    var timeStart = new Date("01/01/2007 " + timestamp1);
    var timeEnd = new Date("01/01/2007 " + timestamp2);
    var diff_result = 0
    if (timestamp2.length > 3) {
        var difference = timeEnd - timeStart;
        diff_result = new Date(difference);
        diff_result = moment(diff_result).format("hh:mm:ss")
    }

    console.log("***", diff_result)
    return diff_result;
}

export class RawData extends Component {

    constructor(props) {
        super(props)


        this.state = {


            domain: 'General (Call-center agent)',
            fdate: moment().subtract(1, 'days'),
            tdate: moment().subtract(1, 'days'),
            userdata: [],
            domaindata: [],
            userdata1: [],
            filterdata: [],
            stext: '',
            islodding: false,
            t_time: '',
            s_time: '',
            working: true,
            non_working: true,
            short_ca: false
        }



    }

    handledobChange(date) {

        this.setState({ fdate: date })
        this.setState({ userdata: [], userdata1: [] })


    }
    handledobChange1(date) {

        this.setState({ tdate: date })

        this.setState({ userdata: [], userdata1: [] })
    }

    componentDidMount = async () => {

    }

    urldecode(str) {
        console.log("===" + str)
        str = (str + '').toString();
        str = str.replaceAll('%', 'per')
        console.log('****' + str)
        str = decodeURIComponent(str)
        str = str.replaceAll('+', ' ')
       // str = str.replace('*', '%')
        return (str)



    }


    handelfilter(re) {



        let re1 = []

        this.setState({ userdata: [], userdata1: [] })
        if (this.state.working === true && this.state.non_working === false && this.state.short_ca === false) {
            re.map(data => {

                if (data.hrs === "workinghrs" && data.direction !== "short_call")

                    re1.push({
                        id: data.id,
                        uuid: data.uuid,
                        dialledto: data.dialledto,
                        callstatus: data.callstatus,
                        direction: data.direction,
                        callrecived_datetime: data.callrecived_datetime,
                        callduration: data.callduration,
                        farmer_no: data.farmer_no,
                        recordingurl: data.recordingurl,
                        agentanswered: data.agentanswered,
                        operator: data.operator === null ? "" : data.operator,
                        tag1: data.tag1 === null ? "" : data.tag1,
                        tag2: data.tag2 === null ? "" : data.tag2,
                        tag3: data.tag3 === null ? "" : data.tag3,
                        tag4: data.tag4 === null ? "" : data.tag4,
                        tag5: data.tag5 === null ? "" : data.tag5,
                        call_type: data.call_type === null ? "" : data.call_type,
                        farmer_name: data.farmer_name === null ? "" : data.farmer_name,
                        district: data.district === null ? "" : data.district,
                        panchayat: data.panchayat === null ? "" : data.panchayat,
                        village: data.village === null ? "" : data.village,
                        duration_sec: data.duration_sec,
                        hrs: data.hrs,
                        answered: data.answered,
                        comment: data.comment === null ? "" : this.urldecode(data.comment, data.uuid),
                        hangup_cause: data.hangup_cause === null ? "" : data.hangup_cause,
                        sip_dispo: data.sip_dispo === null ? "" : data.sip_dispo,
                    })
            });

        }

        if (this.state.working === true && this.state.non_working === true && this.state.short_ca === false) {

            re.map(data => {

                if (data.hrs === "workinghrs" || data.hrs === "non_workinghrs") {
                    if (data.direction !== "short_call") {
                        re1.push({
                            id: data.id,
                            uuid: data.uuid,
                            dialledto: data.dialledto,
                            callstatus: data.callstatus,
                            direction: data.direction,
                            callrecived_datetime: data.callrecived_datetime,
                            callduration: data.callduration,
                            farmer_no: data.farmer_no,
                            recordingurl: data.recordingurl,
                            agentanswered: data.agentanswered,
                            operator: data.operator === null ? "" : data.operator,
                            tag1: data.tag1 === null ? "" : data.tag1,
                            tag2: data.tag2 === null ? "" : data.tag2,
                            tag3: data.tag3 === null ? "" : data.tag3,
                            tag4: data.tag4 === null ? "" : data.tag4,
                            tag5: data.tag5 === null ? "" : data.tag5,
                            call_type: data.call_type === null ? "" : data.call_type,
                            farmer_name: data.farmer_name === null ? "" : data.farmer_name,
                            district: data.district === null ? "" : data.district,
                            panchayat: data.panchayat === null ? "" : data.panchayat,
                            village: data.village === null ? "" : data.village,
                            duration_sec: data.duration_sec,
                            hrs: data.hrs,
                            answered: data.answered,
                            comment: data.comment === null ? "" : this.urldecode(data.comment, data.uuid),
                            hangup_cause: data.hangup_cause === null ? "" : data.hangup_cause,
                            sip_dispo: data.sip_dispo === null ? "" : data.sip_dispo,

                        })
                    }
                }
            });

        }
        if (this.state.working === true && this.state.non_working === true && this.state.short_ca === true) {
            re.map(data => {


                re1.push({
                    id: data.id,
                    uuid: data.uuid,
                    dialledto: data.dialledto,
                    callstatus: data.callstatus,
                    direction: data.direction,
                    callrecived_datetime: data.callrecived_datetime,
                    callduration: data.callduration,
                    farmer_no: data.farmer_no,
                    recordingurl: data.recordingurl,
                    agentanswered: data.agentanswered,
                    operator: data.operator === null ? "" : data.operator,
                    tag1: data.tag1 === null ? "" : data.tag1,
                    tag2: data.tag2 === null ? "" : data.tag2,
                    tag3: data.tag3 === null ? "" : data.tag3,
                    tag4: data.tag4 === null ? "" : data.tag4,
                    tag5: data.tag5 === null ? "" : data.tag5,
                    call_type: data.call_type === null ? "" : data.call_type,
                    farmer_name: data.farmer_name === null ? "" : data.farmer_name,
                    district: data.district === null ? "" : data.district,
                    panchayat: data.panchayat === null ? "" : data.panchayat,
                    village: data.village === null ? "" : data.village,
                    duration_sec: data.duration_sec,
                    hrs: data.hrs,
                    answered: data.answered,
                    comment: data.comment === null ? "" : this.urldecode(data.comment, data.uuid),
                    hangup_cause: data.hangup_cause === null ? "" : data.hangup_cause,
                    sip_dispo: data.sip_dispo === null ? "" : data.sip_dispo,

                })

            });

        }
        if (this.state.working === true && this.state.non_working === false && this.state.short_ca === true) {
            re.map(data => {

                if (data.hrs === "workinghrs" && data.direction === "short_call") {

                    re1.push({
                        id: data.id,
                        uuid: data.uuid,
                        dialledto: data.dialledto,
                        callstatus: data.callstatus,
                        direction: data.direction,
                        callrecived_datetime: data.callrecived_datetime,
                        callduration: data.callduration,
                        farmer_no: data.farmer_no,
                        recordingurl: data.recordingurl,
                        agentanswered: data.agentanswered,
                        operator: data.operator === null ? "" : data.operator,
                        tag1: data.tag1 === null ? "" : data.tag1,
                        tag2: data.tag2 === null ? "" : data.tag2,
                        tag3: data.tag3 === null ? "" : data.tag3,
                        tag4: data.tag4 === null ? "" : data.tag4,
                        tag5: data.tag5 === null ? "" : data.tag5,
                        call_type: data.call_type === null ? "" : data.call_type,
                        farmer_name: data.farmer_name === null ? "" : data.farmer_name,
                        district: data.district === null ? "" : data.district,
                        panchayat: data.panchayat === null ? "" : data.panchayat,
                        village: data.village === null ? "" : data.village,
                        duration_sec: data.duration_sec,
                        hrs: data.hrs,
                        answered: data.answered,
                        comment: data.comment === null ? "" : this.urldecode(data.comment, data.uuid),
                        hangup_cause: data.hangup_cause === null ? "" : data.hangup_cause,
                        sip_dispo: data.sip_dispo === null ? "" : data.sip_dispo,

                    })
                }

            });

        }
        if (this.state.working === false && this.state.non_working === true && this.state.short_ca === true) {
            re.map(data => {

                if (data.hrs === "non_workinghrs" && data.direction === "short_call") {

                    re1.push({
                        id: data.id,
                        uuid: data.uuid,
                        dialledto: data.dialledto,
                        callstatus: data.callstatus,
                        direction: data.direction,
                        callrecived_datetime: data.callrecived_datetime,
                        callduration: data.callduration,
                        farmer_no: data.farmer_no,
                        recordingurl: data.recordingurl,
                        agentanswered: data.agentanswered,
                        operator: data.operator === null ? "" : data.operator,
                        tag1: data.tag1 === null ? "" : data.tag1,
                        tag2: data.tag2 === null ? "" : data.tag2,
                        tag3: data.tag3 === null ? "" : data.tag3,
                        tag4: data.tag4 === null ? "" : data.tag4,
                        tag5: data.tag5 === null ? "" : data.tag5,
                        call_type: data.call_type === null ? "" : data.call_type,
                        farmer_name: data.farmer_name === null ? "" : data.farmer_name,
                        district: data.district === null ? "" : data.district,
                        panchayat: data.panchayat === null ? "" : data.panchayat,
                        village: data.village === null ? "" : data.village,
                        duration_sec: data.duration_sec,
                        hrs: data.hrs,
                        answered: data.answered,
                        comment: data.comment === null ? "" : this.urldecode(data.comment, data.uuid),
                        hangup_cause: data.hangup_cause === null ? "" : data.hangup_cause,
                        sip_dispo: data.sip_dispo === null ? "" : data.sip_dispo,

                    })
                }

            });

        }
        if (this.state.working === false && this.state.non_working === false && this.state.short_ca === true) {
            re.map(data => {

                if (data.direction === "short_call") {

                    re1.push({
                        id: data.id,
                        uuid: data.uuid,
                        dialledto: data.dialledto,
                        callstatus: data.callstatus,
                        direction: data.direction,
                        callrecived_datetime: data.callrecived_datetime,
                        callduration: data.callduration,
                        farmer_no: data.farmer_no,
                        recordingurl: data.recordingurl,
                        agentanswered: data.agentanswered,
                        operator: data.operator === null ? "" : data.operator,
                        tag1: data.tag1 === null ? "" : data.tag1,
                        tag2: data.tag2 === null ? "" : data.tag2,
                        tag3: data.tag3 === null ? "" : data.tag3,
                        tag4: data.tag4 === null ? "" : data.tag4,
                        tag5: data.tag5 === null ? "" : data.tag5,
                        call_type: data.call_type === null ? "" : data.call_type,
                        farmer_name: data.farmer_name === null ? "" : data.farmer_name,
                        district: data.district === null ? "" : data.district,
                        panchayat: data.panchayat === null ? "" : data.panchayat,
                        village: data.village === null ? "" : data.village,
                        duration_sec: data.duration_sec,
                        hrs: data.hrs,
                        answered: data.answered,
                        comment: data.comment === null ? "" : this.urldecode(data.comment, data.uuid),
                        hangup_cause: data.hangup_cause === null ? "" : data.hangup_cause,
                        sip_dispo: data.sip_dispo === null ? "" : data.sip_dispo,

                    })
                }

            });

        }
        if (this.state.working === false && this.state.non_working === true && this.state.short_ca === false) {
            re.map(data => {

                if (data.hrs === "non_workinghrs") {
                    re1.push({
                        id: data.id,
                        uuid: data.uuid,
                        dialledto: data.dialledto,
                        callstatus: data.callstatus,
                        direction: data.direction,
                        callrecived_datetime: data.callrecived_datetime,
                        callduration: data.callduration,
                        farmer_no: data.farmer_no,
                        recordingurl: data.recordingurl,
                        agentanswered: data.agentanswered,
                        operator: data.operator === null ? "" : data.operator,
                        tag1: data.tag1 === null ? "" : data.tag1,
                        tag2: data.tag2 === null ? "" : data.tag2,
                        tag3: data.tag3 === null ? "" : data.tag3,
                        tag4: data.tag4 === null ? "" : data.tag4,
                        tag5: data.tag5 === null ? "" : data.tag5,
                        call_type: data.call_type === null ? "" : data.call_type,
                        farmer_name: data.farmer_name === null ? "" : data.farmer_name,
                        district: data.district === null ? "" : data.district,
                        panchayat: data.panchayat === null ? "" : data.panchayat,
                        village: data.village === null ? "" : data.village,
                        duration_sec: data.duration_sec,
                        hrs: data.hrs,
                        answered: data.answered,
                        comment: data.comment === null ? "" : this.urldecode(data.comment, data.uuid),
                        hangup_cause: data.hangup_cause === null ? "" : data.hangup_cause,
                        sip_dispo: data.sip_dispo === null ? "" : data.sip_dispo,

                    })
                }

            });

        }

        this.setState({ userdata: re1, userdata1: re1 })
    }


    GetDistWise = async (e) => {



        let fdate = moment(this.state.fdate).format("YYYY-MM-DD")
        let tdate = moment(this.state.tdate).format("YYYY-MM-DD")

        let re = ""
        let re1 = []
        let rdata = []

        let basedate = new Date("2023-06-07");
        let basedate1 = new Date(fdate);

        let st = moment(this.state.f_time).format("HH:mm:ss")
        let tt = moment(this.state.t_time).format("HH:mm:ss")

        let diff = basedate1.getDate() - basedate.getDate()


        let item = {}

        if (e.shiftKey === false) {
            item = {
                "datetime_from": fdate + " 00:00:00",
                "datetime_to": tdate + " 23:59:59",
                "workinghrs_from": "09:00:00",
                "workinghrs_to": "20:00:00",
                "data": true,
                "field_last_event": false,
                "direction": [
                    "Inbound",
                    "short_call",

                ],
                "domains": [
                    "6742621800",
                    "6742611800",
                    "9000000000",
                    "1000000000",
                    "2000000000",
                    "3000000000"

                ]
            }

        }
        if (e.shiftKey === true) {
            item = {
                "datetime_from": fdate + " 00:00:00",
                "datetime_to": tdate + " 23:59:59",
                "workinghrs_from": "09:00:00",
                "workinghrs_to": "20:00:00",
                "data": true,
                "field_last_event": true,
                "direction": [
                    "Inbound",
                    "Short_call",

                ],
                "domains": [
                    "6742621800",
                    "6742611800",
                    "9000000000",
                    "1000000000",
                    "2000000000",
                    "3000000000"

                ]
            }

        }

        this.setState({ islodding: true })

        try {
            const roledata = await httpRequest({
                endPoint: `api/lcc/reports/RawDump`,
                method: "post",
                timeout: 15000,
                instance: "instanceOne",
                requestBody: item,

            });
            console.log(roledata.msg.data)


            rdata = roledata.msg.data
            if (e.shiftKey === false) {
                re = rdata.map(data => {



                    return {
                        id: data.id,
                        uuid: data.uuid,
                        dialledto: data.dialledto,
                        callstatus: data.callstatus,
                        direction: data.direction,
                        callrecived_datetime: data.callrecived_datetime,
                        callduration: data.callduration,
                        farmer_no: data.farmer_no,
                        recordingurl: data.recordingurl,
                        agentanswered: data.agentanswered,
                        operator: data.operator === null ? "" : data.operator,
                        tag1: data.tag1 === null ? "" : data.tag1,
                        tag2: data.tag2 === null ? "" : data.tag2,
                        tag3: data.tag3 === null ? "" : data.tag3,
                        tag4: data.tag4 === null ? "" : data.tag4,
                        tag5: data.tag5 === null ? "" : data.tag5,
                        call_type: data.call_type === null ? "" : data.call_type,
                        farmer_name: data.farmer_name === null ? "" : data.farmer_name,
                        district: data.district === null ? "" : data.district,
                        panchayat: data.panchayat === null ? "" : data.panchayat,
                        village: data.village === null ? "" : data.village,
                        duration_sec: data.duration_sec,
                        hrs: data.hrs,
                        answered: data.answered,
                        comment: data.comment === null ? "" : this.urldecode(data.comment, data.uuid),
                        hangup_cause: data.hangup_cause === null ? "" : data.hangup_cause,
                        sip_dispo: data.sip_dispo === null ? "" : data.sip_dispo


                    };
                });

            }

            if (e.shiftKey === true) {
                re = rdata.map(data => {



                    return {
                        id: data.id,
                        uuid: data.uuid,
                        dialledto: data.dialledto,
                        callstatus: data.callstatus,
                        direction: data.direction,
                        callrecived_datetime: data.callrecived_datetime,
                        callduration: data.callduration,
                        farmer_no: data.farmer_no,
                        recordingurl: data.recordingurl,
                        agentanswered: data.agentanswered,
                        operator: data.operator === null ? "" : data.operator,
                        tag1: data.tag1 === null ? "" : data.tag1,
                        tag2: data.tag2 === null ? "" : data.tag2,
                        tag3: data.tag3 === null ? "" : data.tag3,
                        tag4: data.tag4 === null ? "" : data.tag4,
                        tag5: data.tag5 === null ? "" : data.tag5,
                        call_type: data.call_type === null ? "" : data.call_type,
                        farmer_name: data.farmer_name === null ? "" : data.farmer_name,
                        district: data.district === null ? "" : data.district,
                        panchayat: data.panchayat === null ? "" : data.panchayat,
                        village: data.village === null ? "" : data.village,
                        duration_sec: data.duration_sec,
                        hrs: data.hrs,
                        answered: data.answered,
                        comment: data.comment === null ? "" : this.urldecode(data.comment, data.uuid),
                        hangup_cause: data.hangup_cause === null ? "" : data.hangup_cause,
                        sip_dispo: data.sip_dispo === null ? "" : data.sip_dispo,
                        last_event: JSON.stringify(data.last_event),


                    };
                });

            }


            this.setState({ islodding: false, filterdata: re })

            this.handelfilter(re)

        } catch (error) {

            this.setState({ islodding: false })

        }

    }
    handleDomain = (e) => {


        let label = e.target.value;
        this.setState({ domain: label });
    }


    ExportCallWise(array) {
        const link = document.createElement('a');

        let fdate = moment(this.state.fdate).format("YYYY_MM_DD")
        let tdate = moment(this.state.tdate).format("YYYY_MM_DD")
        const filename = 'rawdata_' + this.state.domain + "_f_" + fdate + '_t_' + tdate + '.xls';
        const csv = new xlsexport(array, filename)
        csv.exportToXLS(filename)

    }

    MakeFilter(e) {

        this.setState({ stext: e.target.value })
        console.log("ok")
        const sresult = this.state.userdata1.filter(data => {

            return (
                data.uuid.match(e.target.value) ||
                data.dialledto.match(e.target.value) ||
                data.callstatus.match(e.target.value) ||
                data.direction.match(e.target.value) ||
                data.callrecived_datetime.match(e.target.value) ||
                data.farmer_no.match(e.target.value) ||
                data.agentanswered.match(e.target.value) ||
                data.operator.match(e.target.value) ||
                data.tag1.match(e.target.value) ||
                data.tag2.match(e.target.value) ||
                data.tag3.match(e.target.value) ||
                data.tag4.match(e.target.value) ||
                data.tag5.match(e.target.value) ||
                data.call_type.match(e.target.value) ||
                data.farmer_name.match(e.target.value) ||
                data.district.match(e.target.value) ||
                data.panchayat.match(e.target.value) ||
                data.village.match(e.target.value) ||
                data.hrs.match(e.target.value) ||
                data.comment.match(e.target.value) ||
                data.hangup_cause.match(e.target.value) ||
                data.sip_dispo.match(e.target.value)

            )
        })

        if (e.target.value.length > 0) {
            this.setState({ userdata: sresult })
        } else {
            this.setState({ userdata: this.state.userdata1 })
        }


    }
    palyAudio = async (row, palybut_type) => {
        let ringtone = document.getElementById("ringtone");



        if (palybut_type == 0) {
            let a = localStorage.getItem('lcc_token') || '{}'
            let b = JSON.parse(a)
            let livetocken = b.token
            this.setState({ palybut_type: 1, stopcount: row.id })
            let audio_path = backendHosts.CRM + '/api/lcc/PlayBack/LCC-recording/' + row.recordingurl
            const result = await fetch(audio_path, {
                headers: {
                    Authorization: livetocken
                }
            });
            console.log(audio_path)
            const blob = await result.blob();
            if (blob) {
                console.log("Auido okk")
                ringtone.src = URL.createObjectURL(blob);
                //   ringtone.parentElement.load();

            }
            //ringtone.src = audio_path
            ringtone.play()


        } else {
            ringtone.pause()
            this.setState({ palybut_type: 0, stopcount: 0 })

        }


    }
    chk11(e) {


        this.state.working = !this.state.working
        this.handelfilter(this.state.filterdata, this.state.working, this.state.non_working, this.state.short_ca)
    }

    chk2(e) {

        this.state.non_working = !this.state.non_working
        this.handelfilter(this.state.filterdata, this.state.working, this.state.non_working, this.state.short_ca)
    }
    chk3(e) {

        this.state.short_ca = !this.state.short_ca
        this.handelfilter(this.state.filterdata, this.state.working, this.state.non_working, this.state.short_ca)
    }

    render() {
        const columns = [

            {
                name: '',
                width: '50px',
                cell: row =>


                    <WhiteBackgroundCheckbox

                        id="chk1"

                    />


            },
            {
                name: 'Action',
                width: '50px',
                sortable: true,
                cell: row =>

                    <IconButton


                        onClick={() => this.palyAudio(row, this.state.palybut_type)}
                    >

                        {


                            this.state.palybut_type == 0
                                ?
                                <PlayArrow style={{ color: '#0083CA', fontSize: '18px' }} /> :
                                this.state.palybut_type == 1 && row.id == this.state.stopcount ?
                                    <Stop style={{ color: 'red', fontSize: '18px' }} /> : <PlayArrow style={{ color: '#0083CA', fontSize: '18px' }} />


                        }




                    </IconButton>







            },
            {
                name: 'ID',
                selector: 'id',
                sortable: true,
                cell: row =>
                    row.id
            },
            {
                name: 'UUID',

                sortable: true,
                cell: row =>
                    row.uuid
            },
            {
                name: 'dialledto',
                selector: "dialledto",
                sortable: true,
                cell: row =>
                    row.dialledto
            },




            {
                name: 'direction',
                selector: "direction",
                sortable: true,
                cell: row =>
                    row.direction
            },
            {
                name: 'callstatus',
                selector: "callstatus",
                sortable: true,
                cell: row =>
                    row.callstatus
            },



            {
                name: 'callrecived_datetime',
                selector: "callrecived_datetime",
                sortable: true,
                cell: row =>
                    row.callrecived_datetime
            },
            {
                name: 'callduration',
                selector: "callduration",
                sortable: true,
                cell: row =>
                    row.callduration
            },
            {
                name: 'farmer_no',
                selector: "farmer_no",
                sortable: true,
                cell: row =>
                    row.farmer_no
            },



            {
                name: 'recordingurl',

                selector: "recordingurl",
                filterable: true,
                selector: row => row.recordingurl,

            },

            {
                name: 'agentanswered',
                selector: "agentanswered",
                sortable: true,
                cell: row =>
                    row.agentanswered
            },

            {
                name: 'operator',
                selector: "operator",
                sortable: true,
                cell: row =>
                    row.operator
            },
            {
                name: 'tag1',
                selector: "tag1",
                sortable: true,
                cell: row =>
                    row.tag1
            },
            {
                name: 'tag2',
                selector: "tag2",
                sortable: true,
                cell: row =>
                    row.tag2
            },
            {
                name: 'tag3',
                selector: "tag3",
                sortable: true,
                cell: row =>
                    row.tag3
            },
            {
                name: 'tag4',
                selector: "tag4",
                sortable: true,
                cell: row =>
                    row.tag4
            },
            {
                name: 'tag5',
                selector: "tag5",
                sortable: true,
                cell: row =>
                    row.tag5
            },
            {
                name: 'Call Type',
                selector: "call_type",
                sortable: true,
                cell: row =>
                    row.call_type
            },
            {
                name: 'farmer_name',
                selector: "farmer_name",
                sortable: true,
                cell: row =>
                    row.farmer_name
            },
            {
                name: 'district',
                selector: "district",
                sortable: true,
                cell: row =>
                    row.district
            },
            {
                name: 'panchayat',
                selector: "panchayat",
                sortable: true,
                cell: row =>
                    row.panchayat
            },
            {
                name: 'village',
                selector: "village",
                sortable: true,
                cell: row =>
                    row.village
            },
            {
                name: 'duration_sec',
                selector: "duration_sec",
                sortable: true,
                cell: row =>
                    row.duration_sec
            },
            {
                name: 'hrs',
                selector: "hrs",
                sortable: true,
                cell: row =>
                    row.hrs
            },
            {
                name: 'answered',
                selector: "answered",
                sortable: true,
                cell: row =>
                    row.answered
            },
            {
                name: 'comment',
                selector: "comment",
                sortable: true,
                cell: row =>
                    row.comment
            },
            {
                name: 'hangup_cause',
                selector: "hangup_cause",
                sortable: true,
                cell: row =>
                    row.hangup_cause
            },
            {
                name: 'sip_dispo',
                selector: "sip_dispo",
                sortable: true,
                cell: row =>
                    row.hangup_cause
            },








        ];
        return (
            <div style={{ marginTop: '0%' }}>
                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '1%', marginTop: '2%', }}>
                    <Grid style={{ width: '22%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Typography style={{ marginRight: '1%', color: '#999999', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>From</Typography>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider

                                style={{ height: '20px', }} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    error={false}
                                    helperText={null}

                                    value={this.state.fdate}
                                    onChange={date => { this.handledobChange(date) }}
                                    format="yyyy/MM/dd"

                                    InputProps={{


                                        style: {

                                            fontSize: 14,
                                            height: 38,
                                            color: '#999999',

                                            background: '#ffffff'

                                        }
                                    }}
                                    inputVariant="outlined"



                                    KeyboardButtonProps={{
                                        style: { marginLeft: -24 }
                                    }}

                                />
                            </MuiPickersUtilsProvider>
                        </ThemeProvider>
                    </Grid>

                    <Grid style={{ width: '22%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Typography style={{ marginRight: '1%', color: '#999999', fontSize: '14px', marginLeft: '1%', fontFamily: 'Roboto-Regular' }}>To</Typography>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider

                                style={{ height: '20px' }} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    error={false}
                                    helperText={null}

                                    value={this.state.tdate}
                                    onChange={date => { this.handledobChange1(date) }}
                                    format="yyyy/MM/dd"

                                    InputProps={{


                                        style: {

                                            fontSize: 14,
                                            height: 38,
                                            color: '#999999',

                                            background: '#ffffff'

                                        }
                                    }}
                                    inputVariant="outlined"



                                    KeyboardButtonProps={{
                                        style: { marginLeft: -24 }
                                    }}

                                />
                            </MuiPickersUtilsProvider>
                        </ThemeProvider>
                    </Grid>


                    <Grid style={{ width: '15%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Button



                            variant="contained"
                            size="small"

                            style={{
                                color: "#ffffff",
                                fontFamily: "Roboto-Medium",
                                borderRadius: "4px",
                                backgroundColor: "#7DAB1C",
                                textTransform: 'none',
                                padding: '1.5%',
                                fontSize: "14px",
                                width: '125px',
                                height: '32px',
                                marginLeft: '5%'

                            }}

                            onClick={(e) => { this.GetDistWise(e) }}


                        >

                            Submit
                        </Button>
                    </Grid>
                    <Grid style={{ width: '15%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Button



                            variant="contained"
                            size="small"

                            style={{
                                color: "#ffffff",
                                fontFamily: "Roboto-Medium",
                                borderRadius: "4px",
                                backgroundColor: "#7DAB1C",
                                textTransform: 'none',
                                padding: '1.5%',
                                fontSize: "14px",
                                width: '125px',
                                height: '32px',
                                marginLeft: '5%'

                            }}

                            onClick={() => {
                                this.ExportCallWise(this.state.userdata)
                            }}


                        >

                            Export
                        </Button>
                    </Grid>

                </Grid>

                <Grid style={{ height: 'auto', overflow: 'auto', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', }}>


                    <DataTable

                        columns={columns}
                        data={this.state.userdata}
                        pagination
                        striped
                        responsive
                        paginationPerPage='100'
                        paginationRowsPerPageOptions={[100, 150, 200, 250]}

                        defaultSortFieldId
                        dense

                        customStyles={customStyles}
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                            <div style={{ display: 'flex' }}>
                                <CssTextField
                                    autoFocus={true}
                                    value={this.state.stext}
                                    placeholder="Search"
                                    size="small"
                                    variant="outlined"
                                    onChange={e =>
                                        this.MakeFilter(e)
                                    }
                                />



                                <FormControlLabel style={{ marginLeft: '10px' }}
                                    control={<WhiteBackgroundCheckbox

                                        checked={this.state.working}



                                        onChange={(e) =>
                                            this.chk11(e)
                                        }





                                    />} label="Working"

                                />
                                <FormControlLabel control={<WhiteBackgroundCheckbox
                                    checked={this.state.non_working}
                                    onChange={(e) =>
                                        this.chk2(e)
                                    } />} label="Non-Working" />
                                <FormControlLabel control={<WhiteBackgroundCheckbox
                                    checked={this.state.short_ca}
                                    onChange={(e) =>
                                        this.chk3(e)
                                    } />} label="Short Call" />

                            </div>
                        }
                        subHeaderAlign='left'

                        conditionalRowStyles={conditionalRowStyles}
                    />


                </Grid>
                <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',
                            display: 'flex',
                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >
                        <Loader type="spinner-circle" bgColor={"#7DAB1C"} color={'#ffc31b'} size={75} />
                    </Box>

                </Modal>

            </div >
        )
    }
}

export class Outbound extends Component {

    constructor(props) {
        super(props)


        this.state = {


            domain: 'General (Call-center agent)',
            fdate: moment().subtract(1, 'days'),
            tdate: moment().subtract(1, 'days'),
            userdata: [],
            domaindata: [],
            userdata1: [],
            stext: '',
            t_time: '',
            f_time: moment(new Date()).format("hh:mm:ss"),
            palybut_type: 0,
            stopcount: 0
        }



    }

    handledobChange(date) {

        this.setState({ fdate: date })


    }
    handledobChange1(date) {

        this.setState({ tdate: date })


    }

    componentDidMount = async () => {

    }


    onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }

    urldecode(str) {
        console.log("===" + str)
        str = (str + '').toString();
        str = decodeURIComponent(str)
        str = str.replaceAll('+', ' ')
        console.log('****' + str)
        return (str)



    }


    GetDistWise = async () => {

        let fdate = moment(this.state.fdate).format("YYYY-MM-DD")
        let tdate = moment(this.state.tdate).format("YYYY-MM-DD")

        let re = ""
        let re1 = []
        let rdata = []

        let basedate = new Date("2023-06-07");
        let basedate1 = new Date(fdate);

        let st = moment(this.state.f_time).format("HH:mm:ss")
        let tt = moment(this.state.t_time).format("HH:mm:ss")

        let diff = basedate1.getDate() - basedate.getDate()


        let item = {}
        item = {
            "datetime_from": fdate + " 00:00:00",
            "datetime_to": fdate + " 23:59:59",
            "workinghrs_from": "09:30:00",
            "workinghrs_to": "17:30:00",
            "data": true,
            "field_last_event": false,
            "direction": [
                "Outbound",


            ],
            "domains": [
                "6742621800",
                "6742621808",

            ]
        }



        this.setState({ islodding: true })

        try {
            const roledata = await httpRequest({
                endPoint: `api/lcc/reports/RawDump`,
                method: "post",
                timeout: 15000,
                instance: "instanceOne",
                requestBody: item,

            });
            console.log(roledata.msg.data)


            rdata = roledata.msg.data

            re = rdata.map(data => {



                return {
                    id: data.id,
                    uuid: data.uuid,
                    dialledto: data.dialledto,
                    callstatus: data.callstatus,
                    direction: data.direction,
                    callrecived_datetime: data.callrecived_datetime,
                    callduration: data.callduration,
                    farmer_no: data.farmer_no,
                    recordingurl: data.recordingurl,
                    agentanswered: data.agentanswered,
                    operator: data.operator === null ? "" : data.operator,
                    tag1: data.tag1 === null ? "" : data.tag1,
                    tag2: data.tag2 === null ? "" : data.tag2,
                    tag3: data.tag3 === null ? "" : data.tag3,
                    tag4: data.tag4 === null ? "" : data.tag4,
                    tag5: data.tag5 === null ? "" : data.tag5,
                    call_type: data.call_type === null ? "" : data.call_type,
                    farmer_name: data.farmer_name === null ? "" : data.farmer_name,
                    district: data.district === null ? "" : data.district,
                    panchayat: data.panchayat === null ? "" : data.panchayat,
                    village: data.village === null ? "" : data.village,
                    duration_sec: data.duration_sec === null ? "" : data.duration_sec,
                    hrs: data.hrs === null ? "" : data.hrs,
                    answered: data.answered === null ? "" : data.answered,
                    comment: data.comment === null ? "" : this.urldecode(data.comment),
                    hangup_cause: data.hangup_cause === null ? "" : data.hangup_cause,
                    sip_dispo: data.sip_dispo === null ? "" : data.sip_dispo,

                };
            });


            this.setState({ userdata: re, userdata1: re })
            this.setState({ islodding: false })


        } catch (error) {

        }




    }
    handleDomain = (e) => {


        let label = e.target.value;
        this.setState({ domain: label });
    }


    ExportCallWise(array) {


        let fdate = moment(this.state.fdate).format("YYYY_MM_DD")
        let tdate = moment(this.state.tdate).format("YYYY_MM_DD")
        const filename = 'outbound_rawdata_f_' + fdate + '_t_' + tdate + '.xls';
        const csv = new xlsexport(array, filename)
        csv.exportToXLS(filename)

    }


    MakeFilter(e) {

        this.setState({ stext: e.target.value })

        const sresult = this.state.userdata1.filter(data => {

            return (
                data.uuid.match(e.target.value) ||
                data.dialledto.match(e.target.value) ||
                data.callstatus.match(e.target.value) ||
                data.direction.match(e.target.value) ||
                data.callrecived_datetime.match(e.target.value) ||
                data.farmer_no.match(e.target.value) ||
                data.agentanswered.match(e.target.value) ||
                data.call_type.match(e.target.value) ||
                data.operator.match(e.target.value) ||
                data.tag1.match(e.target.value) ||
                data.tag2.match(e.target.value) ||
                data.tag3.match(e.target.value) ||
                data.tag4.match(e.target.value) ||
                data.tag5.match(e.target.value) ||
                data.farmer_name.match(e.target.value) ||
                data.district.match(e.target.value) ||
                data.panchayat.match(e.target.value) ||
                data.village.match(e.target.value) ||
                data.hrs.match(e.target.value) ||
                data.comment.match(e.target.value) ||
                data.sip_dispo.match(e.target.value) ||
                data.hangup_cause.match(e.target.value)


            )
        })

        if (e.target.value.length > 0) {
            this.setState({ userdata: sresult })
        } else {
            this.setState({ userdata: this.state.userdata1 })
        }


    }
    palyAudio(row, palybut_type) {
        let ringtone = document.getElementById("ringtone");



        if (palybut_type == 0) {
            this.setState({ palybut_type: 1, stopcount: row.id })
            let audio_path = backendHosts.CRM + '/api/lcc/PlayBack/LCC-recording/' + row.recordingurl


            ringtone.src = audio_path
            ringtone.play()
        } else {
            ringtone.pause()
            this.setState({ palybut_type: 0, stopcount: 0 })

        }


    }
    render() {
        const columns = [

            {
                name: '',
                width: '50px',
                cell: row =>


                    <WhiteBackgroundCheckbox

                        id="chk1"

                    />


            },
            {
                name: 'Action',
                width: '50px',
                sortable: true,
                cell: row =>

                    <IconButton


                        onClick={() => this.palyAudio(row, this.state.palybut_type)}
                    >

                        {


                            this.state.palybut_type == 0
                                ?
                                <PlayArrow style={{ color: '#0083CA', fontSize: '18px' }} /> :
                                this.state.palybut_type == 1 && row.id == this.state.stopcount ?
                                    <Stop style={{ color: 'red', fontSize: '18px' }} /> : <PlayArrow style={{ color: '#0083CA', fontSize: '18px' }} />


                        }




                    </IconButton>







            },
            {
                name: 'ID',

                sortable: true,
                cell: row =>
                    row.id
            },
            {
                name: 'UUID',

                sortable: true,
                cell: row =>
                    row.uuid
            },
            {
                name: 'dialledto',

                sortable: true,
                cell: row =>
                    row.dialledto
            },




            {
                name: 'direction',

                sortable: true,
                cell: row =>
                    row.direction
            },
            {
                name: 'callstatus',

                sortable: true,
                cell: row =>
                    row.callstatus
            },



            {
                name: 'callrecived_datetime',

                sortable: true,
                cell: row =>
                    row.callrecived_datetime
            },
            {
                name: 'callduration',

                sortable: true,
                cell: row =>
                    row.callduration
            },
            {
                name: 'farmer_no',

                sortable: true,
                cell: row =>
                    row.farmer_no
            },



            {
                name: 'recordingurl',


                filterable: true,
                selector: row => row.recordingurl,

            },

            {
                name: 'agentanswered',

                sortable: true,
                cell: row =>
                    row.agentanswered
            },

            {
                name: 'operator',

                sortable: true,
                cell: row =>
                    row.operator
            },
            {
                name: 'tag1',

                sortable: true,
                cell: row =>
                    row.tag1
            },
            {
                name: 'tag2',

                sortable: true,
                cell: row =>
                    row.tag2
            },
            {
                name: 'tag3',

                sortable: true,
                cell: row =>
                    row.tag3
            },
            {
                name: 'tag4',

                sortable: true,
                cell: row =>
                    row.tag4
            },
            {
                name: 'tag5',

                sortable: true,
                cell: row =>
                    row.tag5
            },
            {
                name: 'Call Type',

                sortable: true,
                cell: row =>
                    row.call_type
            },


            {
                name: 'farmer_name',

                sortable: true,
                cell: row =>
                    row.farmer_name
            },
            {
                name: 'district',

                sortable: true,
                cell: row =>
                    row.district
            },
            {
                name: 'panchayat',

                sortable: true,
                cell: row =>
                    row.panchayat
            },
            {
                name: 'village',

                sortable: true,
                cell: row =>
                    row.village
            },
            {
                name: 'duration_sec',

                sortable: true,
                cell: row =>
                    row.duration_sec
            },
            {
                name: 'hrs',

                sortable: true,
                cell: row =>
                    row.hrs
            },
            {
                name: 'answered',

                sortable: true,
                cell: row =>
                    row.answered
            },
            {
                name: 'comment',

                sortable: true,
                cell: row =>
                    row.comment
            },

            {
                name: 'hangup_cause',

                sortable: true,
                cell: row =>
                    row.hangup_cause
            },

            {
                name: 'sip_dispo',

                sortable: true,
                cell: row =>
                    row.sip_dispo
            },











        ];
        return (
            <div style={{ marginTop: '0%' }}>
                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '1%', marginTop: '2%', }}>
                    <Grid style={{ width: '22%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Typography style={{ marginRight: '1%', color: '#999999', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>From</Typography>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider

                                style={{ height: '20px', }} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    error={false}
                                    helperText={null}

                                    value={this.state.fdate}
                                    onChange={date => { this.handledobChange(date) }}
                                    format="yyyy/MM/dd"

                                    InputProps={{


                                        style: {

                                            fontSize: 14,
                                            height: 38,
                                            color: '#999999',

                                            background: '#ffffff'

                                        }
                                    }}
                                    inputVariant="outlined"



                                    KeyboardButtonProps={{
                                        style: { marginLeft: -24 }
                                    }}

                                />
                            </MuiPickersUtilsProvider>
                        </ThemeProvider>
                    </Grid>
                    {/* <Grid style={{ width: '22%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Typography style={{ marginRight: '1%', color: '#999999', fontSize: '14px', marginLeft: '1%', fontFamily: 'Roboto-Regular' }}>To</Typography>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider

                                style={{ height: '20px' }} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    error={false}
                                    helperText={null}

                                    value={this.state.tdate}
                                    onChange={date => { this.handledobChange1(date) }}
                                    format="yyyy/MM/dd"

                                    InputProps={{


                                        style: {

                                            fontSize: 14,
                                            height: 38,
                                            color: '#999999',

                                            background: '#ffffff'

                                        }
                                    }}
                                    inputVariant="outlined"



                                    KeyboardButtonProps={{
                                        style: { marginLeft: -24 }
                                    }}

                                />
                            </MuiPickersUtilsProvider>
                        </ThemeProvider>
                    </Grid>*/}

                    <Grid style={{ width: '15%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Button



                            variant="contained"
                            size="small"

                            style={{
                                color: "#ffffff",
                                fontFamily: "Roboto-Medium",
                                borderRadius: "4px",
                                backgroundColor: "#7DAB1C",
                                textTransform: 'none',
                                padding: '1.5%',
                                fontSize: "14px",
                                width: '125px',
                                height: '32px',
                                marginLeft: '5%'

                            }}

                            onClick={() => { this.GetDistWise() }}


                        >

                            Submit
                        </Button>
                    </Grid>
                    <Grid style={{ width: '15%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Button



                            variant="contained"
                            size="small"

                            style={{
                                color: "#ffffff",
                                fontFamily: "Roboto-Medium",
                                borderRadius: "4px",
                                backgroundColor: "#7DAB1C",
                                textTransform: 'none',
                                padding: '1.5%',
                                fontSize: "14px",
                                width: '125px',
                                height: '32px',
                                marginLeft: '5%'

                            }}

                            onClick={() => {
                                this.ExportCallWise(this.state.userdata)
                            }}


                        >

                            Export
                        </Button>
                    </Grid>

                </Grid>

                <Grid style={{ height: 'auto', overflow: 'auto', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', }}>


                    <DataTable

                        columns={columns}
                        data={this.state.userdata}
                        pagination
                        striped
                        responsive
                        paginationPerPage='100'
                        paginationRowsPerPageOptions={[100, 150, 200, 250]}

                        defaultSortFieldId
                        dense

                        customStyles={customStyles}
                        highlightOnHover
                        subHeader
                        subHeaderComponent={<CssTextField
                            autoFocus={true}
                            value={this.state.stext}
                            placeholder="Search"
                            size="small"
                            variant="outlined"
                            onChange={e =>
                                this.MakeFilter(e)
                            }
                        />}
                        subHeaderAlign='left'

                        conditionalRowStyles={conditionalRowStyles}
                    />


                </Grid>

                <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',
                            display: 'flex',
                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >
                        <Loader type="spinner-circle" bgColor={"#7DAB1C"} color={'#ffc31b'} size={75} />
                    </Box>

                </Modal>
            </div >
        )
    }
}
export class Campaign extends Component {

    constructor(props) {
        super(props)


        this.state = {


            domain: 'General (Call-center agent)',
            fdate: moment().subtract(1, 'days'),
            tdate: moment().subtract(1, 'days'),
            userdata: [],
            domaindata: [],
            userdata1: [],
            stext: '',
            t_time: '',
            f_time: moment(new Date()).format("hh:mm:ss"),
            palybut_type: 0,
            stopcount: 0
        }



    }

    handledobChange(date) {

        this.setState({ fdate: date })


    }
    handledobChange1(date) {

        this.setState({ tdate: date })


    }

    componentDidMount = async () => {

    }


    onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }



    GetDistWise = async () => {

        let fdate = moment(this.state.fdate).format("YYYY-MM-DD")
        let tdate = moment(this.state.tdate).format("YYYY-MM-DD")

        let re = ""
        let re1 = []
        let rdata = []

        let basedate = new Date("2023-06-07");
        let basedate1 = new Date(fdate);

        let st = moment(this.state.f_time).format("HH:mm:ss")
        let tt = moment(this.state.t_time).format("HH:mm:ss")

        let diff = basedate1.getDate() - basedate.getDate()


        let item = {}
        item = {
            "datetime_from": fdate + " 00:00:00",
            "datetime_to": fdate + " 23:59:59",
            "workinghrs_from": "09:00:00",
            "workinghrs_to": "15:00:00",
            "data": true,
            "field_last_event": false,
            "direction": [
                "CAMPAIGN_CALL",


            ],
            "domains": [
                "6742621800",
                "6742621808",
                "9999999999",

            ]
        }



        this.setState({ islodding: true })

        try {
            const roledata = await httpRequest({
                endPoint: `api/lcc/reports/RawDump`,
                method: "post",
                timeout: 15000,
                instance: "instanceOne",
                requestBody: item,

            });
            console.log(roledata.msg.data)


            rdata = roledata.msg.data

            re = rdata.map(data => {



                return {
                    id: data.id,
                    uuid: data.uuid,
                    dialledto: data.dialledto,
                    callstatus: data.callstatus,
                    direction: data.direction,
                    callrecived_datetime: data.callrecived_datetime,
                    callduration: data.callduration,
                    farmer_no: data.farmer_no,
                    recordingurl: data.recordingurl,
                    agentanswered: data.agentanswered,
                    operator: data.operator,
                    tag1: data.tag1,
                    tag2: data.tag2,
                    tag3: data.tag3,
                    tag4: data.tag4,
                    tag5: data.tag5,
                    farmer_name: data.farmer_name,
                    district: data.district,
                    panchayat: data.panchayat,
                    village: data.village,
                    duration_sec: data.duration_sec,
                    hrs: data.hrs,
                    answered: data.answered,
                    comment: data.comment,


                };
            });


            this.setState({ userdata: re, userdata1: re })
            this.setState({ islodding: false })


        } catch (error) {

        }




    }
    handleDomain = (e) => {


        let label = e.target.value;
        this.setState({ domain: label });
    }


    ExportCallWise(array) {


        let fdate = moment(this.state.fdate).format("YYYY_MM_DD")
        let tdate = moment(this.state.tdate).format("YYYY_MM_DD")
        const filename = 'outbound_rawdata_f_' + fdate + '_t_' + tdate + '.xls';
        const csv = new xlsexport(array, filename)
        csv.exportToXLS(filename)

    }


    MakeFilter(e) {

        this.setState({ stext: e.target.value })

        const sresult = this.state.userdata.filter(data => {
            if (data.aid)
                return (
                    data.agent_id.match(e.target.value) ||
                    data.domain.match(e.target.value) ||
                    data.name.match(e.target.value)


                )
        })

        if (e.target.value.length > 0) {
            this.setState({ userdata: sresult })
        } else {
            this.setState({ userdata: this.state.userdata1 })
        }


    }
    palyAudio(row, palybut_type) {
        let ringtone = document.getElementById("ringtone");



        if (palybut_type == 0) {
            this.setState({ palybut_type: 1, stopcount: row.id })
            let audio_path = backendHosts.CRM + '/api/lcc/PlayBack/LCC-recording/' + row.recordingurl


            ringtone.src = audio_path
            ringtone.play()
        } else {
            ringtone.pause()
            this.setState({ palybut_type: 0, stopcount: 0 })

        }


    }
    render() {
        const columns = [

            {
                name: '',
                width: '50px',
                cell: row =>


                    <WhiteBackgroundCheckbox

                        id="chk1"

                    />


            },
            {
                name: 'Action',
                width: '50px',
                sortable: true,
                cell: row =>

                    <IconButton


                        onClick={() => this.palyAudio(row, this.state.palybut_type)}
                    >

                        {


                            this.state.palybut_type == 0
                                ?
                                <PlayArrow style={{ color: '#0083CA', fontSize: '18px' }} /> :
                                this.state.palybut_type == 1 && row.id == this.state.stopcount ?
                                    <Stop style={{ color: 'red', fontSize: '18px' }} /> : <PlayArrow style={{ color: '#0083CA', fontSize: '18px' }} />


                        }




                    </IconButton>







            },
            {
                name: 'ID',

                sortable: true,
                cell: row =>
                    row.id
            },
            {
                name: 'UUID',

                sortable: true,
                cell: row =>
                    row.uuid
            },
            {
                name: 'dialledto',

                sortable: true,
                cell: row =>
                    row.dialledto
            },




            {
                name: 'direction',

                sortable: true,
                cell: row =>
                    row.direction
            },
            {
                name: 'callstatus',

                sortable: true,
                cell: row =>
                    row.callstatus
            },



            {
                name: 'callrecived_datetime',

                sortable: true,
                cell: row =>
                    row.callrecived_datetime
            },
            {
                name: 'callduration',

                sortable: true,
                cell: row =>
                    row.callduration
            },
            {
                name: 'farmer_no',

                sortable: true,
                cell: row =>
                    row.farmer_no
            },



            {
                name: 'recordingurl',


                filterable: true,
                selector: row => row.recordingurl,

            },

            {
                name: 'agentanswered',

                sortable: true,
                cell: row =>
                    row.agentanswered
            },

            {
                name: 'operator',

                sortable: true,
                cell: row =>
                    row.operator
            },
            {
                name: 'tag1',

                sortable: true,
                cell: row =>
                    row.tag1
            },
            {
                name: 'tag2',

                sortable: true,
                cell: row =>
                    row.tag2
            },
            {
                name: 'tag3',

                sortable: true,
                cell: row =>
                    row.tag3
            },
            {
                name: 'tag4',

                sortable: true,
                cell: row =>
                    row.tag4
            },
            {
                name: 'tag5',

                sortable: true,
                cell: row =>
                    row.tag5
            },
            {
                name: 'farmer_name',

                sortable: true,
                cell: row =>
                    row.farmer_name
            },
            {
                name: 'district',

                sortable: true,
                cell: row =>
                    row.district
            },
            {
                name: 'panchayat',

                sortable: true,
                cell: row =>
                    row.panchayat
            },
            {
                name: 'village',

                sortable: true,
                cell: row =>
                    row.village
            },
            {
                name: 'duration_sec',

                sortable: true,
                cell: row =>
                    row.duration_sec
            },
            {
                name: 'hrs',

                sortable: true,
                cell: row =>
                    row.hrs
            },
            {
                name: 'answered',

                sortable: true,
                cell: row =>
                    row.answered
            },
            {
                name: 'comment',

                sortable: true,
                cell: row =>
                    row.comment
            },












        ];
        return (
            <div style={{ marginTop: '0%' }}>
                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '1%', marginTop: '2%', }}>
                    <Grid style={{ width: '22%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Typography style={{ marginRight: '1%', color: '#999999', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>From</Typography>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider

                                style={{ height: '20px', }} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    error={false}
                                    helperText={null}

                                    value={this.state.fdate}
                                    onChange={date => { this.handledobChange(date) }}
                                    format="yyyy/MM/dd"

                                    InputProps={{


                                        style: {

                                            fontSize: 14,
                                            height: 38,
                                            color: '#999999',

                                            background: '#ffffff'

                                        }
                                    }}
                                    inputVariant="outlined"



                                    KeyboardButtonProps={{
                                        style: { marginLeft: -24 }
                                    }}

                                />
                            </MuiPickersUtilsProvider>
                        </ThemeProvider>
                    </Grid>
                    {/* <Grid style={{ width: '22%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Typography style={{ marginRight: '1%', color: '#999999', fontSize: '14px', marginLeft: '1%', fontFamily: 'Roboto-Regular' }}>To</Typography>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider

                                style={{ height: '20px' }} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    error={false}
                                    helperText={null}

                                    value={this.state.tdate}
                                    onChange={date => { this.handledobChange1(date) }}
                                    format="yyyy/MM/dd"

                                    InputProps={{


                                        style: {

                                            fontSize: 14,
                                            height: 38,
                                            color: '#999999',

                                            background: '#ffffff'

                                        }
                                    }}
                                    inputVariant="outlined"



                                    KeyboardButtonProps={{
                                        style: { marginLeft: -24 }
                                    }}

                                />
                            </MuiPickersUtilsProvider>
                        </ThemeProvider>
                    </Grid>*/}

                    <Grid style={{ width: '15%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Button



                            variant="contained"
                            size="small"

                            style={{
                                color: "#ffffff",
                                fontFamily: "Roboto-Medium",
                                borderRadius: "4px",
                                backgroundColor: "#7DAB1C",
                                textTransform: 'none',
                                padding: '1.5%',
                                fontSize: "14px",
                                width: '125px',
                                height: '32px',
                                marginLeft: '5%'

                            }}

                            onClick={() => { this.GetDistWise() }}


                        >

                            Submit
                        </Button>
                    </Grid>
                    <Grid style={{ width: '15%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Button



                            variant="contained"
                            size="small"

                            style={{
                                color: "#ffffff",
                                fontFamily: "Roboto-Medium",
                                borderRadius: "4px",
                                backgroundColor: "#7DAB1C",
                                textTransform: 'none',
                                padding: '1.5%',
                                fontSize: "14px",
                                width: '125px',
                                height: '32px',
                                marginLeft: '5%'

                            }}

                            onClick={() => {
                                this.ExportCallWise(this.state.userdata)
                            }}


                        >

                            Export
                        </Button>
                    </Grid>

                </Grid>

                <Grid style={{ height: 'auto', overflow: 'auto', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', }}>


                    <DataTable

                        columns={columns}
                        data={this.state.userdata}
                        pagination
                        striped
                        responsive
                        paginationPerPage='100'
                        paginationRowsPerPageOptions={[100, 150, 200, 250]}

                        defaultSortFieldId
                        dense

                        customStyles={customStyles}
                        highlightOnHover
                        subHeader
                        subHeaderComponent={<CssTextField
                            autoFocus={true}
                            value={this.state.stext}
                            placeholder="Search"
                            size="small"
                            variant="outlined"
                            onChange={e =>
                                this.MakeFilter(e)
                            }
                        />}
                        subHeaderAlign='left'

                        conditionalRowStyles={conditionalRowStyles}
                    />


                </Grid>

                <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',
                            display: 'flex',
                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >
                        <Loader type="spinner-circle" bgColor={"#7DAB1C"} color={'#ffc31b'} size={75} />
                    </Box>

                </Modal>
            </div >
        )
    }
}
export default class rawreports extends Component {
    constructor(props) {
        super(props)


        this.state = {


            tabvalue: 0,
            butname: '',
            islodding: false


        }



    }
    handleTabChange = (event, value) => {
        this.setState({ tabvalue: value });
    };
    render() {
        return (
            <div>
                <Grid style={{ width: '100%', marginTop: '1%' }}>
                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Raw Dump</Typography>
                </Grid>

                <Tabs value={this.state.tabvalue} onChange={this.handleTabChange} style={{ marginTop: '.5%', background: '#F5F5F5' }}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "#7DAB1C",

                        }
                    }}

                >
                    <Tab style={{ color: '#333333', textTransform: 'none', fontSize: '12', fontFamily: 'Roboto-Medium' }} label="Inbound" />
                    <Tab style={{ color: '#333333', textTransform: 'none', fontSize: '12', fontFamily: 'Roboto-Medium' }} label="Outbound" />
                    {/*<Tab style={{ color: '#333333', textTransform: 'none', fontSize: '12', fontFamily: 'Roboto-Medium' }} label="Campaign" />*/}


                </Tabs>

                {this.state.tabvalue === 0 &&
                    <div>
                        <RawData />
                    </div>
                }

                {this.state.tabvalue === 1 &&
                    <div>
                        <Outbound />
                    </div>
                }
                {this.state.tabvalue === 2 &&
                    <div>
                        <Campaign />
                    </div>
                }

            </div>
        )
    }
}
