
import React, { Component } from 'react'
import { Typography, Grid, Button, Checkbox, IconButton, Snackbar, SnackbarContent, TextField, Modal, Box } from '@material-ui/core'
import Loader from "react-js-loader";
import Select from 'react-select'

import { withStyles, styled } from "@material-ui/core/styles";
import DataTable from 'react-data-table-component';
import moment from 'moment'
import { httpRequest } from '../../../ui-utils/api'
import backendHosts from '../../../ui-utils/apiConfig'
import xlsexport from 'xlsexport'
import CloseIcon from '@material-ui/icons/Close'
import { createTheme, ThemeProvider, MuiThemeProvider } from "@material-ui/core/styles";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';

import { PlayArrow, Stop, PhoneInTalk } from '@material-ui/icons';
const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))
const defaultMaterialTheme = createTheme({
    palette: {
        primary: {
            light: '#7DAB1C',
            main: '#7DAB1C',
            dark: '#7DAB1C',
            contrastText: '#fff',

        },
        secondary: {
            light: '#7DAB1C',
            main: '#7DAB1C',
            dark: '#7DAB1C',
            contrastText: '#000',
        },
    },
});
const data1 = [
    { title: 'district', value: '' },
    { title: 'subdistrict', value: '' },
    { title: 'domain', value: 'lcc-default' },
    { title: 'transfer to agri', value: '' },


]
const conditionalRowStyles = [
    {
        when: row => row.account_status == "E",
        style: {
            backgroundColor: 'green',
            color: 'white',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    },

];
const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    }
};

const customStyles = {
    title: {
        style: {
            fontSize: '14px', // override the row height
        },
    },
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
        stripedStyle: {

            //  background: 'red'



        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#E9F4FF',
            fontSize: '14px',
            color: '#333333',
            height: '45px',
            fontFamily: 'Roboto-Medium'
        },
    },
    cells: {
        style: {
            paddingLeft: '2px', // override the cell padding for data cells
            paddingRight: '2px',


        },
    },
    highlightOnHoverStyle: {
        style: {
            color: 'red',
            background: 'blue',
            backgroundColor: 'yellow'



        },
    },

};
const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#41AB1C",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 10,
            width: 10,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#41AB1C'
        }
    },
    checked: { color: "#41AB1C", }
}))(Checkbox);
const CssTextField = styled(TextField)({
    borderRadius: '23px',
    fontFamily: 'InterRegular',
    background: '#ffff',

    '& .MuiInput-underline:after': {
        borderBottomColor: '#dddddd',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#dddddd',
        },
        '&:hover fieldset': {
            borderColor: '#dddddd',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#dddddd',
        },
    },
});
let distcolumns = []

function TimeDiff(timestamp1, timestamp2) {
    var timeStart = new Date("01/01/2007 " + timestamp1);
    var timeEnd = new Date("01/01/2007 " + timestamp2);
    var diff_result = 0
    if (timestamp2.length > 3) {
        var difference = timeEnd - timeStart;
        diff_result = new Date(difference);
        diff_result = moment(diff_result).format("hh:mm:ss")
    }

    console.log("***", diff_result)
    return diff_result;
}

let agentid

export default class missing extends Component {
    constructor(props) {
        super(props)


        agentid = props.aid

        this.state = {

            mysnack: snackeror.mysucc,
            domain: 'lcc-default',
            fdate: moment().subtract(0, 'days'),
            tdate: moment().subtract(0, 'days'),
            userdata: [],
            domaindata: [],
            userdata1: [],
            stext: '',
            palybut_type: 0,
            stopcount: 0,
            islodding1: false,
            islodding: false,
            formarnolist: [],
            showerr: false,
            gw: '0'
        }



    }
    closeContactModal() {
        this.setState({ showerr: false })
    }

    handledobChange(date) {

        this.setState({ fdate: date })


    }
    handledobChange1(date) {

        this.setState({ tdate: date })


    }

    componentDidMount = async () => {
        this.GetDomain()
    }

    GetDomain = async () => {
        let sql = ""

        sql = "select * from  pad_gwlist where uid=0"
        console.log(sql)
        const domainitem1 = {
            "stmt": sql

        }
        this.setState({ islodding: true })
        try {
            const domaindata = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: domainitem1,

            });

            this.setState({ islodding: false })


            if (domaindata.success == true) {

                this.setState({ gw: domaindata.msg[0].gw_string })
            }



        } catch (error) {

        }

        sql = "select uid,name from  pad_domains where name<>'Outbound' order by name"
        console.log(sql)
        const domainitem = {
            "stmt": sql

        }
        this.setState({ islodding: true })
        try {
            const domaindata = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: domainitem,

            });

            this.setState({ islodding: false })
            console.log('domaindata.msg ', domaindata.msg)

            if (domaindata.success == true) {

                // this.setState({ domaindata: domaindata.msg, domain: 'lcc-default' })
            }

            const domaindata1 = domaindata.msg.map(data => {
                return { label: data.name, value: data.name };
            });

            this.setState({ domaindata: domaindata1 })
        } catch (error) {

        }


    }




    GetDistWise = async () => {

        let fdate = moment(this.state.fdate).format("YYYY-MM-DD")
        let tdate = moment(this.state.tdate).format("YYYY-MM-DD")
        this.setState({ islodding: true })
        let item = {}
        item = {
            "datetime_from": fdate + " 00:00:00",
            "datetime_to": tdate + " 23:59:59",
            "workinghrs_from": "09:00:00",
            "workinghrs_to": "20:00:00",
            "data": true,
            "field_last_event": false,
            "direction": [
                "Inbound",


            ],
            "domains": [
                "6742621800",
                "6742621808",
                "6742621809",
                "9000000000",
                "1000000000",
                "2000000000",
                "3000000000"
            ]
        }



        try {
            const roledata = await httpRequest({
                endPoint: `api/lcc/reports/RawDump`,
                method: "post",
                instance: "instanceOne",
                requestBody: item,

            });

            let maindata = []
            this.setState({ islodding: false })
            if (roledata.success == true) {
                let rdata = []
                rdata = roledata.msg.data
                console.log(rdata)
                const re = rdata.map(data => {
                    if (data.agentanswered === "0") {

                        maindata.push(
                            {


                                callrecived_datetime: data.callrecived_datetime, //+ " : " + moment(data.time1).add(330, 'minutes').format("hh:mm:ss"),


                                farmer_no: data.farmer_no,
                                hrs: data.hrs,
                                dialledto: data.dialledto,
                                callstatus: data.callstatus,
                                direction: data.direction,

                                callduration: (data.callduration / 60).toFixed(2),
                                recordingurl: data.recordingurl,
                                id: data.id,
                                uuid: data.uuid


                            })

                    }
                })

                this.setState({
                    userdata: maindata, userdata1: maindata
                })

                /*  let rdata = []
                  rdata = roledata.msg.data
  
                  this.setState({ userdata: roledata.msg.data, userdata1: roledata.msg.data })*/

            }
        } catch (error) {

        }


    }
    handleDomain = (e) => {


        let label = e.target.value;
        this.setState({ domain: label });
    }


    ExportCallWise(array) {
        const link = document.createElement('a');

        let fdate = moment(this.state.fdate).format("YYYY_MM_DD")
        let tdate = moment(this.state.tdate).format("YYYY_MM_DD")
        const filename = 'misscall_' + this.state.domain + "_f_" + fdate + '_t_' + tdate + '.xls';
        const csv = new xlsexport(array, filename)
        csv.exportToXLS(filename)

    }

    MakeFilter(e) {

        this.setState({ stext: e.target.value })

        const sresult = this.state.userdata.filter(data => {
            if (data.dialledto)
                return (
                    data.farmer_no.match(e.target.value) ||

                    data.dialledto.match(e.target.value)


                )
        })

        if (e.target.value.length > 0) {
            this.setState({ userdata: sresult })
        } else {
            this.setState({ userdata: this.state.userdata1 })
        }


    }
    palyAudio(row, palybut_type) {
        let ringtone = document.getElementById("ringtone");
        console.log(row.id)
        this.setState({ palybut_type: 1, stopcount: row.id })

        if (palybut_type == 0) {
            this.setState({ palybut_type: 1, stopcount: row.id })
            let audio_path = backendHosts.CRM + '/api/lcc/PlayBack/LCC-recording/' + row.recordingurl
            console.log(audio_path)

            ringtone.src = audio_path
            ringtone.play()
        } else {
            this.setState({ palybut_type: 0, stopcount: 0 })
            ringtone.pause()
        }


    }
    UseCheck(row, e) {

        const { formarnolist } = this.state

        if (e.target.checked == true) {
            this.state.formarnolist.push(row.farmer_no)
        }
        if (e.target.checked == false) {

            const nlist = formarnolist.filter(function (person) {

                return person !== row.farmer_no
            })


            this.setState({ formarnolist: nlist })

        }

    }
    SaveMissCall = async (row) => {
        const { userdata } = this.state
        let payload = {}

        let uuid


        let aa = []
        const domaindata1 = userdata.map(data => {

            if (data.farmer_no === row.farmer_no) {
                uuid = data.uuid
            }
        });

        payload = {
            aid: parseInt(agentid),
            farmer_no: parseInt(row.farmer_no),
            uuid: uuid,
            form_id: 1,
            type: 'Non_Working',
            gateway: this.state.gw

        }

        this.setState({ islodding1: true })


        try {

            const submitdata = await httpRequest({
                endPoint: `api/fs/GenMissCall`,
                method: "post",
                instance: "instanceOne",
                requestBody: payload,

            });

            if (submitdata.success == true) {
                await sleep(10000)
                this.setState({ islodding1: false })
            } else {
                this.setState({ showerr: true, errorMsg: submitdata.msg, mysnack: snackeror.myerror })
            }

        } catch (error) {

        }

        /* const sresult = this.state.userdata.filter(data => {
             if (data.call_id)
                 return (
                     data.farmer_ph_no.match(e.target.value) ||
                     data.call_id.match(e.target.value)
 
 
                 )
         })
         /*  const { formarnolist } = this.state
   
   
           let uniqueArray = formarnolist.filter(function (item, pos) {
               return formarnolist.indexOf(item) == pos;
           })
   
           console.log(uniqueArray)*/

    }
    render() {
        const columns = [

            {
                name: '',
                width: '50px',
                cell: row =>


                    <WhiteBackgroundCheckbox

                        id="chk1"
                        onClick={(e) => this.UseCheck(row, e)}

                    />


            },





            {
                name: 'callrecived_datetime',
                selector: 'callrecived_datetime',
                sortable: true,
                cell: row =>
                    row.callrecived_datetime
            },
            {
                name: 'Landing',
                selector: 'hrs',
                sortable: true,
                cell: row =>
                    row.hrs
            },

            {
                name: 'Domain',
                selector: 'hrs',
                sortable: true,
                cell: row =>
                    row.dialledto
            },

            {
                name: 'CallDuration',
                selector: 'callduration',
                width: '150px',
                sortable: true,
                cell: row =>
                    row.callduration
            },
            {
                name: 'farmer_no',
                selector: 'farmer_no',
                sortable: true,
                cell: row =>
                    row.farmer_no
            },


            {
                name: 'Action',
                width: '50px',
                sortable: true,
                cell: row =>

                    <IconButton


                        onClick={() => this.palyAudio(row, this.state.palybut_type)}
                    >



                        {


                            this.state.palybut_type == 0
                                ?
                                <PlayArrow style={{ color: '#0083CA', fontSize: '18px' }} /> :
                                this.state.palybut_type == 1 && row.id == this.state.stopcount ?
                                    <Stop style={{ color: 'red', fontSize: '18px' }} /> : <PlayArrow style={{ color: '#0083CA', fontSize: '18px' }} />


                        }


                    </IconButton>







            },
            {
                name: 'Call',
                width: '50px',
                sortable: true,
                cell: row =>

                    <IconButton


                        onClick={() => this.SaveMissCall(row)}
                    >



                        <PhoneInTalk style={{ color: '#0083CA', fontSize: '18px' }} /> :




                    </IconButton>







            }





        ];
        return (
            <div>

                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '1%', marginTop: '2%', }}>
                    <Grid style={{ width: '22%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Typography style={{ marginRight: '1%', color: '#999999', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>From</Typography>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider

                                style={{ height: '20px', }} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    error={false}
                                    helperText={null}

                                    value={this.state.fdate}
                                    onChange={date => { this.handledobChange(date) }}
                                    format="yyyy/MM/dd"

                                    InputProps={{


                                        style: {

                                            fontSize: 14,
                                            height: 38,
                                            color: '#999999',

                                            background: '#ffffff'

                                        }
                                    }}
                                    inputVariant="outlined"



                                    KeyboardButtonProps={{
                                        style: { marginLeft: -24 }
                                    }}

                                />
                            </MuiPickersUtilsProvider>
                        </ThemeProvider>
                    </Grid>

                    <Grid style={{ width: '22%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Typography style={{ marginRight: '1%', color: '#999999', fontSize: '14px', marginLeft: '1%', fontFamily: 'Roboto-Regular' }}>To</Typography>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider

                                style={{ height: '20px' }} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    error={false}
                                    helperText={null}

                                    value={this.state.tdate}
                                    onChange={date => { this.handledobChange1(date) }}
                                    format="yyyy/MM/dd"

                                    InputProps={{


                                        style: {

                                            fontSize: 14,
                                            height: 38,
                                            color: '#999999',

                                            background: '#ffffff'

                                        }
                                    }}
                                    inputVariant="outlined"



                                    KeyboardButtonProps={{
                                        style: { marginLeft: -24 }
                                    }}

                                />
                            </MuiPickersUtilsProvider>
                        </ThemeProvider>
                    </Grid>
                    <Grid style={{ width: '22%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        {/* <Typography style={{ marginRight: '1%', color: '#999999', fontSize: '14px', marginLeft: '1%', fontFamily: 'Roboto-Regular' }}></Typography>Domain
                          <Grid style={{ width: '300px' }}>
                            <Select ref={(input) => this.menu = input} options={this.state.domaindata} placeholder="Select Domain"

                                inputProps={{
                                    style: { background: '#ffffff', fontSize: '14px', fontFamily: "Roboto-Regular", height: '20px', width: '300px' },


                                }}
                                defaultValue={this.state.domain.length > 0 &&
                                    { label: this.state.domain, value: 0 }
                                }
                                onChange={e => {
                                    this.setState({ domain: e.label, userdata: [] });
                                }}
                            />

                            </Grid>*/}

                    </Grid>
                    <Grid style={{ width: '15%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Button



                            variant="contained"
                            size="small"

                            style={{
                                color: "#ffffff",
                                fontFamily: "Roboto-Medium",
                                borderRadius: "4px",
                                backgroundColor: "#7DAB1C",
                                textTransform: 'none',
                                padding: '1.5%',
                                fontSize: "14px",
                                width: '125px',
                                height: '32px',
                                marginLeft: '5%'

                            }}

                            onClick={() => { this.GetDistWise() }}


                        >

                            Submit
                        </Button>
                    </Grid>
                    <Grid style={{ width: '15%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Button



                            variant="contained"
                            size="small"

                            style={{
                                color: "#ffffff",
                                fontFamily: "Roboto-Medium",
                                borderRadius: "4px",
                                backgroundColor: "#7DAB1C",
                                textTransform: 'none',
                                padding: '1.5%',
                                fontSize: "14px",
                                width: '125px',
                                height: '32px',
                                marginLeft: '5%'

                            }}

                            onClick={() => {
                                this.ExportCallWise(this.state.userdata)
                            }}


                        >

                            Export
                        </Button>
                    </Grid>

                </Grid>

                <Grid style={{ height: 'auto', overflow: 'auto', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', }}>


                    <DataTable

                        columns={columns}
                        data={this.state.userdata}
                        pagination
                        striped
                        responsive
                        paginationPerPage='100'
                        paginationRowsPerPageOptions={[100, 150, 200, 250]}

                        defaultSortFieldId
                        dense

                        customStyles={customStyles}
                        highlightOnHover
                        subHeader
                        subHeaderComponent={<CssTextField
                            autoFocus={true}
                            value={this.state.stext}
                            placeholder="Search"
                            size="small"
                            variant="outlined"
                            onChange={e =>
                                this.MakeFilter(e)
                            }
                        />}
                        subHeaderAlign='left'

                        conditionalRowStyles={conditionalRowStyles}
                    />


                </Grid>
                <Grid style={{ marginTop: '10px', width: '100%', display: 'flex', justifyContent: 'flex-end', marginRight: '5%' }}>

                </Grid>
                <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',
                            display: 'flex',
                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >
                        <Loader type="spinner-circle" bgColor={"#7DAB1C"} color={'#ffc31b'} size={75} />
                    </Box>

                </Modal>


                <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding1}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '45%',
                            height: '25%',
                            padding: '1%',
                            outline: 'none',
                            background: '#ffffff',
                            p: 4,
                            borderRadius: '12px',

                        }}
                    >
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>

                            <Grid style={{ cursor: 'pointer', width: '5%', display: 'flex', justifyContent: 'flex-end' }}>

                                <CloseIcon
                                    onClick={() => this.setState({ islodding1: false })}

                                />
                            </Grid>
                        </Grid>
                        <Loader type="spinner-circle" bgColor={"#7DAB1C"} color={'#ffc31b'} size={75} />
                        <Typography style={{ textAlign: 'center', color: '#111111', fontSize: '18px', fontFamily: 'Roboto-Bold' }} >Please wait - the call is getting generated for you</Typography>
                    </Box>

                </Modal>

                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "Roboto-Bold"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
            </div>
        )

    }
}
