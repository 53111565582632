import React, { Component } from 'react'
import {
    Typography, Grid, MenuItem, Select, Checkbox, TextField, Button, IconButton,
    RadioGroup, Radio, FormControlLabel, Tab, Tabs, Snackbar, SnackbarContent,
    Modal, Box, Switch, FormGroup
} from '@material-ui/core'
import { httpRequest } from '../../../../ui-utils/api'
import DataTable from 'react-data-table-component';
import moment from 'moment'
import { withStyles, styled } from "@material-ui/core/styles";
import Autocomplete from '@mui/material/Autocomplete';
import { EditOutlined, Settings, PlayArrow, DeleteOutlineSharp, Stop } from '@material-ui/icons';
import backendHosts from '../../../../ui-utils/apiConfig'
import { createTheme, ThemeProvider, MuiThemeProvider } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close'
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
    KeyboardTimePicker
} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import * as XLSX from 'xlsx';
const txtstyles = theme => ({




})
const MySwitch = withStyles(theme => ({
    root: {
        '& .MuiSwitch-switchBase': {

            '&.Mui-checked': {
                color: '#7DAB1C',

                '& .MuiSwitch-thumb:before': {
                    backgroundColor: '#7DAB1C'
                },
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: "#7DAB1C"
                },
            },
        },

    },

}))(Switch);
const defaultMaterialTheme = createTheme({
    palette: {
        primary: {
            light: '#7DAB1C',
            main: '#7DAB1C',
            dark: '#7DAB1C',
            contrastText: '#fff',

        },
        secondary: {
            light: '#7DAB1C',
            main: '#7DAB1C',
            dark: '#7DAB1C',
            contrastText: '#000',
        },
    },
});
const Radioheckbox = withStyles(theme => ({
    root: {
        color: "#333333",
        background: "#ffffff",
        "@media(max-width:960px)": {
            background: "#080808",
        },

        '&$checked': {
            color: '#7DAB1C'
        }
    },
    checked: { color: "#7DAB1C", }
}))(Radio);
const customStyles = {
    title: {
        style: {
            fontSize: '14px', // override the row height
        },
    },
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
        stripedStyle: {

            //  background: 'red'



        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#E9F4FF',
            fontSize: '14px',
            color: '#333333',
            height: '45px',
            fontFamily: 'Roboto-Medium'
        },
    },
    cells: {
        style: {
            paddingLeft: '2px', // override the cell padding for data cells
            paddingRight: '2px',


        },
    },
    highlightOnHoverStyle: {
        style: {
            color: 'red',
            background: 'blue',
            backgroundColor: 'yellow'



        },
    },

};
const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    }
};
let keylist = ['0', '1', '2', '3', '4', '5', '6', '7', '8']




const CssTextField = styled(TextField)({
    borderRadius: '23px',
    fontFamily: 'InterRegular',
    background: '#ffff',

    '& .MuiInput-underline:after': {
        borderBottomColor: '#dddddd',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#dddddd',
        },
        '&:hover fieldset': {
            borderColor: '#dddddd',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#dddddd',
        },
    },
});


const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#41AB1C",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 8,
            width: 8,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#41AB1C'
        }
    },
    checked: { color: "#41AB1C", }
}))(Checkbox);

class ivrmenu extends Component {
    constructor(props) {
        super(props)


        this.state = {

            ivrlist: [],
            ivrconfiglist: [],
            pageno: 0,
            ivrconfiglist1: [],
            ivrconfig_uid: 0,
            ivraction: '',
            showerr: false,
            errorMsg: '',
            audiofilename: '',
            audiofiledata: '0',
            welcomeaudiodata: '',
            domaindata: '',
            mysnack: snackeror.mysucc,
            ivrkey: '',
            ivrtype: 'Playback',
            ivruid: '',
            domainuid: '',
            ivrpage: 0,
            ivrname: '',
            ivrname1: '',
            tabvalue: 1,

            ivrconfiglist: [],
            ivrconfig_uid: 0,
            distlist: [],
            subdist: [],
            croplist_k: [],
            croplist_r: [],
            croplist_f: [],
            maintopic: [],

            petlist: [],
            main_top: [],
            subtopic: [],
            subtopic1: [],
            savetype: 0,
            lang: '',

            groupivrname: '',
            groupuid: '',
            groupdate: '',
            grouptime: '',
            groupretry: '',
            grouptemp: false,
            groupsavehist: false,
            numofrows: '0',
            fisherytypes: '',
            croptype: false,

            liveStockTypes: '',
            irrigation: 'None',
            kitchen_garden: "None",
            pushsamivr: 0,
            pushodsivr: 0,
            shtime: '',
            ratingtype: false,
            shdate: '',
            save_user_history_type: false,
            description: '',
            mainaudio: '',
            ivrdesp: '',
            welcomeaudiodataname: '0',
            welconeaudiouid: '0',
            mainaudiouid: '0',
            audionameuid: '0',
            reqexit: true,
            numberlist: '',
            numbercount: 0,
            playbut1: false,
            playbut2: false,
            playbut3: false,
            ivr_id: 0,
            uploadkey: 0,
            uploadpalyback: 0


        }



    }
    urlencode(str) {
        str = (str + '').toString();

        // Tilde should be allowed unescaped in future versions of PHP (as reflected below), but if you want to reflect current
        // PHP behavior, you would need to add ".replace(/~/g, '%7E');" to the following.
        return encodeURIComponent(str)
            .replace('!', '%21')
            .replace('\'', '%27')
            .replace('(', '%28')
            .replace(')', '%29')
            .replace('*', '%2A')
            .replace('%20', '+');
    }
    handelIVRType(e) {



        this.setState({ ivrtype: e.target.value })

    }
    GetIVRList = async (e) => {
        try {
            const roledata1 = await httpRequest({
                endPoint: `/api/lcc/IVRList`,
                method: "get",
                instance: "instanceOne",


            });



            console.log('ivrlist', roledata1.msg)

            if (roledata1.success == true) {

                this.setState({ ivrlist: roledata1.msg, numofrows: roledata1.msg.length })


            }
        } catch (error) {

        }

    }
    GetIVRConfList = async () => {

        try {
            const roledata1 = await httpRequest({
                endPoint: `/api/lcc/IVRConfList`,
                method: "get",
                instance: "instanceOne",


            });

            console.log('A====', roledata1.msg)
            if (roledata1.success == true) {
                this.setState({ ivrconfiglist: roledata1.msg })
            }


        } catch (error) {

        }


    }
    GetAudioFile = async (e) => {

        try {
            const roledata1 = await httpRequest({
                endPoint: `/api/lcc/AudioList`,
                method: "get",
                instance: "instanceOne",


            });



            if (roledata1.success == true) {

                this.setState({ audiolist: roledata1.msg })


            }
        } catch (error) {

        }







    }
    GetDistricts = async () => {

        try {
            const roledata1 = await httpRequest({
                endPoint: `/api/lcc/getDistricts`,
                method: "get",
                instance: "instanceOne",


            });

            console.log('distlist', roledata1.msg)
            // if (roledata1.success == true) {
            this.setState({ distlist: roledata1.msg })
            //}


        } catch (error) {

        }


    }
    CropList = async (e) => {

        try {

            const submitdata = await httpRequest({
                endPoint: `api/lcc/CropList`,
                method: "get",
                instance: "instanceOne",


            });


            this.setState({ croplist_k: submitdata.crop.kharif, croplist_r: submitdata.crop.rabi, croplist_f: submitdata.crop.rabi })



        } catch (error) {

        }

    }

    getMainSubTopics = async () => {
        try {
            const roledata1 = await httpRequest({
                endPoint: `/api/lcc/getMainSubTopics`,
                method: "get",
                instance: "instanceOne",



            });



            console.log('non_agriculture', roledata1.msg[0])

            let non_agri = []
            let subtop = []

            let subtop1 = []

            roledata1.msg[0].non_agriculture.split(',').map((step) =>

                non_agri.push({
                    label: step,
                    grp: 'Non Agriculture'
                })




            )
            roledata1.msg[0].main_topic.split(',').map((step) =>

                non_agri.push({
                    label: step,
                    grp: 'Main Topic'
                })
            )
            roledata1.msg[0].sub_topic.split(',').map((step) =>

                subtop.push({
                    label: step,

                })
            )
            roledata1.msg[0].sub_topic_2.split(',').map((step) =>

                subtop1.push({
                    label: step,

                })
            )



            this.setState({ main_top: non_agri, subtopic: subtop, subtopic1: subtop1 })





            /*  if (roledata1.success == true) {
  
  
                  this.setState({ subdist: roledata1.msg })
  
  
              }*/
        } catch (error) {

        }

    }
    componentDidMount = async () => {

        this.GetIVRConfList()

        this.GetIVRList()
        this.GetDomain()

        this.GetAudioFile()

        this.GetDistricts()

        this.CropList()
        this.getMainSubTopics()

    }

    removeA(arr) {
        var what, a = arguments, L = a.length, ax;
        while (L > 1 && arr.length) {
            what = a[--L];
            while ((ax = arr.indexOf(what)) !== -1) {
                arr.splice(ax, 1);
            }
        }
        return arr;
    }

    AddChild = async (e) => {
        let isok = 0
        const { uploadkey, uploadpalyback, reqexit, mainaudiouid, audionameuid, mainaudio, domainuid, ivrkey, audiofiledata, ivrtype, ivrname1, ivruid } = this.state




        if (!ivrkey.length) {

            isok = 1
            this.setState({ showerr: true, errorMsg: "Select the Action Key", mysnack: snackeror.myerror })

        }


        if (mainaudiouid === "0") {

            isok = 1
            this.setState({ showerr: true, errorMsg: "Select theAudio File", mysnack: snackeror.myerror })

        }

        if (ivrtype === "Playback") {


            if (audionameuid === "0") {
                isok = 1
                this.setState({ showerr: true, errorMsg: "Select the Play Back Audio File", mysnack: snackeror.myerror })

            }




        }


        if (ivrtype === "Domain_change") {
            if (!domainuid.length) {
                isok = 1
                this.setState({ showerr: true, errorMsg: "Select the Domain Name", mysnack: snackeror.myerror })

            }


        }

        if (ivrtype === "IVR") {

            if (!ivruid.length) {
                isok = 1
                this.setState({ showerr: true, errorMsg: "Select the IVR Name", mysnack: snackeror.myerror })

            }

        }

        let item = {}
        if (isok === 0) {

            if (mainaudiouid.includes('VRAudio_') !== 0) {
                this.setState.mainaudiouid = this.state.mainaudioname
            }
            if (audionameuid.includes('VRAudio_') !== 0) {
                this.setState.audionameuid = this.state.audiofilename
            }

            if (ivrtype === "Playback") {

                item = {

                    'key': ivrkey,
                    'audioname': audionameuid,
                    'audiodata': audiofiledata,
                    'keytype': ivrtype,
                    'mainaudioname': mainaudiouid,
                    'mainaudio': mainaudio,
                    'exit': reqexit,
                    'uploadkey': uploadkey,
                    'uploadpalyback': uploadpalyback



                }


            }
            if (ivrtype === "Domain_change") {
                item = {

                    'key': ivrkey,
                    'audioname': domainuid,

                    'keytype': ivrtype,
                    'mainaudioname': mainaudiouid,
                    'mainaudio': mainaudio



                }

            }


            if (ivrtype === "IVR") {
                item = {

                    'key': ivrkey,
                    'audioname': ivruid.split(',')[1],
                    'audiodata': ivruid.split(',')[0],
                    'keytype': ivrtype,
                    'mainaudioname': mainaudiouid,
                    'mainaudio': mainaudio,


                }

            }



            this.removeA(keylist, ivrkey);





            this.state.ivrconfiglist1.push(item)

            this.setState({ mainaudiouid: '', audionameuid: '', audioname: '', mainaudio: '', mainaudioname: '', ivrkey: '', ivraction: '', audiofiledata: '', domainuid: '', ivruid: '' })


        }

    }


    fileSelectHandlerwelcome = async (e) => {
        e.preventDefault();
        let isok = 0
        let load = 0

        if (e.target.files[0].type == 'audio/wav') {
            load = 1

        }

        if (e.target.files[0].type == 'audio/mpeg') {
            load = 1

        }
        if (e.target.files[0].type == 'audio/x-gsm') {
            load = 1

        }
        if (e.target.files[0].type == 'video/mpeg') {
            load = 1

        }



        if (load === 0) {

            isok = 1
            this.setState({ showerr: true, errorMsg: "Only mpeg,pcm,mp3 and wav  files Allowed", mysnack: snackeror.myerror })

        }

        if (isok === 0) {
            let fn = e.target.files[0].name.replace(/[^0-9a-zA-Z.]/g, "_");

            fn = this.urlencode(fn)



            this.state.audiolist.push({ uid: fn, audio_name: fn })
            this.setState({ welcomeaudiodata: e.target.files[0], welcomeaudiodataname: fn, welconeaudiouid: fn })




        }


    }
    fileSelectHandlermain = async (e) => {
        e.preventDefault();
        let isok = 0
        let load = 0

        if (e.target.files[0].type == 'audio/wav') {
            load = 1

        }

        if (e.target.files[0].type == 'audio/mpeg') {
            load = 1

        }
        if (e.target.files[0].type == 'audio/x-gsm') {
            load = 1

        }
        if (e.target.files[0].type == 'video/mpeg') {
            load = 1

        }



        if (load === 0) {

            isok = 1
            this.setState({ showerr: true, errorMsg: "Only mpeg,pcm,mp3 and wav  files Allowed", mysnack: snackeror.myerror })

        }

        if (isok === 0) {
            let fn = e.target.files[0].name.replace(/[^0-9a-zA-Z.]/g, "_");

            fn = this.urlencode(fn)

            this.state.audiolist.push({ uid: fn, audio_name: fn })
            this.setState({ mainaudio: e.target.files[0], mainaudiouid: fn, uploadkey: 1 })
        }
    }

    fileSelectHandler = async (e) => {
        e.preventDefault();
        let isok = 0
        let load = 0

        if (e.target.files[0].type == 'audio/wav') {
            load = 1

        }

        if (e.target.files[0].type == 'audio/mpeg') {
            load = 1

        }
        if (e.target.files[0].type == 'audio/x-gsm') {
            load = 1

        }
        if (e.target.files[0].type == 'video/mpeg') {
            load = 1

        }



        if (load === 0) {

            isok = 1
            this.setState({ showerr: true, errorMsg: "Only mpeg,pcm,mp3 and wav  files Allowed", mysnack: snackeror.myerror })

        }
        if (isok === 0) {
            let fn = e.target.files[0].name.replace(/[^0-9a-zA-Z.]/g, "_");

            fn = this.urlencode(fn)

            this.state.audiolist.push({ uid: fn, audio_name: fn })

            this.setState({ audiofiledata: e.target.files[0], audionameuid: fn, uploadpalyback: 1 })
        }
    }

    closeContactModal() {
        this.setState({ showerr: false })
    }
    CreateIVR() {


        if (this.state.savetype === 0) {
            this.SaveMenu()
        }


        if (this.state.savetype !== 0) {
            this.EditIVRMenu()
        }


    }
    EditIVRMenu = async (e) => {
        const { ivrconfiglist1, lang, ivr_id, ivrconfig_uid } = this.state
        let mainfilename = ""
        let palybackfilename = ""
        const data = new FormData();
        let callaudiocreate = 0
        let a = 0
        let payload = {}
        let menu = []
        let item1 = {}
        console.log("action file name=")

        const menu_data = ivrconfiglist1.map(rname => {


            console.log(rname.keytype)

            let mainfilename = ""
            let palybackfilename = ""

            if (rname.mainaudioname.includes('VRAudio_') == 0) {
                callaudiocreate = 1

                mainfilename = "IVRAudio_" + lang + "_" + rname.mainaudioname
                data.append('file', rname.mainaudio, "IVRAudio_" + lang + "_" + rname.mainaudioname);
            } else {
                mainfilename = rname.mainaudioname
            }
            if (rname.keytype === "Playback") {



                if (rname.audioname.includes('VRAudio_') == 0) {

                    callaudiocreate = 1

                    palybackfilename = "IVRAudio_" + lang + "_" + rname.audioname
                    console.log("After=", palybackfilename)
                    data.append('file', rname.audiodata, "IVRAudio_" + lang + "_" + rname.audioname);
                } else {
                    palybackfilename = rname.audioname
                }

                item1 = {
                    "DTMF": rname.key,
                    "audio": mainfilename,
                    "action": palybackfilename,
                    "exit": rname.exit

                }

            }

            if (rname.keytype === "Domain_change") {

                palybackfilename = rname.audioname
                console.log('Domain Name', rname.audioname)

                if (rname.audioname.includes('OMAIN_') == 0) {
                    palybackfilename = "DOMAIN_" + rname.audioname
                }




                console.log("**********")
                item1 = {
                    "DTMF": rname.key,
                    "audio": mainfilename,
                    "action": palybackfilename


                }
                console.log(item1)

            }

            if (rname.keytype === "IVR") {

                palybackfilename = rname.audiodata
                if (rname.audiodata.includes('VR_') == 0) {
                    palybackfilename = "IVR_" + rname.audiodata
                }

                item1 = {
                    "DTMF": rname.key,
                    "audio": mainfilename,
                    "action": palybackfilename


                }


            }
            menu.push(item1)

            return menu;
        });

        console.log("Edit Menu", menu)

        if (callaudiocreate === 1) {
            let a = localStorage.getItem('lcc_token') || '{}'
            let b = JSON.parse(a)
         

          
            let audio_path = backendHosts.CRM + 'api/lcc/CreateAudio'
            fetch(audio_path, {
                method: 'POST',
                headers: {
                  
                    Authorization: b.token
                  },
                body: data,
            }).then((response) => {
                response.json().then((body) => {
                    let data = body.msg
                    console.log("Responce", data)

                    const newState = data.map(obj => {



                        console.log('name', obj.audio)

                        if (a == 0) {
                            if (obj.status === "failed") {
                                a = 1
                                // this.setState({ showerr: true, errorMsg: obj.audio + " This file already exists", mysnack: snackeror.myerror })
                            }

                            if (obj.status === "success") {

                            }
                        }
                        return obj;
                    });





                });
            });
        }

        const menu1 = {
            "config": ivrconfig_uid,
            "welcome": this.state.welconeaudiouid,

            menu,

        }
        const item = {

            "ivr_id": ivr_id,
            "ivr": menu1

        }
        console.log('final', item)





        try {

            const submitdata = await httpRequest({
                endPoint: `api/lcc/IVRUpdate`,
                method: "post",
                instance: "instanceOne",
                requestBody: item,

            });
            console.log('responce', submitdata.success)
            if (submitdata.success == true) {
                keylist = []
                keylist = ['1', '2', '3', '4', '5', '6', '7', '8']
                this.GetIVRList()
                this.setState({ welconeaudiouid: '', ivrconfiglist1: '', ivrname: '', ivrdesp: '', lang: '', pageno: 0, mainaudiouid: '', audionameuid: '' })
                this.setState({ showerr: true, errorMsg: "New IVR Menu is Created", mysnack: snackeror.mysucc })


            }




        } catch (error) {

        }


    }
    SaveMenu = async (e) => {
        let callaudiocreate = 0
        const { ivr_id, mainaudiouid, ivrname, ivrdesp, lang, ivrconfiglist, audionameuid, welconeaudiouid } = this.state
        const { ivrconfiglist1, ivrconfig_uid, welcomeaudiodata, welcomeaudiodataname } = this.state
        let isok = 0
        const data = new FormData();
        let item1 = {}
        if (!ivrname.length) {

            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter the IVR Name", mysnack: snackeror.myerror })

        }
        if (!ivrdesp.length) {

            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter the IVR Description", mysnack: snackeror.myerror })

        }

        if (!lang.length) {

            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter the IVR Language", mysnack: snackeror.myerror })

        }


        if (!ivrconfig_uid > 0) {

            isok = 1
            this.setState({ showerr: true, errorMsg: "Select the  Global Config", mysnack: snackeror.myerror })

        }


        if (welconeaudiouid === "0") {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Select Welcome audio File", mysnack: snackeror.myerror })

        }

        if (ivrconfiglist1.length < 1) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Configure any one Menu", mysnack: snackeror.myerror })



        }

        if (isok === 0) {

            if (welconeaudiouid.includes('VRAudio_') == 0) {

                callaudiocreate = 1

                this.state.welconeaudiouid = "IVRAudio_" + lang + "_" + welcomeaudiodataname
                data.append('file', welcomeaudiodata, "IVRAudio_" + lang + "_" + welcomeaudiodataname);
            }


        }
        if (welconeaudiouid.length < 1) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Select welcome Audio File", mysnack: snackeror.myerror })

        }



        if (isok === 0) {
            let payload = {}
            let menu = []







            const menu_data = ivrconfiglist1.map(rname => {


                console.log(rname.keytype)

                let mainfilename = ""
                let palybackfilename = ""

                if (rname.mainaudioname.includes('VRAudio_') == 0) {
                    callaudiocreate = 1

                    mainfilename = "IVRAudio_" + lang + "_" + rname.mainaudioname
                    if (rname.uploadkey === 1) {
                        data.append('file', rname.mainaudio, "IVRAudio_" + lang + "_" + rname.mainaudioname);
                    }
                } else {
                    mainfilename = rname.mainaudioname
                }
                if (rname.keytype === "Playback") {



                    if (rname.audioname.includes('VRAudio_') == 0) {

                        callaudiocreate = 1

                        palybackfilename = "IVRAudio_" + lang + "_" + rname.audioname
                        console.log("After=", palybackfilename)
                        if (rname.uploadpalyback === 1) {
                            data.append('file', rname.audiodata, "IVRAudio_" + lang + "_" + rname.audiodata.name);
                        }
                    } else {
                        palybackfilename = rname.audioname
                    }

                    item1 = {
                        "DTMF": rname.key,
                        "audio": mainfilename,
                        "action": "AUDIO_" + palybackfilename,
                        "exit": rname.exit

                    }

                }

                if (rname.keytype === "Domain_change") {

                    console.log("**********")
                    item1 = {
                        "DTMF": rname.key,
                        "audio": mainfilename,
                        "action": "DOMAIN_" + rname.audioname,


                    }
                    console.log(item1)

                }

                if (rname.keytype === "IVR") {
                    item1 = {
                        "DTMF": rname.key,
                        "audio": mainfilename,
                        "action": "IVR_" + rname.audiodata,


                    }


                }
                menu.push(item1)

                return menu;
            });


            console.log('payload', menu)
            let a = 0
            if (callaudiocreate === 1) {
              
                

                
                    let a = localStorage.getItem('lcc_token') || '{}'
                    let b = JSON.parse(a)
                 
                    
                  

                let audio_path = backendHosts.CRM + 'api/lcc/CreateAudio'
                fetch(audio_path, {
                    method: 'POST',
                    headers: {
                     
                        Authorization: b.token
                      },
                    body: data,
                }).then((response) => {
                    response.json().then((body) => {
                        let data = body.msg
                        console.log("Responce", data)

                        const newState = data.map(obj => {



                            console.log('name', obj.audio)

                            if (a == 0) {
                                if (obj.status === "failed") {
                                    a = 1
                                    // this.setState({ showerr: true, errorMsg: obj.audio + " This file already exists", mysnack: snackeror.myerror })
                                }

                                if (obj.status === "success") {

                                }
                            }
                            return obj;
                        });





                    });
                });
            }
            const menu1 = {
                menu,

                "config": ivrconfig_uid,
                "welcome": this.state.welconeaudiouid //"IVRAudio_" + lang + "_" + welcomeaudiodata.name,
            }

            const item = {
                "IVRName": ivrname,
                "IVRDescription": ivrdesp,
                "IVRLanguage": lang,

                "IVR": menu1

            }
            console.log('final', item)





            try {

                const submitdata = await httpRequest({
                    endPoint: `api/lcc/CreateIVR`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: item,

                });
                console.log('responce', submitdata.success)
                if (submitdata.success == true) {
                    keylist = []
                    keylist = ['1', '2', '3', '4', '5', '6', '7', '8']
                    this.GetIVRList()
                    this.setState({ welconeaudiouid: '', ivrconfiglist1: '', ivrname: '', ivrdesp: '', lang: '', pageno: 0, mainaudiouid: '', audionameuid: '' })
                    this.setState({ showerr: true, errorMsg: "New IVR Menu is Created", mysnack: snackeror.mysucc })


                }




            } catch (error) {

            }

        }

    }
    GetDomain = async () => {
        let sql = ""




        sql = "select uid,name from  pad_domains order by name"
        console.log(sql)
        const domainitem = {
            "stmt": sql

        }
        try {
            const domaindata = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: domainitem,

            });


            console.log('domaindata.msg ', domaindata.msg)

            if (domaindata.success == true) {

                this.setState({ domaindata: domaindata.msg })
            }
        } catch (error) {

        }


    }
    ShowSchedule(row) {
        this.setState({ pageno: 2, ivrname: row.ivr_name })

    }
    handleTabChange = (event, value) => {
        this.setState({ tabvalue: value });
    };







    DeleteAudio(label, index) {
        console.log('index', index)
        this.state.ivrconfiglist1.splice(index, 1);
        keylist.push(label.key)
        this.setState({ showerr: true, errorMsg: "Menu Field is Deleted", mysnack: snackeror.myerror })

    }


    EditMenu(row) {
        console.log('Edit', row)
        console.log('Edit****', row.ivr.welcome)

        //this.setState({  pageno: 1, , , ivrconfig_uid:  })
        this.setState({ welconeaudiouid: row.ivr.welcome, pageno: 1, ivr_id: row.uid, savetype: row.uid, ivrname: row.ivr_name, lang: row.ivr_lang, ivrdesp: row.ivr_description, ivrconfig_uid: row.ivr.config })
        const re = row.ivr.menu.map(data => {

            let keytype = "Playback"







            if (data.action.includes('OMAIN') > 0) {

                keytype = "Domain_change"
            }
            if (data.action.includes('VR') > 0) {

                keytype = "IVR"
            }

            if (data.action.includes('AUDIO_IVR') > 0) {

                keytype = "Playback"
            }

            let item = {
                'key': data.DTMF,
                'audioname': data.action,
                'audiodata': data.action,
                'mainaudioname': data.audio,
                'keytype': keytype,
                'edit': 0,
                'exit': data.exit,

            }

            this.state.ivrconfiglist1.push(item)


        })






    }

    DeleteConfig(label, index) {

        console.log(label)
        // actionlist.push(label.action)
        this.state.ivrconfiglist1.splice(index, 1);

        this.setState({ showerr: true, errorMsg: "Config Field is Deleted", mysnack: snackeror.myerror })


    }

    PalyAudio1 = async (e) => {

        const { welconeaudiouid } = this.state
        let isok = 0
        if (welconeaudiouid == 0) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Select the Audio File to Play", mysnack: snackeror.myerror })
        }

        console.log('welconeaudiouid', welconeaudiouid)
        let ringtone = document.getElementById("ringtone");
        if (isok == 0) {
            if (this.state.playbut1 == false) {
                let a = localStorage.getItem('lcc_token') || '{}'
                let b = JSON.parse(a)
                let livetocken = b.token

                let audio_path = backendHosts.CRM + 'api/lcc/PlayBack/IVRAudio/' + welconeaudiouid

                const result = await fetch(audio_path, {
                    headers: {
                        Authorization: livetocken
                    }
                });
                const blob = await result.blob();
                if (blob) {
                    console.log("Auido okk")
                    ringtone.src = URL.createObjectURL(blob);
                    //   ringtone.parentElement.load();

                }
                ringtone.play()
                this.setState({ playbut1: true })
            } else {
                this.setState({ playbut1: false })
                ringtone.pause()

            }


        }

    }


    PalyAudio2 = async (e) => {

        const { mainaudiouid } = this.state
        let isok = 0
        if (mainaudiouid == 0) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Select the Audio File to Play", mysnack: snackeror.myerror })
        }


        if (isok == 0) {
            let ringtone = document.getElementById("ringtone");
            if (this.state.playbut2 == false) {
                let a = localStorage.getItem('lcc_token') || '{}'
                let b = JSON.parse(a)
                let livetocken = b.token
                let audio_path = backendHosts.CRM + 'api/lcc/PlayBack/IVRAudio/' + this.state.welconeaudiouid
                const result = await fetch(audio_path, {
                    headers: {
                        Authorization: livetocken
                    }
                });
                const blob = await result.blob();
                if (blob) {
                    console.log("Auido okk")
                    ringtone.src = URL.createObjectURL(blob);
                    //   ringtone.parentElement.load();

                }
                ringtone.play()
                this.setState({ playbut2: true })
            } else {
                this.setState({ playbut2: false })
                ringtone.pause()
            }


        }

    }


    PalyAudio3 = async (e) => {

        const { audionameuid } = this.state
        let isok = 0
        if (audionameuid == 0) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Select the Audio File to Play", mysnack: snackeror.myerror })
        }


        if (isok == 0) {
            let ringtone = document.getElementById("ringtone");
            if (this.state.playbut3 == false) {
                let a = localStorage.getItem('lcc_token') || '{}'
                let b = JSON.parse(a)
                let livetocken = b.token
                let audio_path = backendHosts.CRM + 'api/lcc/PlayBack/IVRAudio/' + this.state.welconeaudiouid
                const result = await fetch(audio_path, {
                    headers: {
                        Authorization: livetocken
                    }
                });
                const blob = await result.blob();
                if (blob) {
                    console.log("Auido okk")
                    ringtone.src = URL.createObjectURL(blob);
                    //   ringtone.parentElement.load();

                }

                ringtone.play()
                this.setState({ playbut3: true })
            } else {
                this.setState({ playbut3: false })
                ringtone.pause()

            }


        }

    }
    render() {

        const { audiolist } = this.state

        const profile_source_name = [


            { label: 'adapt' },
            { label: 'adapt_krushak_fs' },
            { label: 'adapt_krushak_od' },
            { label: 'automated' },
            { label: 'csisa' },
            { label: 'csisa_jul2020' },
            { label: 'csisa_jul2021' },
            { label: 'dafe_krushak_od' },
            { label: 'farmers_krishi_jan2020' },
            { label: 'field' },
            { label: 'image' },
            { label: 'ko_api' },
            { label: 'krushak_od' },
            { label: 'pradan' },
            { label: 'rcm_kharif_2019' },
            { label: 'rcm_rabi_2019' },
            { label: 'rct_od_kharif' },
            { label: 'None' },
            { label: '5' },
            { label: 'Missing' },


        ];
        const columns = [

            {
                name: '',
                width: '100px',
                cell: row =>


                    <WhiteBackgroundCheckbox

                        id="chk1"

                    />


            },
            {
                name: 'Name',

                sortable: true,
                cell: row =>
                    row.ivr_name
            },



            {
                name: 'Description',

                sortable: true,
                cell: row =>
                    row.ivr_description
            },
            {
                name: 'Language',

                sortable: true,
                cell: row =>
                    row.ivr_lang
            },
            {
                name: 'From Date',

                sortable: true,
                cell: row =>
                    row.active_from
            },

            {
                name: 'Action',
                width: '75px',
                sortable: true,
                cell: row =>
                    <IconButton


                        onClick={() => this.EditMenu(row)}
                    >
                        <EditOutlined style={{ color: '#0083CA', fontSize: '18px' }} />
                    </IconButton>
            },


        ];
        const { pageno } = this.state
        return (

            <div>


                {
                    pageno == 1 &&
                    <div>

                        <Grid style={{ width: '100%', alignItems: 'center', background: '#F7F9FA' }}>
                            <Grid style={{ width: '100%', marginTop: '1%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                <Grid style={{ width: '23%' }}>
                                    <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>IVR Name</Typography>
                                </Grid>
                                <Grid style={{ width: '23%' }}>
                                    <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>Description</Typography>
                                </Grid>
                                <Grid style={{ width: '23%' }}>
                                    <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>Language</Typography>
                                </Grid>
                                <Grid style={{ width: '23%' }}>
                                    <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>Config</Typography>
                                </Grid>
                            </Grid>

                            <Grid style={{ width: '100%', marginTop: '1%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>

                                <Grid style={{ width: '23%' }}>
                                    <CssTextField focusColor="#999999"


                                        fullWidth
                                        inputProps={{
                                            style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                        }}
                                        size="small"

                                        variant="outlined"
                                        placeholder="Enter IVR Name"
                                        value={this.state.ivrname}

                                        onChange={e => {

                                            this.setState({ ivrname: e.target.value });

                                        }}
                                    />
                                </Grid>
                                <Grid style={{ width: '23%' }}>
                                    <CssTextField focusColor="#999999"


                                        fullWidth
                                        inputProps={{
                                            style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                        }}
                                        size="small"

                                        variant="outlined"
                                        placeholder="Enter IVR Description"
                                        value={this.state.ivrdesp}

                                        onChange={e => {

                                            this.setState({ ivrdesp: e.target.value });

                                        }}
                                    />
                                </Grid>

                                <Grid style={{ width: '23%' }}>
                                    <Select value={this.state.lang}
                                        displayEmpty
                                        variant="outlined"
                                        fullWidth
                                        size="small"

                                        placeholder='Select11'
                                        style={{ height: '41px', }}
                                        inputProps={{
                                            style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                        }}

                                        onChange={e => {
                                            this.setState({ lang: e.target.value, domain: e.target.lable });
                                            //this.handleDomain(e)

                                        }}

                                    >
                                        <MenuItem value={'Odia'}>Odia</MenuItem>
                                        <MenuItem value={'Sambhalpuri'}>Sambhalpuri</MenuItem>



                                    </Select>
                                </Grid>
                                <Grid style={{ width: '23%' }}>
                                    <Select
                                        value={this.state.ivrconfig_uid}
                                        displayEmpty
                                        variant="outlined"
                                        fullWidth
                                        size="small"

                                        placeholder='Select11'
                                        style={{ height: '41px', }}
                                        inputProps={{
                                            style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                        }}

                                        onChange={e => {
                                            this.setState({ ivrconfig_uid: e.target.value, domain: e.target.lable });
                                            //this.handleDomain(e)

                                        }}

                                    > <MenuItem value={'0'}>Select</MenuItem>
                                        {this.state.ivrconfiglist.map(rname =>

                                            <option style={{ padding: '5px', color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }} value={rname.uid} label={rname.name}>{rname.name}</option>
                                        )};


                                    </Select>
                                </Grid>
                            </Grid>

                            <Grid style={{ width: '100%', marginLeft: '2%', marginTop: '2%', display: 'flex' }}>
                                <Grid style={{ width: '12%' }}>
                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>IVR Menu Setting</Typography>
                                </Grid>
                                <Grid style={{ width: '85%', borderBottom: '1px solid rgba(153, 153, 153, 0.5)',display:'flex',justifyContent:'flex-end' }}>
                                <Typography style={{ color: 'red', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Caution: The audio file should be .wav with 8000 sample rate & mono channel</Typography>
                                


                                </Grid>

                            </Grid>

                            <Grid style={{ width: '100%' }}>





                                <Grid style={{ width: '95%', marginLeft: '2%', marginTop: '.75%', borderRadius: '4px' }}>
                                    <Grid style={{ width: '100%', display: 'flex' }}>
                                        <Grid style={{ width: '9%' }}>
                                            <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>Welcome audio</Typography>
                                        </Grid>

                                    </Grid>



                                    <Grid style={{ width: '90%', marginTop: '1%', display: 'flex', alignItems: 'center' }}>
                                        <Select value={this.state.welconeaudiouid}
                                            displayEmpty
                                            variant="outlined"
                                            fullWidth
                                            size="small"

                                            placeholder='Select11'
                                            style={{ height: '30px', }}
                                            inputProps={{
                                                style: { fontSize: '12px', fontFamily: "Roboto-Regular" },


                                            }}

                                            onChange={e => {
                                                this.setState({ welconeaudiouid: e.target.value });
                                                //this.handleDomain(e)

                                            }}

                                        >
                                            <MenuItem value={'0'}>Select</MenuItem>
                                            {audiolist.map(rname =>

                                                <option style={{ padding: '5px', color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }} value={rname.audio_name} label={rname.audio_name}>{rname.audio_name}</option>
                                            )};



                                        </Select>
                                        <input
                                            accept="audio/mp3"
                                            name="welcomeaudio"
                                            style={{ display: 'none' }}
                                            id="welcomeaudio"
                                            multiple
                                            type="file"
                                            onClick={e => e.target.value = ''}
                                            onChange={e => { this.fileSelectHandlerwelcome(e) }}
                                        />
                                        <label htmlFor="welcomeaudio">
                                            <Button

                                                variant="text"
                                                component="span"
                                                style={{
                                                    color: "#7DAB1C",
                                                    fontFamily: "Roboto-Medium",
                                                    borderRadius: "4px",
                                                    backgroundColor: "#ffffff",
                                                    textTransform: 'none',
                                                    border: '1px solid #7DAB1C',
                                                    marginLeft: '5px',
                                                    fontSize: "12px",

                                                    height: '30px',

                                                }}



                                            >+
                                            </Button>
                                        </label>
                                        <IconButton
                                            style={{
                                                width: '50px',
                                                height: '30px', borderRadius: "4px", marginLeft: '1px', fontSize: '10px'
                                            }}

                                            onClick={() => this.PalyAudio1()}
                                        >
                                            {this.state.playbut1 ?
                                                <Stop style={{
                                                    color: 'red', fontSize: '20px'

                                                }} />
                                                :
                                                <PlayArrow style={{
                                                    color: '#92A4C1', fontSize: '20px'

                                                }}
                                                />
                                            }
                                        </IconButton>


                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid style={{ width: '100%', marginTop: '2%', }}>
                            <Grid style={{ width: '98%', borderRadius: '4px', border: '1px solid rgba(153, 153, 153, 0.5)' }}>

                                <Grid style={{ width: '100%', display: 'flex', marginLeft: '2%', marginTop: '2%', alignItems: 'center' }}>


                                    <Grid style={{ width: '15%', }}>
                                        <Select value={this.state.ivrkey}
                                            displayEmpty
                                            variant="outlined"
                                            fullWidth
                                            size="small"

                                            placeholder='Select11'
                                            style={{ height: '30px', }}
                                            inputProps={{
                                                style: { fontSize: '12px', fontFamily: "Roboto-Regular" },


                                            }}

                                            onChange={e => {
                                                this.setState({ ivrkey: e.target.value });
                                                //this.handleDomain(e)

                                            }}

                                        >

                                            {keylist.map(rname =>

                                                <option style={{ padding: '5px', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }} value={rname} label={rname}>{rname}</option>
                                            )};



                                        </Select>
                                    </Grid>
                                    <Grid style={{ width: '75%', display: 'flex', marginLeft: '2%', alignItems: 'center' }}>



                                        <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                            <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>Audio File</Typography>
                                            <Select value={this.state.mainaudiouid}
                                                displayEmpty
                                                variant="outlined"

                                                size="small"

                                                placeholder='Select11'
                                                style={{ height: '30px', marginLeft: '5px', width: '75%' }}
                                                inputProps={{
                                                    style: { fontSize: '12px', fontFamily: "Roboto-Regular" },


                                                }}

                                                onChange={e => {
                                                    this.setState({ mainaudiouid: e.target.value, uploadkey: 0 });
                                                    //this.handleDomain(e)

                                                }}

                                            >
                                                <MenuItem value={'0'}>Select</MenuItem>
                                                {audiolist.map(rname =>

                                                    <option style={{ padding: '5px', color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }} value={rname.audio_name} label={rname.audio_name}>{rname.audio_name}</option>
                                                )};



                                            </Select>
                                            <input
                                                accept="audio/mp3"
                                                name="mainaudio"
                                                style={{ display: 'none' }}
                                                id="mainaudio"
                                                multiple
                                                type="file"
                                                onClick={e => e.target.value = ''}
                                                onChange={e => { this.fileSelectHandlermain(e) }}
                                            />
                                            <label htmlFor="mainaudio">
                                                <Button

                                                    variant="text"
                                                    component="span"
                                                    style={{
                                                        color: "#7DAB1C",
                                                        fontFamily: "Roboto-Medium",
                                                        borderRadius: "4px",
                                                        backgroundColor: "#ffffff",
                                                        textTransform: 'none',
                                                        border: '1px solid #7DAB1C',

                                                        fontSize: "12px",
                                                        marginLeft: '5px',
                                                        height: '30px',

                                                    }}



                                                >+
                                                </Button>
                                            </label>
                                            <IconButton
                                                style={{
                                                    width: '50px',
                                                    height: '30px', borderRadius: "4px", marginLeft: '1px', fontSize: '10px'
                                                }}

                                                onClick={() => this.PalyAudio2()}
                                            >

                                                {
                                                    this.state.playbut2 ?
                                                        <Stop style={{
                                                            color: 'red', fontSize: '20px'
                                                        }} />
                                                        :
                                                        <PlayArrow style={{
                                                            color: '#92A4C1', fontSize: '20px'
                                                        }} />
                                                }
                                            </IconButton>


                                        </Grid>

                                    </Grid>

                                    <Grid style={{ width: '10%', display: 'flex', justifyContent: 'flex-end', marginRight: '2%' }}>

                                        <Button

                                            variant="text"
                                            component="span"
                                            style={{
                                                color: "#ffffff",
                                                fontFamily: "Roboto-Medium",
                                                borderRadius: "4px",
                                                backgroundColor: "#41AB1C",
                                                textTransform: 'none',
                                                marginRight: '15px',

                                                fontSize: "12px",
                                                width: '150px',
                                                height: '30px',

                                            }}
                                            onClick={e => {

                                                this.AddChild()

                                            }}


                                        >+  Add Child
                                        </Button>

                                    </Grid>
                                </Grid>
                                <Grid style={{ width: '100%', marginLeft: '2%', display: 'flex', alignItems: 'center', marginTop: '1%' }}>
                                    <RadioGroup
                                        row
                                        defaultValue="Internal"
                                        value={this.state.ivrtype}


                                        onChange={e =>
                                            this.handelIVRType(e)


                                        }
                                    >
                                        <FormControlLabel value="Playback" control={<Radioheckbox size='small' style={{ fontSize: '21px', }} />} label={<Typography style={{ color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Playback</Typography>} />
                                        <FormControlLabel value="Domain_change" control={<Radioheckbox size='small' style={{}} />} label={<Typography style={{ color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Domain  change</Typography>} />
                                        <FormControlLabel value="IVR" control={<Radioheckbox size='small' style={{}} />} label={<Typography style={{ color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>IVR</Typography>} />







                                    </RadioGroup>
                                </Grid>
                                <Grid style={{ width: '100%', marginLeft: '2%', display: 'flex', alignItems: 'center', marginTop: '0.5%', marginBottom: '2%' }}>

                                    {
                                        this.state.ivrtype === "Playback" &&
                                        <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                            <Grid style={{ width: '6%' }}>
                                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>Play Back</Typography>
                                            </Grid>
                                            <Grid style={{ width: '90%', display: 'flex', alignItems: 'center', marginLeft: '2%' }}>
                                                <Select value={this.state.audionameuid}
                                                    displayEmpty
                                                    variant="outlined"

                                                    size="small"

                                                    placeholder='Select11'
                                                    style={{ height: '30px', marginLeft: '5px', width: '75%' }}
                                                    inputProps={{
                                                        style: { fontSize: '12px', fontFamily: "Roboto-Regular" },


                                                    }}

                                                    onChange={e => {
                                                        this.setState({ audionameuid: e.target.value, uploadpalyback: 0 });
                                                        //this.handleDomain(e)

                                                    }}

                                                >
                                                    <MenuItem value={'0'}>Select</MenuItem>
                                                    {audiolist.map(rname =>

                                                        <option style={{ padding: '5px', color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }} value={rname.audio_name} label={rname.audio_name}>{rname.audio_name}</option>
                                                    )};



                                                </Select>
                                                <input
                                                    accept="audio/mp3"
                                                    name="g9"
                                                    style={{ display: 'none' }}
                                                    id="g9"
                                                    multiple
                                                    type="file"
                                                    onClick={e => e.target.value = ''}
                                                    onChange={e => { this.fileSelectHandler(e) }}
                                                />
                                                <label htmlFor="g9">
                                                    <Button

                                                        variant="text"
                                                        component="span"
                                                        style={{
                                                            color: "#7DAB1C",
                                                            fontFamily: "Roboto-Medium",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#ffffff",
                                                            textTransform: 'none',
                                                            border: '1px solid #7DAB1C',

                                                            fontSize: "12px",
                                                            marginLeft: '5px',
                                                            height: '30px',

                                                        }}



                                                    >+
                                                    </Button>
                                                </label>
                                                <IconButton
                                                    style={{
                                                        width: '50px',
                                                        height: '30px', borderRadius: "4px", marginLeft: '1px', fontSize: '10px'
                                                    }}

                                                    onClick={() => this.PalyAudio3()}
                                                >
                                                    {this.state.playbut3 ?
                                                        <Stop style={{
                                                            color: 'red', fontSize: '20px'
                                                        }} />
                                                        :
                                                        <PlayArrow style={{
                                                            color: '#92A4C1', fontSize: '20px'
                                                        }} />
                                                    }
                                                </IconButton>


                                                <FormGroup>
                                                    <FormControlLabel style={{ color: '#999999', fontSize: '10px', fontFamily: 'Roboto-Medium' }}
                                                        control={<MySwitch
                                                            checked={this.state.reqexit}
                                                            inputProps={{ color: 'yellow' }}



                                                            onClick={() => this.setState({ reqexit: !this.state.reqexit })}
                                                        />} label="Exit" />

                                                </FormGroup>

                                            </Grid>
                                        </Grid>

                                    }

                                    {
                                        this.state.ivrtype === "Domain_change" &&
                                        <Grid style={{ width: '95%', display: 'flex', alignItems: 'center' }}>
                                            <Grid style={{ width: '10%' }}>
                                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>Select Domain</Typography>
                                            </Grid>
                                            <Grid style={{ width: '20%', display: 'flex', alignItems: 'center', marginLeft: '2%' }}>
                                                <Select value={this.state.domainuid}
                                                    displayEmpty
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"

                                                    placeholder='Select11'
                                                    style={{ height: '30px', }}
                                                    inputProps={{
                                                        style: { fontSize: '12px', fontFamily: "Roboto-Regular" },


                                                    }}

                                                    onChange={e => {
                                                        this.setState({ domainuid: e.target.value });
                                                        //this.handleDomain(e)

                                                    }}

                                                >
                                                    <MenuItem value={'0'}>Select</MenuItem>
                                                    {this.state.domaindata.map(rname =>

                                                        <option style={{ padding: '5px', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }} value={rname.name}>{rname.name}</option>
                                                    )};



                                                </Select>
                                            </Grid>
                                        </Grid>

                                    }

                                    {
                                        this.state.ivrtype === "IVR" &&
                                        <Grid style={{ width: '95%', display: 'flex', alignItems: 'center' }}>
                                            <Grid style={{ width: '10%' }}>
                                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>Select IVR</Typography>
                                            </Grid>
                                            <Grid style={{ width: '20%', display: 'flex', alignItems: 'center', marginLeft: '2%' }}>
                                                <Select value={this.state.ivruid}
                                                    displayEmpty
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"

                                                    placeholder='Select11'
                                                    style={{ height: '30px', }}
                                                    inputProps={{
                                                        style: { fontSize: '12px', fontFamily: "Roboto-Regular" },


                                                    }}

                                                    onChange={e => {
                                                        this.setState({ ivruid: e.target.value, ivrname1: e.target.label });


                                                    }}

                                                >
                                                    <MenuItem value={'0'}>Select</MenuItem>
                                                    {this.state.ivrlist.map(rname =>

                                                        <option style={{ padding: '5px', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }} value={rname.uid + "," + rname.ivr_name} name={rname.ivr_name}>{rname.ivr_name}</option>
                                                    )};



                                                </Select>
                                            </Grid>
                                        </Grid>

                                    }
                                </Grid>

                            </Grid>
                        </Grid>





                        <Grid style={{ width: '95%', display: 'flex', justifyContent: 'center' }}>
                            <Grid style={{ width: '100%' }}>
                                <Grid style={{ marginTop: '1%', width: '100%', background: '#E9F4FF', padding: '1%', display: 'flex', borderBottom: '1px solid #999999' }}>
                                    <Grid style={{ width: '15%', marginLeft: '1%' }}>
                                        <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Key</Typography>
                                    </Grid>
                                    <Grid style={{ width: '15%' }}>
                                        <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Type</Typography>
                                    </Grid>
                                    <Grid style={{ width: '60%' }}>
                                        <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Audio File</Typography>
                                    </Grid>
                                    <Grid style={{ width: '10%' }}>

                                    </Grid>

                                </Grid>

                                {this.state.ivrconfiglist1.map((label, index) => {

                                    return (
                                        <div>
                                            <Grid style={{ width: '100%', display: 'flex', borderBottom: '1px solid #999999', background: '#ffffff', alignItems: 'center' }}>
                                                <Grid style={{ width: '15%', marginLeft: '1%' }}>
                                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>{label.key}</Typography>
                                                </Grid>
                                                <Grid style={{ width: '15%' }}>
                                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>{label.keytype}</Typography>
                                                </Grid>
                                                <Grid style={{ width: '60%' }}>
                                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>{label.mainaudioname}</Typography>
                                                </Grid>

                                                <Grid style={{ width: '10%' }}>
                                                    <IconButton


                                                        onClick={() => this.PalyAudio(label, index)}
                                                    >
                                                        <PlayArrow style={{ color: '#92A4C1', fontSize: '20px' }} />
                                                    </IconButton>
                                                    <IconButton


                                                        onClick={() => this.DeleteAudio(label, index)}
                                                    >
                                                        <DeleteOutlineSharp style={{ color: 'red', fontSize: '18px' }} />
                                                    </IconButton>

                                                </Grid>
                                            </Grid>
                                        </div>
                                    )
                                })}

                            </Grid>
                        </Grid>

                        <Grid style={{ width: '95%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '2%' }}>



                            <Button



                                variant="contained"
                                size="small"

                                style={{
                                    color: "#ffffff",
                                    fontFamily: "Roboto-Medium",
                                    borderRadius: "4px",
                                    backgroundColor: "#41AB1C",
                                    textTransform: 'none',
                                    padding: '1.5%',
                                    fontSize: "14px",
                                    width: '150px',
                                    height: '32px',
                                    marginLeft: '3%'

                                }}

                                onClick={() => { this.CreateIVR() }}


                            >

                                Save
                            </Button>
                        </Grid>
                    </div>
                }

                {
                    pageno == 0 &&
                    <Grid style={{ width: '100%', padding: '1%', justifyContent: 'center' }}>


                        <Grid style={{ width: '95%', display: 'flex', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', padding: '2%' }}>
                            <Grid style={{ width: '75%', }}>
                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>{this.state.numofrows} IVR</Typography>
                            </Grid>
                            <Grid style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>

                                <Button



                                    variant="contained"
                                    size="small"

                                    style={{
                                        color: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        borderRadius: "4px",
                                        backgroundColor: "#41AB1C",
                                        textTransform: 'none',
                                        padding: '1.5%',
                                        fontSize: "14px",
                                        width: '150px',
                                        height: '32px',
                                        marginLeft: '5%'

                                    }}

                                    onClick={() => { this.setState({ pageno: 1, ivrconfiglist1: [] }) }}


                                >

                                    Add Menu
                                </Button>


                            </Grid>

                        </Grid>
                        <Grid style={{ height: 'auto', overflow: 'auto', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', }}>


                            <DataTable

                                columns={columns}
                                data={this.state.ivrlist}
                                pagination
                                striped
                                responsive
                                paginationPerPage='30'

                                dense

                                customStyles={customStyles}
                                highlightOnHover



                            />


                        </Grid>
                    </Grid>
                }
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "Roboto-Bold"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
                <Modal
                    sx={{ border: 'none' }}




                    open={this.state.addgroup}





                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '45%',
                            height: 'auto',
                            background: '#fffbee',
                            outline: 'none',
                            p: 4,
                            borderRadius: '12px',
                            padding: '2%'
                        }}
                    >

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Grid style={{ width: '90%', display: 'flex', justifyContent: 'center' }}>
                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Create Group</Typography>
                            </Grid>
                            <Grid style={{ width: '10%', display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton
                                    aria-label="close"

                                    onClick={() => this.setState({ addgroup: false })}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Grid style={{ width: '90%', display: 'flex', alignItems: 'center' }}>
                                <Grid style={{ width: '18%' }}>
                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Group Name</Typography>
                                </Grid>
                                <Grid style={{ width: '70%' }}>
                                    <CssTextField focusColor="#999999"
                                        autoFocus
                                        fullWidth
                                        inputProps={{
                                            style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                        }}
                                        size="small"

                                        variant="outlined"
                                        placeholder="Enter Name"
                                        value={this.state.gname}

                                        onChange={e => {

                                            this.setState({ gname: e.target.value });

                                        }}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid style={{ marginTop: '1%', width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Grid style={{ width: '90%', display: 'flex', alignItems: 'center' }}>
                                <Grid style={{ width: '60%' }}>
                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Group file (CSV or XLS with "phone" column)</Typography>
                                </Grid>
                                <Grid style={{ width: '29%', display: 'flex', justifyContent: 'center' }}>
                                    <input

                                        name="img3"
                                        style={{ display: 'none' }}
                                        id="raised-button-file3"
                                        multiple
                                        type="file"
                                        onClick={e => e.target.value = ''}
                                        onChange={e => { this.Getxlsnumber(e) }}
                                    />
                                    <label htmlFor="raised-button-file3">
                                        <Button

                                            variant="text"
                                            component="span"
                                            style={{
                                                color: "#333333",
                                                fontFamily: "Roboto-Medium",
                                                borderRadius: "4px",
                                                backgroundColor: "#E7EAF0",
                                                textTransform: 'none',

                                                fontSize: "14px",
                                                width: '150px',
                                                height: '40px',

                                            }}



                                        >Upload XLS
                                        </Button>
                                    </label>

                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', marginTop: '2%' }}>
                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>{this.state.numbercount} Found</Typography>
                        </Grid>

                        <Grid style={{ width: '100%', display: 'flex', marginTop: '2%' }}>
                            <Grid style={{ width: '90%', display: 'flex', justifyContent: 'flex-end' }}>

                                <Button



                                    variant="contained"
                                    size="small"

                                    style={{
                                        color: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        borderRadius: "4px",
                                        backgroundColor: "#7DAB1C",
                                        textTransform: 'none',
                                        padding: '1.5%',
                                        fontSize: "14px",
                                        width: '100px',
                                        height: '32px',
                                        marginLeft: '5%'

                                    }}

                                    onClick={(e) => { this.SaveGroup(e) }}


                                >

                                    Save
                                </Button>

                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            </div >
        )

    }
}
export default (withStyles(txtstyles)((ivrmenu)));