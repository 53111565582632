import React, { useContext } from 'react'
import { FormContext } from '../../FormContext';
import {
    Checkbox, Typography, FormGroup, FormControlLabel, Grid
} from '@material-ui/core'

import { withStyles, styled } from "@material-ui/core/styles";
const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#41AB1C",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 5,
            width: 5,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#41AB1C'
        }
    },
    checked: { color: "#41AB1C", }
}))(Checkbox);

const Checkbox1 = ({ field_id, field_label, field_value, field_options }) => {
    //const { handleChange } = useContext(FormContext)

    return (
        <div>
            <Typography style={{ fontSize: '14px', fontFamily: "Roboto-Regular", color: '#333333' }}>{field_label}</Typography>
            <div >

                <FormGroup>
                    <Grid style={{ width: '100%', display: 'flex' }}>
                        {
                            field_options.split(',').map((step) =>



                                <FormControlLabel style={{ display: 'flex' }} control={<WhiteBackgroundCheckbox />} label={step} />



                            )
                        }
                    </Grid>
                </FormGroup>

            </div>
        </div>
    )
}

export default Checkbox1
