import React, { Component } from 'react'
import { TextField, Modal, Box, Typography, Grid, Button, Checkbox, IconButton, Snackbar, SnackbarContent } from '@material-ui/core'
import { withStyles, styled } from "@material-ui/core/styles";
import { httpRequest } from '../../../ui-utils/api'
import DataTable from 'react-data-table-component';
import { EditOutlined, Settings } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close'

const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#41AB1C",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 10,
            width: 10,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#41AB1C'
        }
    },
    checked: { color: "#41AB1C", }
}))(Checkbox);
const conditionalRowStyles = [
    {
        when: row => row.account_status == "E",
        style: {
            backgroundColor: 'green',
            color: 'white',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    },

];

const customStyles = {
    title: {
        style: {
            fontSize: '14px', // override the row height
        },
    },
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
        stripedStyle: {

            //  background: 'red'



        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#E9F4FF',
            fontSize: '14px',
            color: '#333333',
            height: '45px',
            fontFamily: 'Roboto-Medium'
        },
    },
    cells: {
        style: {
            paddingLeft: '2px', // override the cell padding for data cells
            paddingRight: '2px',


        },
    },
    highlightOnHoverStyle: {
        style: {
            color: 'red',
            background: 'blue',
            backgroundColor: 'yellow'



        },
    },

};


const txtstyles = theme => ({




})


const CssTextField = styled(TextField)({
    borderRadius: '12px',


    '& label.Mui-focused': {
        color: '#999999',

    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#999999',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#999999',
        },
        '&:hover fieldset': {
            borderColor: '#999999',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#999999',
        },
    },
});
const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    }
};

class newdomain extends Component {
    constructor(props) {
        super(props)



        this.state = {

            domaindata: [],
            numofrows: 0,
            showadd_domain: false,
            domainename: '',
            domaindes: '',
            savetype: 0,
            mysnack: snackeror.mysucc,
            showconfig: false,
            config_did: ''

        }
    }

    EditDomain(row) {
        this.setState({ showadd_domain: true, savetype: row.uid, domainename: row.name, domaindes: row.description })

    }
    ShowConfig(row) {

        this.setState({ showconfig: true, savetype: row.uid, config_did: row.config_did, gwid: row.gwlist_id })
    }
    GetDomain = async () => {
        let sql = "select uid,name,description,gwlist_id,config_did from  pad_domains order by uid"
        console.log(sql)



        const item = {
            "stmt": sql

        }







        try {
            const domaindata = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: item,

            });


            if (domaindata.success == true) {

                this.setState({ domaindata: domaindata.msg, numofrows: domaindata.msg.length })
            }
        } catch (error) {

        }
    }

    componentDidMount = async () => {
        this.GetDomain()
    }

    closeContactModal() {
        this.setState({ showerr: false })
    }
    AddDomain = async () => {
        const { savetype, domainename, domaindes } = this.state
        let isok = 0

        if (!domainename.length) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter Domain Name", mysnack: snackeror.myerror })
        }

        if (!domaindes.length && isok == 0) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter Domain description", mysnack: snackeror.myerror })
        }


        if (isok == 0 & savetype > 0) {
            let sql = "update pad_domains set name='" + domainename.toString().toUpperCase() + "', description='" + domaindes.toString().toUpperCase() + "' where uid=" + savetype + " returning 1 as result"
            console.log(sql)
            const item = {
                "stmt": sql

            }
            try {
                const editrole = await httpRequest({
                    endPoint: `api/db/stmt`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: item,

                });

                console.log(editrole)
                if (editrole.success == true) {
                    this.setState({ showerr: true, errorMsg: "Domain Modifyed successfully", mysnack: snackeror.mysucc })
                    this.setState({ domainename: '', domaindes: '', showadd_domain: false, savetype: 0 })
                    this.GetDomain()
                }
                if (editrole.success == false) {
                    this.setState({ showerr: true, errorMsg: "Something went wrong please try again", mysnack: snackeror.myerror })
                    this.setState({ savetype: 0 })
                }

            } catch (error) {

            }

        }

        if (isok == 0 & savetype == 0) {
            let sql = "insert into pad_domains(name,description) values('" + domainename.toString().toUpperCase() + "','" + domaindes.toString().toUpperCase() + "') returning 1 as result"
            console.log(sql)
            const item = {
                "stmt": sql

            }
            try {
                const adddomian = await httpRequest({
                    endPoint: `api/db/stmt`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: item,

                });

                console.log(adddomian)
                if (adddomian.success == true) {
                    this.setState({ showerr: true, errorMsg: "New Domian added successfully", mysnack: snackeror.mysucc })
                    this.setState({ domainename: '', domaindes: '', showadd_domain: false })
                    this.GetRoles()
                }
                if (adddomian.success == false) {
                    this.setState({ showerr: true, errorMsg: "Something went wrong please try again", mysnack: snackeror.myerror })
                }

            } catch (error) {

            }

        }

    }

    AddConfig = async () => {
        const { savetype, gwid, config_did } = this.state
        let isok = 0

        if (!gwid.length) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter GateWay ID", mysnack: snackeror.myerror })
        }

        if (!config_did.length && isok == 0) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter Config DID", mysnack: snackeror.myerror })
        }
        if (isok == 0 & savetype > 0) {
            let sql = "update pad_domains set gwlist_id='" + gwid + "', config_did='" + config_did + "' where uid=" + savetype + " returning 1 as result"
            console.log(sql)
            const item = {
                "stmt": sql

            }
            try {
                const editrole = await httpRequest({
                    endPoint: `api/db/stmt`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: item,

                });

                console.log(editrole)
                if (editrole.success == true) {
                    this.setState({ showerr: true, errorMsg: "Domain Config Modifyed successfully", mysnack: snackeror.mysucc })
                    this.setState({ gwid: '', config_did: '', showconfig: false, savetype: 0 })

                }
                if (editrole.success == false) {
                    this.setState({ showerr: true, errorMsg: "Something went wrong please try again", mysnack: snackeror.myerror })

                }

            } catch (error) {

            }

        }


    }
    render() {
        const columns = [

            {
                name: '',
                width: '100px',
                cell: row =>


                    <WhiteBackgroundCheckbox

                        id="chk1"

                    />


            },
            {
                name: 'UID',

                sortable: true,
                cell: row =>
                    row.uid
            },



            {
                name: 'Name',

                sortable: true,
                cell: row =>
                    row.name
            },
            {
                name: 'Description',

                sortable: true,
                cell: row =>
                    row.description
            },

            {
                name: 'Action',
                width: '75px',
                sortable: true,
                cell: row =>
                    <IconButton


                        onClick={() => this.EditDomain(row)}
                    >
                        <EditOutlined style={{ color: '#0083CA', fontSize: '18px' }} />
                    </IconButton>
            },
            {
                name: 'Config',

                sortable: true,

                cell: row =>
                    <IconButton


                        onClick={() => this.ShowConfig(row)}
                    >
                        <Settings style={{ color: '#0083CA', fontSize: '18px' }} />
                    </IconButton>
            },


        ];

        return (
            <Grid style={{ width: '100%', padding: '1%', justifyContent: 'center' }}>

                <Grid style={{ width: '95%' }}>
                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Domain</Typography>
                </Grid>

                <Grid style={{ width: '95%', display: 'flex', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', padding: '2%' }}>
                    <Grid style={{ width: '75%', }}>
                        <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>{this.state.numofrows} Domain</Typography>
                    </Grid>
                    <Grid style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>

                        <Button



                            variant="contained"
                            size="small"

                            style={{
                                color: "#ffffff",
                                fontFamily: "Roboto-Medium",
                                borderRadius: "4px",
                                backgroundColor: "#41AB1C",
                                textTransform: 'none',
                                padding: '1.5%',
                                fontSize: "14px",
                                width: '150px',
                                height: '32px',
                                marginLeft: '5%'

                            }}

                            onClick={() => { this.setState({ showadd_domain: true, savetype: 0, domainename: '', domaindes: '' }) }}


                        >

                            Add Domain
                        </Button>
                    </Grid>

                </Grid>
                <Grid style={{ height: 'auto', overflow: 'auto', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', }}>


                    <DataTable

                        columns={columns}
                        data={this.state.domaindata}
                        pagination
                        striped
                        responsive
                        paginationPerPage='30'

                        dense

                        customStyles={customStyles}
                        highlightOnHover


                        conditionalRowStyles={conditionalRowStyles}
                    />


                </Grid>

                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "Roboto-Bold"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
                <Modal
                    sx={{ border: 'none' }}




                    open={this.state.showadd_domain}





                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '45%',
                            height: 'auto',
                            background: '#fffbee',
                            outline: 'none',
                            p: 4,
                            borderRadius: '12px',
                            padding: '2%'
                        }}
                    >

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Grid style={{ width: '90%', display: 'flex', justifyContent: 'center' }}>
                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Add Domain</Typography>
                            </Grid>
                            <Grid style={{ width: '10%', display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton
                                    aria-label="close"

                                    onClick={() => this.setState({ showadd_domain: false })}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Grid style={{ width: '90%', display: 'flex', alignItems: 'center' }}>
                                <Grid style={{ width: '18%' }}>
                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Domain Name</Typography>
                                </Grid>
                                <Grid style={{ width: '70%' }}>
                                    <CssTextField focusColor="#999999"
                                        autoFocus
                                        fullWidth
                                        inputProps={{
                                            style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                        }}
                                        size="small"

                                        variant="outlined"
                                        placeholder="Enter Domain Name"
                                        value={this.state.domainename}

                                        onChange={e => {

                                            this.setState({ domainename: e.target.value });

                                        }}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid style={{ marginTop: '1%', width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Grid style={{ width: '90%', display: 'flex', alignItems: 'center' }}>
                                <Grid style={{ width: '18%' }}>
                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Description</Typography>
                                </Grid>
                                <Grid style={{ width: '70%' }}>
                                    <CssTextField focusColor="#999999"


                                        fullWidth
                                        inputProps={{
                                            style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                        }}
                                        size="small"

                                        variant="outlined"
                                        placeholder="Enter Domain Description"
                                        value={this.state.domaindes}

                                        onChange={e => {

                                            this.setState({ domaindes: e.target.value });

                                        }}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid style={{ width: '100%', display: 'flex', marginTop: '2%' }}>
                            <Grid style={{ width: '90%', display: 'flex', justifyContent: 'flex-end' }}>

                                <Button



                                    variant="contained"
                                    size="small"

                                    style={{
                                        color: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        borderRadius: "4px",
                                        backgroundColor: "#7DAB1C",
                                        textTransform: 'none',
                                        padding: '1.5%',
                                        fontSize: "14px",
                                        width: '100px',
                                        height: '32px',
                                        marginLeft: '5%'

                                    }}

                                    onClick={(e) => { this.AddDomain(e) }}


                                >

                                    Submit
                                </Button>

                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                <Modal
                    sx={{ border: 'none' }}




                    open={this.state.showconfig}





                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '45%',
                            height: 'auto',
                            background: '#fffbee',
                            outline: 'none',
                            p: 4,
                            borderRadius: '12px',
                            padding: '2%'
                        }}
                    >

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Grid style={{ width: '90%', display: 'flex', justifyContent: 'center' }}>
                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Set Configurations</Typography>
                            </Grid>
                            <Grid style={{ width: '10%', display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton
                                    aria-label="close"

                                    onClick={() => this.setState({ showconfig: false })}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Grid style={{ width: '90%', display: 'flex', alignItems: 'center' }}>
                                <Grid style={{ width: '18%' }}>
                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Configure DID</Typography>
                                </Grid>
                                <Grid style={{ width: '70%' }}>
                                    <CssTextField focusColor="#999999"
                                        autoFocus
                                        fullWidth
                                        inputProps={{
                                            style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                        }}
                                        size="small"

                                        variant="outlined"
                                        placeholder="Enter Configure DID"
                                        value={this.state.config_did}

                                        onChange={e => {

                                            this.setState({ config_did: e.target.value });

                                        }}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid style={{ marginTop: '1%', width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Grid style={{ width: '90%', display: 'flex', alignItems: 'center' }}>
                                <Grid style={{ width: '18%' }}>
                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Gateway ID</Typography>
                                </Grid>
                                <Grid style={{ width: '70%' }}>
                                    <CssTextField focusColor="#999999"


                                        fullWidth
                                        inputProps={{
                                            style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                        }}
                                        size="small"

                                        variant="outlined"
                                        placeholder="Enter Gateway ID"
                                        value={this.state.gwid}

                                        onChange={e => {

                                            this.setState({ gwid: e.target.value });

                                        }}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid style={{ width: '100%', display: 'flex', marginTop: '2%' }}>
                            <Grid style={{ width: '90%', display: 'flex', justifyContent: 'flex-end' }}>

                                <Button



                                    variant="contained"
                                    size="small"

                                    style={{
                                        color: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        borderRadius: "4px",
                                        backgroundColor: "#7DAB1C",
                                        textTransform: 'none',
                                        padding: '1.5%',
                                        fontSize: "14px",
                                        width: '100px',
                                        height: '32px',
                                        marginLeft: '5%'

                                    }}

                                    onClick={(e) => { this.AddConfig(e) }}


                                >

                                    Submit
                                </Button>

                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

            </Grid>


        )
    }
}
export default (withStyles(txtstyles)((newdomain)));