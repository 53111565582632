import React from 'react'
import Checkbox1 from './elements/Checkbox'
import Input from './elements/Input';
import Select from './elements/Select';
import Cascading from './elements/Cascading';
const Element = ({ field: { field_options1, filed_desp, field_type, field_id, field_label, field_placeholder, field_value, field_value1, field_options } }) => {

    switch (field_type) {
        case 'text':
            return (<Input
                field_id={field_id}
                field_label={field_label}
                field_placeholder={field_placeholder}
                field_value={field_value}
                filed_desp={filed_desp}

            />)
        case 'select':
            return (<Select
                field_id={field_id}
                field_label={field_label}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_options={field_options}
            />)
        case 'checkbox':
            return (<Checkbox1
                field_id={field_id}
                field_label={field_label}
                field_value={field_value}
                field_options={field_options}
            />)
        case 'cascading':
            return (<Cascading
                field_id={field_id}
                field_label={field_label}
                field_value={field_value}
                field_value1={field_value1}
                field_options1={field_options1}
                field_options={field_options}
            />)
        default:
            return null;
    }


}

export default Element
