import React, { Component } from 'react'
import { httpRequest } from '../../../../ui-utils/api'
import Select from 'react-select'
import DataTable from 'react-data-table-component';
import xlsexport from 'xlsexport'
import {
    Grid, Button, Checkbox, TextField, Modal, Box
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles, styled } from "@material-ui/core/styles";
import Loader from "react-js-loader";
import moment from 'moment'
import { Typography } from '@mui/material';

const CssTextField = styled(TextField)({
    borderRadius: '23px',
    fontFamily: 'InterRegular',
    background: '#ffff',

    '& .MuiInput-underline:after': {
        borderBottomColor: '#dddddd',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#dddddd',
        },
        '&:hover fieldset': {
            borderColor: '#dddddd',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#dddddd',
        },
    },
});
const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#41AB1C",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 10,
            width: 10,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#41AB1C'
        }
    },
    checked: { color: "#41AB1C", }
}))(Checkbox);
const conditionalRowStyles = [
    {
        when: row => row.account_status == "E",
        style: {
            backgroundColor: 'green',
            color: 'white',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    },

];
const customStyles = {
    title: {
        style: {
            fontSize: '14px', // override the row height
        },
    },
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
        stripedStyle: {

            //  background: 'red'



        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#E9F4FF',
            fontSize: '14px',
            color: '#333333',
            height: '45px',
            fontFamily: 'Roboto-Medium'
        },
    },
    cells: {
        style: {
            paddingLeft: '2px', // override the cell padding for data cells
            paddingRight: '2px',


        },
    },
    highlightOnHoverStyle: {
        style: {
            color: 'red',
            background: 'blue',
            backgroundColor: 'yellow'



        },
    },

};
export default class obdreport extends Component {
    constructor(props) {
        super(props)


        this.state = {

            ivrshudellist: [],
            obdname: '',
            obdid: '',
            obddata: [],
            islodding: false

        }



    }

    GetOBDdesp(e) {
        console.log(e.uid)
        this.setState({ obdname: e.label, obdid: e.uid })

    }

    GEtOBDdump = async () => {
        this.setState({ islodding: true })
        try {
            const roledata1 = await httpRequest({
                endPoint: "/api/lcc/reports/OBDdump/" + this.state.obdid,
                method: "get",
                instance: "instanceOne",
            })
            this.setState({ islodding: false })
            if (roledata1.success === true) {
                this.setState({ obddata: roledata1.data })
            }
        } catch (error) {

        }
    }


    GetShudelList = async () => {
        this.setState({ islodding: true })

        let fdate = moment(new Date()).subtract(30, 'days').format("YYYY-MM-DD")
        let tdate = moment(new Date()).add(30, 'days').format("YYYY-MM-DD")


        try {
            const roledata1 = await httpRequest({
                endPoint: "/api/lcc/OBDScheduledList/" + fdate + "/" + tdate,
                method: "get",
                instance: "instanceOne",


            });


            this.setState({ islodding: false })
            console.log('ivrshudellist', roledata1.data)
            let maindata = []
            if (roledata1.success == true) {


                const re = roledata1.data.map(data => {

                    let status1 = data.status

                    maindata.push(
                        {


                            uid: data.uid,

                            label: data.summary,

                        })

                })


                this.setState({ ivrshudellist: maindata, filterdata: maindata })


            }
        } catch (error) {

        }
    }
    componentDidMount = async () => {

        this.GetShudelList()


    }

    ExportCallWise(array) {
        const link = document.createElement('a');

        let fdate = moment(this.state.fdate).format("YYYY_MM_DD")
        let tdate = moment(this.state.tdate).format("YYYY_MM_DD")
        const filename = 'OBDrawdata_' + this.state.obdname + '.csv';
        const csv = new xlsexport(array, filename)
        csv.exportToCSV(filename)

    }
    render() {
        const columns = [

            {
                name: '',
                width: '50px',
                cell: row =>


                    <WhiteBackgroundCheckbox

                        id="chk1"

                    />


            },

            {
                name: 'OBD_ID',

                sortable: true,
                width: '100px',
                cell: row =>
                    row.obd_id
            },

            {
                name: 'Call_DateTime',

                sortable: true,
                width: '150px',
                cell: row =>
                    row.Call_DateTime.substring(0, 19)
            },

            {
                name: 'Farmer_number',
                width: '120px',
                sortable: true,
                cell: row =>
                    row.Farmer_number
            },

{
                name: 'Duration',
                width: '120px',
                sortable: true,
                cell: row =>
                    row.duration
            },
            
            
            {
                name: 'District',
                width: '200px',
                sortable: true,
                cell: row =>
                    row.District.substring(1, row.District.length - 1).replaceAll('"', '').replaceAll('null,', '').replaceAll('null', '')
            },




            {
                name: 'Status',
                width: '100px',
                sortable: true,
                cell: row =>
                    row.Status
            },
            {
                name: 'DTMF',
                width: '100px',
                sortable: true,
                cell: row =>
                    row.DTMF
            },

            {
                name: 'HANGUP-CAUSE',
                width: '150px',
                sortable: true,
                cell: row =>
                    row['HANGUP-CAUSE']
            },
            {
                name: 'Disposition',
                width: '150px',
                sortable: true,
                cell: row =>
                    row.Dicposition
            },








        ];
        return (
            <div>
                <Grid style={{ width: '100%', display: 'flex', marginTop: '2%', marginLeft: '1%' }}>
                    <Grid style={{ width: '60%', display: 'flex', alignItems: 'center' }}>
                        <Grid style={{ width: '20%', alignItems: 'center' }}>

                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}> OBD List :</Typography>
                        </Grid>
                        <Grid style={{ width: '60%', }}>

                            <Select
                                style={{

                                    width: '400px',


                                }}
                                options={this.state.ivrshudellist}
                                values={this.state.obdname}





                                onChange={e => {
                                    this.GetOBDdesp(e);


                                }}
                            >
                            </Select>

                        </Grid>
                    </Grid>


                    <Grid style={{ width: '15%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Button



                            variant="contained"
                            size="small"

                            style={{
                                color: "#ffffff",
                                fontFamily: "Roboto-Medium",
                                borderRadius: "4px",
                                backgroundColor: "#7DAB1C",
                                textTransform: 'none',
                                padding: '1.5%',
                                fontSize: "14px",
                                width: '125px',
                                height: '32px',
                                marginLeft: '5%'

                            }}

                            onClick={() => { this.GEtOBDdump() }}


                        >

                            Submit
                        </Button>
                    </Grid>
                    <Grid style={{ width: '15%', display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                        <Button



                            variant="contained"
                            size="small"

                            style={{
                                color: "#ffffff",
                                fontFamily: "Roboto-Medium",
                                borderRadius: "4px",
                                backgroundColor: "#7DAB1C",
                                textTransform: 'none',
                                padding: '1.5%',
                                fontSize: "14px",
                                width: '125px',
                                height: '32px',
                                marginLeft: '5%'

                            }}

                            onClick={() => {
                                this.ExportCallWise(this.state.obddata)
                            }}


                        >

                            Export
                        </Button>
                    </Grid>
                </Grid>
                <Grid style={{ height: 'auto', overflow: 'auto', marginTop: '1%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', }}>


                    <DataTable

                        columns={columns}
                        data={this.state.obddata}
                        pagination
                        striped
                        responsive
                        paginationPerPage='100'
                        paginationRowsPerPageOptions={[100, 150, 200, 250]}

                        defaultSortFieldId
                        dense

                        customStyles={customStyles}
                        highlightOnHover
                        subHeader
                        subHeaderComponent={<CssTextField
                            autoFocus={true}
                            value={this.state.stext}
                            placeholder="Search"
                            size="small"
                            variant="outlined"
                            onChange={e =>
                                this.MakeFilter(e)
                            }
                        />}
                        subHeaderAlign='left'

                        conditionalRowStyles={conditionalRowStyles}
                    />


                </Grid>
                <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',
                            display: 'flex',
                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >
                        <Loader type="spinner-circle" bgColor={"#7DAB1C"} color={'#ffc31b'} size={75} />
                    </Box>

                </Modal>
            </div>
        )
    }
}
