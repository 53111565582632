import React, { Component } from 'react'
import {
    Typography, Grid, MenuItem, Checkbox, TextField, Button, IconButton,
    RadioGroup, Radio, FormControlLabel, Snackbar, SnackbarContent,
    Modal, Box, Switch, FormGroup
} from '@material-ui/core'
import validator from 'validator'
import Select from 'react-select'
import { httpRequest } from '../../../../ui-utils/api'

import moment from 'moment'

import { withStyles, styled } from "@material-ui/core/styles";
import Autocomplete from '@mui/material/Autocomplete';
import { PlayArrow, DeleteOutlineSharp } from '@material-ui/icons';
import backendHosts from '../../../../ui-utils/apiConfig'
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close'
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
    KeyboardTimePicker
} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import * as XLSX from 'xlsx';
import Loader from "react-js-loader";
const txtstyles = theme => ({




})
const MySwitch = withStyles(theme => ({
    root: {
        '& .MuiSwitch-switchBase': {

            '&.Mui-checked': {
                color: '#7DAB1C',

                '& .MuiSwitch-thumb:before': {
                    backgroundColor: '#7DAB1C'
                },
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: "#7DAB1C"
                },
            },
        },

    },

}))(Switch);

const dialectsopt = [
    { label: "Odia", value: "odia", key: 1 },
    { label: "Sambalpuri", value: "sambalpuri", key: 2 },
    
    { label: "Missing", value: "missing", key: 1 }
]

const irrigationopt = [
    { label: "With and without irrigation, including missing (default)", value: "None", key: 1 },
    { label: "No", value: "0", key: 2 },
    { label: "Yes", value: "1", key: 3 },
]

const landtypeopt = [
    { label: "Low Land", value: "1", key: 1 },
    { label: "Medium Land", value: "2", key: 2 },
    { label: "High Land", value: "3", key: 3 },
    { label: "Missing Land Type", value: "None", key: 4 },
]

const kitchenopt = [
    { label: "All, including missing (default)", value: "None", key: 1 },
    { label: "No", value: "0", key: 2 },
    { label: "Yes", value: "1", key: 3 },
]

const profile_source_name = [


    { label: 'adapt', value: 'adapt' },
    { label: 'adapt_krushak_fs', value: 'adapt_krushak_fs' },
    { label: 'adapt_krushak_od', value: 'adapt_krushak_od' },
    { label: 'automated', value: 'automated' },
    { label: 'csisa', value: 'csisa' },
    { label: 'csisa_jul2020', value: 'csisa_jul2020' },
    { label: 'csisa_jul2021', value: 'csisa_jul2021' },
    { label: 'dafe_krushak_od', value: 'dafe_krushak_od' },
    { label: 'farmers_krishi_jan2020', value: 'farmers_krishi_jan2020' },
    { label: 'field', value: 'field' },
    { label: 'image', value: 'image' },
    { label: 'ko_api', value: 'ko_api' },
    { label: 'krushak_od', value: 'krushak_od' },
    { label: 'pradan', value: 'pradan' },
    { label: 'rcm_kharif_2019', value: 'rcm_kharif_2019' },
    { label: 'rcm_rabi_2019', value: 'rcm_rabi_2019' },
    { label: 'rct_od_kharif', value: 'rct_od_kharif' },
    { label: 'None', value: 'None' },
    { label: '5', value: '5' },
    { label: 'Missing', value: 'Missing' },


];


const phonetypeopt = [
    { label: "All phone types, including missing (default)", value: "None", key: 1 },
    { label: "Feature phone", value: "0", key: 2 },
    { label: "Smart phone", value: "1", key: 3 },
]
const genderopt = [
    { label: "Unknown/other", value: "None", key: 1 },
    { label: "Male", value: "0", key: 2 },
    { label: "Female", value: "1", key: 3 },
]

const pushtypeopt = [
    { label: "Filtered = Get numbers using filters below (e.g. advisory call, etc.)", value: "Filtered", key: 1 },
    { label: "Custom = Use custom numbers-csv-file (e.g. welcome call, experimental call, etc.)", value: "Custom", key: 2 },

]

const advtypeopt = [
    { label: "Agricultural Cropping Advisory", value: "crops", key: 1 },
    { label: "Livestock Advisory", value: "livestock", key: 2 },
    { label: "Fisheries Advisory", value: "fisheries", key: 2 },

]

const defaultMaterialTheme = createTheme({
    palette: {
        primary: {
            light: '#7DAB1C',
            main: '#7DAB1C',
            dark: '#7DAB1C',
            contrastText: '#fff',

        },
        secondary: {
            light: '#7DAB1C',
            main: '#7DAB1C',
            dark: '#7DAB1C',
            contrastText: '#000',
        },
    },
});
const Radioheckbox = withStyles(theme => ({
    root: {
        color: "#333333",
        background: "#ffffff",
        "@media(max-width:960px)": {
            background: "#080808",
        },

        '&$checked': {
            color: '#7DAB1C'
        }
    },
    checked: { color: "#7DAB1C", }
}))(Radio);
const customStyles = {
    title: {
        style: {
            fontSize: '14px', // override the row height
        },
    },
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
        stripedStyle: {

            //  background: 'red'



        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            backgroundColor: '#E9F4FF',
            fontSize: '14px',
            color: '#333333',
            height: '45px',
            fontFamily: 'Roboto-Medium'
        },
    },
    cells: {
        style: {
            paddingLeft: '2px', // override the cell padding for data cells
            paddingRight: '2px',


        },
    },
    highlightOnHoverStyle: {
        style: {
            color: 'red',
            background: 'blue',
            backgroundColor: 'yellow'



        },
    },

};
const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    }
};
let keylist = ['1', '2', '3', '4', '5', '6', '7', '8']




const CssTextField = styled(TextField)({
    borderRadius: '23px',
    fontFamily: 'InterRegular',
    background: '#ffff',

    '& .MuiInput-underline:after': {
        borderBottomColor: '#dddddd',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#dddddd',
        },
        '&:hover fieldset': {
            borderColor: '#dddddd',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#dddddd',
        },
    },
});


const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#41AB1C",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 8,
            width: 8,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#41AB1C'
        }
    },
    checked: { color: "#41AB1C", }
}))(Checkbox);
class ivrshudel extends Component {
    constructor(props) {
        super(props)


        this.state = {
            profiling_date: null,
            profiling_time: null,
            ivrlist: [],
            ivrconfiglist: [],
            pageno: 2,
            ivrconfiglist1: [],
            ivrconfig_uid: 0,
            ivraction: '',
            showerr: false,
            errorMsg: '',
            audiofilename: '',
            audiofiledata: '0',
            welcomeaudiodata: '',
            domaindata: '',
            mysnack: snackeror.mysucc,
            ivrkey: '',
            ivrtype: 'Playback',
            ivruid: '',
            domainuid: '',
            ivrpage: 0,
            ivrname: '',
            ivrname1: '',
            tabvalue: 0,
            summary: 'None',
            ivrconfiglist: [],
            ivrconfig_uid: 0,
            distlist: [],
            subdist: [],
            croplist_k: [],
            croplist_r: [],
            croplist_f: [],
            maintopic: [],

            petlist: [],
            main_top: [],
            subtopic: [],
            subtopic1: [],
            main_topname: '',
            lang: '',
            smartphone: 'None',
            groupivrname: '',
            groupuid: '',
            groupdate: '',
            grouptime: '',
            groupretry: '',
            grouptemp: false,
            groupsavehist: false,
            numofrows: '0',
            fisherytypes: '',
            croptype: true,
            advtype: 'crops',
            cropname: '',
            district_name: 'None',
            subdistname: 'None',
            dialects: 'None',
            dialects1: 'None',
            liveStockname: 'None',
            pushtype: 'Filtered',
            filter_type: 0,
            liveStockTypes: '',
            irrigation: 'None',
            kitchen_garden: "None",
            pushsamivr: 0,
            pushodsivr: 0,
            shtime: '',
            ratingtype: false,
            shdate: '',
            save_user_history_type: false,
            description: 'None',
            mainaudio: '',
            ivrdesp: '',
            welcomeaudiodataname: '',
            welconeaudiouid: '0',
            mainaudiouid: '0',
            audionameuid: '0',
            reqexit: true,
            numberlist: '',
            numbercount: 0,
            gender: 'None',
            subtopicname: 'None',
            subtopic_name1: 'None',
            shdate1: '',
            shtime1: '',
            retries: '2,2',
            profile_source_name1: 'None',
            landtype: "None",
            islodding: false

        }



    }

    handelIVRType(e) {



        this.setState({ ivrtype: e.target.value })

    }
    GetIVRList = async (e) => {
        try {
            const roledata1 = await httpRequest({
                endPoint: `/api/lcc/IVRList`,
                method: "get",
                instance: "instanceOne",


            });



            console.log('ivrlist', roledata1.msg)

            if (roledata1.success == true) {

                this.setState({ ivrlist: roledata1.msg, numofrows: roledata1.msg.length })


            }
        } catch (error) {

        }

    }
    GetIVRConfList = async () => {

        try {
            const roledata1 = await httpRequest({
                endPoint: `/api/lcc/IVRConfList`,
                method: "get",
                instance: "instanceOne",


            });

            
            if (roledata1.success == true) {
                this.setState({ ivrconfiglist: roledata1.msg })
            }


        } catch (error) {

        }


    }
    GetAudioFile = async (e) => {

        try {
            const roledata1 = await httpRequest({
                endPoint: `/api/lcc/AudioList`,
                method: "get",
                instance: "instanceOne",


            });



            if (roledata1.success == true) {

                this.setState({ audiolist: roledata1.msg })


            }
        } catch (error) {

        }







    }
    GetLiveStockTypes = async () => {

        try {
            const roledata1 = await httpRequest({
                endPoint: `/api/lcc/getLiveStockTypes`,
                method: "get",
                instance: "instanceOne",


            });



            console.log(roledata1.msg)

            if (roledata1.success == true) {

                //  console.log(roledata1.msg[0].livestock_types)
                this.setState({ liveStockTypes: roledata1.msg[0].livestock_types })


            }
        } catch (error) {

        }
    }
    GetDistricts = async () => {
        console.log('GetDistricts')
        try {
            const roledata1 = await httpRequest({
                endPoint: `/api/lcc/getDistricts`,
                method: "get",
                instance: "instanceOne",


            });
let dist=[
    {
        district:"None"
    }
]

roledata1.msg.map((step) =>

dist.push({
    district:step.district
    
})




)
console.log('dist list',dist)
            console.log('distlist', roledata1.msg)
            // if (roledata1.success == true) {
            this.setState({ distlist: dist })
            //}


        } catch (error) {

        }

        this.setState({district_name:
        {
                    label: "None",
                    value: "None",
                    key: 0
                }
            })
        
     
    }
    CropList = async (e) => {

        try {

            const submitdata = await httpRequest({
                endPoint: `api/lcc/CropList`,
                method: "get",
                instance: "instanceOne",


            });


            this.setState({ croplist_k: submitdata.crop.kharif, croplist_r: submitdata.crop.rabi, croplist_f: submitdata.crop.rabi })



        } catch (error) {

        }

    }

    getMainSubTopics = async () => {
        try {
            const roledata1 = await httpRequest({
                endPoint: `/api/lcc/getMainSubTopics`,
                method: "get",
                instance: "instanceOne",



            });



            console.log('non_agriculture', roledata1.msg[0])

            let non_agri = []
            let subtop = []

            let subtop1 = []

            roledata1.msg[0].non_agriculture.split(',').map((step) =>

                non_agri.push({
                    label: step,
                    grp: 'Non Agriculture'
                })




            )
            roledata1.msg[0].main_topic.split(',').map((step) =>

                non_agri.push({
                    label: step,
                    grp: 'Main Topic'
                })
            )
            roledata1.msg[0].sub_topic.split(',').map((step) =>

                subtop.push({
                    label: step,

                })
            )
            roledata1.msg[0].sub_topic_2.split(',').map((step) =>

                subtop1.push({
                    label: step,

                })
            )



            this.setState({ main_top: non_agri, subtopic: subtop, subtopic1: subtop1 })





            /*  if (roledata1.success == true) {
  
  
                  this.setState({ subdist: roledata1.msg })
  
  
              }*/
        } catch (error) {

        }

    }
    componentDidMount = async () => {

        this.GetIVRConfList()

        this.GetIVRList()
        this.GetDomain()

        this.GetAudioFile()

        this.GetDistricts()

        this.CropList()
        this.getMainSubTopics()


   
    }

    removeA(arr) {
        var what, a = arguments, L = a.length, ax;
        while (L > 1 && arr.length) {
            what = a[--L];
            while ((ax = arr.indexOf(what)) !== -1) {
                arr.splice(ax, 1);
            }
        }
        return arr;
    }

    AddChild = async (e) => {
        let isok = 0
        const { reqexit, mainaudiouid, audionameuid, mainaudio, domainuid, ivrkey, audiofiledata, ivrtype, ivrname1, ivruid } = this.state



        if (!ivrkey.length) {

            isok = 1
            this.setState({ showerr: true, errorMsg: "Select the Action Key", mysnack: snackeror.myerror })

        }

        if (ivrtype === "Playback") {

            if (!audionameuid === 0) {
                isok = 1
                this.setState({ showerr: true, errorMsg: "Select the Audio File", mysnack: snackeror.myerror })

            }




        }


        if (ivrtype === "Domain_change") {
            if (!domainuid.length) {
                isok = 1
                this.setState({ showerr: true, errorMsg: "Select the Domain Name", mysnack: snackeror.myerror })

            }


        }

        if (ivrtype === "IVR") {

            if (!ivruid.length) {
                isok = 1
                this.setState({ showerr: true, errorMsg: "Select the IVR Name", mysnack: snackeror.myerror })

            }

        }

        let item = {}
        if (isok === 0) {
            if (mainaudiouid.includes('VRAudio_') !== 0) {
                this.setState.mainaudiouid = this.state.mainaudioname
            }
            if (audionameuid.includes('VRAudio_') !== 0) {
                this.setState.audionameuid = this.state.audiofilename
            }

            if (ivrtype === "Playback") {

                item = {

                    'key': ivrkey,
                    'audioname': audionameuid,
                    'audiodata': audiofiledata,
                    'keytype': ivrtype,
                    'mainaudioname': mainaudiouid,
                    'mainaudio': mainaudio,
                    'exit': reqexit



                }


            }
            if (ivrtype === "Domain_change") {
                item = {

                    'key': ivrkey,
                    'audioname': domainuid,

                    'keytype': ivrtype,
                    'mainaudioname': mainaudiouid,
                    'mainaudio': mainaudio



                }

            }


            if (ivrtype === "IVR") {
                item = {

                    'key': ivrkey,
                    'audioname': ivruid.split(',')[1],
                    'audiodata': ivruid.split(',')[0],
                    'keytype': ivrtype,
                    'mainaudioname': mainaudio.name,
                    'mainaudio': mainaudio



                }

            }



            this.removeA(keylist, ivrkey);





            this.state.ivrconfiglist1.push(item)

            this.setState({ mainaudiouid: '', audionameuid: '', audioname: '', mainaudio: '', mainaudioname: '', ivrkey: '', ivraction: '', audiofiledata: '', domainuid: '', ivruid: '' })


        }

    }



    closeContactModal() {
        this.setState({ showerr: false })
    }
    SaveMenu = async (e) => {
        let callaudiocreate = 0
        const { mainaudiouid, ivrname, ivrdesp, lang, ivrconfiglist, audionameuid, welconeaudiouid } = this.state
        const { ivrconfiglist1, ivrconfig_uid, welcomeaudiodata, welcomeaudiodataname } = this.state
        let isok = 0
        const data = new FormData();

        if (!ivrname.length) {

            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter the IVR Name", mysnack: snackeror.myerror })

        }
        if (!ivrdesp.length) {

            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter the IVR Description", mysnack: snackeror.myerror })

        }

        if (!lang.length) {

            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter the IVR Language", mysnack: snackeror.myerror })

        }


        if (!ivrconfig_uid > 0) {

            isok = 1
            this.setState({ showerr: true, errorMsg: "Select the  Global Config", mysnack: snackeror.myerror })

        }


        if (ivrconfiglist1.length < 1) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Configure any one Menu", mysnack: snackeror.myerror })



        }

        if (isok === 0) {
            if (welconeaudiouid.includes('VRAudio_') == 0) {

                callaudiocreate = 1
                this.state.welconeaudiouid = "IVRAudio_" + lang + "_" + welcomeaudiodataname
                data.append('file', welcomeaudiodata, "IVRAudio_" + lang + "_" + welcomeaudiodataname);
            }


        }
        if (welconeaudiouid.length < 1) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Select welcome Audio File", mysnack: snackeror.myerror })

        }



        if (isok === 0) {
            let payload = {}
            let menu = []






            let item1
            const menu_data = ivrconfiglist1.map(rname => {


                console.log(rname.keytype)

                let mainfilename = ""
                let palybackfilename = ""
                console.log("action file name=", rname.audioname)
                if (rname.mainaudioname.includes('VRAudio_') == 0) {
                    callaudiocreate = 1

                    mainfilename = "IVRAudio_" + lang + "_" + rname.mainaudioname
                    data.append('file', rname.mainaudio, "IVRAudio_" + lang + "_" + rname.mainaudioname);
                } else {
                    mainfilename = rname.mainaudioname
                }
                if (rname.keytype === "Playback") {



                    if (rname.audioname.includes('VRAudio_') == 0) {

                        callaudiocreate = 1
                        console.log("in")
                        palybackfilename = "IVRAudio_" + lang + "_" + rname.audioname
                        console.log("After=", palybackfilename)
                        data.append('file', rname.audiodata, "IVRAudio_" + lang + "_" + rname.audiodata.name);
                    } else {
                        palybackfilename = rname.audioname
                    }

                    item1 = {
                        "DTMF": rname.key,
                        "audio": mainfilename,
                        "action": "AUDIO_" + palybackfilename,
                        "exit": rname.exit

                    }

                }

                if (rname.keytype === "Domain_change") {

                    console.log("**********")
                    item1 = {
                        "DTMF": rname.key,
                        "audio": mainfilename,
                        "action": "DOMAIN_" + rname.audioname,


                    }
                    console.log(item1)

                }

                if (rname.keytype === "IVR") {
                    item1 = {
                        "DTMF": rname.key,
                        "audio": mainfilename,
                        "action": "IVR_" + rname.audiodata,


                    }


                }
                menu.push(item1)

                return menu;
            });


            console.log('payload', menu)
            let a = 0
            if (callaudiocreate === 1) {
                let audio_path = backendHosts.CRM + 'api/lcc/CreateAudio'
                fetch(audio_path, {
                    method: 'POST',
                    body: data,
                }).then((response) => {
                    response.json().then((body) => {
                        let data = body.msg
                        console.log("Responce", data)

                        const newState = data.map(obj => {



                            console.log('name', obj.audio)

                            if (a == 0) {
                                if (obj.status === "failed") {
                                    a = 1
                                    // this.setState({ showerr: true, errorMsg: obj.audio + " This file already exists", mysnack: snackeror.myerror })
                                }

                                if (obj.status === "success") {

                                }
                            }
                            return obj;
                        });





                    });
                });
            }
            const menu1 = {
                menu,

                "config": ivrconfig_uid,
                "welcome": this.state.welconeaudiouid //"IVRAudio_" + lang + "_" + welcomeaudiodata.name,
            }

            const item = {
                "IVRName": ivrname,
                "IVRDescription": ivrdesp,
                "IVRLanguage": lang,

                "IVR": menu1

            }
            console.log('final', item)
            /*  try {
  
                  const submitdata = await httpRequest({
                      endPoint: `api/lcc/CreateIVR`,
                      method: "post",
                      instance: "instanceOne",
                      requestBody: item,
  
                  });
                  console.log('responce', submitdata.success)
                  if (submitdata.success == true) {
                      keylist = []
                      keylist = ['1', '2', '3', '4', '5', '6', '7', '8']
                      this.GetIVRConfList()
                      this.setState({ welconeaudiouid: '', ivrconfiglist1: '', ivrname: '', ivrdesp: '', lang: '', pageno: 0, mainaudiouid: '', audionameuid: '' })
                      this.setState({ showerr: true, errorMsg: "New IVR Menu is Created", mysnack: snackeror.mysucc })
  
  
                  }
  
  
  
  
              } catch (error) {
  
              }*/
        }

    }
    GetDomain = async () => {
        let sql = ""




        sql = "select uid,name from  pad_domains order by name"
        console.log(sql)
        const domainitem = {
            "stmt": sql

        }
        try {
            const domaindata = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: domainitem,

            });


            console.log('domaindata.msg ', domaindata.msg)

            if (domaindata.success == true) {

                this.setState({ domaindata: domaindata.msg })
            }
        } catch (error) {

        }


    }

    ChangeCrop() {
        this.setState({ croptype: !this.state.croptype })

        if (this.state.croptype == true) {
            this.setState({ croplist_f: this.state.croplist_r })
        } else {

            this.setState({ croplist_f: this.state.croplist_k })
        }
    }

    advisoryTypeChanged(e) {


        if (e.value === "crops") { this.setState({ advtype: e.value, filter_type: 0 }) }
        if (e.value === "livestock") {
            this.setState({ advtype: e.value, filter_type: 1 })
            this.GetLiveStockTypes()
        }
        if (e.value === "fisheries") {
            this.setState({ advtype: e.value, filter_type: 2 })
            this.GetFisheries()
        }
        /* if (e.value === "livestock") {
 
 
             //this.setState({ advtype: e.value, filter_type: 1 })
 
             //this.GetLiveStockTypes()
         }
         if (e.target.value === "fisheries") {
 
             this.GetFisheries()
             this.setState({ advtype: e.value, filter_type: 2 })
         }
 
 */

    }


    TestFilter = async () => {




        let item = this.TestFilterData()

        try {

            const submitdata = await httpRequest({
                endPoint: `api/lcc/getFilterCount`,
                method: "post",
                instance: "instanceOne",
                requestBody: item,

            });
            console.log('Filter Resp', submitdata.success)
            if (submitdata.success === true) {
                let a = "Filter settings correspond to " + submitdata.msg[0].count + " profiles in the profiles-table."
                this.setState({ showerr: true, errorMsg: a, mysnack: snackeror.mysucc })
            }




        } catch (error) {

        }

    }
    TestFilterData() {

        let payload = {}
        let croptype1 = "off";
        let rating = "off";
        let save_user_history = "off"
        const { filter_type, landtype, subdistname, profile_source_name1, summary, main_topname, description, retries, shtime, shdate, cropname, save_user_history_type, ratingtype, pushtype, advtype, croptype, irrigation, kitchen_garden, liveStockname, dialects } = this.state

        const { profiling_date, smartphone, profiling_time, gender, district_name } = this.state

        let isok = 0


        let cropname1 = []
        let dialects1 = []
        let district_name1 = []
        let subdistname1 = []
        let liveStockname1 = []

        let landtype1 = []

        let irrigation1 = []
        let kitchen_garden1 = []
        if (subdistname === "None") {
            subdistname1 = "None"
        }

        if (subdistname !== "None") {
            for (var key in subdistname) {
                subdistname1.push(subdistname[key]['value'])
            }

        }

        if (district_name === "None") {
            district_name1 = "None"
        }

        if (district_name !== "None") {
            for (var key in district_name) {
                district_name1.push(district_name[key]['value'])
            }

        }





        if (dialects === "None") {
            dialects1 = "None"
        }
        if (dialects !== "None") {
            for (var key in dialects) {
                dialects1.push(dialects[key]['value'])


            }



        }


        if (cropname === "None") {
            cropname1 = "None"
        }


        if (cropname !== "None") {

            for (var key in cropname) {
                cropname1.push(cropname[key]['value'])


            }
            // cropname1 = cropname1.substring(1, cropname1.length - 2);


        }


        if (landtype !== "None") {
            for (var key in landtype) {
                landtype1.push(landtype[key]['value'])


            }
        }



        if (liveStockname === "None") {
            liveStockname1 = "None"
        }

        if (irrigation === "None") {
            irrigation1 = "None"
        } else {
            irrigation1 = irrigation.value
        }
        if (kitchen_garden === "None") {
            kitchen_garden1 = "None"
        } else {
            kitchen_garden1 = kitchen_garden.value
        }


        if (liveStockname !== "None") {
            for (var key in liveStockname) {
                liveStockname1.push(liveStockname[key]['value'])


            }



        }

        if (isok == 0) {

            if (croptype === true) { croptype1 = "on" }
            if (ratingtype === true) { croptype1 = "on" }
            if (save_user_history_type === true) { save_user_history = "on" }

            //"district": [district_name], "gender": [gender],

            let profiling_date1 = ""
            let profiling_time1 = ""
            if (profiling_date !== null) {
                profiling_date1 = moment(profiling_date).format("YYYY-MM-DD")
            }
            if (profiling_time !== null) {
                profiling_time1 = moment(profiling_time).format("HH:MM")
            }
            let shtime1 = ""
            let shdate1 = "None"
            if (validator.isDate(shtime) == true) {
                shtime1 = moment(shtime).format("HH:MM")
            }

            if (validator.isDate(shdate) == true) { shdate1 = moment(shdate).format("YYYY-MM-DD") }

            let croplist = "None"



            if (filter_type === 0) {
                if (main_topname.length > 0) {
                    main_topname.map((option) =>
                        croplist = croplist + option.label + ","

                    )
                    croplist = croplist.substring(0, croplist.length - 1);
                    console.log(croplist)
                }



                payload = {
                    "pushcall_type": [pushtype],
                    "advisory_type": [advtype],
                    "crops": cropname1,

                    "kharif": [croptype1],

                    "irrigation": [irrigation1],
                    "kitchen_garden": [kitchen_garden1],
                    "dialect": dialects1,
                    "save_user_history": [save_user_history],

                    "district": district_name1,
                    "land_type": [parseInt(landtype)],
                    "subdistrict": subdistname1,

                    "profiling_date": [profiling_date1 + " " + profiling_time1],
                    "profile_source": [profile_source_name1],
                    "smartphone": [smartphone],
                    "female": [gender],
                    "ratings": [rating],
                    "retries": [retries],
                    "start_time": [shdate1 + " " + shtime1],
                    "summary": [summary],
                    "description": [description],
                    "call_tag_1": [croplist],


                }

            }
            if (filter_type === 1) {

                payload = {
                    "pushcall_type": [pushtype],
                    "advisory_type": [advtype],
                    "livestock_types": [liveStockname1],

                    "kharif": [croptype1],
                    "land_type": [parseInt(landtype)],
                    "irrigation": [irrigation],
                    "kitchen_garden": [kitchen_garden],
                    "save_user_history": [save_user_history],
                    "dialect": dialects1,
                    "district": district_name1,
                    "subdistrict": subdistname1,
                    "profile_source": [profile_source_name1],
                    "profiling_date": [profiling_date1 + " " + profiling_time1],
                    "smartphone": [smartphone],
                    "female": [gender],
                    "ratings": [rating],
                    "retries": [retries],
                    "start_time": [shdate1 + " " + shtime1],
                    "summary": [summary],
                    "description": [description],
                    "call_tag_1": [croplist],


                }

            }

            if (filter_type === 2) {

                payload = {
                    "pushcall_type": [pushtype],
                    "advisory_type": [advtype],
                    "fishery_types": liveStockname1,

                    "kharif": [croptype1],
                    "land_type": [parseInt(landtype)],
                    "irrigation": [irrigation],
                    "kitchen_garden": [kitchen_garden],
                    "save_user_history": [save_user_history],
                    "dialect": dialects1,
                    "district": district_name1,
                    "subdistrict": subdistname1,
                    "profiling_date": [profiling_date1 + " " + profiling_time1],
                    "profile_source": [profile_source_name1],
                    "smartphone": [smartphone],
                    "female": [gender],
                    "ratings": [rating],
                    "retries": [retries],
                    "start_time": [shdate1 + " " + shtime1],
                    "summary": [summary],
                    "description": [description],
                    "call_tag_1": [croplist],


                }

            }

            let payload1 = []


            if (gender === "None") {
                delete payload['female'];
            }
            if (profile_source_name1 === "None") {
                delete payload['profile_source'];
            }


            if (landtype === "None") {
                delete payload['land_type'];
            }


            if (dialects1 === "None") {
                payload['dialect'] = []

            }
            if (district_name1 === "None") {
                delete payload['district'];
            }
            if (subdistname1 === "None") {
                delete payload['subdistrict'];
            }

            if (rating === "off") {
                delete payload['ratings'];
            }

            if (croplist === "None") {
                delete payload['call_tag_1'];
            }

            if (cropname1.length === 0) {
                payload['crops'] = []
            }


            if (liveStockname1 === "None") {
                payload['livestock_types'] = []
                payload['fishery_types'] = []

            }

            if (summary === "None") {

                payload['summary'] = []
            }

            if (summary === "None") {

                payload['summary'] = []
            }


            if (description === "None") {

                payload['description'] = []
            }
            if (profiling_date === null) {


                payload['profiling_date'] = [""]

            }
            if (validator.isDate(shtime) === false) {
                payload['start_time'] = [""]
            }




            console.log("After", payload1)
            let item = {
                filter: payload
            }
            return item
        }

    }
    GetSubDist = async (distname) => {



        this.setState({ district_name: distname })
        console.log("After distname",distname.length)
        if(distname.length>0)
        {
        const item = {
            "district_name": distname[0].value
        }
        try {
            const roledata1 = await httpRequest({
                endPoint: `/api/lcc/getSubDistrict`,
                method: "post",
                instance: "instanceOne",

                requestBody: item

            });



            console.log(roledata1.msg)

            if (roledata1.success == true) {


                this.setState({ subdist: roledata1.msg })


            }
        } catch (error) {

        }
    }

    }




    pushcallsubmit = async () => {

        let isok = 0



        const { district_name, advtype, shdate, shtime, liveStockname, shdate1, summary, shtime1, filter_type, dialects, cropname, main_topname, description, pushodsivr, subtopicname, subtopic_name1, pushsamivr } = this.state

        let tag1 = ""

        let district_name1 = []

        if (this.state.pushtype === "Custom") {


            if (district_name === "None") {
                isok = 1
                this.setState({ showerr: true, errorMsg: "Select the District", mysnack: snackeror.myerror })

            }
        }

        if (filter_type === 0) {
            if (cropname === "None") {
                isok = 1
                this.setState({ showerr: true, errorMsg: "Select the Crops", mysnack: snackeror.myerror })

            }
        }
        if (filter_type === 1) {
            if (!liveStockname.length) {
                isok = 1
                this.setState({ showerr: true, errorMsg: "Select the Live Stock Name", mysnack: snackeror.myerror })

            }
        }
        if (filter_type === 1) {
            if (!liveStockname.length) {
                isok = 1
                this.setState({ showerr: true, errorMsg: "Select the Fisher Type", mysnack: snackeror.myerror })

            }
        }


        if (!main_topname.length) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Select Tag 1: Main topic", mysnack: snackeror.myerror })

        }

        if (this.state.pushtype !== "Custom") {
            if (dialects === "None") {
                isok = 1
                this.setState({ showerr: true, errorMsg: "Select dialects ", mysnack: snackeror.myerror })

            }
        }

        if (summary === "None") {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter the Call title/summary", mysnack: snackeror.myerror })

        }

        console.log('shdate===', shtime)


        if (!validator.isDate(shdate)) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Select the Shudel Start Date", mysnack: snackeror.myerror })
        }

        if (!validator.isDate(shtime)) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Select the Shudel Start Time", mysnack: snackeror.myerror })
        }

        if (isok == 0) {
            main_topname.map((option) =>
                tag1 = tag1 + option.label + ","

            )

            let tag2 = ""
            if (subtopicname === "None") {
                tag2 = "None"
            }
            if (subtopicname !== "None") {


                for (var key in subtopicname) {

                    tag2 = tag2 + subtopicname[key]['label'] + ","


                }
                tag2 = tag2.substring(0, tag2.length - 1);

            }

            let tag3 = ""
            if (subtopic_name1 === "None") {
                tag3 = "None"
            }
            if (subtopic_name1 !== "None") {
                for (var key in subtopic_name1) {
                    console.log(subtopic_name1[key])
                    tag3 = tag3 + subtopic_name1[key]['label'] + ","


                }
                tag3 = tag3.substring(0, tag3.length - 1);
            }


            tag1 = tag1.substring(0, tag1.length - 1);




            let shdate1 = moment(shdate).format("YYYY-MM-DD")
            let shtime1 = moment(shtime).format("HH:MM")


            let payload = {}
            let da = shdate1 + " " + shtime1
            let ivrload = {
                "odia": pushodsivr.value,
                "sambalpuri": pushsamivr.value
            }

            if (this.state.pushtype === "Filtered") {
                payload = {
                    "start_time": da,
                    "summary": summary,
                    "description": description,
                    "filter_parameters": [this.TestFilterData()],

                    "ivr_id": ivrload,

                    "active": "true",
                    "tag1": [tag1],
                    "tag2": [tag2],
                    "tag3": [tag3],

                    "rating": "true",
                    "gateway_id": 1

                }
            }

            if (this.state.pushtype === "Custom") {

                if (district_name === "None") {
                    district_name1 = "None"
                }

                if (district_name !== "None") {
                    for (var key in district_name) {
                        district_name1.push(district_name[key]['value'])
                    }

                }


                let item = {
                    custom: this.state.numberlist,
                    "advisory_type": [advtype],
                    "district": district_name1,

                }
                payload = {
                    "start_time": da,
                    "summary": summary,
                    "description": description,
                    "filter_parameters": [item],

                    "ivr_id": ivrload,

                    "active": "true",
                    "tag1": [tag1],
                    "tag2": [tag2],
                    "tag3": [tag3],

                    "rating": "true",
                    "gateway_id": 1

                }
            }




            console.log(payload)
            this.setState({ islodding: true })
            try {

                const submitdata = await httpRequest({
                    endPoint: `api/lcc/CreateOBDSchedule`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: payload,

                });
                console.log('Filter Resp', submitdata.success)
                this.setState({ islodding: false })
                if (submitdata.success == true) {
                    this.setState({ showerr: true, errorMsg: "OBD PushCall is  Schedule to " + submitdata.calls_count + " farmers", mysnack: snackeror.mysucc })
                    this.setState({
                        cropname: '', subtopicname: '', subtopic_name1: '', subtopicname: '', dialects: '', gender: '', subdistname: '', district_name: '',
                        smartphone: '', profile_source_name1: '', kitchen_garden: '', landtype: '', irrigation: '',
                        pushodsivr: '', pushsamivr: '', main_topname: [0], description: '', summary: '', tabvalue: 1
                    })

                    window.Changemytab('a')


                    try {

                        const submitdata1 = await httpRequest({
                            endPoint: `api/fs/scheduleDeamon/status`,
                            method: "get",
                            instance: "instanceOne",


                        });

                        if (submitdata1.success == true) {


                            try {
                                const submitdata2 = await httpRequest({
                                    endPoint: `api/fs/scheduleDeamon/start`,
                                    method: "get",
                                    instance: "instanceOne",


                                });
                            } catch (error) {

                            }
                        }

                    } catch (error) {

                    }



                }


            } catch (error) {

            }


        }

    }

    GetFisheries = async () => {
        try {
            const roledata1 = await httpRequest({
                endPoint: `/api/lcc/getFisheryTypes`,
                method: "get",
                instance: "instanceOne",


            });



            console.log(roledata1.msg)

            if (roledata1.success == true) {

                console.log(roledata1.msg[0].fishery_types)
                this.setState({ fisherytypes: roledata1.msg[0].fishery_types })


            }
        } catch (error) {

        }
    }

    Getxlsnumber = async (e) => {

        this.setState({ filename: e.target.files[0].name })//e.target.files[0].name,

        const file = e.target.files[0];
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data);
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            range: 1,
            defval: "",
        });



        let nolist = ''
        let a = 0
        const aa = jsonData.map(rname =>


            nolist = nolist + rname[0] + ","

        );
        nolist = nolist.substring(0, nolist.length - 1);

        this.setState({ numbercount: jsonData.length, numberlist: nolist })
        console.log(jsonData);

    }


    Selectalldialects() {
        let dialects = []
        let menuname1 = []
        this.setState({ dialects: [] })
        dialectsopt.map(rname =>

            menuname1.push({
                label: rname.label,
                value: rname.value,

            })


        )
        console.log('menuname1******', menuname1)
        this.setState({ dialects: menuname1 })
    }
    CropSelectAll() {
        let cropname = []
        let menuname1 = []
        this.setState({ cropname: [] })
        this.state.croplist_f.map(rname =>

            menuname1.push({
                label: rname.crop,
                value: rname.crop,

            })


        )
        console.log('menuname1******', menuname1)
        this.setState({ cropname: menuname1 })
    }


    livestockSelectall() {

        let liveStockname = []
        let menuname1 = []
        this.setState({ liveStockname: [] })
        this.state.liveStockTypes.split(',').map((guest, index) => {
            menuname1.push({
                label: guest,
                value: guest,
                key: index
            }
            )
        })

        console.log('menuname1******', menuname1)
        this.setState({ liveStockname: menuname1 })
    }
    fishselectall() {
        let liveStockname = []
        let menuname1 = []
        this.setState({ liveStockname: [] })
        this.state.fisherytypes.split(',').map((guest, index) => {
            menuname1.push({
                label: guest,
                value: guest,
                key: index
            }
            )
        })

        console.log('menuname1******', menuname1)
        this.setState({ liveStockname: menuname1 })
    }
    render() {

        const { audiolist } = this.state
        const { pageno } = this.state
        return (

            <div>
                {
                    pageno == 2 &&
                    <div>

                        {this.state.tabvalue === 0 &&
                            <div>
                                <Grid style={{ marginTop: '1%', width: '95%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', padding: '2%' }}>
                                    <Grid style={{ width: '100%', }}>
                                        <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                            <Grid style={{ width: '20%', alignItems: 'center' }}>

                                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}> Select puschall Type:</Typography>
                                            </Grid>
                                            <Grid style={{ width: '80%', }}>

                                                <Select
                                                    options={pushtypeopt}
                                                    values={this.state.pushtype}

                                                    defaultValue={pushtypeopt[0]}



                                                    onChange={e => {
                                                        this.setState({ pushtype: e.value });


                                                    }}
                                                >
                                                </Select>


                                            </Grid>
                                        </Grid>
                                        <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: '2%' }}>
                                            <Grid style={{ width: '20%', alignItems: 'center' }}>

                                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Select Advisory Type:</Typography>
                                            </Grid>
                                            <Grid style={{ width: '80%', }}>
                                                <Select


                                                    options={advtypeopt}
                                                    defaultValue={advtypeopt[0]}
                                                    onChange={e => {

                                                        this.advisoryTypeChanged(e)

                                                    }}


                                                >
                                                </Select>

                                            </Grid>

                                        </Grid>
                                    </Grid>
                                    {this.state.pushtype === "Custom" &&
                                        <div>
                                            <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '2%', marginTop: '2%' }}>
                                                <Typography style={{ color: '#333333', fontSize: '16px', fontFamily: 'Roboto-Bold' }}>Filter Criteria</Typography>

                                                <Typography style={{ marginLeft: '2%', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>   required fields = </Typography>
                                                <Typography style={{ marginLeft: '5px', color: 'red', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>*</Typography>
                                            </Grid>
                                            <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '2%', marginTop: '2%' }}>

                                                <Typography style={{ marginLeft: '2%', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>DISABLED! Instead, upload a csv file with a column "phone" with the 10-digit phone numbers this push-call will go out to.</Typography>
                                            </Grid>
                                            <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '2%', marginTop: '2%' }}>
                                                <Typography style={{ marginLeft: '2%', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>*Upload CSV-file with one "phone" column:</Typography>
                                            </Grid>
                                            <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '2%', marginTop: '2%' }}>

                                                <input

                                                    name="img3"
                                                    style={{ display: 'none' }}
                                                    id="raised-button-file3"
                                                    multiple
                                                    type="file"
                                                    onClick={e => e.target.value = ''}
                                                    onChange={e => { this.Getxlsnumber(e) }}
                                                />
                                                <label htmlFor="raised-button-file3">
                                                    <Button

                                                        variant="text"
                                                        component="span"
                                                        style={{
                                                            color: "#333333",
                                                            fontFamily: "Roboto-Medium",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#E7EAF0",
                                                            textTransform: 'none',

                                                            fontSize: "14px",
                                                            width: '150px',
                                                            height: '40px',

                                                        }}



                                                    >Choose File
                                                    </Button>
                                                </label>

                                                <Typography style={{ marginLeft: '2%', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>{this.state.filename} {"  "}{this.state.numbercount} {" "}Numbers Found</Typography>
                                            </Grid>

                                            <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '5%' }}>
                                                <Grid style={{ width: '30%', alignItems: 'center' }}>
                                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Districts (leave blank for all):</Typography>
                                                </Grid>
                                                <Grid style={{ width: '40%', marginTop: '2%' }}>
                                                    <Select

                                                        isMulti
                                                        value={this.state.district_name}
                                                        options={
                                                            this.state.distlist.map((guest, index) => {
                                                                return {
                                                                    label: guest.district,
                                                                    value: guest.district,
                                                                    key: index
                                                                }
                                                            })
                                                        }

                                                        onChange={e => {
                                                            this.GetSubDist(e)


                                                        }}
                                                    >
                                                    </Select>

                                                </Grid>
                                            </Grid>



                                        </div>
                                    }


                                    {this.state.pushtype === "Filtered" &&
                                        <div>

                                            <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '2%', marginTop: '2%' }}>
                                                <Typography style={{ color: '#333333', fontSize: '16px', fontFamily: 'Roboto-Bold' }}>Filter Criteria</Typography>

                                                <Typography style={{ marginLeft: '2%', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>   required fields = </Typography>
                                                <Typography style={{ marginLeft: '5px', color: 'red', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>*</Typography>
                                            </Grid>

                                            <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '5%', marginTop: '2%' }}>
                                                <Typography style={{ color: '#333333', fontSize: '16px', fontFamily: 'Roboto-Bold' }}>{this.state.advtype} filters </Typography>
                                            </Grid>

                                            {

                                                this.state.filter_type == 2 &&
                                                <div>



                                                    <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '5%' }}>
                                                        <Grid style={{ width: '30%', display: 'flex', alignItems: 'center' }}>
                                                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Fishery Types</Typography>
                                                            <Typography style={{ marginLeft: '5px', color: 'red', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>*</Typography>
                                                        </Grid>
                                                        <Grid style={{ width: '40%', }}>


                                                            <Select
                                                                values={this.state.liveStockname}
                                                                isMulti
                                                                options={this.state.fisherytypes.split(',').map((guest, index) => {
                                                                    return {
                                                                        label: guest,
                                                                        value: guest,
                                                                        key: index
                                                                    }
                                                                })}


                                                                onChange={e => {
                                                                    this.setState({ liveStockname: e })


                                                                }}
                                                            >
                                                            </Select>

                                                        </Grid>


                                                        <Grid style={{ width: '10%', marginLeft: '3%' }}>
                                                            <Button



                                                                variant="contained"
                                                                size="small"

                                                                style={{
                                                                    color: "#ffffff",
                                                                    fontFamily: "Roboto-Medium",
                                                                    borderRadius: "4px",
                                                                    backgroundColor: "#41AB1C",
                                                                    textTransform: 'none',
                                                                    padding: '1.5%',
                                                                    fontSize: "14px",
                                                                    width: '100px',
                                                                    height: '32px',


                                                                }}

                                                                onClick={() => { this.fishselectall() }}


                                                            >

                                                                Select All
                                                            </Button>
                                                        </Grid>


                                                    </Grid>
                                                </div>
                                            }
                                            {

                                                this.state.filter_type == 1 &&
                                                <div>



                                                    <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '5%' }}>
                                                        <Grid style={{ width: '30%', display: 'flex', alignItems: 'center' }}>
                                                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Livestock Animal Types:</Typography>
                                                            <Typography style={{ marginLeft: '5px', color: 'red', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>*</Typography>
                                                        </Grid>
                                                        <Grid style={{ width: '40%', }}>
                                                            <Select
                                                                value={this.state.liveStockname}
                                                                isMulti
                                                                options={this.state.liveStockTypes.split(',').map((guest, index) => {
                                                                    return {
                                                                        label: guest,
                                                                        value: guest,
                                                                        key: index
                                                                    }
                                                                })}


                                                                onChange={e => {
                                                                    this.setState({ liveStockname: e })


                                                                }}
                                                            >
                                                            </Select>

                                                        </Grid>


                                                        <Grid style={{ width: '10%', marginLeft: '3%' }}>
                                                            <Button



                                                                variant="contained"
                                                                size="small"

                                                                style={{
                                                                    color: "#ffffff",
                                                                    fontFamily: "Roboto-Medium",
                                                                    borderRadius: "4px",
                                                                    backgroundColor: "#41AB1C",
                                                                    textTransform: 'none',
                                                                    padding: '1.5%',
                                                                    fontSize: "14px",
                                                                    width: '100px',
                                                                    height: '32px',


                                                                }}

                                                                onClick={() => { this.livestockSelectall() }}


                                                            >

                                                                Select All
                                                            </Button>
                                                        </Grid>


                                                    </Grid>
                                                </div>
                                            }
                                            {

                                                this.state.filter_type == 0 &&
                                                <div>

                                                    <Grid style={{ width: '100%', display: 'flex', marginTop: '1%', alignItems: 'center', marginLeft: '5%' }}>
                                                        <Grid style={{ width: '30%', }}>
                                                            <FormControlLabel style={{ display: 'flex' }} control={<WhiteBackgroundCheckbox
                                                                checked={this.state.croptype}

                                                                onClick={() => { this.ChangeCrop() }}

                                                            />} label="Kharif (unchecked implies Rabi):" />

                                                        </Grid>


                                                    </Grid>

                                                    <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '5%' }}>
                                                        <Grid style={{ width: '30%', display: 'flex', alignItems: 'center' }}>
                                                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Crops:</Typography>
                                                            <Typography style={{ marginLeft: '5px', color: 'red', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>*</Typography>
                                                        </Grid>
                                                        <Grid style={{ width: '40%', }}>
                                                            <Select
                                                                value={this.state.cropname}
                                                                isMulti
                                                                options={this.state.croplist_f.map((guest, index) => {
                                                                    return {
                                                                        label: guest.crop,
                                                                        value: guest.crop,
                                                                        key: index
                                                                    }
                                                                })}


                                                                onChange={e => {
                                                                    this.setState({ cropname: e })

                                                                }}
                                                            >
                                                            </Select>

                                                        </Grid>


                                                        <Grid style={{ width: '10%', marginLeft: '3%' }}>
                                                            <Button



                                                                variant="contained"
                                                                size="small"

                                                                style={{
                                                                    color: "#ffffff",
                                                                    fontFamily: "Roboto-Medium",
                                                                    borderRadius: "4px",
                                                                    backgroundColor: "#41AB1C",
                                                                    textTransform: 'none',
                                                                    padding: '1.5%',
                                                                    fontSize: "14px",
                                                                    width: '100px',
                                                                    height: '32px',


                                                                }}

                                                                onClick={() => { this.CropSelectAll() }}


                                                            >

                                                                Select All
                                                            </Button>
                                                        </Grid>


                                                    </Grid>

                                                    <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '5%', marginTop: '2%' }}>
                                                        <Grid style={{ width: '30%', alignItems: 'center' }}>
                                                            <Typography style={{ color: '#999999', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Irrigation (leave default for all):</Typography>
                                                        </Grid>
                                                        <Grid style={{ width: '40%', }}>

                                                            <Select


                                                                options={irrigationopt}


                                                                value={this.state.irrigation}
                                                                onChange={e => {
                                                                    this.setState({ irrigation: e });


                                                                }}
                                                            >
                                                            </Select>


                                                        </Grid>
                                                    </Grid>
                                                    <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '5%', marginTop: '1%' }}>
                                                        <Grid style={{ width: '30%', alignItems: 'center' }}>
                                                            <Typography style={{ color: '#999999', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Land Type (leave blank for all):</Typography>
                                                        </Grid>
                                                        <Grid style={{ width: '40%', }}>
                                                            <Select


                                                                options={landtypeopt}

                                                                value={this.state.landtype}

                                                                onChange={e => {
                                                                    this.setState({ landtype: e });


                                                                }}
                                                            >
                                                            </Select>

                                                        </Grid>
                                                    </Grid>

                                                    <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '5%', marginTop: '1%' }}>
                                                        <Grid style={{ width: '30%', alignItems: 'center' }}>
                                                            <Typography style={{ color: '#999999', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Kitchen garden (leave default for all):</Typography>
                                                        </Grid>
                                                        <Grid style={{ width: '40%', }}>
                                                            <Select
                                                                options={kitchenopt}
                                                                value={this.state.kitchen_garden}
                                                                onChange={e => {
                                                                    this.setState({ kitchen_garden: e });
                                                                }}
                                                            >
                                                            </Select>

                                                        </Grid>
                                                    </Grid>

                                                </div>
                                            }

                                            {/* General Farmer filters  */}
                                            <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '2%' }}>
                                                <Typography style={{ color: '#333333', fontSize: '16px', fontFamily: 'Roboto-Medium' }}>General Farmer filters</Typography>
                                            </Grid>

                                            <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '5%' }}>
                                                <Grid style={{ width: '30%', alignItems: 'center', display: 'flex' }}>
                                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Dialects</Typography>
                                                    <Typography style={{ marginLeft: '5px', color: 'red', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>*</Typography>
                                                </Grid>
                                                <Grid style={{ width: '40%', }}>

                                                    <Select

                                                        isMulti
                                                        placeholder="Select dialects"
                                                        options={dialectsopt}
                                                        value={this.state.dialects}


                                                        onChange={e => {
                                                            this.setState({ dialects: e });


                                                        }}
                                                    >
                                                    </Select>


                                                </Grid>
                                                <Grid style={{ width: '10%', marginLeft: '3%' }}>
                                                    <Button



                                                        variant="contained"
                                                        size="small"

                                                        style={{
                                                            color: "#ffffff",
                                                            fontFamily: "Roboto-Medium",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#41AB1C",
                                                            textTransform: 'none',
                                                            padding: '1.5%',
                                                            fontSize: "14px",
                                                            width: '100px',
                                                            height: '32px',


                                                        }}

                                                        onClick={() => { this.Selectalldialects() }}


                                                    >

                                                        Select All
                                                    </Button>
                                                </Grid>


                                            </Grid>

                                            <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '5%' }}>
                                                <Grid style={{ width: '30%', alignItems: 'center' }}>
                                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Districts (leave blank for all):</Typography>
                                                </Grid>
                                                <Grid style={{ width: '40%', marginTop: '2%' }}>
                                                    <Select

                                                        isMulti
                                                        value={this.state.district_name}
                                                        options={
                                                            this.state.distlist.map((guest, index) => {
                                                                return {
                                                                    label: guest.district,
                                                                    value: guest.district,
                                                                    key: index
                                                                }
                                                            })
                                                        }

                                                        onChange={e => {
                                                            this.GetSubDist(e)


                                                        }}
                                                    >
                                                    </Select>

                                                </Grid>
                                            </Grid>
                                            <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '5%', marginTop: '2%' }}>
                                                <Grid style={{ width: '30%', alignItems: 'center' }}>
                                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Sub-districts (leave blank for all, first select districts):</Typography>
                                                </Grid>
                                                <Grid style={{ width: '40%', }}>
                                                    <Select

                                                        isMulti
                                                        value={this.state.subdistname}
                                                        options={
                                                            this.state.subdist.map((guest, index) => {
                                                                return {
                                                                    label: guest.sub_dist,
                                                                    value: guest.sub_dist,
                                                                    key: index
                                                                }
                                                            })
                                                        }

                                                        onChange={e => {
                                                            this.setState({ subdistname: e })


                                                        }}
                                                    >
                                                    </Select>

                                                </Grid>
                                            </Grid>
                                            <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '5%', marginTop: '2%' }}>
                                                <Grid style={{ width: '30%', alignItems: 'center' }}>
                                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Profile Sources (leave blank for all):</Typography>
                                                </Grid>
                                                <Grid style={{ width: '40%', }}>

                                                    <Select

                                                        value={this.state.profile_source_name1}
                                                        options={profile_source_name
                                                        }

                                                        onChange={e => {
                                                            this.setState({ profile_source_name1: e })


                                                        }}
                                                    >
                                                    </Select>


                                                </Grid>
                                            </Grid>
                                            <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '5%', marginTop: '2%' }}>
                                                <Grid style={{ width: '30%', alignItems: 'center', display: 'flex' }}>
                                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Profiling up to date (leave blank for all):</Typography>

                                                </Grid>
                                                <Grid style={{ width: '60%', display: 'flex', alignItems: 'center' }}>

                                                    <ThemeProvider theme={defaultMaterialTheme}>
                                                        <MuiPickersUtilsProvider

                                                            style={{ height: '20px', }} utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                error={false}
                                                                helperText={null}

                                                                value={this.state.profiling_date}
                                                                onChange={date => { this.setState({ profiling_date: date }) }}
                                                                format="yyyy/MM/dd"

                                                                InputProps={{


                                                                    style: {

                                                                        fontSize: 14,
                                                                        height: 38,
                                                                        color: '#999999',
                                                                        width: '150px',
                                                                        background: '#ffffff'

                                                                    }
                                                                }}
                                                                inputVariant="outlined"



                                                                KeyboardButtonProps={{
                                                                    style: { marginLeft: -24 }
                                                                }}

                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </ThemeProvider>
                                                    <Typography style={{ marginLeft: '5%', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}> Time</Typography>
                                                    <ThemeProvider theme={defaultMaterialTheme}>
                                                        <MuiPickersUtilsProvider

                                                            style={{ height: '20px', marginLeft: '2%' }} utils={DateFnsUtils}>
                                                            <KeyboardTimePicker
                                                                ampm={false} // use this
                                                                placeholder="08:00 AM"
                                                                mask="__:__ _M"
                                                                format="HH:mm a"
                                                                error={false}
                                                                helperText={null}
                                                                value={this.state.profiling_time}
                                                                onChange={(time) => this.setState({ profiling_time: time })}
                                                                InputProps={{


                                                                    style: {
                                                                        width: '125px',
                                                                        fontSize: 14,
                                                                        height: 38,
                                                                        color: '#999999',

                                                                        background: '#ffffff'

                                                                    }
                                                                }}
                                                                inputVariant="outlined"



                                                                KeyboardButtonProps={{
                                                                    style: { marginLeft: -24 }
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </ThemeProvider>
                                                </Grid>


                                            </Grid>
                                            <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '5%', marginTop: '2%' }}>
                                                <Grid style={{ width: '30%', alignItems: 'center' }}>
                                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Phone type (leave default for all):</Typography>
                                                </Grid>
                                                <Grid style={{ width: '40%', }}>
                                                    <Select

                                                        value={this.state.smartphone}
                                                        options={phonetypeopt
                                                        }

                                                        onChange={e => {
                                                            this.setState({ smartphone: e })


                                                        }}
                                                    >
                                                    </Select>

                                                </Grid>
                                            </Grid>
                                            <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '5%', marginTop: '2%' }}>
                                                <Grid style={{ width: '30%', alignItems: 'center' }}>
                                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Gender (leave blank for all):</Typography>
                                                </Grid>
                                                <Grid style={{ width: '40%', }}>
                                                    <Select

                                                        value={this.state.gender}
                                                        options={genderopt}

                                                        onChange={e => {
                                                            this.setState({ gender: e })


                                                        }}
                                                    >
                                                    </Select>

                                                </Grid>
                                            </Grid>
                                            <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                                                <Button



                                                    variant="contained"
                                                    size="small"

                                                    style={{
                                                        color: "#ffffff",
                                                        fontFamily: "Roboto-Medium",
                                                        borderRadius: "4px",
                                                        backgroundColor: "#41AB1C",
                                                        textTransform: 'none',
                                                        padding: '1.5%',
                                                        fontSize: "14px",
                                                        width: '200px',
                                                        height: '32px',


                                                    }}

                                                    onClick={() => { this.TestFilter() }}


                                                >

                                                    Test Filter
                                                </Button>
                                            </Grid>
                                        </div>

                                    }
                                    <Grid style={{ width: '100%', display: 'flex', marginTop: '2%', alignItems: 'center' }}>
                                        <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Push-Call Data and Meta-Data:</Typography>
                                    </Grid>
                                    <Grid style={{ width: '100%', display: 'flex', marginTop: '2%', alignItems: 'center', marginLeft: '5%' }}>
                                        <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Audio Input:</Typography>
                                    </Grid>
                                    <Grid style={{ width: '100%', display: 'flex', marginTop: '2%', alignItems: 'center', marginLeft: '5%' }}>
                                        <Grid style={{ width: '30%' }}>
                                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Odia</Typography>
                                        </Grid>
                                        <Grid style={{ width: '50%' }}>


                                            <Select

                                                value={this.state.pushodsivr}
                                                options={
                                                    this.state.ivrlist.map((guest, index) => {
                                                        return {
                                                            label: guest.ivr_name,
                                                            value: guest.uid,
                                                            key: index
                                                        }
                                                    })
                                                }

                                                onChange={e => {
                                                    this.setState({ pushodsivr: e })


                                                }}
                                            >
                                            </Select>
                                        </Grid>
                                    </Grid>
                                    <Grid style={{ width: '100%', display: 'flex', marginTop: '2%', alignItems: 'center', marginLeft: '5%' }}>
                                        <Grid style={{ width: '30%' }}>
                                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Sambhalpuri</Typography>
                                        </Grid>
                                        <Grid style={{ width: '50%' }}>

                                            <Select

                                                value={this.state.pushsamivr}
                                                options={
                                                    this.state.ivrlist.map((guest, index) => {
                                                        return {
                                                            label: guest.ivr_name,
                                                            value: guest.uid,
                                                            key: index
                                                        }
                                                    })
                                                }

                                                onChange={e => {
                                                    this.setState({ pushsamivr: e })


                                                }}
                                            >
                                            </Select>

                                        </Grid>
                                    </Grid>

                                    <Grid style={{ width: '100%', display: 'flex', marginTop: '1%', alignItems: 'center', marginLeft: '5%' }}>
                                        <Grid style={{ width: '30%', }}>
                                            <FormControlLabel style={{ display: 'flex' }} control={<WhiteBackgroundCheckbox />} label="Include default rating template" />

                                        </Grid>


                                    </Grid>
                                    <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '5%' }}>
                                        <Grid style={{ width: '30%', }}>
                                            <FormControlLabel style={{ display: 'flex' }} control={<WhiteBackgroundCheckbox />} label="Save to user history" />

                                        </Grid>


                                    </Grid>
                                    <Grid style={{ width: '100%', display: 'flex', marginTop: '2%', alignItems: 'center', marginLeft: '5%' }}>
                                        <Grid style={{ width: '30%', }}>
                                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}> Retry intervals (hours, comma separated)</Typography>
                                        </Grid>
                                        <Grid style={{ width: '35%', }}>
                                            <CssTextField focusColor="#999999"


                                                fullWidth
                                                inputProps={{
                                                    style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                                }}
                                                size="small"

                                                variant="outlined"

                                                value={this.state.retries}

                                                onChange={e => {

                                                    this.setState({ retries: e.target.value });

                                                }}
                                            />
                                        </Grid>

                                    </Grid>

                                    <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '5%', marginTop: '2%' }}>
                                        <Grid style={{ width: '30%', alignItems: 'center', display: 'flex' }}>
                                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}> Start Date and Time</Typography>
                                            <Typography style={{ marginLeft: '5px', color: 'red', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>*</Typography>

                                        </Grid>
                                        <Grid style={{ width: '60%', display: 'flex', alignItems: 'center' }}>

                                            <ThemeProvider theme={defaultMaterialTheme}>
                                                <MuiPickersUtilsProvider

                                                    style={{ height: '20px', }} utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        error={false}
                                                        helperText={null}

                                                        value={this.state.shdate}
                                                        onChange={date => { this.setState({ shdate: date }) }}
                                                        format="yyyy/MM/dd"

                                                        InputProps={{


                                                            style: {

                                                                fontSize: 14,
                                                                height: 38,
                                                                color: '#999999',
                                                                width: '150px',
                                                                background: '#ffffff'

                                                            }
                                                        }}
                                                        inputVariant="outlined"



                                                        KeyboardButtonProps={{
                                                            style: { marginLeft: -24 }
                                                        }}

                                                    />
                                                </MuiPickersUtilsProvider>
                                            </ThemeProvider>
                                            <Typography style={{ marginLeft: '5%', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}> Time</Typography>
                                            <ThemeProvider theme={defaultMaterialTheme}>
                                                <MuiPickersUtilsProvider

                                                    style={{ height: '20px', marginLeft: '2%' }} utils={DateFnsUtils}>
                                                    <KeyboardTimePicker
                                                        ampm={false} // use this
                                                        placeholder="08:00 AM"
                                                        mask="__:__ _M"
                                                        format="HH:mm a"
                                                        error={false}
                                                        helperText={null}
                                                        value={this.state.shtime}
                                                        onChange={(time) => this.setState({ shtime: time })}
                                                        InputProps={{


                                                            style: {
                                                                width: '125px',
                                                                fontSize: 14,
                                                                height: 38,
                                                                color: '#999999',

                                                                background: '#ffffff'

                                                            }
                                                        }}
                                                        inputVariant="outlined"



                                                        KeyboardButtonProps={{
                                                            style: { marginLeft: -24 }
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </ThemeProvider>
                                        </Grid>
                                    </Grid>



                                    <Grid style={{ width: '100%', display: 'flex', marginTop: '2%', alignItems: 'center', marginLeft: '5%' }}>
                                        <Grid style={{ width: '30%', display: 'flex' }}>
                                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}> Call title/summary:</Typography>
                                            <Typography style={{ marginLeft: '5px', color: 'red', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>*</Typography>
                                        </Grid>
                                        <Grid style={{ width: '35%', }}>
                                            <CssTextField focusColor="#999999"


                                                fullWidth
                                                inputProps={{
                                                    style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                                }}
                                                size="small"

                                                variant="outlined"

                                                value={this.state.summary}

                                                onChange={e => {

                                                    this.setState({ summary: e.target.value });

                                                }}
                                            />
                                        </Grid>

                                    </Grid>

                                    <Grid style={{ width: '100%', display: 'flex', marginTop: '2%', alignItems: 'center', marginLeft: '5%' }}>
                                        <Grid style={{ width: '30%', }}>
                                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Description:</Typography>
                                        </Grid>
                                        <Grid style={{ width: '35%', }}>
                                            <CssTextField focusColor="#999999"


                                                fullWidth
                                                inputProps={{
                                                    style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                                }}
                                                size="small"

                                                variant="outlined"

                                                value={this.state.description}

                                                onChange={e => {

                                                    this.setState({ description: e.target.value });

                                                }}
                                            />
                                        </Grid>

                                    </Grid>



                                    <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '5%', marginTop: '2%' }}>
                                        <Grid style={{ width: '30%', alignItems: 'center', display: 'flex' }}>
                                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Tag 1: Main topic:</Typography>
                                            <Typography style={{ marginLeft: '5px', color: 'red', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>*</Typography>
                                        </Grid>
                                        <Grid style={{ width: '40%', }}>

                                            <Autocomplete
                                                id="grouped-demo"
                                                fullWidth
                                                multiple
                                                options={this.state.main_top}

                                                variant='outlined'
                                                groupBy={(option) => option.grp}
                                                getOptionLabel={(option) => option.label}
                                                onChange={(event, value) => this.setState({ main_topname: value })}

                                                renderInput={(params) => <TextField value={this.state.main_topname}
                                                    variant='outlined' {...params} />}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '5%', marginTop: '2%' }}>
                                        <Grid style={{ width: '30%', alignItems: 'center' }}>
                                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Tag 2: Sub topic:</Typography>
                                        </Grid>
                                        <Grid style={{ width: '40%', }}>

                                            <Select

                                                isMulti
                                                options={this.state.subtopic}
                                                value={this.state.subtopicname}
                                                onChange={e => {
                                                    this.setState({ subtopicname: e })


                                                }}
                                            >
                                            </Select>


                                        </Grid>
                                    </Grid>
                                    <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: '5%', marginTop: '2%' }}>
                                        <Grid style={{ width: '30%', alignItems: 'center' }}>
                                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}>Tag 3: Name of Pest/Input/Actual:</Typography>
                                        </Grid>
                                        <Grid style={{ width: '40%', }}>
                                            <Select

                                                isMulti
                                                options={this.state.subtopic1}
                                                value={this.state.subtopic_name1}
                                                onChange={e => {
                                                    this.setState({ subtopic_name1: e })


                                                }}
                                            >
                                            </Select>

                                        </Grid>
                                    </Grid>


                                    <Grid style={{ width: '100%', display: 'flex', marginTop: '2%', alignItems: 'center', justifyContent: 'flex-end' }}>





                                        <Button



                                            variant="contained"
                                            size="small"

                                            style={{
                                                color: "#ffffff",
                                                fontFamily: "Roboto-Medium",
                                                borderRadius: "4px",
                                                backgroundColor: "#41AB1C",
                                                textTransform: 'none',
                                                padding: '1.5%',
                                                fontSize: "14px",
                                                width: '150px',
                                                height: '32px',
                                                marginLeft: '3%'

                                            }}

                                            onClick={() => { this.pushcallsubmit() }}


                                        >

                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid >
                            </div >
                        }
                        {
                            this.state.tabvalue === 10 &&
                            <div>
                                <Grid style={{ marginTop: '1%', width: '95%', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', padding: '2%' }}>
                                    <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                        <Grid style={{ width: '10%', alignItems: 'center' }}>

                                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}> Message</Typography>
                                        </Grid>
                                        <Grid style={{ width: '90%', }}>

                                            <Select value={this.state.groupivrname}
                                                displayEmpty
                                                variant="outlined"
                                                fullWidth
                                                size="small"

                                                placeholder='Select'
                                                style={{ height: '35px', }}
                                                inputProps={{
                                                    style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                                }}

                                                onChange={e => {
                                                    this.setState({ groupivrname: e.target.value });
                                                    //this.handleDomain(e)

                                                }}

                                            >
                                                {this.state.ivrlist.map(rname =>

                                                    <option style={{ padding: '5px', color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }} value={rname.uid} label={rname.ivr_name}>{rname.ivr_name}</option>
                                                )};



                                            </Select>
                                        </Grid>

                                    </Grid>

                                    <Grid style={{ width: '100%', display: 'flex', marginTop: '2%', alignItems: 'center' }}>
                                        <Grid style={{ width: '10%', }}>

                                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}> Group</Typography>
                                        </Grid>
                                        <Grid style={{ width: '75%', }}>

                                            <Select value={this.state.groupuid}
                                                displayEmpty
                                                variant="outlined"
                                                fullWidth
                                                size="small"

                                                placeholder='Select'
                                                style={{ height: '35px', }}
                                                inputProps={{
                                                    style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                                }}

                                                onChange={e => {
                                                    this.setState({ groupuid: e.target.value });


                                                }}

                                            >
                                                <MenuItem value={'0'}>G 1</MenuItem>
                                                <MenuItem value={'1'}>G 2</MenuItem>


                                            </Select>
                                        </Grid>
                                        <Grid style={{ width: '20%', display: 'flex', justifyContent: 'flex-end' }}>

                                            <Button



                                                variant="contained"
                                                size="small"

                                                style={{
                                                    color: "#ffffff",
                                                    fontFamily: "Roboto-Medium",
                                                    borderRadius: "4px",
                                                    backgroundColor: "#41AB1C",
                                                    textTransform: 'none',
                                                    padding: '1.5%',
                                                    fontSize: "14px",
                                                    width: '150px',
                                                    height: '32px',
                                                    marginLeft: '3%'

                                                }}

                                                onClick={() => { this.setState({ addgroup: true }) }}


                                            >

                                                Add Group
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    <Grid style={{ width: '100%', display: 'flex', marginTop: '2%', alignItems: 'center' }}>
                                        <Grid style={{ width: '10%', }}>

                                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}> Date</Typography>
                                        </Grid>
                                        <Grid style={{ width: '30%', }}>

                                            <ThemeProvider theme={defaultMaterialTheme}>
                                                <MuiPickersUtilsProvider

                                                    style={{ height: '20px', }} utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        error={false}
                                                        helperText={null}

                                                        value={this.state.groupdate}
                                                        onChange={date => { this.setState({ groupdate: date }) }}
                                                        format="yyyy/MM/dd"

                                                        InputProps={{


                                                            style: {

                                                                fontSize: 14,
                                                                height: 38,
                                                                color: '#999999',

                                                                background: '#ffffff'

                                                            }
                                                        }}
                                                        inputVariant="outlined"



                                                        KeyboardButtonProps={{
                                                            style: { marginLeft: -24 }
                                                        }}

                                                    />
                                                </MuiPickersUtilsProvider>
                                            </ThemeProvider>
                                        </Grid>
                                        <Grid style={{ width: '5%', }}>

                                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}> Time</Typography>
                                        </Grid>
                                        <Grid style={{ width: '20%', display: 'flex', justifyContent: 'flex-end' }}>
                                            <ThemeProvider theme={defaultMaterialTheme}>
                                                <MuiPickersUtilsProvider

                                                    style={{ height: '20px', }} utils={DateFnsUtils}>
                                                    <KeyboardTimePicker
                                                        ampm={false} // use this
                                                        placeholder="08:00 AM"
                                                        mask="__:__ _M"
                                                        format="HH:mm a"
                                                        error={false}
                                                        helperText={null}
                                                        value={this.state.grouptime}
                                                        onChange={(time) => this.setState({ grouptime: time })}
                                                        InputProps={{


                                                            style: {

                                                                fontSize: 14,
                                                                height: 38,
                                                                color: '#999999',

                                                                background: '#ffffff'

                                                            }
                                                        }}
                                                        inputVariant="outlined"



                                                        KeyboardButtonProps={{
                                                            style: { marginLeft: -24 }
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </ThemeProvider>

                                        </Grid>
                                        <Grid style={{ width: '20%', marginLeft: '5%' }}>

                                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}> (UTC+0530)</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid style={{ width: '100%', display: 'flex', marginTop: '2%', alignItems: 'center' }}>
                                        <Grid style={{ width: '30%', }}>
                                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}> Retry intervals (hours, comma separated)</Typography>
                                        </Grid>
                                        <Grid style={{ width: '35%', }}>
                                            <CssTextField focusColor="#999999"


                                                fullWidth
                                                inputProps={{
                                                    style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                                }}
                                                size="small"

                                                variant="outlined"

                                                value={this.state.groupretry}

                                                onChange={e => {

                                                    this.setState({ groupretry: e.target.value });

                                                }}
                                            />
                                        </Grid>

                                    </Grid>
                                    <Grid style={{ width: '100%', display: 'flex', marginTop: '2%', alignItems: 'center' }}>
                                        <Grid style={{ width: '30%', }}>
                                            <FormControlLabel style={{ display: 'flex' }} control={<WhiteBackgroundCheckbox

                                                onChange={e => {

                                                    this.setState({ grouptemp: !this.state.grouptemp });

                                                }}

                                            />} label="Include default rating template" />

                                        </Grid>


                                    </Grid>
                                    <Grid style={{ width: '100%', display: 'flex', marginTop: '2%', alignItems: 'center' }}>
                                        <Grid style={{ width: '30%', }}>
                                            <FormControlLabel style={{ display: 'flex' }} control={<WhiteBackgroundCheckbox
                                                onChange={e => {

                                                    this.setState({ groupsavehist: !this.state.groupsavehist });

                                                }}


                                            />} label="Save to user history" />

                                        </Grid>


                                    </Grid>
                                    <Grid style={{ width: '100%', display: 'flex', marginTop: '2%', alignItems: 'center', justifyContent: 'flex-end' }}>
                                        <Button



                                            variant="contained"
                                            size="small"

                                            style={{
                                                color: "#ffffff",
                                                fontFamily: "Roboto-Medium",
                                                borderRadius: "4px",
                                                backgroundColor: "#41AB1C",
                                                textTransform: 'none',
                                                padding: '1.5%',
                                                fontSize: "14px",
                                                width: '150px',
                                                height: '32px',
                                                marginLeft: '3%'

                                            }}

                                            onClick={() => { this.SaveGroupPush() }}


                                        >

                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                    </div >
                }

                {
                    pageno == 1 &&
                    <div>

                        <Grid style={{ width: '100%', alignItems: 'center', background: '#F7F9FA' }}>
                            <Grid style={{ width: '100%', marginTop: '1%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                <Grid style={{ width: '23%' }}>
                                    <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>IVR Name</Typography>
                                </Grid>
                                <Grid style={{ width: '23%' }}>
                                    <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>Description</Typography>
                                </Grid>
                                <Grid style={{ width: '23%' }}>
                                    <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>Language</Typography>
                                </Grid>
                                <Grid style={{ width: '23%' }}>
                                    <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>Config</Typography>
                                </Grid>
                            </Grid>

                            <Grid style={{ width: '100%', marginTop: '1%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>

                                <Grid style={{ width: '23%' }}>
                                    <CssTextField focusColor="#999999"


                                        fullWidth
                                        inputProps={{
                                            style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                        }}
                                        size="small"

                                        variant="outlined"
                                        placeholder="Enter IVR Name"
                                        value={this.state.ivrname}

                                        onChange={e => {

                                            this.setState({ ivrname: e.target.value });

                                        }}
                                    />
                                </Grid>
                                <Grid style={{ width: '23%' }}>
                                    <CssTextField focusColor="#999999"


                                        fullWidth
                                        inputProps={{
                                            style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                        }}
                                        size="small"

                                        variant="outlined"
                                        placeholder="Enter IVR Description"
                                        value={this.state.ivrdesp}

                                        onChange={e => {

                                            this.setState({ ivrdesp: e.target.value });

                                        }}
                                    />
                                </Grid>

                                <Grid style={{ width: '23%' }}>
                                    <Select value={this.state.lang}
                                        displayEmpty
                                        variant="outlined"
                                        fullWidth
                                        size="small"

                                        placeholder='Select11'
                                        style={{ height: '41px', }}
                                        inputProps={{
                                            style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                        }}

                                        onChange={e => {
                                            this.setState({ lang: e.target.value, domain: e.target.lable });
                                            //this.handleDomain(e)

                                        }}

                                    >
                                        <MenuItem value={'Odia'}>Odia</MenuItem>
                                        <MenuItem value={'Sambalpuri'}>Sambalpuri</MenuItem>



                                    </Select>
                                </Grid>
                                <Grid style={{ width: '23%' }}>
                                    <Select
                                        value={this.state.ivrconfig_uid}
                                        displayEmpty
                                        variant="outlined"
                                        fullWidth
                                        size="small"

                                        placeholder='Select11'
                                        style={{ height: '41px', }}
                                        inputProps={{
                                            style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                        }}

                                        onChange={e => {
                                            this.setState({ ivrconfig_uid: e.target.value, domain: e.target.lable });
                                            //this.handleDomain(e)

                                        }}

                                    > <MenuItem value={'0'}>Select</MenuItem>
                                        {this.state.ivrconfiglist.map(rname =>

                                            <option style={{ padding: '5px', color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }} value={rname.uid} label={rname.name}>{rname.name}</option>
                                        )};


                                    </Select>
                                </Grid>
                            </Grid>

                            <Grid style={{ width: '100%', marginLeft: '2%', marginTop: '2%', display: 'flex' }}>
                                <Grid style={{ width: '12%' }}>
                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>IVR Menu Setting</Typography>
                                </Grid>
                                <Grid style={{ width: '85%', borderBottom: '1px solid rgba(153, 153, 153, 0.5)' }}></Grid>

                            </Grid>

                            <Grid style={{ width: '100%' }}>





                                <Grid style={{ width: '95%', marginLeft: '2%', marginTop: '.75%', borderRadius: '4px' }}>
                                    <Grid style={{ width: '100%', display: 'flex' }}>
                                        <Grid style={{ width: '9%' }}>
                                            <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>Welcome audio</Typography>
                                        </Grid>

                                    </Grid>



                                    <Grid style={{ width: '90%', marginTop: '1%', display: 'flex', alignItems: 'center' }}>
                                        <Select value={this.state.welconeaudiouid}
                                            displayEmpty
                                            variant="outlined"
                                            fullWidth
                                            size="small"

                                            placeholder='Select11'
                                            style={{ height: '30px', }}
                                            inputProps={{
                                                style: { fontSize: '12px', fontFamily: "Roboto-Regular" },


                                            }}

                                            onChange={e => {
                                                this.setState({ welconeaudiouid: e.target.value });
                                                //this.handleDomain(e)

                                            }}

                                        >
                                            <MenuItem value={'0'}>Select</MenuItem>
                                            {audiolist.map(rname =>

                                                <option style={{ padding: '5px', color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }} value={rname.audio_name} label={rname.audio_name}>{rname.audio_name}</option>
                                            )};



                                        </Select>
                                        <input
                                            accept="audio/mp3"
                                            name="welcomeaudio"
                                            style={{ display: 'none' }}
                                            id="welcomeaudio"
                                            multiple
                                            type="file"
                                            onClick={e => e.target.value = ''}
                                            onChange={e => { this.fileSelectHandlerwelcome(e) }}
                                        />
                                        <label htmlFor="welcomeaudio">
                                            <Button

                                                variant="text"
                                                component="span"
                                                style={{
                                                    color: "#7DAB1C",
                                                    fontFamily: "Roboto-Medium",
                                                    borderRadius: "4px",
                                                    backgroundColor: "#ffffff",
                                                    textTransform: 'none',
                                                    border: '1px solid #7DAB1C',
                                                    marginLeft: '5px',
                                                    fontSize: "12px",

                                                    height: '30px',

                                                }}



                                            >+
                                            </Button>
                                        </label>
                                        <IconButton
                                            style={{
                                                width: '50px',
                                                height: '30px', borderRadius: "4px", marginLeft: '1px', fontSize: '10px'
                                            }}

                                        //onClick={() => this.EditDomain()}
                                        >
                                            <PlayArrow style={{
                                                color: '#92A4C1', fontSize: '20px'
                                            }} />
                                        </IconButton>


                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid style={{ width: '100%', marginTop: '2%', }}>
                            <Grid style={{ width: '98%', borderRadius: '4px', border: '1px solid rgba(153, 153, 153, 0.5)' }}>

                                <Grid style={{ width: '100%', display: 'flex', marginLeft: '2%', marginTop: '2%', alignItems: 'center' }}>


                                    <Grid style={{ width: '15%', }}>
                                        <Select value={this.state.ivrkey}
                                            displayEmpty
                                            variant="outlined"
                                            fullWidth
                                            size="small"

                                            placeholder='Select11'
                                            style={{ height: '30px', }}
                                            inputProps={{
                                                style: { fontSize: '12px', fontFamily: "Roboto-Regular" },


                                            }}

                                            onChange={e => {
                                                this.setState({ ivrkey: e.target.value });
                                                //this.handleDomain(e)

                                            }}

                                        >

                                            {keylist.map(rname =>

                                                <option style={{ padding: '5px', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }} value={rname} label={rname}>{rname}</option>
                                            )};



                                        </Select>
                                    </Grid>
                                    <Grid style={{ width: '75%', display: 'flex', marginLeft: '2%', alignItems: 'center' }}>



                                        <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                            <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>Audio File</Typography>
                                            <Select value={this.state.mainaudiouid}
                                                displayEmpty
                                                variant="outlined"

                                                size="small"

                                                placeholder='Select11'
                                                style={{ height: '30px', marginLeft: '5px', width: '75%' }}
                                                inputProps={{
                                                    style: { fontSize: '12px', fontFamily: "Roboto-Regular" },


                                                }}

                                                onChange={e => {
                                                    this.setState({ mainaudiouid: e.target.value });
                                                    //this.handleDomain(e)

                                                }}

                                            >
                                                <MenuItem value={'0'}>Select</MenuItem>
                                                {audiolist.map(rname =>

                                                    <option style={{ padding: '5px', color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }} value={rname.audio_name} label={rname.audio_name}>{rname.audio_name}</option>
                                                )};



                                            </Select>
                                            <input
                                                accept="audio/mp3"
                                                name="mainaudio"
                                                style={{ display: 'none' }}
                                                id="mainaudio"
                                                multiple
                                                type="file"
                                                onClick={e => e.target.value = ''}
                                                onChange={e => { this.fileSelectHandlermain(e) }}
                                            />
                                            <label htmlFor="mainaudio">
                                                <Button

                                                    variant="text"
                                                    component="span"
                                                    style={{
                                                        color: "#7DAB1C",
                                                        fontFamily: "Roboto-Medium",
                                                        borderRadius: "4px",
                                                        backgroundColor: "#ffffff",
                                                        textTransform: 'none',
                                                        border: '1px solid #7DAB1C',

                                                        fontSize: "12px",
                                                        marginLeft: '5px',
                                                        height: '30px',

                                                    }}



                                                >+
                                                </Button>
                                            </label>
                                            <IconButton
                                                style={{
                                                    width: '50px',
                                                    height: '30px', borderRadius: "4px", marginLeft: '1px', fontSize: '10px'
                                                }}

                                            //onClick={() => this.EditDomain()}
                                            >
                                                <PlayArrow style={{
                                                    color: '#92A4C1', fontSize: '20px'
                                                }} />
                                            </IconButton>


                                        </Grid>

                                    </Grid>

                                    <Grid style={{ width: '10%', display: 'flex', justifyContent: 'flex-end', marginRight: '2%' }}>

                                        <Button

                                            variant="text"
                                            component="span"
                                            style={{
                                                color: "#ffffff",
                                                fontFamily: "Roboto-Medium",
                                                borderRadius: "4px",
                                                backgroundColor: "#41AB1C",
                                                textTransform: 'none',
                                                marginRight: '15px',

                                                fontSize: "12px",
                                                width: '150px',
                                                height: '30px',

                                            }}
                                            onClick={e => {

                                                this.AddChild()

                                            }}


                                        >+  Add Child
                                        </Button>

                                    </Grid>
                                </Grid>
                                <Grid style={{ width: '100%', marginLeft: '2%', display: 'flex', alignItems: 'center', marginTop: '1%' }}>
                                    <RadioGroup
                                        row
                                        defaultValue="Internal"
                                        value={this.state.ivrtype}


                                        onChange={e =>
                                            this.handelIVRType(e)


                                        }
                                    >
                                        <FormControlLabel value="Playback" control={<Radioheckbox size='small' style={{ fontSize: '21px', }} />} label={<Typography style={{ color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Playback</Typography>} />
                                        <FormControlLabel value="Domain_change" control={<Radioheckbox size='small' style={{}} />} label={<Typography style={{ color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Domain  change</Typography>} />
                                        <FormControlLabel value="IVR" control={<Radioheckbox size='small' style={{}} />} label={<Typography style={{ color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>IVR</Typography>} />







                                    </RadioGroup>
                                </Grid>
                                <Grid style={{ width: '100%', marginLeft: '2%', display: 'flex', alignItems: 'center', marginTop: '0.5%', marginBottom: '2%' }}>

                                    {
                                        this.state.ivrtype === "Playback" &&
                                        <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                            <Grid style={{ width: '6%' }}>
                                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>Play Back</Typography>
                                            </Grid>
                                            <Grid style={{ width: '90%', display: 'flex', alignItems: 'center', marginLeft: '2%' }}>
                                                <Select value={this.state.audionameuid}
                                                    displayEmpty
                                                    variant="outlined"

                                                    size="small"

                                                    placeholder='Select11'
                                                    style={{ height: '30px', marginLeft: '5px', width: '75%' }}
                                                    inputProps={{
                                                        style: { fontSize: '12px', fontFamily: "Roboto-Regular" },


                                                    }}

                                                    onChange={e => {
                                                        this.setState({ audionameuid: e.target.value });
                                                        //this.handleDomain(e)

                                                    }}

                                                >
                                                    <MenuItem value={'0'}>Select</MenuItem>
                                                    {audiolist.map(rname =>

                                                        <option style={{ padding: '5px', color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }} value={rname.audio_name} label={rname.audio_name}>{rname.audio_name}</option>
                                                    )};



                                                </Select>
                                                <input
                                                    accept="audio/mp3"
                                                    name="g9"
                                                    style={{ display: 'none' }}
                                                    id="g9"
                                                    multiple
                                                    type="file"
                                                    onClick={e => e.target.value = ''}
                                                    onChange={e => { this.fileSelectHandler(e) }}
                                                />
                                                <label htmlFor="g9">
                                                    <Button

                                                        variant="text"
                                                        component="span"
                                                        style={{
                                                            color: "#7DAB1C",
                                                            fontFamily: "Roboto-Medium",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#ffffff",
                                                            textTransform: 'none',
                                                            border: '1px solid #7DAB1C',

                                                            fontSize: "12px",
                                                            marginLeft: '5px',
                                                            height: '30px',

                                                        }}



                                                    >+
                                                    </Button>
                                                </label>
                                                <IconButton
                                                    style={{
                                                        width: '50px',
                                                        height: '30px', borderRadius: "4px", marginLeft: '1px', fontSize: '10px'
                                                    }}

                                                //onClick={() => this.EditDomain()}
                                                >
                                                    <PlayArrow style={{
                                                        color: '#92A4C1', fontSize: '20px'
                                                    }} />
                                                </IconButton>


                                                <FormGroup>
                                                    <FormControlLabel style={{ color: '#999999', fontSize: '10px', fontFamily: 'Roboto-Medium' }}
                                                        control={<MySwitch
                                                            checked={this.state.reqexit}
                                                            inputProps={{ color: 'yellow' }}



                                                            onClick={() => this.setState({ reqexit: !this.state.reqexit })}
                                                        />} label="Exit" />

                                                </FormGroup>

                                            </Grid>
                                        </Grid>

                                    }

                                    {
                                        this.state.ivrtype === "Domain_change" &&
                                        <Grid style={{ width: '95%', display: 'flex', alignItems: 'center' }}>
                                            <Grid style={{ width: '10%' }}>
                                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>Select Domain</Typography>
                                            </Grid>
                                            <Grid style={{ width: '20%', display: 'flex', alignItems: 'center', marginLeft: '2%' }}>
                                                <Select value={this.state.domainuid}
                                                    displayEmpty
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"

                                                    placeholder='Select11'
                                                    style={{ height: '30px', }}
                                                    inputProps={{
                                                        style: { fontSize: '12px', fontFamily: "Roboto-Regular" },


                                                    }}

                                                    onChange={e => {
                                                        this.setState({ domainuid: e.target.value });
                                                        //this.handleDomain(e)

                                                    }}

                                                >
                                                    <MenuItem value={'0'}>Select</MenuItem>
                                                    {this.state.domaindata.map(rname =>

                                                        <option style={{ padding: '5px', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }} value={rname.name}>{rname.name}</option>
                                                    )};



                                                </Select>
                                            </Grid>
                                        </Grid>

                                    }

                                    {
                                        this.state.ivrtype === "IVR" &&
                                        <Grid style={{ width: '95%', display: 'flex', alignItems: 'center' }}>
                                            <Grid style={{ width: '10%' }}>
                                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Medium' }}>Select IVR</Typography>
                                            </Grid>
                                            <Grid style={{ width: '20%', display: 'flex', alignItems: 'center', marginLeft: '2%' }}>
                                                <Select value={this.state.ivruid}
                                                    displayEmpty
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"

                                                    placeholder='Select11'
                                                    style={{ height: '30px', }}
                                                    inputProps={{
                                                        style: { fontSize: '12px', fontFamily: "Roboto-Regular" },


                                                    }}

                                                    onChange={e => {
                                                        this.setState({ ivruid: e.target.value, ivrname1: e.target.label });


                                                    }}

                                                >
                                                    <MenuItem value={'0'}>Select</MenuItem>
                                                    {this.state.ivrlist.map(rname =>

                                                        <option style={{ padding: '5px', color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }} value={rname.uid + "," + rname.ivr_name} name={rname.ivr_name}>{rname.ivr_name}</option>
                                                    )};



                                                </Select>
                                            </Grid>
                                        </Grid>

                                    }
                                </Grid>

                            </Grid>
                        </Grid>





                        <Grid style={{ width: '95%', display: 'flex', justifyContent: 'center' }}>
                            <Grid style={{ width: '100%' }}>
                                <Grid style={{ marginTop: '1%', width: '100%', background: '#E9F4FF', padding: '1%', display: 'flex', borderBottom: '1px solid #999999' }}>
                                    <Grid style={{ width: '15%', marginLeft: '1%' }}>
                                        <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Key</Typography>
                                    </Grid>
                                    <Grid style={{ width: '15%' }}>
                                        <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Type</Typography>
                                    </Grid>
                                    <Grid style={{ width: '60%' }}>
                                        <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Audio File</Typography>
                                    </Grid>
                                    <Grid style={{ width: '10%' }}>

                                    </Grid>

                                </Grid>

                                {this.state.ivrconfiglist1.map((label, index) => {

                                    return (
                                        <div>
                                            <Grid style={{ width: '100%', display: 'flex', borderBottom: '1px solid #999999', background: '#ffffff', alignItems: 'center' }}>
                                                <Grid style={{ width: '15%', marginLeft: '1%' }}>
                                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>{label.key}</Typography>
                                                </Grid>
                                                <Grid style={{ width: '15%' }}>
                                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>{label.keytype}</Typography>
                                                </Grid>
                                                <Grid style={{ width: '60%' }}>
                                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>{label.mainaudioname}</Typography>
                                                </Grid>

                                                <Grid style={{ width: '10%' }}>
                                                    <IconButton


                                                        onClick={() => this.PalyAudio(label, index)}
                                                    >
                                                        <PlayArrow style={{ color: '#92A4C1', fontSize: '20px' }} />
                                                    </IconButton>
                                                    <IconButton


                                                        onClick={() => this.DeleteAudio(label, index)}
                                                    >
                                                        <DeleteOutlineSharp style={{ color: 'red', fontSize: '18px' }} />
                                                    </IconButton>

                                                </Grid>
                                            </Grid>
                                        </div>
                                    )
                                })}

                            </Grid>
                        </Grid>

                        <Grid style={{ width: '95%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '2%' }}>



                            <Button



                                variant="contained"
                                size="small"

                                style={{
                                    color: "#ffffff",
                                    fontFamily: "Roboto-Medium",
                                    borderRadius: "4px",
                                    backgroundColor: "#41AB1C",
                                    textTransform: 'none',
                                    padding: '1.5%',
                                    fontSize: "14px",
                                    width: '150px',
                                    height: '32px',
                                    marginLeft: '3%'

                                }}

                                onClick={() => { this.SaveMenu() }}


                            >

                                Save
                            </Button>
                        </Grid>
                    </div>
                }

                {
                    pageno == 0 &&
                    <Grid style={{ width: '100%', padding: '1%', justifyContent: 'center' }}>


                        <Grid style={{ width: '95%', display: 'flex', border: '1px solid #DFE0EB', background: '#ffffff', borderRadius: '8px', padding: '2%' }}>
                            <Grid style={{ width: '75%', }}>
                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>{this.state.numofrows} IVR</Typography>
                            </Grid>
                            <Grid style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>

                                <Button



                                    variant="contained"
                                    size="small"

                                    style={{
                                        color: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        borderRadius: "4px",
                                        backgroundColor: "#41AB1C",
                                        textTransform: 'none',
                                        padding: '1.5%',
                                        fontSize: "14px",
                                        width: '150px',
                                        height: '32px',
                                        marginLeft: '5%'

                                    }}

                                    onClick={() => { this.setState({ pageno: 1, ivrconfiglist1: [] }) }}


                                >

                                    Add Menu
                                </Button>


                            </Grid>

                        </Grid>

                    </Grid>
                }

                <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',
                            display: 'flex',
                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >
                        <Loader type="spinner-circle" bgColor={"#7DAB1C"} color={'#ffc31b'} size={75} />
                    </Box>

                </Modal>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "Roboto-Bold"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
                <Modal
                    sx={{ border: 'none' }}




                    open={this.state.addgroup}





                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '45%',
                            height: 'auto',
                            background: '#fffbee',
                            outline: 'none',
                            p: 4,
                            borderRadius: '12px',
                            padding: '2%'
                        }}
                    >

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Grid style={{ width: '90%', display: 'flex', justifyContent: 'center' }}>
                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Create Group</Typography>
                            </Grid>
                            <Grid style={{ width: '10%', display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton
                                    aria-label="close"

                                    onClick={() => this.setState({ addgroup: false })}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Grid style={{ width: '90%', display: 'flex', alignItems: 'center' }}>
                                <Grid style={{ width: '18%' }}>
                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Group Name</Typography>
                                </Grid>
                                <Grid style={{ width: '70%' }}>
                                    <CssTextField focusColor="#999999"
                                        autoFocus
                                        fullWidth
                                        inputProps={{
                                            style: { fontSize: '14px', fontFamily: "Roboto-Regular", background: '#ffffff', height: '20px' },


                                        }}
                                        size="small"

                                        variant="outlined"
                                        placeholder="Enter Name"
                                        value={this.state.gname}

                                        onChange={e => {

                                            this.setState({ gname: e.target.value });

                                        }}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid style={{ marginTop: '1%', width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Grid style={{ width: '90%', display: 'flex', alignItems: 'center' }}>
                                <Grid style={{ width: '60%' }}>
                                    <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Group file (CSV or XLS with "phone" column)</Typography>
                                </Grid>
                                <Grid style={{ width: '29%', display: 'flex', justifyContent: 'center' }}>
                                    <input

                                        name="img3"
                                        style={{ display: 'none' }}
                                        id="raised-button-file3"
                                        multiple
                                        type="file"
                                        onClick={e => e.target.value = ''}
                                        onChange={e => { this.Getxlsnumber(e) }}
                                    />
                                    <label htmlFor="raised-button-file3">
                                        <Button

                                            variant="text"
                                            component="span"
                                            style={{
                                                color: "#333333",
                                                fontFamily: "Roboto-Medium",
                                                borderRadius: "4px",
                                                backgroundColor: "#E7EAF0",
                                                textTransform: 'none',

                                                fontSize: "14px",
                                                width: '150px',
                                                height: '40px',

                                            }}



                                        >Upload XLS
                                        </Button>
                                    </label>

                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', marginTop: '2%' }}>
                            <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>{this.state.numbercount} Found</Typography>
                        </Grid>

                        <Grid style={{ width: '100%', display: 'flex', marginTop: '2%' }}>
                            <Grid style={{ width: '90%', display: 'flex', justifyContent: 'flex-end' }}>

                                <Button



                                    variant="contained"
                                    size="small"

                                    style={{
                                        color: "#ffffff",
                                        fontFamily: "Roboto-Medium",
                                        borderRadius: "4px",
                                        backgroundColor: "#7DAB1C",
                                        textTransform: 'none',
                                        padding: '1.5%',
                                        fontSize: "14px",
                                        width: '100px',
                                        height: '32px',
                                        marginLeft: '5%'

                                    }}

                                    onClick={(e) => { this.SaveGroup(e) }}


                                >

                                    Save
                                </Button>

                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            </div >
        )

    }
}
export default (withStyles(txtstyles)((ivrshudel)));