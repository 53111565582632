import { Typography, Grid, IconButton, Box, Modal, Button } from '@material-ui/core'

import React, { Component } from 'react'
import MoreHoriz from '@material-ui/icons/MoreHoriz'
import { PieChart } from 'react-minimal-pie-chart';
import moment from 'moment'
import { httpRequest } from '../../../ui-utils/api'
import { createTheme, ThemeProvider, MuiThemeProvider } from "@material-ui/core/styles";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import Loader from "react-js-loader";
function drawPieBorder(chart) {
    var svg = chart.getElementsByTagName("svg")[0];
    var path = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "circle"
    );
    path.setAttribute("stroke", "#999999");
    path.setAttribute("stroke-width", .15);
    path.setAttribute("fill", "transparent");
    path.setAttribute("cx", 50);
    path.setAttribute("cy", 50);
    path.setAttribute("r", 46);
    svg.prepend(path);
}
const data = [
    { title: 'One', value: 0, color: '#D69C47' },
    { title: 'Two', value: 0, color: '#FFFFFF' },


]
const data1 = [
    { title: 'One', value: 0, color: '#E6A09B' },
    { title: 'Two', value: 0, color: '#D69C47' },
    { title: 'Three', value: 0, color: '#C6D592' },


]
let janus_server = ""
let janus_proxy_url = ""


const defaultMaterialTheme = createTheme({
    palette: {
        primary: {
            light: '#7DAB1C',
            main: '#7DAB1C',
            dark: '#7DAB1C',
            contrastText: '#fff',

        },
        secondary: {
            light: '#7DAB1C',
            main: '#7DAB1C',
            dark: '#7DAB1C',
            contrastText: '#000',
        },
    },
});
let agentid, password, agentname, liginresp, user_domain, user_domain_id
const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))
export default class dashbord extends Component {
    constructor(props) {
        super(props)


        this.state = {


            totaluser: 0,
            totalans: 0,
            totalideal: 0,
            totdur: 0,
            totalmissed: 0,
            chatdata: data1,
            chatdatain: data,
            totincall: 0,
            totinavgtime: 0,

            totoutbound: 0,
            totoutboundavb: 0,
            tot1: 0,
            tot2: 0,
            islodding: false,
            totaloutans: 0,
            totaloutmissed: 0,
            totoutdur: 0,
            totoutcall: 0,
            totoutavgtime: 0,
            totincallper: 0,
            totoutcallper: 0,
            totnonworkcall: 0,
            outchartdate: [],
            avgouttime: 0,
            tdate: moment(new Date()).format("YYYY-MM-DD"),
            totavgoutcall: 0,
            totavgincall: 0

        }

        console.log(props)
        agentname = props.data.logindata.user_data.user_name
        liginresp = props.data.logindata
        user_domain = props.data.logindata.user_data.user_domain
        this.state.user_domain1 = props.data.logindata.user_data.user_domain
        agentid = props.data.logindata.user_data.user_id
        user_domain_id = props.data.logindata.user_data.user_domain_id



    }

    JanusSIPRegister() {

        let a = document.getElementById("remote-stream-audio")
        janus_server = liginresp.user_data.fs_sip_websocket_proxy_sec_url.split(':')[0] + ':' + liginresp.user_data.fs_sip_websocket_proxy_sec_url.split(':')[1] + ':8989/janus'
        janus_proxy_url = 'sip:' + liginresp.user_data.fs_sip_outbound_proxy_url.split('://')[1]

        /* janus_server = "wss://crm-stg.amakrushi.in:8989/janus"*/


        console.log('janus_server', janus_server)
        console.log('janus_proxy_url', janus_proxy_url)

        window.janus_sip.initAndLogin({
            server: janus_server,
            proxy: janus_proxy_url,
            name: liginresp.user_data.fs_sip_display_name,
            exten: liginresp.user_data.fs_sip_impi,
            password: liginresp.user_data.fs_sip_cred,
            audioId: "remote-stream-audio",
            localVideoId: 'local-stream-video',
            remoteVideoId: 'remote-stream-video',
            iceServers: [{ urls: "stun:stun.l.google.com:19302" }]
        });
    }

    secondsToHms(seconds) {
        var date = new Date(null);
        date.setSeconds(seconds);
        return date.toISOString().substr(11, 8);
    }
    /*secondsToHms(d) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + (h == 1 ? ":" : ":") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? ":" : ":") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? "" : "") : "";
        return hDisplay + mDisplay + sDisplay;
    }*/
    componentDidMount = async () => {




        window.JanusEventforDashBoard = async (result, msg) => {
        }
        this.JanusSIPRegister()
        this.NewDash(new Date())
        drawPieBorder(document.getElementById("piechart"));
        drawPieBorder(document.getElementById("piechart1"));

    }

    date_diff_indays(date1, date2) {
        let dt1 = new Date(date1);
        let dt2 = new Date(date2);
        return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
    }


    Attregen = async () => {
        let da = moment(this.state.tdate).format("YYYY-MM-DD")


        let sql = "SELECT distinct aid   from  pad_agent  where  (todate is null or date(todate)>='" + da + "') and  status='Enabled' order by aid";
        console.log("tot user", sql)
        const item_user = {
            "stmt": sql

        }
        let rdata = ''
        try {
            const roledata = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: item_user,

            });


            if (roledata.success == true) {
                rdata = roledata.msg









            }
        } catch (error) {

        }
        let a = 0
        for (let i = 0; i <= rdata.length - 1; i++) {

            a = a + 1
            if (a === 5) {
                await sleep(10000);
                a = 0
            }

            console.log(rdata[i].aid)

            try {
                const roledata = await httpRequest({
                    endPoint: 'api/users/regen_att_log/' + da + '/' + rdata[i].aid,
                    method: "get",
                    instance: "instanceOne",


                });
            } catch (error) {

            }

        }



    }
    NewDash = async (tdate) => {

        let da = moment(tdate).format("YYYY-MM-DD")

        let da1 = moment(tdate).format("YYYY-MM-DD")

        let da2 = moment(tdate).format("YYYY-MM-DD") + " " + "00:00:00"
        let da3 = moment(tdate).format("YYYY-MM-DD") + " " + "08:59:00"

        let da4 = moment(tdate).format("YYYY-MM-DD")

        let outcount = 0
        let incount = 0

        let outcountlive = 0
        let incountlive = 0
        let basedate = new Date("2023-06-07");
        let basedate1 = new Date(tdate);

        let sysdate = new Date();
        let diff = this.date_diff_indays(basedate, basedate1)
        //let diff = basedate.getDate() - basedate1.getDate()
        let diff1 = sysdate.getDate() - basedate1.getDate()

        let ldate = "-"

        /* if (diff1 !== 0) {
             ldate = da
         }
         try {
             const usernewdata = await httpRequest({
                 endPoint: `api/users/LiveStatus/` + ldate,
                 method: "get",
                 instance: "instanceOne",
     
     
             });
             if (usernewdata.success === true) {
                 console.log('usernewdata==', usernewdata.msg.domains)
                 for (var key in usernewdata.msg.domains) {
     
                     if (key === "lcc-default" || key === "agriculture" || key === "animal_husbandry" || key === "fishery") {
     
                         incount = parseInt(incount) + parseInt(usernewdata.msg.domains[key]['total'])
                         incountlive = parseInt(incountlive) + parseInt(usernewdata.msg.domains[key]['live'])
     
     
                     }
     
                     if (key === "Outbound") {
     
                         outcount = parseInt(outcount) + parseInt(usernewdata.msg.domains[key]['total'])
                         outcountlive = parseInt(outcountlive) + parseInt(usernewdata.msg.domains[key]['live'])
                     }
     
     
     
     
                 }
     
                 this.setState({ totaluser: incountlive, totalideal: incount, totoutbound: outcount, totoutboundavb: outcountlive })
             }
         } catch (error) {
     
         }*/

        let sql = ""
        this.setState({ islodding: true })
        sql = "SELECT distinct pad_agent_activate.aid,pad_agent.domain  from pad_agent_activate join pad_agent on pad_agent_activate.aid=pad_agent.aid where  date(date)='" + da + "' and (todate is null or date(todate)>='" + da + "') "
        console.log("tot user", sql)
        const item_user = {
            "stmt": sql

        }

        try {
            const roledata = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: item_user,

            });


            if (roledata.success == true) {
                let rdata = roledata.msg

                for (var i = 0; i < rdata.length; i++) {
                    if (rdata[i].domain == "Outbound") {
                        outcount = outcount + 1
                    }
                    if (rdata[i].domain == "lcc-default") {
                        incount = incount + 1
                    }
                    if (rdata[i].domain == "agriculture") {
                        incount = incount + 1
                    }
                    if (rdata[i].domain == "fishery") {
                        incount = incount + 1
                    }
                    if (rdata[i].domain == "animal_husbandry") {
                        incount = incount + 1
                    }


                }

                this.setState({ totaluser: incount, totoutboundavb: outcount })

            }
        } catch (error) {

        }
        sql = "select aid from pad_agent where domain='Outbound' and  (todate is null or date(todate)>='" + da + "')"

        const itemout = {
            "stmt": sql

        }

        try {
            const roledata = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: itemout,

            });


            if (roledata.success == true) {




                this.setState({ totoutbound: roledata.msg.length })
            }
        } catch (error) {

        }
        //Avable

        sql = "select aid from pad_agent where (domain='fishery' or  domain='animal_husbandry' or domain='agriculture' or domain='lcc-default') and status='Enabled'"

        const itemidel = {
            "stmt": sql

        }

        try {
            const roledata = await httpRequest({
                endPoint: `api/db/stmt`,
                method: "post",
                instance: "instanceOne",
                requestBody: itemidel,

            });


            if (roledata.success == true) {

                this.setState({ totalideal: roledata.msg.length })

                let tot2 = this.state.totoutbound + roledata.msg.length
                let tot1 = this.state.totoutboundavb + this.state.totaluser

                this.setState({ tot2: tot2, tot1: tot1 })
            }
        } catch (error) {



        }
        const item = {
            "datetime_from": da + " 00:00:00",
            "datetime_to": da + " 23:59:59",
            "workinghrs_from": "09:00:00",
            "workinghrs_to": "20:00:00",
            "data": false,
            "field_last_event": false,
            "pre_fill_directions": true,
            "direction": [
                "Inbound",
                "Outbound",
                "short_call"
            ],
            "domains": [
                "6742621800",
                "6742611800",
                "9000000000",
                "1000000000",
                "2000000000",
                "3000000000"
            ]
        }
        let totincall = 0
        let totalans = 0
        let totalmissed = 0
        let totdur = 0
        let totnonworkcall = 0
        let avgtime = 0
        let totoutcall = 0
        let totoutdur = 0
        let pieval3 = 0
        let totaloutans = 0
        try {

            const data_workinghrs = await httpRequest({
                endPoint: `api/lcc/reports/RawDump`,
                method: "post",
                instance: "instanceOne",
                requestBody: item,

            });







            for (var key in data_workinghrs.msg.Inbound.workinghrs.domains) {


                totincall = parseInt(totincall) + parseInt(data_workinghrs.msg.Inbound.workinghrs.domains[key]['total'])
                totalans = parseInt(totalans) + parseInt(data_workinghrs.msg.Inbound.workinghrs.domains[key]['answered'])
                totalmissed = parseInt(totalmissed) + parseInt(data_workinghrs.msg.Inbound.workinghrs.domains[key]['missed'])
                totdur = parseInt(totdur) + parseInt(data_workinghrs.msg.Inbound.workinghrs.domains[key]['duration_sec'])


            }

            console.log('*********************--------------------')
            if (diff >= 0) {
                console.log('1111111111111111')
                for (var key in data_workinghrs.msg.Outbound.workinghrs.domains) {


                    totoutcall = parseInt(totoutcall) + parseInt(data_workinghrs.msg.Outbound.workinghrs.domains[key]['total'])
                    totaloutans = parseInt(totaloutans) + parseInt(data_workinghrs.msg.Outbound.workinghrs.domains[key]['answered'])



                    totoutdur = parseInt(totoutdur) + parseInt(data_workinghrs.msg.Outbound.workinghrs.domains[key]['duration_sec'])


                }
                totoutdur = (parseInt(totoutdur) / 60).toFixed(0)
                this.setState({ totoutcall: totoutcall, totaloutans: totaloutans, totoutdur: totoutdur })
            }

            console.log('222222222222')
            totnonworkcall = parseInt(data_workinghrs.msg.short_call.non_workinghrs.total) + parseInt(data_workinghrs.msg.Inbound.non_workinghrs.total)
            console.log('*********==', totnonworkcall)
            console.log(totincall)

            totdur = (parseInt(totdur) / 60).toFixed(0)

            this.setState({ totalmissed: totalmissed, totdur: totdur, totalans: totalans, totincall: totincall, totnonworkcall: totnonworkcall })



            avgtime = (totdur / totalans) * 60
            avgtime = avgtime.toFixed(0)
            this.setState({ totinavgtime: avgtime })
            let b = (totalans / this.state.totaluser) * avgtime
            b = b / 60
            b = b.toFixed(0)
            this.setState({ totavgincall: b })

            console.log('*********333333')
            let gtotcall = totincall + totnonworkcall
            let pieval1 = ((100 * totalans) / gtotcall).toFixed(0)
            let avgouttime

            avgouttime = (totoutdur / totaloutans) * 60
            avgouttime = avgouttime.toFixed(0)

            this.setState({ totoutavgtime: avgouttime })

            let a = 0
            console.log('3333333333333333')
            this.setState({ totavgoutcall: 0 })
            if (this.state.totoutboundavb > 0) {

                let a = (totaloutans / this.state.totoutboundavb) * avgouttime
                a = a / 60
                a = a.toFixed(0)
                this.setState({ totavgoutcall: a })
            }





            let pieval2 = ((100 * totalmissed) / gtotcall).toFixed(0)


            let pieval3 = 100 - (parseInt(pieval1) + parseInt(pieval2))

            const newState = this.state.chatdata.map(obj => {

                if (obj.title === "One") {

                    return { ...obj, value: parseInt(pieval1) };
                }
                if (obj.title === "Two") {

                    return { ...obj, value: parseInt(pieval2) };
                }
                if (obj.title === "Three") {

                    return { ...obj, value: parseInt(pieval3) };
                }

                return obj;
            });

            this.setState({ chatdata: newState })

        } catch (error) {

        }
        console.log('************#################-----------------')
        pieval3 = ((100 * totaloutans) / totoutcall).toFixed(2)
        let pieval44 = this.state.totnonworkcall
        this.setState({ totaloutmissed: totoutcall - totaloutans })
        let pieval4 = ((100 * (totoutcall - totaloutans)) / totoutcall).toFixed(2)

        const newState = this.state.chatdatain.map(obj => {

            if (obj.title === "One") {

                return { ...obj, value: parseInt(pieval3) };
            }
            if (obj.title === "Two") {

                return { ...obj, value: parseInt(pieval4) };
            }


            return obj;
        });
        this.setState({ outchartdate: newState })
        this.setState({ islodding: false })

        if (diff < 0) {


            sql = "select uid from fs_cdr where  date(start_stamp)='" + da4 + "' and caller_id_name <>caller_id_number and destination_number like '%+91%'"
            const totoutcallsql = {
                "stmt": sql

            }

            try {
                const roledata = await httpRequest({
                    endPoint: `api/db/stmt`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: totoutcallsql,

                });


                if (roledata.success == true) {

                    this.setState({ totoutcall: roledata.msg.length })
                }
            } catch (error) {

                this.setState({ islodding: false })

            }




            sql = "select count(billsec) as answered,sum(billsec/60)::text as duration from fs_cdr where billsec>0 and hangup_cause='NORMAL_CLEARING' and  caller_id_name <>caller_id_number and   date(start_stamp)='" + da4 + "' and destination_number like '%+91%'"
            console.log(sql)
            let totoutdur = 0
            let totaloutans = 0
            const totaloutans_palload = {
                "stmt": sql

            }

            try {
                const roledata = await httpRequest({
                    endPoint: `api/db/stmt`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: totaloutans_palload,

                });


                if (roledata.success == true) {
                    totoutdur = roledata.msg[0].duration
                    totaloutans = roledata.msg[0].answered
                    this.setState({ totaloutans: roledata.msg[0].answered, totoutdur: totoutdur })

                    pieval3 = ((100 * roledata.msg[0].answered) / this.state.totoutcall).toFixed(0)
                    let pieval44 = this.state.totnonworkcall
                    let pieval4 = this.state.totoutcall - roledata.msg[0].answered
                    alert(pieval4)
                    this.state.totaloutmissed = pieval4
                    const newState = this.state.chatdatain.map(obj => {

                        if (obj.title === "One") {

                            return { ...obj, value: parseInt(pieval3) };
                        }
                        if (obj.title === "Two") {

                            return { ...obj, value: parseInt(pieval4) };
                        }


                        return obj;
                    });
                    let avgouttime
                    avgouttime = (totoutdur / totaloutans) * 60
                    avgouttime = avgouttime.toFixed(0)


                    this.setState({ outchartdate: newState })

                    this.setState({ totoutavgtime: avgouttime })
                    let b = (totalans / this.state.totaluser) * avgtime
                    b = b / 60
                    b = b.toFixed(0)
                    this.setState({ totavgincall: b })


                    let a = (totaloutans / this.state.totoutboundavb) * avgouttime
                    a = a / 60
                    a = a.toFixed(0)

                    this.setState({ totavgoutcall: a })



                }
            } catch (error) {
                this.setState({ islodding: false })
            }

        }

    }
    handledobChange(date) {

        this.setState({ tdate: date })

        this.NewDash(moment(date))

    }
    render() {

        return (
            <div>

                <Grid style={{ width: '100%', marginTop: '1%', display: 'flex', alignItems: 'center', }}>

                    <Grid style={{ width: '60%', display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Medium' }}>Daily Activities Dashboard</Typography>

                        <Grid style={{ marginLeft: '25px', width: '175px' }}>
                            <ThemeProvider theme={defaultMaterialTheme}>
                                <MuiPickersUtilsProvider

                                    style={{ height: '20px' }} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        error={false}
                                        helperText={null}

                                        value={this.state.tdate}
                                        onChange={date => { this.handledobChange(date) }}
                                        format="yyyy/MM/dd"

                                        InputProps={{


                                            style: {

                                                fontSize: 14,
                                                height: 38,
                                                color: '#999999',

                                                background: '#ffffff'

                                            }
                                        }}
                                        inputVariant="outlined"



                                        KeyboardButtonProps={{
                                            style: { marginLeft: -24 }
                                        }}

                                    />
                                </MuiPickersUtilsProvider>
                            </ThemeProvider>
                        </Grid>

                        { /*  <Button


                            onClick={() => { this.Attregen() }}
                                    >Regen</Button>*/}


                    </Grid>
                    <Grid style={{ width: '40%', alignItems: 'center', }}>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Grid style={{ width: '20%', display: 'flex', justifyContent: 'flex-end' }}>
                                <Typography style={{ color: '#000000', fontSize: '12px', fontFamily: 'Roboto-Bold', marginLeft: '10px' }}>Timings</Typography>
                            </Grid>
                            <Grid style={{ width: '60%', display: 'flex' }}>
                                <Typography style={{ color: '#000000', fontSize: '12px', fontFamily: 'Roboto-Regular', marginLeft: '10px' }}>InBound:{" "} 9:00 AM-8:00 PM</Typography>
                                <Typography style={{ color: '#000000', fontSize: '12px', fontFamily: 'Roboto-Regular', marginLeft: '10px' }}>(All Days)</Typography>
                            </Grid>

                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Grid style={{ width: '20%', display: 'flex', justifyContent: 'flex-end' }}>

                            </Grid>
                            <Grid style={{ width: '60%', display: 'flex' }}>
                                <Typography style={{ color: '#000000', fontSize: '12px', fontFamily: 'Roboto-Regular', marginLeft: '10px' }}>OutBound:{" "} 9:30 AM-5:30 PM</Typography>

                            </Grid>

                        </Grid>

                    </Grid>
                </Grid>
                <Grid style={{ width: '100%', marginTop: '1%', display: 'flex', justifyContent: 'space-evenly' }}>
                    <Grid style={{ width: '45%', padding: '2%', paddingBottom: '0%', border: '1px solid #E7EAF0', background: '#ffffff', borderRadius: '10px' }}>
                        <Grid style={{ width: '100%', display: 'flex' }}>
                            <Grid style={{ width: '95%', display: 'flex' }}>
                                <Typography style={{ color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}>Number of Agents Logged in Today</Typography>
                            </Grid>
                            <Grid style={{ width: '5%', display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton
                                    size="medium"

                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <MoreHoriz fontSize="36px" style={{ color: '#231F20' }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', borderBottom: '1px solid #D1D9E2', padding: '2%' }}>
                            <Grid style={{ width: '40%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }}></Typography>
                            </Grid>
                            <Grid style={{ width: '20%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Available</Typography>
                            </Grid>
                            <Grid style={{ width: '30%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Total Agents</Typography>
                            </Grid>

                        </Grid>

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', borderBottom: '1px solid #D1D9E2', padding: '1%' }}>
                            <Grid style={{ width: '40%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Inbound </Typography>
                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular', marginLeft: '3px' }}>(incl. experts)</Typography>
                            </Grid>
                            <Grid style={{ width: '20%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>{this.state.totaluser}</Typography>
                            </Grid>
                            <Grid style={{ width: '30%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>{this.state.totalideal}</Typography>
                            </Grid>

                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', borderBottom: '1px solid #D1D9E2', padding: '1%' }}>
                            <Grid style={{ width: '40%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Outbound</Typography>
                            </Grid>
                            <Grid style={{ width: '20%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>{this.state.totoutboundavb}</Typography>
                            </Grid>
                            <Grid style={{ width: '30%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>{this.state.totoutbound}</Typography>
                            </Grid>

                        </Grid>

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', borderBottom: '1px solid #D1D9E2', padding: '1%' }}>
                            <Grid style={{ width: '40%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Total</Typography>
                            </Grid>
                            <Grid style={{ width: '20%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>{this.state.tot1}</Typography>
                            </Grid>
                            <Grid style={{ width: '30%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>{this.state.tot2}</Typography>
                            </Grid>

                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', padding: '1%' }}>
                            <Grid style={{ width: '100%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Regular' }}> ** {" "} {this.state.tot2 - this.state.tot1} agents are on roster and leave</Typography>
                            </Grid>

                        </Grid>

                    </Grid>
                    <Grid style={{ width: '45%', padding: '2%', border: '1px solid #E7EAF0', borderRadius: '10px', background: '#ffffff' }}>

                        <Grid style={{ width: '100%', display: 'flex' }}>
                            <Grid style={{ width: '95%', display: 'flex' }}>
                                <Typography style={{ color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}>Number of Calls(Working Hours) </Typography>
                            </Grid>
                            <Grid style={{ width: '5%', display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton
                                    size="medium"

                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <MoreHoriz fontSize="36px" style={{ color: '#231F20' }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', borderBottom: '1px solid #D1D9E2', padding: '2%' }}>
                            <Grid style={{ width: '25%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }}></Typography>
                            </Grid>
                            <Grid style={{ width: '25%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Answered</Typography>
                            </Grid>
                            <Grid style={{ width: '25%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Missed</Typography>
                            </Grid>
                            <Grid style={{ width: '25%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Duration(min)</Typography>
                            </Grid>


                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', borderBottom: '1px solid #D1D9E2', padding: '1%' }}>
                            <Grid style={{ width: '25%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Inbound</Typography>
                            </Grid>
                            <Grid style={{ width: '25%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>{this.state.totalans === null ? this.state.totalans : this.state.totalans.toLocaleString('en-IN')}</Typography>
                            </Grid>
                            <Grid style={{ width: '25%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>{this.state.totalmissed === null ? this.state.totalmissed : this.state.totalmissed.toLocaleString('en-IN')}</Typography>
                            </Grid>
                            <Grid style={{ width: '25%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>{this.state.totdur === null ? this.state.totdur : this.state.totdur.toLocaleString('en-IN')}</Typography>
                            </Grid>


                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', borderBottom: '1px solid #D1D9E2', padding: '2%' }}>
                            <Grid style={{ width: '25%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }}></Typography>
                            </Grid>
                            <Grid style={{ width: '25%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Dialled</Typography>
                            </Grid>
                            <Grid style={{ width: '25%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Answered</Typography>
                            </Grid>
                            <Grid style={{ width: '25%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Bold' }}>Duration(min)</Typography>
                            </Grid>


                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', borderBottom: '1px solid #D1D9E2', padding: '1%' }}>
                            <Grid style={{ width: '25%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '14px', fontFamily: 'Roboto-Bold' }}>Outbound</Typography>
                            </Grid>
                            <Grid style={{ width: '25%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>{this.state.totoutcall === null ? this.state.totoutcall : this.state.totoutcall.toLocaleString('en-IN')}</Typography>
                            </Grid>
                            <Grid style={{ width: '25%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>{this.state.totaloutans === null ? this.state.totaloutans : this.state.totaloutans.toLocaleString('en-IN')}</Typography>
                            </Grid>
                            <Grid style={{ width: '25%', display: 'flex' }}>
                                <Typography style={{ color: '#333333', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>{Number(this.state.totoutdur).toLocaleString('en-IN')}</Typography>
                            </Grid>


                        </Grid>
                    </Grid>
                </Grid>


                <Grid style={{ width: '100%', marginTop: '1%', display: 'flex', justifyContent: 'space-between' }}>
                    <Grid style={{ width: '29.5%', padding: '2%', border: '1px solid #E7EAF0', borderRadius: '10px', background: '#ffffff' }}>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}> Inbound Call Status Summary</Typography>
                        </Grid>


                        <Grid id="piechart1" style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                            <PieChart
                                style={{ height: '200px' }}
                                animate="true"
                                animationDuration={500}
                                animationEasing="ease-in"
                                data={this.state.chatdata}
                                label={(props) => { return props.dataEntry.value + "%"; }}
                                labelPosition={50}
                                lengthAngle={360}

                                paddingAngle={0}

                                radius={46}
                                startAngle={100}
                                viewBoxSize={[100, 100]}


                                labelStyle={{
                                    fontSize: "7px",
                                    color: "red",

                                }}
                            />
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>

                            <Grid style={{ width: '33%', display: 'flex' }}>
                                <Grid style={{ width: '12%', borderRadius: '4px', background: '#E6A09B', paddingLeft: '1px', height: '3vh', marginTop: '15px' }}></Grid>
                                <Grid style={{ width: '88%', justifyContent: 'flex-start' }}>
                                    <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '30px', fontFamily: 'Roboto-Bold' }}>{this.state.totalans}</Typography>
                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Accepted</Typography>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '33%', display: 'flex' }}>
                                <Grid style={{ width: '12%', borderRadius: '4px', background: '#D69C47', paddingLeft: '1px', height: '3vh', marginTop: '15px' }}></Grid>
                                <Grid style={{ width: '88%', justifyContent: 'flex-start' }}>
                                    <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '30px', fontFamily: 'Roboto-Bold' }}>{this.state.totalmissed}</Typography>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Missed</Typography>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '33%', display: 'flex' }}>
                                <Grid style={{ width: '12%', borderRadius: '4px', background: '#C6D592', paddingLeft: '1px', height: '3vh', marginTop: '15px' }}></Grid>
                                <Grid style={{ width: '88%', justifyContent: 'flex-start' }}>
                                    <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '30px', fontFamily: 'Roboto-Bold' }}>{this.state.totnonworkcall}</Typography>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Non working</Typography>
                                </Grid>
                            </Grid>




                        </Grid>
                    </Grid>
                    <Grid style={{ width: '29.5%', }}>
                        <Grid style={{ border: '1px solid #E7EAF0', background: '#ffffff', borderRadius: '10px', padding: '4% 8%' }}>
                            <Grid style={{ width: '100%', display: 'flex', }}>
                                <Typography style={{ color: '#000000', fontSize: '18px', fontFamily: 'Roboto-Bold' }}>Incoming Call Summary</Typography>
                            </Grid>
                            <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>

                                <Grid style={{ width: '50%', }}>
                                    <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}>{this.state.totalans.toLocaleString()}</Typography>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Number of Calls</Typography>
                                </Grid>
                                <Grid style={{ width: '50%', }}>
                                    <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}>{this.state.totinavgtime}</Typography>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Avg time per call(Sec)</Typography>
                                </Grid>
                                {/*  <Grid style={{ width: '50%', }}>
                                    <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}>{this.state.totincallper}</Typography>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Calls received per hour </Typography>
                                </Grid>
                                */}

                            </Grid>


                        </Grid>
                        <Grid style={{ border: '1px solid #E7EAF0', background: '#ffffff', borderRadius: '10px', padding: '4% 8%', marginTop: '3%' }}>
                            <Grid style={{ width: '100%', display: 'flex', }}>
                                <Typography style={{ color: '#000000', fontSize: '18px', fontFamily: 'Roboto-Bold' }}>Outgoing Call Summary</Typography>
                            </Grid>
                            <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>

                                <Grid style={{ width: '50%', }}>
                                    <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}>{this.state.totaloutans.toLocaleString()}</Typography>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Total # of Calls Answered</Typography>
                                </Grid>
                                <Grid style={{ width: '50%', }}>
                                    <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}>{this.state.totoutavgtime.toLocaleString()}</Typography>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Avg time per call(Sec)</Typography>
                                </Grid>
                                {/*<Grid style={{ width: '50%', }}>
                                    <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}>{this.state.totoutcallper}</Typography>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Calls dialled per hour </Typography>
                            </Grid>*/}

                            </Grid>


                        </Grid>
                        <Grid style={{ border: '1px solid #E7EAF0', background: '#ffffff', borderRadius: '10px', padding: '4% 8%', marginTop: '3%' }}>
                            <Grid style={{ width: '100%', display: 'flex', }}>
                                <Typography style={{ color: '#000000', fontSize: '16px', fontFamily: 'Roboto-Bold' }}>Average time spent by agent per day</Typography>
                            </Grid>
                            <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>

                                <Grid style={{ width: '50%' }}>

                                    <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}>{this.state.totavgincall.toLocaleString()}</Typography>
                                        <Typography style={{ marginTop: '5px', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular', marginLeft: '5px' }}>{" "} mins</Typography>
                                    </Grid>
                                    <Grid style={{ width: '100%' }}>
                                        <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Inbound</Typography>
                                    </Grid>
                                </Grid>
                                <Grid style={{ width: '50%', }}>
                                    <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                        <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}>{this.state.totavgoutcall.toLocaleString()}</Typography>
                                        <Typography style={{ marginTop: '5px', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular', marginLeft: '5px' }}>{" "} mins</Typography>

                                    </Grid>
                                    <Grid style={{ width: '100%' }}>
                                        <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>OutBound</Typography>
                                    </Grid>
                                </Grid>


                            </Grid>


                        </Grid>

                    </Grid>
                    <Grid style={{ width: '29.5%', padding: '2%', border: '1px solid #E7EAF0', borderRadius: '10px', background: '#ffffff' }}>

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '20px', fontFamily: 'Roboto-Bold' }}>Outbound Call Status Summary</Typography>

                        </Grid>


                        <Grid id="piechart" style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>

                            <PieChart
                                style={{ height: '200px' }}
                                animate="true"
                                animationDuration={500}
                                animationEasing="ease-in"
                                data={this.state.outchartdate}
                                label={(props) => { return props.dataEntry.value + "%"; }}

                                segmentsStyle={{
                                    border: '4px solid red',
                                }}
                                labelPosition={50}
                                lengthAngle={360}

                                paddingAngle={0}

                                radius={46}
                                startAngle={100}
                                viewBoxSize={[100, 100]}

                                stroke='red'
                                stroke-width='2px'
                                labelStyle={{
                                    fill: '#FFFFFF',
                                    fontSize: '7px'
                                }}
                            />

                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                            <Grid style={{ width: '33%', display: 'flex', }}>

                                <Grid style={{ width: '12%', borderRadius: '4px', background: '#ffffff', paddingLeft: '1px', height: '3vh', marginTop: '15px' }}></Grid>
                                <Grid style={{ width: '88%', justifyContent: 'flex-start' }}>

                                    <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '30px', fontFamily: 'Roboto-Bold' }}>{this.state.totoutcall.toLocaleString()}</Typography>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Dialled</Typography>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '33%', display: 'flex', }}>

                                <Grid style={{ width: '12%', borderRadius: '4px', background: '#D69C47', paddingLeft: '1px', height: '3vh', marginTop: '15px' }}></Grid>
                                <Grid style={{ width: '88%', justifyContent: 'flex-start' }}>

                                    <Typography style={{ textAlign: 'center', color: '#000000', fontSize: '30px', fontFamily: 'Roboto-Bold' }}>{this.state.totaloutans.toLocaleString()}</Typography>

                                    <Typography style={{ textAlign: 'center', color: '#999999', fontSize: '12px', fontFamily: 'Roboto-Regular' }}>Answered</Typography>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
                <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',
                            display: 'flex',
                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >
                        <Loader type="spinner-circle" bgColor={"#7DAB1C"} color={'#ffc31b'} size={75} />
                    </Box>

                </Modal>
            </div>
        )
    }
}
