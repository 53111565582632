let backendHosts = {};
const hostname = window && window.location && window.location.hostname;
console.log('windows log', window.location.origin)
if (
  hostname.includes("rm.amakrushi.in")

) {
  backendHosts.CRM = "https://crm.amakrushi.in/"


}



else {
  backendHosts.CRM = "https://crm-stg.amakrushi.in/"
  backendHosts.CAT = '2'
}



export default backendHosts;
